import { useEffect } from 'react';
import { Form, FormInstance, Input } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import validationRules from 'utils/formValidationRules';

interface IBasicDetails {
    onInputChange: (key: string, value: string | null) => void;
    form: FormInstance;
    orgPropertiesIdForEdit?: number;
    isSubscrActiveOrCreate: boolean;
}

const BasicDetails: React.FC<IBasicDetails> = ({
    form,
    onInputChange,
    orgPropertiesIdForEdit,
    isSubscrActiveOrCreate,
}) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Основные реквизиты</div>

            <MyFormItem
                name='full_name_org'
                label='Полное наименование юридического лица'
                rules={[{ required: true, message: 'Пожалуйста, введите полное наименование юридического лица!' }]}
            >
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('full_name_org', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <MyFormItem
                name='short_name_org'
                label='Сокращённое наименование юридического лица'
                rules={[{ required: true, message: 'Пожалуйста, введите полное наименование юридического лица!' }]}
            >
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('short_name_org', event.target.value)}
                    maxLength={64}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <div className='shortInnersBlock'>
                <MyFormItem
                    name='inn'
                    label='ИНН'
                    rules={[{ required: true, message: 'Пожалуйста, введите ИНН!' }, validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('inn', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='kpp' label='КПП'>
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('kpp', event.target.value)}
                        maxLength={9}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='ogrn'
                    label='ОГРН'
                    rules={[validationRules.digitsOnly, { required: true, message: 'Пожалуйста, введите ОГРНИП!' }]}
                >
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('ogrn', event.target.value)}
                        maxLength={13}
                        disabled={!!orgPropertiesIdForEdit || !isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='okpo' label='ОКПО'>
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('okpo', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='okato' label='ОКАТО'>
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('okato', event.target.value)}
                        maxLength={11}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='okved' label='ОКВЭД'>
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('okved', event.target.value)}
                        maxLength={8}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default BasicDetails;
