import { APIService } from 'connectors/api/rest';
import { IUserData } from 'common/AuthProvider/AuthProvider';
import { ISubscription } from './Subscriptions';
import { IProjectCustomerDetails } from './Organizations';
import { IUserDataPatch } from './Users';

interface ISimpleGetWithPagination {
    limit: number;
    offset: number;
    ordering?: string;
}

export interface IProjects {
    count: number;
    results: IProject[];
}

export interface IPeople {
    count: number;
    results: IPerson[];
}

export interface IPerson {
    id: number;
    last_name: string;
    first_name: string;
    patronymic: string | null;
    email: string;
    photo: string | null;
    projects?: { id: number; name: string; organizer_user: number }[];
    owner?: {
        id: number;
        last_name: string;
        first_name: string;
    };
}

export interface IProject {
    id: number;
    name: string;
    description: string | null;
    working_name: string | null;
    project_type: string | null;
    genre: string | null;
    timing: string | null;
    number_episodes: string | null;
    episode_timing: string | null;
    media_type: string | null;
    color: string | null;
    language: string | null;
    fio_screenwriter: string | null;
    fio_director: string | null;
    organizer_user: number;
    organizer_organization: {
        id: number;
        name: string;
    } | null;
    participants: IParticipant[];
    subscriptions: ISubscription[];
    created_at: string;
    updated_at: string;
    created_by: number;
    updated_by: number;
    owner: IUserData;
    ends_at: string;
}

export const isProject = (p: unknown): p is IProject =>
    (p as IProject).subscriptions !== undefined &&
    (p as IProject).id !== undefined &&
    // (p as IProject).name !== undefined && вернуть после того, как вернется нейм в апи
    (p as IProject).participants !== undefined;

export const defaultProject: IProject = {
    id: 0,
    name: '',
    description: null,
    working_name: null,
    project_type: null,
    genre: null,
    timing: null,
    number_episodes: null,
    episode_timing: null,
    media_type: null,
    color: null,
    language: null,
    fio_screenwriter: null,
    fio_director: null,
    organizer_user: 0,
    organizer_organization: null,
    participants: [],
    subscriptions: [],
    created_at: '',
    updated_at: '',
    ends_at: '',
    created_by: 0,
    updated_by: 0,
    owner: {
        id: 0,
        last_name: '',
        first_name: '',
    },
};

interface IProjectCharacteristic {
    name: string;
    version_name?: string;
    description?: string;
    working_name?: boolean;
    project_type?: string;
    genre?: any[];
    timing?: string;
    number_episodes?: string | number | null;
    episode_timing?: string;
    media_type?: string[];
    color?: string;
    language?: string;
    writers?: any[];
    directors?: any[];
    parent_characteristic?: string | null;
}

interface ICreateProjectParams {
    product: number;
    product_name: string;
    organizer_organization_id: number;
    project_characteristic: IProjectCharacteristic;
}

type IUpdateProjectParams = Omit<ICreateProjectParams, 'organizer_organization_id' | 'product'>;

export interface IGetProjectsParams {
    limit?: number;
    offset?: number;
    ordering?: '-updated_at';
    search?: string;
}

export interface IParticipant {
    id: number;
    participant: IPerson;
}

export const defaultProjects: IProjects = {
    count: 0,
    results: [],
};

interface IGetPeopleParams {
    limit: number;
    offset: number;
    search: string;
    ordering?: string;
}

export enum InvoiceStatuses {
    Paid = 'PAD',
    Canceled = 'CNL',
    Error = 'ERR',
    Overdue = 'OVD',
    Pending = 'PND',
    Draft = 'DRF',
}

export interface IInvoice {
    id: number;
    subscription: ISubscription;
    created_at: string;
    due_date: unknown;
    amount: string;
    status: string;
    payment_method: unknown;
    payer: number;
}

export interface IPayment {
    id: number;
    sum: string;
    clientid: string;
    orderid: number;
    key: string;
    service_name: string;
    client_email: string;
    client_phone: unknown;
    ps_id: string;
    batch_date: unknown;
    fop_receipt_key: unknown;
    bank_id: unknown;
    card_number: string;
    card_holder: string;
    card_expiry: string;
    invoice: IInvoice;
}

export type IPaymentInvoiceOrderingType = 'starts_at' | 'ends_at';
export type IPaymentInvoiceOrderingWithDirectionType = IPaymentInvoiceOrderingType | `-${IPaymentInvoiceOrderingType}`;
export interface IPaymentInvoiceGetParams {
    ordering?: IPaymentInvoiceOrderingWithDirectionType;
    limit?: number;
    offset?: number;
}

interface ICreateContractParams {
    name: string;
    dateDayTwoDigits: number;
    dateMonthTwoDigits: number;
    dateYearFourDigits: number;
    contract_number: number;
    counterparty: string;
}
interface ISavedContractFreeVars {
    id: number;
    value: string;
    contract_element_var_tag: number;
}
interface ISavedContractQuestionAnswerVars {
    id: number;
    question: number;
    answer: number;
}
export interface IContract {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    contract_date: string;
    contract_number: number;
    counterparty: string;
    version_name: string;
    project: number;
    created_by: IUserDataPatch | null;
    updated_by: IUserDataPatch | null;
    status: 'DN' | 'PGS' | null;
    contract_free_vars: ISavedContractFreeVars[];
    question_answer_vars: ISavedContractQuestionAnswerVars[];
    project_characteristic: IProjectProperties | null;
    organization_characteristic: IProjectCustomerDetails | null;
    contract_template_type: { id: number; name: string } | null;
    raw_text?: string | null;
}

export interface IProjectProperties {
    id: number;
    version_difference?: string[];
    created_at: string;
    updated_at: string;
    version_name: string | null;
    description: string | null;
    working_name: string | null;
    project_type: string | null;
    genre: string | null;
    timing: string | null;
    number_episodes: number | null;
    episode_timing: string | null;
    media_type: string | null;
    color: string | null;
    language: string | null;
    fio_screenwriter: string | null;
    fio_director: string | null;
    project: number;
    parent_characteristic: null;
    created_by: number;
    updated_by: string | null;
}

interface IGetAllOrgCharacteristicsPayload {
    organization_type?: string;
    inn: string;
    name: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export const PersonalAccount = {
    async getProjects(params: IGetProjectsParams) {
        try {
            return await APIService.GET('/lk/project/', params);
        } catch (e) {
            return e;
        }
    },

    async getProject(id: string | number) {
        try {
            return await APIService.GET(`/lk/project/${id}/`);
        } catch (e) {
            return e;
        }
    },

    async createProject(params: ICreateProjectParams) {
        try {
            return await APIService.POST('/lk/project/', params);
        } catch (e) {
            return e;
        }
    },

    async updateProject(id: string | number, params: IUpdateProjectParams) {
        try {
            return await APIService.PATCH(`/lk/project/${id}/`, params);
        } catch (e) {
            return e;
        }
    },

    async updateProjectProperties(
        projectId: string | number,
        propertiesId: string | number,
        params: IProjectCharacteristic,
    ) {
        try {
            return await APIService.PATCH(`/lk/project/${projectId}/characteristics/${propertiesId}/`, params);
        } catch (e) {
            return e;
        }
    },

    async deleteProject(id: string | number) {
        try {
            return await APIService.DELETE(`/lk/project/${id}/`);
        } catch (e) {
            return e;
        }
    },
    async getProjectContracts(projectId: string | number, params: ISimpleGetWithPagination) {
        try {
            return await APIService.GET(`/lk/project/${projectId}/contract/`, params);
        } catch (e) {
            return e;
        }
    },
    async getProjectContract(projectId: string | number, contractId: string | number) {
        try {
            return await APIService.GET(`/lk/project/${projectId}/contract/${contractId}`);
        } catch (e) {
            return e;
        }
    },

    async copyContractToAnotherProject(
        projectId: string | number,
        contractId: string | number,
        toProjectId: string | number,
    ) {
        try {
            return await APIService.POST(`/lk/project/${projectId}/contract/${contractId}/copy/`, {
                copy_to_project: toProjectId,
            });
        } catch (e) {
            return e;
        }
    },

    async createContract(projectId: string | number, params: any) {
        try {
            return await APIService.POST(`/lk/project/${projectId}/contract/`, params);
        } catch (e) {
            return e;
        }
    },
    async updateContract(projectId: string | number, contractId: string | number, params: any) {
        try {
            return await APIService.PUT(`/lk/project/${projectId}/contract/${contractId}`, params);
        } catch (e) {
            return e;
        }
    },
    async deleteContract(projectId: string | number, contractId: string | number) {
        try {
            return await APIService.DELETE(`/lk/project/${projectId}/contract/${contractId}/`);
        } catch (e) {
            return e;
        }
    },

    async addParticipant(id: string | number, email: string) {
        try {
            return await APIService.POST(`/lk/project/${id}/add_participant/`, {
                participant_email: email.toLowerCase(),
            });
        } catch (e) {
            return e;
        }
    },

    async removeParticipant(id: string | number, email: string) {
        try {
            return await APIService.POST(`/lk/project/${id}/remove_participant/`, {
                participant_email: email.toLowerCase(),
            });
        } catch (e) {
            return e;
        }
    },

    async getUnreadMessagesCount() {
        try {
            return await APIService.GET('/lk/message/unread_count/');
        } catch (e) {
            return e;
        }
    },

    async getPayments(params?: IPaymentInvoiceGetParams) {
        try {
            return await APIService.GET('/lk/payments/', params);
        } catch (e) {
            return e;
        }
    },

    async getInvoices(params?: IPaymentInvoiceGetParams) {
        try {
            return await APIService.GET('/lk/invoice/', params);
        } catch (e) {
            return e;
        }
    },

    async getPeople(params?: IGetPeopleParams) {
        try {
            return await APIService.GET('/lk/people/', params);
        } catch (e) {
            return e;
        }
    },

    async getCommonProjects(ids: number[]) {
        const p = new URLSearchParams();

        ids.forEach((t) => p.append('id', t.toString()));

        try {
            return await APIService.GET('/lk/people/common_projects/', p);
        } catch (e) {
            return e;
        }
    },

    async getProjectProperties(projectId: string | number, params?: ISimpleGetWithPagination) {
        try {
            return await APIService.GET(`/lk/project/${projectId}/characteristics/`, params);
        } catch (e) {
            return e;
        }
    },
    async getProjectPropertiesSet(projectId: string | number, propertiesId: string | number) {
        try {
            return await APIService.GET(`/lk/project/${projectId}/characteristics/${propertiesId}/`);
        } catch (e) {
            return e;
        }
    },
    async createProjectPropertiesCopy(
        projectId: string | number,
        characteristicsId: number | string,
        versionName?: string,
    ) {
        try {
            return await APIService.POST(`/lk/project/${projectId}/characteristics/${characteristicsId}/copy/`, {
                version_name: versionName,
            });
        } catch (e) {
            return e;
        }
    },
    async getAllOrgCharacteristics(payload: IGetAllOrgCharacteristicsPayload) {
        try {
            return await APIService.GET(`/lk/organization-characteristics/`, payload);
        } catch (e) {
            return e;
        }
    },
    async getOrgCharacteristicsById(id: string | number) {
        try {
            return await APIService.GET(`/lk/organization-characteristics/${id}/`);
        } catch (e) {
            return e;
        }
    },
};

export default PersonalAccount;
