import clsx from 'clsx';
import { FC } from 'react';

import _ from './Text.module.css';

interface ITextProp {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    style?: React.CSSProperties;
    mb?: number | string;
    mt?: number | string;
    pt?: number | string;
    width?: number | string;
    className?: string;
    white?: boolean;
    white2?: boolean;
    gray?: boolean;
    small?: boolean;
    lightGray?: boolean;
    blue?: boolean;
    mid?: boolean;
    center?: boolean;
    bold?: boolean;
    darkBlue?: boolean;
    ellipsis?: boolean;
    title?: string;
}

const colors = {
    white: '#fff',
    gray: '#63676D',
    lightGray: '#858997',
    default: '#000',
    white2: '#CDD0D8',
    blue: '#5b98fa',
    darkBlue: '#166DF9',
};

const Text: FC<ITextProp> = ({
    children,
    onClick,
    style,
    mb,
    mt,
    pt,
    width,
    className,
    small,
    mid,
    center,
    gray,
    lightGray,
    white,
    white2,
    bold,
    blue,
    darkBlue,
    ellipsis,
    title,
}) => {
    let color = colors.default;
    white && (color = colors.white);
    white2 && (color = colors.white2);
    gray && (color = colors.gray);
    lightGray && (color = colors.lightGray);
    blue && (color = colors.blue);
    darkBlue && (color = colors.darkBlue);

    return (
        <div
            onClick={onClick}
            className={clsx(_.text, small && _.small, mid && _.mid, ellipsis && _.ellipsis, className)}
            style={{
                marginBottom: mb,
                marginTop: mt,
                paddingTop: pt,
                color,
                maxWidth: width,
                ...(center ? { textAlign: 'center' } : {}),
                ...(bold ? { fontWeight: 'bold' } : {}),
                ...style,
            }}
            title={title || ''}
        >
            {children}
        </div>
    );
};

export default Text;
