import { FC, useEffect, useState } from 'react';

import { Subscriptions, IModule } from 'connectors/query/Subscriptions';
import CardStepOne from './components/CardStepOne';

import _ from './StepOne.module.css';

interface IStepOne {
    data: any;
    setData: (i: any) => void;
}

const StepOne: FC<IStepOne> = ({ data, setData }) => {
    const [modules, setModules] = useState<IModule[]>([]);
    const getModules = async () => {
        const res = await Subscriptions.getModules();
        const filteredModules = res.filter((module: IModule) => module.ptype !== 'HTL');
        if (Array.isArray(res)) {
            setModules(filteredModules);
        }
    };

    useEffect(() => {
        getModules();
    }, []);

    return (
        <>
            <div className='title'>Выберите тариф</div>

            <div className={_.cardsBlock}>
                {modules.map((module: IModule) => (
                    <CardStepOne module={module} data={data} setData={setData} key={module.id} />
                ))}
            </div>
        </>
    );
};

export default StepOne;
