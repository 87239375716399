import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { openInfoNotification } from 'common/Notifications/Notifications';
import AuthForm from './components/Form/Form';

const AuthPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { logged } = useContext(AuthContext);

    useEffect(() => {
        if ((state && logged && !state.afterLoginAction) || (!state && logged)) navigate('/cabinet');
        if (state && logged && state.afterLoginAction === 'subsribe_to_practice') navigate('/subsribe-to-practice');
    }, [logged]);

    return (
        <>
            <Header />

            <AuthForm />

            <Footer />
        </>
    );
};

export default AuthPage;
