import React, { createContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import localforage from 'localforage';
import dayjs from 'dayjs';

import { ISubscription, ESubscriptionStatuses, Subscriptions, IModule } from 'connectors/query/Subscriptions';
import { deleteAllCookies } from 'connectors/api/rest';
import { ITimeLeftLabels, createTimeLeftLabel, dayDiff } from 'utils/utils';
import setTabName from 'utils/setTabName';

interface IAuthContextProps {
    clearAuth: () => void;
    userData: IUserData | null;
    logged: boolean;
    hasLoaded: boolean;
    checkToken: () => void;
    updateUserData: (res: IUserData, callback?: () => void) => void;
    hotlineSubscr: ISubscription | null;
    isSubscrLoaded: boolean;
    subscrDiff: ITimeLeftLabels;
}

export interface IUserData {
    id: number;
    first_name: string;
    last_name: string;
    patronymic?: string;
    email?: string;
    token?: string;
    phone?: string;
    customer_profile?: {
        photo: string | null;
        organization: any;
    } | null;
}
export const isUserData = (data: any): data is IUserData => {
    const userData = data as IUserData;
    return (
        userData.id !== undefined &&
        userData.first_name !== undefined &&
        userData.last_name !== undefined &&
        userData.patronymic !== undefined &&
        userData.email !== undefined &&
        userData.phone !== undefined
    );
};
const getCookieByName = (name: string) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
};

const AuthContext = createContext<IAuthContextProps>({
    clearAuth: () => null,
    userData: null,
    logged: false,
    hasLoaded: false,
    checkToken: () => null,
    updateUserData: () => null,
    hotlineSubscr: null,
    isSubscrLoaded: false,
    subscrDiff: { prevWord: '', timeLeft: '' },
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();
    const [logged, setLogged] = useState<boolean>(false);
    const [userData, setUserData] = useState<IUserData | null>(null);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [hotlineSubscr, setHotlineSubscr] = useState<ISubscription | null>(null);
    const [isSubscrLoaded, setIsSubscrLoaded] = useState<boolean>(false);
    const [subscrDiff, setSubscrDiff] = useState<ITimeLeftLabels>({ prevWord: '', timeLeft: '' });

    useEffect(() => {
        if (
            location.pathname.includes('/webinar') ||
            location.pathname.includes('/lecture') ||
            location.pathname.includes('/practice')
        ) {
            if (hasLoaded && logged) getSubscriptions();
        }
    }, [hasLoaded, logged, location.pathname]);

    useEffect(() => {
        checkToken();
    }, []);

    useEffect(() => {
        // @ts-ignore
        let intervalID;
        if (userData?.id) {
            intervalID = setInterval(() => {
                const lastCookie = getCookieByName('user_id');
                if (userData?.id.toString() !== lastCookie) {
                    clearInterval(lastCookie);
                    clearAuth();
                }
            }, 10000);
        }
        return () => {
            // @ts-ignore
            clearInterval(intervalID);
        };
    }, [userData]);

    useEffect(() => {
        setTabName(location.pathname);
        if (!location.state?.scrolling) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [location.pathname]);

    const updateUserData = async (res: IUserData) => {
        setUserData(res);
        await localforage.setItem('userData', res);
    };
    const checkToken = async () => {
        const data = await localforage.getItem('userData');
        // @ts-ignore
        setUserData(data);
        setLogged(!!data);
        setHasLoaded(true);
    };
    const clearAuth = async () => {
        await localforage.removeItem('userData');
        setLogged(false);
        checkToken();
        deleteAllCookies();
    };

    const getSubscriptions = async () => {
        setIsSubscrLoaded(false);

        const data = await Subscriptions.getSubscriptions();
        const modules = await Subscriptions.getModules();
        console.log('data', data);

        console.log('modules', modules);

        if (Array.isArray(data.results) && Array.isArray(modules)) {
            const hotLineModules = (modules as IModule[]).filter((module) =>
                module.options.some((option) => option.is_cinema_law_access),
            );
            console.log('hotLineModules', hotLineModules);

            const activeSubscrs = (data.results as ISubscription[]).filter(
                (subscr: ISubscription) =>
                    hotLineModules.some((module) => module.id === subscr.plan.product.id) &&
                    subscr.ends_at &&
                    subscr.status === ESubscriptionStatuses.Active &&
                    !subscr.plan.is_trial,
            );

            if (activeSubscrs.length < 1) {
                setIsSubscrLoaded(true);
                setHotlineSubscr(null);
                return;
            }

            const hotline = activeSubscrs.reduce((prev, current) =>
                dayjs(current.ends_at).unix() > dayjs(prev.ends_at).unix() ? current : prev,
            );

            if (hotline) {
                setHotlineSubscr(hotline);
                if ((hotline as ISubscription).ends_at) {
                    const { ends_at } = { ...(hotline as ISubscription) };
                    const diff = ends_at ? dayDiff(ends_at) : { days: 0, hours: 0, minutes: 0 };
                    setSubscrDiff(createTimeLeftLabel(diff));
                }
            } else {
                setHotlineSubscr(null);
            }
        }

        setIsSubscrLoaded(true);
    };

    if (hasLoaded && !logged && location.pathname.includes('/cabinet')) return <Navigate to='/auth' />;

    return (
        <div>
            <AuthContext.Provider
                value={{
                    clearAuth,
                    userData,
                    logged,
                    hasLoaded,
                    checkToken,
                    updateUserData,
                    hotlineSubscr,
                    isSubscrLoaded,
                    subscrDiff,
                }}
            >
                {children}
            </AuthContext.Provider>
        </div>
    );
};

export default AuthContext;
