import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table, Modal } from 'antd';
import dayjs from 'dayjs';

import AuthContext from 'common/AuthProvider/AuthProvider';
import Container from 'common/StyledElements/Container/Container';
import Paginator from 'common/Paginator/Paginator';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import FindInput from 'common/FindInput/FindInput';
import Button from 'common/Button/Button';
import { IProject, PersonalAccount } from 'connectors/query/PersonalAccount';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import { ESubscriptionStatuses, ISubscription, ESubscriptionStatusTrial } from 'connectors/query/Subscriptions';
import projectsColumns, { IFormattedProject } from './columns';

import _ from './AddProject.module.css';
import './styles.css';

const limit = 10;
const AddProject = () => {
    const navigate = useNavigate();
    const { userData } = useContext(AuthContext);
    const [projects, setProjects] = useState<IFormattedProject[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState<boolean>(false);
    const [projectNameToDelete, setProjectNameToDelete] = useState<string>('');
    const [projectIdToDelete, setProjectIdToDelete] = useState<string | number>('');
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [offsetTable, setOffsetTable] = useState<number>(0);
    const [totalProjects, setTotalProjects] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        getProjects((currentPage - 1) * limit, searchQuery);
    }, [currentPage, searchQuery]);

    const getProjects = async (offset: number, search: string) => {
        setLoadingStatus(true);
        const data = await PersonalAccount.getProjects({ limit, offset, ordering: '-updated_at', search });
        if (Array.isArray(data.results)) {
            const formattedProjects: IFormattedProject[] = (data.results as IProject[]).map((project) => {
                let lastSubscr: null | ISubscription = null;
                const filteredSubs = project.subscriptions.filter(
                    (sub) => sub.status !== ESubscriptionStatuses.Pending,
                );
                if (filteredSubs.length >= 2) {
                    lastSubscr = filteredSubs.reduce((prevSubscr, currentSubscr) =>
                        dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0 ? currentSubscr : prevSubscr,
                    );
                }
                if (filteredSubs.length === 1) {
                    [lastSubscr] = filteredSubs;
                }
                let isTrial = null;
                if (lastSubscr?.status === ESubscriptionStatuses.Active && lastSubscr.plan.is_trial) {
                    isTrial = ESubscriptionStatusTrial.Trial;
                }
                return {
                    id: project.id,
                    organizer_user: project.organizer_user,
                    createdAt: project.created_at,
                    name: project.name,
                    activeTo: lastSubscr?.ends_at || null,
                    organizer_organization: project.organizer_organization,
                    module: lastSubscr?.plan.product.name || null,
                    status: isTrial || lastSubscr?.status || null,
                };
            });
            setProjects(formattedProjects);
            setTotalProjects(data.count);
        }
        setLoadingStatus(false);
        setOffsetTable((currentPage - 1) * limit);
    };

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const openDeleteProjectModal = (projectName: string, projectId: number | string) => {
        setShowDeleteProjectModal(true);
        setProjectNameToDelete(projectName);
        setProjectIdToDelete(projectId);
    };

    const closeDeleteProjectModal = () => {
        setShowDeleteProjectModal(false);
        setProjectNameToDelete('');
        setProjectIdToDelete('');
    };

    const deleteProject = async () => {
        const res = await PersonalAccount.deleteProject(projectIdToDelete);
        if (res.status === 400 || res.status === 401) {
            Object.keys(res?.data).forEach((key) => {
                openErrorNotification('Ошибка!', res?.data[key]);
            });
        } else if (res.status >= 400) {
            openErrorNotification('Ошибка удаления проекта!');
        } else if (
            res.status === 'ok' ||
            res.status === 200 ||
            res.status === 201 ||
            res.status === 204 ||
            res.status === 202
        ) {
            getProjects((currentPage - 1) * limit, searchQuery);
            openSuccessNotification('Проект удален');
            closeDeleteProjectModal();
        }
    };

    return (
        <>
            <Container style={{ paddingTop: 50 }} column>
                <div className={_.topBlock}>
                    <div className={_.topButtonBlock}>
                        <Button width='290px' onClick={() => navigate('/cabinet/create')} plus>
                            Создать проект
                        </Button>
                    </div>

                    <div className={_.topInputBlock}>
                        <FindInput
                            placeholder='Поиск по названиям проекта или организациям'
                            setSearch={setSearchQuery}
                        />
                    </div>
                </div>

                <Table
                    className='addProjectTable'
                    columns={projectsColumns(navigate, userData?.id, openDeleteProjectModal, offsetTable)}
                    dataSource={projects}
                    pagination={false}
                    loading={loadingStatus}
                />
                <Paginator
                    maxPages={Math.ceil(totalProjects / limit)}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </Container>
            <Modal
                open={showDeleteProjectModal}
                onOk={() => {
                    closeDeleteProjectModal();
                }}
                onCancel={() => {
                    closeDeleteProjectModal();
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainer}>
                        <div className={_.titleInvite}>
                            <Title mb={30} width={468}>
                                Удаление проекта
                            </Title>
                        </div>

                        <Text center mb={30} width={530}>
                            Подтвердите удаление проекта <b>{projectNameToDelete}</b>
                        </Text>
                        <div className={_.btnsRemoveParticipantModal}>
                            <Button
                                onClick={() => {
                                    deleteProject();
                                }}
                                htmlType='submit'
                                width='250px'
                            >
                                Удалить
                            </Button>

                            <Button
                                onClick={() => {
                                    closeDeleteProjectModal();
                                }}
                                htmlType='submit'
                                width='250px'
                            >
                                Отмена
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddProject;
