import clsx from 'clsx';
import { FC } from 'react';

import _ from './Link.module.css';

interface ILinkProp {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
    mb?: number | string;
    row?: boolean;
    style?: React.CSSProperties;
}

const Link: FC<ILinkProp> = ({ children, onClick, mb, row, style = {} }) => (
    <div
        onClick={onClick}
        className={clsx(_.link, row && _.row)}
        style={{
            marginBottom: mb,
            ...style,
        }}
    >
        {children}
    </div>
);

export default Link;
