import { CSSProperties, FC } from 'react';
import { Upload } from 'antd';

import paperclipIcon from 'assets/contacts/paperclipIcon.svg';
import FileAttachmentInfo from 'common/FileAttachmentInfo/FileAttachmentToAddInfo';
import Link from 'common/StyledElements/Link/Link';

import _ from './FileUploader.module.css';

interface IFileUploader {
    files: File[];
    ellipsisWidth?: number | string;
    attachmentStyle?: CSSProperties;
    setFiles: (files: File[]) => void;
    customRequest: (f: any) => void;
}

const FileUploader: FC<IFileUploader> = ({ files, ellipsisWidth, attachmentStyle, setFiles, customRequest }) => (
    <div className={_.file}>
        <FileAttachmentInfo ellipsisWidth={ellipsisWidth} style={attachmentStyle} files={files} setFiles={setFiles} />

        <Upload className={_.upload} maxCount={5} customRequest={customRequest} showUploadList={false} multiple>
            <img className={_.paperclipIcon} src={paperclipIcon} alt='paperclip icon' />
            <Link style={{ display: 'inline-block' }}>Прикрепить файл</Link>
        </Upload>
    </div>
);

export default FileUploader;
