import { FC } from 'react';

import Container from 'common/StyledElements/Container/Container';
import Title from 'common/StyledElements/Title/Title';
import { ILanding } from 'components/LandingPage/LandingPage';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import Reason from './components/Reason/Reason';
import Video from './components/Video/Video';

import _ from './Why.module.css';

interface IWhyProps {
    whyUsData: any;
}

const Why: FC<IWhyProps> = ({ whyUsData }) => (
    <Container pb={80} column>
        <Title mb={50}> {logoReplacer(whyUsData.title)}</Title>

        <div className={_.reasonsContainer}>
            {whyUsData?.reasons.map((r: ILanding) => (
                <Reason key={r.id} pic={r.icon || ''} title={r.title || ''} description={r.description || ''} />
            ))}
        </div>

        <Video
            video={whyUsData.video}
            video_preview={whyUsData.video_preview}
            video_description={whyUsData.video_description}
        />
    </Container>
);

export default Why;
