import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import Table from 'antd/es/table';
import { useForm } from 'antd/es/form/Form';
import { Form, Input, Modal } from 'antd';
import clsx from 'clsx';

import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import Pagination from 'common/Paginator/Paginator';
import { openSuccessNotification } from 'common/Notifications/Notifications';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';
import { IProjectCustomerDetails, Organizations } from 'connectors/query/Organizations';
import { columnsCustomerDetails } from '../../columns';

import _ from './ProjectCustomerDetails.module.css';
import '../../styles.scss';

interface IProjectCustomerDetailsBlock {
    orgId?: string | number;
    projectId?: string | number;
    isActiveProject: boolean;
}

const limit = 3;
const ProjectCustomerDetails: FC<IProjectCustomerDetailsBlock> = ({ orgId, projectId, isActiveProject }) => {
    const navigate = useNavigate();
    const [dataCustomerDetails, setDataCustomerDetails] = useState<IProjectCustomerDetails[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countAll, setCountAll] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showCreateProjectCustomerDetailsCopyModal, setShowCreateProjectCustomerDetailsCopyModal] = useState(false);
    const [detailsIdForCopy, setDetailsIdForCopy] = useState(0);

    const [form] = useForm();

    useEffect(() => {
        if (orgId) getProjectProperties();
    }, [orgId]);

    useEffect(() => {
        getProjectProperties();
    }, [currentPage]);

    const closeCreateProjectCustomerDetailsCopyModal = () => {
        setShowCreateProjectCustomerDetailsCopyModal(false);
        form.resetFields();
    };

    const createProjectCustomerDetailsCopy = async (values: { customerDetailsVersionForm: string | undefined }) => {
        if (detailsIdForCopy && orgId && values?.customerDetailsVersionForm) {
            const result = await Organizations.createProjectCustomerDetailsCopy(
                orgId,
                detailsIdForCopy,
                values.customerDetailsVersionForm,
            );
            if (result.id) {
                openSuccessNotification('Копия реквизитов заказчика успешно создана!');
                closeCreateProjectCustomerDetailsCopyModal();
                if (currentPage === 1) {
                    getProjectProperties();
                } else {
                    setCurrentPage(1);
                }
            } else {
                Object.keys(result?.data).forEach((key) => {
                    form.setFields([{ name: key, errors: result?.data[key] }]);
                });
            }
        }
    };

    const getProjectProperties = async () => {
        if (orgId) {
            setIsLoading(true);
            const res = await Organizations.getProjectCustomerDetails(orgId, {
                limit,
                offset: (currentPage - 1) * limit,
                ordering: '-updated_at',
            });
            if (Array.isArray(res?.results)) {
                setDataCustomerDetails(res?.results);
            }
            if (res?.count) {
                setCountAll(res?.count);
            } else setCountAll(0);
            setIsLoading(false);
        }
    };
    return (
        <div className={_.block}>
            <Title mt={50} mb={40} small>
                Реквизиты заказчика
            </Title>

            <Table
                className='addProjectTable'
                columns={columnsCustomerDetails(
                    (id: number) => {
                        setShowCreateProjectCustomerDetailsCopyModal(true);
                        setDetailsIdForCopy(id);
                    },
                    orgId,
                    projectId,
                    navigate,
                    isActiveProject,
                )}
                dataSource={dataCustomerDetails}
                pagination={false}
                loading={isLoading}
            />
            <Pagination
                maxPages={Math.ceil(countAll / limit)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                className={_.modalCustom}
                open={showCreateProjectCustomerDetailsCopyModal}
                onOk={() => {
                    closeCreateProjectCustomerDetailsCopyModal();
                }}
                onCancel={() => {
                    closeCreateProjectCustomerDetailsCopyModal();
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainerCopyProperties}>
                        <div className={_.titleInvite}>
                            <Title mb={30} width={468}>
                                Создать копию реквизитов заказчика
                            </Title>
                        </div>

                        <Form
                            name='customerDetailsVersionForm'
                            className={clsx(_.customerDetailsVersionForm)}
                            form={form}
                            onFinish={createProjectCustomerDetailsCopy}
                        >
                            <Form.Item
                                name={['customerDetailsVersionForm']}
                                label={<div style={{ fontFamily: 'Manrope' }}>Введите название версии</div>}
                                rules={[
                                    {
                                        min: 3,
                                        message: 'Минимум 3 символа',
                                    },
                                    {
                                        required: true,
                                        message: 'Необходимо ввести название',
                                    },
                                ]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={_.projectPropertyVersionNameWrapper}
                                required={false}
                            >
                                <Input
                                    maxLength={64}
                                    className={_.projectPropertyVersionName}
                                    onBlur={() => handleInputBlur(form, 'version')}
                                />
                            </Form.Item>
                            <Button
                                grey
                                onClick={closeCreateProjectCustomerDetailsCopyModal}
                                width='210px'
                                marginRight='20px'
                            >
                                Отмена
                            </Button>
                            <Button htmlType='submit' width='210px'>
                                Создать
                            </Button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProjectCustomerDetails;
