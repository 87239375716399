import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'antd';

import Success from 'assets/notifications/success.svg';
import { Users } from 'connectors/query/Users';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { openErrorNotification } from 'common/Notifications/Notifications';

import _ from './DeleteAccountConfirm.module.css';

// при переходе на эту страницу - отстреливается запрос на удаление аккаунта
const DeleteAccountConfirmPage = () => {
    const { clearAuth } = useContext(AuthContext);
    const data = useParams();
    const navigate = useNavigate();

    const exit = async () => {
        // const res = await Auth.logout();

        // if (res?.status === 200 || res?.status === 201 || res?.status === 'ok')
        clearAuth();
    };

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const DeleteAccountConfirm = async () => {
        if (data.uid && data.token) {
            const res = await Users.deleteAccountConfirm({ uid: data.uid, token: data.token });

            if (res.status !== 'ok' && res.status !== 200 && res.status !== 201) {
                Object.keys(res?.data).forEach((key) => {
                    openErrorNotification('Ошибка!', res?.data[key][0]);
                });
                navigate('/auth');
            } else {
                setShowModalSuccess(true);
                exit();
            }
        } else {
            openErrorNotification('Некорректная ссылка');
            navigate('/auth');
        }
    };

    useEffect(() => {
        DeleteAccountConfirm();
    }, []);

    return (
        <Modal
            open={showModalSuccess}
            onOk={() => {
                setShowModalSuccess(false);
                navigate('/');
            }}
            onCancel={() => {
                setShowModalSuccess(false);
                navigate('/');
            }}
            footer={null}
            width={700}
            centered
        >
            <div className={_.modalWrapper}>
                <div style={{ height: 250 }} className={_.modalContainer}>
                    <img style={{ marginBottom: 30 }} src={Success} alt='success' />

                    <Title mb={30}>Аккаунт удалён!</Title>

                    <Button onClick={() => navigate('/')} width='auto'>
                        Перейти на главную
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteAccountConfirmPage;
