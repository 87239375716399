import { useEffect, useState } from 'react';

import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import bucketIcon from 'assets/statuses/bucket.svg';
import Container from 'common/StyledElements/Container/Container';
import Text from 'common/StyledElements/Text/Text';
import FindInput from 'common/FindInput/FindInput';
import { IPerson, PersonalAccount } from 'connectors/query/PersonalAccount';
import PersonAvatar from 'common/PersonAvatar/PersonAvatar';
import Paginator from 'common/Paginator/Paginator';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import StatusWrapper, { IStatusAliases } from 'common/StatusWrapper/StatusWrapper';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import ProjectsList from './components/ProjectsList/ProjectsList';

import _ from './People.module.css';
import './styles.css';

const columns: (
    openDeleteParticipantModal: (p: IPerson) => void,
    offset: number,
    sortedInfo: any,
) => ColumnsType<IPerson> = (openDeleteParticipantModal, offset, sortedInfo) => [
    {
        title: '№',
        dataIndex: 'pos',
        key: 'pos',
        render: (pos, obj, i) => (
            <div className={_.idWrapper}>
                <Text mid>{i + 1 + offset}</Text>
            </div>
        ),
    },
    {
        title: 'ФИО',
        dataIndex: 'last_name',
        key: 'last_name',
        sorter: () => 0,
        sortOrder: sortedInfo.field === 'last_name' ? sortedInfo.order : undefined,
        width: 350,
        render: (last_name, obj) => (
            <div className={_.nameWrapper}>
                <PersonAvatar firstName={obj.first_name} lastName={obj.last_name} photo={obj.photo} />
                <Text
                    className={_.fio}
                    width={400}
                    title={`${obj.last_name} ${obj.first_name}${obj.patronymic ? ' ' : ''}${obj.patronymic || ''}`}
                    ellipsis
                    mid
                >{`${obj.last_name} ${obj.first_name}${obj.patronymic ? ' ' : ''}${obj.patronymic || ''}`}</Text>
            </div>
        ),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (email) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text mid>{email || ''}</Text>
            </div>
        ),
    },
    {
        title: 'Проекты, в которых участвует',
        dataIndex: 'projects',
        key: 'projects',
        render: (projects, personObj) => (
            <div className={_.list}>
                {projects?.map((project: { id: number; name: string; organizer_user: number }) => (
                    <div className={_.listEl}>
                        <Text className={_.listProjectName} key={project.id} mid>
                            {project.name}
                        </Text>
                        {project.organizer_user === personObj.id && (
                            <StatusWrapper
                                className={_.ownerStatus}
                                text='Владелец'
                                statusAliases={{
                                    greenCheck: [{ codeName: 'Владелец' }],
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: 'Действие',
        dataIndex: 'action',
        align: 'right',
        key: 'action',
        render: (a, obj) => (
            <div className={_.bucketBlock}>
                <div
                    className={_.bucket}
                    onClick={() => {
                        openDeleteParticipantModal(obj);
                    }}
                >
                    <img src={bucketIcon} alt='bucket' />
                </div>
            </div>
        ),
    },
];
const limit = 10;

const People = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [people, setPeople] = useState<IPerson[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [offsetTable, setOffsetTable] = useState<number>(0);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [participantToDelete, setParticipantToDelete] = useState<IPerson | null>(null);
    const [selectedProject, setSelectedProject] = useState<number | null>(null);
    const [sortedInfo, setSortedInfo] = useState<{ field?: string; order?: string }>({});
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        getPeople((currentPage - 1) * limit);
    }, [search, currentPage, sortedInfo]);

    const getPeople = async (offset: number) => {
        setLoadingStatus(true);
        let ordering = '';
        if (sortedInfo.field === 'last_name' && sortedInfo.order) {
            ordering = sortedInfo.order === 'ascend' ? 'last_name' : '-last_name';
        }
        const result = await PersonalAccount.getPeople({ limit, offset, search, ordering });

        if (result.count && !Number.isNaN(result.count)) {
            setTotalPages(Math.ceil(result.count / limit));
        }

        if (Array.isArray(result.results)) {
            const ids = result.results.map((person: IPerson) => person.id);

            const resultCommonProjects = await PersonalAccount.getCommonProjects(ids);

            if (resultCommonProjects[ids[0]]) {
                const peopleWithProjects = result.results.map((person: IPerson) => {
                    const projects = resultCommonProjects[person.id.toString()];
                    return { ...person, projects };
                });

                setPeople(peopleWithProjects);
            } else {
                setPeople(result.results);
            }
        }
        setOffsetTable((currentPage - 1) * limit);
        setLoadingStatus(false);
    };

    const handleTableChange = (_: unknown, __: unknown, sorter: any) => {
        setSortedInfo(sorter);
    };

    const openDeleteParticipantModal = (p: IPerson) => {
        setParticipantToDelete(p);

        if (p.projects?.length === 1 && p.projects[0].organizer_user !== p.id) {
            setSelectedProject(p.projects[0].id);
        } else {
            setSelectedProject(null);
        }

        setShowDeleteModal(true);
    };

    const closeDeleteParticipantModal = () => {
        setParticipantToDelete(null);
        setSelectedProject(null);
        setShowDeleteModal(false);
    };

    const removeParticipant = async () => {
        if (selectedProject && participantToDelete) {
            const res = await PersonalAccount.removeParticipant(selectedProject, participantToDelete.email);
            if (res.status !== 'ok' && res.status !== 200 && res.status !== 201 && res.status !== 204) {
                Object.keys(res?.data).forEach((key) => {
                    openErrorNotification('Ошибка!', res?.data[key]);
                });
            } else {
                getPeople((currentPage - 1) * limit);
                openSuccessNotification(`Пользователь удален из проекта.`);
                closeDeleteParticipantModal();
            }
        }
    };

    return (
        <Container style={{ paddingTop: 50 }} column>
            <FindInput setSearch={setSearch} placeholder='Поиск по ФИО' />

            <Table
                className='peopleTable'
                columns={columns(openDeleteParticipantModal, offsetTable, sortedInfo)}
                dataSource={people}
                loading={loadingStatus}
                pagination={false}
                onChange={handleTableChange}
            />
            <Paginator maxPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <Modal
                className={_.modalCustom}
                open={showDeleteModal}
                onOk={closeDeleteParticipantModal}
                onCancel={closeDeleteParticipantModal}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainerRemoveParticipant}>
                        <div className={_.titleDelete}>
                            <Title mb={30} width={468}>
                                Удаление пользователя из проекта
                            </Title>
                        </div>

                        <Text center mb={30} width={530}>
                            Выберите проект, из которого хотите удалить участника{' '}
                            <b>{`${participantToDelete?.last_name} ${participantToDelete?.first_name}${
                                participantToDelete?.patronymic || ''
                            }`}</b>
                        </Text>
                        <div className={_.modalProjects}>
                            <ProjectsList
                                participant={participantToDelete}
                                selectedProject={selectedProject}
                                setSelectedProject={setSelectedProject}
                            />
                        </div>
                        <div className={_.btnsRemoveParticipantModal}>
                            <Button
                                disabled={!selectedProject}
                                onClick={() => {
                                    removeParticipant();
                                }}
                                htmlType='submit'
                                width='250px'
                            >
                                Удалить
                            </Button>

                            <Button onClick={closeDeleteParticipantModal} htmlType='submit' width='250px'>
                                Отмена
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Container>
    );
};

export default People;
