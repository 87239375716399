import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { Table, Button as AntdButton } from 'antd';
import { ColumnsType } from 'antd/es/table';

import Button from 'common/Button/Button';
import FindInput from 'common/FindInput/FindInput';
import Container from 'common/StyledElements/Container/Container';
import Paginator from 'common/Paginator/Paginator';
import Text from 'common/StyledElements/Text/Text';
import editIcon from 'assets/icons/editIcon.svg';
// import { IOrganization, Organizations } from 'connectors/query/Organizations';
import PersonalAccount from 'connectors/query/PersonalAccount';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';

import _ from './Contragents.module.css';
import './styles.css';

interface ContragentsType {
    id: number;
    name: string;
    full_name: string;
    inn: string;
    organization: number;
    organization_type: string;
    created_at: string;
    ogrn: string;
    ogrnip: string;
}

type ColumnsTypeContragents = (navigate: (path: string) => void) => ColumnsType<ContragentsType>;

const typeMap: { [key: string]: string } = {
    IP: 'ИП',
    UL: 'Юридическое лицо',
    FL: 'Физическое лицо',
};

const convertType = (type: string) => typeMap[type] || type;

const Contragents = () => {
    const navigate = useNavigate();
    const [legalType, setLegalType] = useState<0 | 1 | 2 | 3>(0);
    const [organizations, setOrganizations] = useState<ContragentsType[]>([]);
    const [searchName, setSearchName] = useState<string>('');
    const [searchINN, setSearchINN] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalOrganizations, setTotalOrganizations] = useState<number>(0);
    const [sortedInfo, setSortedInfo] = useState<{ field?: keyof ContragentsType; order?: 'ascend' | 'descend' }>({
        field: 'created_at',
        order: 'descend',
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [organizationType, setOrganizationType] = useState<string | null>(null);

    const limit = 10;

    useEffect(() => {
        const ordering = sortedInfo.order === 'ascend' ? sortedInfo.field : `-${sortedInfo.field}`;
        loadOrganizations((currentPage - 1) * limit, ordering);
    }, [searchName, searchINN, currentPage, sortedInfo, organizationType]);

    // const organizationToContragent = (organization: IOrganization): ContragentsType => ({
    //     id: organization.id,
    //     name: organization.name,
    //     inn: organization.inn,
    //     type: organization.organization_type,
    // });

    // Функция сортировки
    const handleTableChange = (_: unknown, __: unknown, sorter: any) => {
        setSortedInfo(sorter);
    };

    // const deleteContragent = async (id: number) => {
    //     try {
    //         await Organizations.deleteOrganization(id);
    //         setOrganizations(organizations.filter((org) => org.id !== id));
    //         openSuccessNotification('Контрагент успешно удалён');
    //     } catch (error) {
    //         console.error('Ошибка при удалении контрагента:', error);
    //         openErrorNotification('Ошибка при удалении контрагента');
    //     }
    // };

    const loadOrganizations = async (offset: number, ordering?: string) => {
        setIsLoading(true);
        const response = await PersonalAccount.getAllOrgCharacteristics({
            name: searchName,
            inn: searchINN,
            limit,
            offset,
            ordering,
            ...(organizationType ? { organization_type: organizationType } : {}),
        });

        const { results, count } = response;
        setOrganizations(results);
        setTotalOrganizations(count);
        setIsLoading(false);
    };

    const toggleOrganizationType = (typeIndex: 0 | 1 | 2 | 3, typeString: string | null) => {
        if (legalType === typeIndex) {
            setLegalType(0);
            setOrganizationType(null);
        } else {
            setLegalType(typeIndex);
            setOrganizationType(typeString);
        }
        setCurrentPage(1);
    };

    const columns: ColumnsTypeContragents = (navigate) => [
        {
            title: '№',
            dataIndex: 'pos',
            key: 'pos',
            render: (pos, obj, i) => <Text mid>{i + 1 + (currentPage - 1) * limit}</Text>,
        },
        {
            title: 'Название компании',
            dataIndex: 'name',
            key: 'name',
            width: 450,
            sorter: () => 0,
            sortOrder: sortedInfo.field === 'name' ? sortedInfo.order : undefined,
        },
        {
            title: 'Тип контрагента',
            dataIndex: 'organization_type',
            key: 'organization_type',
            render: (organization_type) => convertType(organization_type),
        },
        {
            title: 'ИНН',
            dataIndex: 'inn',
            key: 'inn',
            sorter: () => 0,
            sortOrder: sortedInfo.field === 'inn' ? sortedInfo.order : undefined,
            render: (inn, characteristic) => (
                <div className={_.innWrapper}>
                    <AntdButton
                        onClick={() =>
                            navigate(
                                `/cabinet/organizations/${characteristic.organization}/details/${characteristic.id}`,
                            )
                        }
                        className={_.button_link}
                        type='link'
                    >
                        {inn}
                    </AntdButton>
                </div>
            ),
        },
        {
            title: 'ОГРН/ОГРНИП',
            dataIndex: 'ogrn_or_ogrnip', // Указываем ключ, которого нет в данных, чтобы функция render вызвалась с необходимыми данными
            key: 'ogrn_or_ogrnip',
            render: (_, record) => {
                // Здесь используем второй параметр функции render, который является записью всего объекта данных
                // Проверяем поля и возвращаем ОГРН или ОГРНИП в зависимости от того, что имеется в записи
                if (record.ogrn) {
                    return record.ogrn;
                } if (record.ogrnip) {
                    return record.ogrnip;
                }
                return null;
            },
        },
        {
            title: 'Дата создания',
            dataIndex: 'created_at',
            key: 'created_at',
            sortOrder: sortedInfo.field === 'created_at' ? sortedInfo.order : undefined,
            render: (created_at) => dayjs(created_at).format('DD.MM.YYYY'),
        },
    ];

    return (
        <Container style={{ paddingTop: 50 }} column>
            <div className={_.buttonsBlock}>
                <Button
                    className={_.button}
                    style={{
                        ...(legalType === 1 && { borderColor: '#1677ff' }),
                    }}
                    grey2
                    width='auto'
                    marginRight='20px'
                    onClick={() => toggleOrganizationType(1, 'UL')}
                >
                    Юридическое лицо
                </Button>
                <Button
                    className={_.button}
                    style={{
                        ...(legalType === 2 && { borderColor: '#1677ff' }),
                    }}
                    grey2
                    width='auto'
                    marginRight='20px'
                    onClick={() => toggleOrganizationType(2, 'FL')}
                >
                    Физическое лицо
                </Button>
                <Button
                    className={_.button}
                    style={{
                        ...(legalType === 3 && { borderColor: '#1677ff' }),
                    }}
                    grey2
                    width='auto'
                    onClick={() => toggleOrganizationType(3, 'IP')}
                >
                    ИП
                </Button>

                {/* <div className={_.top_buttons}>
                    <Button
                        className={_.button}
                        grey2
                        width='auto'
                        onClick={() => {
                            navigate('/cabinet/contragent/add');
                        }}
                    >
                        Добавить контрагента
                    </Button>
                </div> */}
            </div>

            <div className={_.top_FindInputs}>
                <div className={_.top_FindInput_item}>
                    <FindInput setSearch={setSearchName} placeholder='Поиск по названию' />
                </div>
                <div className={_.top_FindInput_item}>
                    <FindInput setSearch={setSearchINN} placeholder='Поиск по ИНН' />
                </div>
            </div>

            <Table
                className='contragentsTable'
                columns={columns(navigate)}
                dataSource={organizations}
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading}
            />
            <Paginator
                maxPages={Math.ceil(totalOrganizations / limit)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </Container>
    );
};

export default Contragents;
