import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import Container from 'common/StyledElements/Container/Container';
import { Messenger, Status, IThread, IMessage } from 'connectors/query/Messenger';
import AuthContext from 'common/AuthProvider/AuthProvider';
import MessageBox from './components/MessageBox/MessageBox';
import ReplyMessage from './components/ReplyMessage/ReplyMessage';

import _ from './Correspondence.module.css';

const Correspondence = () => {
    const navigate = useNavigate();
    const [thread, setThread] = useState<IThread | null>(null);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const { userData } = useContext(AuthContext);
    const { id } = useParams();

    const fetchThread = async () => {
        const threadData = await Messenger.Threads.getOne(Number(id));
        if (!(threadData?.status >= 400 && threadData?.status < 600)) {
            Messenger.MessagesNew.setMessageViewed(threadData.messages[0].id);
            setThread(threadData);
            setMessages(threadData.messages.reverse());
        }
    };

    useEffect(() => {
        fetchThread();
    }, []);

    return (
        <Container style={{ paddingTop: 40, paddingBottom: 40 }}>
            <div className={_.container}>
                <Button className={_.breadcrumb__btn} type='link' onClick={() => navigate('/cabinet/messages')}>
                    <ArrowLeftOutlined />
                    Назад к сообщениям
                </Button>

                {messages.map((message, i) => (
                    <MessageBox
                        message={message}
                        title={i === 0 ? thread?.title : undefined}
                        type={message.sender_user === userData?.id ? 'outgoing' : 'incoming'}
                    />
                ))}

                {thread?.status !== Status.Closed && <ReplyMessage thread={thread} fetchThread={fetchThread} />}
            </div>
        </Container>
    );
};

export default Correspondence;
