import { FC } from 'react';

import webinar from 'assets/webs/webinar.svg';
import lecture from 'assets/webs/lecture.svg';
import Text from 'common/StyledElements/Text/Text';

import _ from './TypeHotline.module.css';

export enum ETypeHotline {
    Webinar = 'webinar',
    Lecture = 'lecture',
    Archive = 'archive',
    Event = 'event',
}

interface ITypeHotlineProps {
    type: ETypeHotline[];
}

const imgs = {
    webinar,
    lecture,
};

const title = {
    webinar: 'Вебинар',
    lecture: 'Лекция',
    archive: 'Архив',
};

const color = {
    webinar: '#32AAF0',
    lecture: '#ED8864',
    archive: '#166DF9',
};

const TypeHotline: FC<ITypeHotlineProps> = ({ type }) => (
    <div className={_.main}>
        {type.map((typeItem) =>
            typeItem !== ETypeHotline.Event ? (
                <div className={_.statusWrapper} style={{ backgroundColor: color[typeItem] }}>
                    {typeItem !== 'archive' && <img className={_.icon} src={imgs[typeItem]} alt='status' />}
                    <Text white small>
                        {title[typeItem]}
                    </Text>
                </div>
            ) : null,
        )}
    </div>
);

export default TypeHotline;
