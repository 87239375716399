import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import validationRules from 'utils/formValidationRules';

interface IStepFourBank {
    onInputChange: (key: string, value: string | null) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const StepFourBank: React.FC<IStepFourBank> = ({ form, onInputChange, isSubscrActiveOrCreate }) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Банковские реквизиты</div>

            <div className='shortInnersBlock'>
                <MyFormItem name='bank' label='Банк'>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('bank', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='correspondent_account'
                    label='Корреспондентский счёт'
                    rules={[validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('correspondent_account', event.target.value)}
                        maxLength={20}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='checking_account' label='Расчётный счёт' rules={[validationRules.digitsOnly]}>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('checking_account', event.target.value)}
                        maxLength={20}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='bik' label='БИК' rules={[validationRules.digitsOnly]}>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('bik', event.target.value)}
                        maxLength={9}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default StepFourBank;
