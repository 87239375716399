import { FC } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

interface Route {
    path: string;
    breadcrumbName: string;
    children?: Omit<Route, 'children'>[];
}

interface IBreadcrumbAbsoluteProps {
    routes: Route[];
}

const itemRender = (route: any) => (
    // const last = routes.length - 1 === routes.findIndex((r: any) => r.path === route.path);

    // if (!last) {
    //     return <Link to={`/${route.path}`}>{route.title}</Link>;
    // }
    <Link to={`/${route.path}`}>{route.title}</Link>
);
export const Breadcrumbs: FC<IBreadcrumbAbsoluteProps> = ({ routes }) => (
    <Breadcrumb routes={routes} itemRender={itemRender} />
);

export default Breadcrumbs;
