import { FC } from 'react';

import _ from './Container.module.css';

interface IContainerProp {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    style?: React.CSSProperties;
    bgImage?: string;
    column?: boolean;
    pb?: number | string;
    pt?: number | string;
    top?: number | string;
    dark?: boolean;
    gray?: boolean;
    noRound?: boolean;
    minHeight?: string;
}

const Container: FC<IContainerProp> = ({
    children,
    pb,
    pt,
    column,
    dark,
    gray,
    bgImage,
    style,
    noRound,
    top,
    minHeight,
}) => {
    let color = '#fff';
    dark && (color = '#282C3B');
    gray && (color = '#F2F4F8');

    return (
        <div
            className={_.wrapper}
            style={{
                background: color,
                paddingBottom: pb,
                top,
                minHeight,
                ...(bgImage ? { backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' } : {}),
                ...(noRound ? { marginTop: 0 } : {}),
            }}
        >
            <div
                className={_.container}
                style={{
                    display: 'flex',
                    paddingTop: pt,
                    ...(column ? { flexDirection: 'column' } : {}),
                    ...style,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default Container;
