import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import DatePicker from 'common/DatePicker/DatePicker';
import validationRules from 'utils/formValidationRules';

interface IBasicDetails {
    data: any;
    onInputChange: (key: string, value: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    form: FormInstance;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    isSubscrActiveOrCreate: boolean;
}

const BasicDetails: React.FC<IBasicDetails> = ({ form, onInputChange, onDateChange, isSubscrActiveOrCreate }) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Основные реквизиты</div>

            <div className='shortInnersBlock'>
                <MyFormItem
                    name='first_name'
                    label='Имя'
                    rules={[{ required: true, message: 'Пожалуйста, введите имя!' }]}
                >
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('first_name', event.target.value)}
                        maxLength={64}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='last_name'
                    label='Фамилия'
                    rules={[{ required: true, message: 'Пожалуйста, введите фамилию!' }]}
                >
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('last_name', event.target.value)}
                        maxLength={64}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='patronymic' label='Отчество'>
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('patronymic', event.target.value)}
                        maxLength={64}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='inn'
                    label='ИНН'
                    rules={[{ required: true, message: 'Пожалуйста, введите ИНН!' }, validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => {
                            onInputChange('inn', event.target.value);
                        }}
                        maxLength={12}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='birth_date' label='Дата рождения'>
                    <DatePicker
                        className='inputInner inputInnerShort'
                        onChange={onDateChange('birth_date')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>

                <MyFormItem name='snils' label='СНИЛС' rules={[validationRules.digitsOnly]}>
                    <Input
                        className='inputInner inputInnerShort'
                        onChange={(event) => onInputChange('snils', event.target.value)}
                        maxLength={11}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default BasicDetails;
