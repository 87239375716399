import { FC, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import clsx from 'clsx';

import _ from './BackButton.module.css';

interface IButtonProps {
    onClick?: () => void;
    children: React.ReactNode;
    style?: CSSProperties;
    className?: string;
}

const BackButton: FC<IButtonProps> = ({ onClick, children, style = {}, className = '' }) => {
    const navigate = useNavigate();

    return (
        <Button
            className={clsx(_.backButton, className)}
            type='link'
            onClick={() => {
                onClick ? onClick() : navigate(-1);
            }}
            style={style}
        >
            <ArrowLeftOutlined />
            {children}
        </Button>
    );
};

export default BackButton;
