import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import localforage from 'localforage';

import Success from 'assets/notifications/success.svg';
import { openErrorNotification } from 'common/Notifications/Notifications';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import AuthContext, { isUserData } from 'common/AuthProvider/AuthProvider';
import { Users } from 'connectors/query/Users';

import _ from './ChangeEmailConfirm.module.css';

// при переходе на эту страницу - отстреливается запрос на подтверждение смены E-mail
const ChangeEmailConfirmPage = () => {
    const data = useParams();
    const navigate = useNavigate();
    const { updateUserData } = useContext(AuthContext);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const ChangeEmailConfirm = async () => {
        if (data.uid && data.token) {
            const res = await Users.changeEmailConfirm({ uid: data.uid, token: data.token });

            if (res?.status !== 'ok' && res?.status !== 200 && res?.status !== 201) {
                Object.keys(res?.data).forEach((key) => {
                    openErrorNotification('Ошибка!', res?.data[key][0]);
                });
                navigate('/auth');
            } else {
                const userData = await localforage.getItem('userData');
                if (isUserData(userData) && res.new_email) {
                    updateUserData({ ...userData, email: res.new_email });
                }
                setShowModalSuccess(true);
            }
        } else {
            openErrorNotification('Некорректная ссылка');
            navigate('/auth');
        }
    };

    useEffect(() => {
        ChangeEmailConfirm();
    }, []);

    return (
        <Modal
            open={showModalSuccess}
            onOk={() => {
                setShowModalSuccess(false);
                navigate('/auth');
            }}
            onCancel={() => {
                setShowModalSuccess(false);
                navigate('/auth');
            }}
            footer={null}
            width={700}
            centered
        >
            <div className={_.modalWrapper}>
                <div style={{ height: 250 }} className={_.modalContainer}>
                    <img style={{ marginBottom: 30 }} src={Success} alt='success' />

                    <Title mb={30}>Смена Email прошла успешно!</Title>

                    <Button onClick={() => navigate('/auth')} width='100px'>
                        Ок
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeEmailConfirmPage;
