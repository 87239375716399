import { FC } from 'react';
import { Modal } from 'antd';

import Title from 'common/StyledElements/Title/Title';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './DocumentModal.module.css';

interface IDocumentModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string | undefined;
    content: string | null | undefined;
    width?: string;
}

const DocumentModal: FC<IDocumentModalProps> = ({ isOpen, onClose, title, content, width = '700px' }) => (
    <Modal open={isOpen} onCancel={onClose} width={width} footer={null} centered>
        {content !== null && content !== undefined && (
            <div className={_.docModalInner}>
                {title !== undefined && (
                    <Title mb={30} small family='Manrope'>
                        {logoReplacer(title)}
                    </Title>
                )}
                <div dangerouslySetInnerHTML={{ __html: logoReplacer(content, 'dangerouslySetInnerHTML') }} />
            </div>
        )}
    </Modal>
);

export default DocumentModal;
