import { useEffect, useState } from 'react';

import { Checkbox, Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import DatePicker from 'common/DatePicker/DatePicker';

import _ from './PropertyVersion.module.css';

interface IPropertyVersion {
    data: any;
    onInputChange: (key: string, value: string | null | boolean) => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
    formName: string;
}

const PropertyVersion: React.FC<IPropertyVersion> = ({
    onInputChange,
    onDateChange,
    data,
    form,
    isSubscrActiveOrCreate,
    formName,
}) => {
    const [componentDisabled] = useState<boolean>(true);

    return (
        <>
            <div className='title'>Свойства версии</div>

            <div className='blockCard w100'>
                <Form form={form} name={formName} layout='vertical' scrollToFirstError>
                    <div className='shortInnersBlock'>
                        <MyFormItem name='id' label='Номер версии'>
                            <Input disabled={componentDisabled} className='inputInner inputInnerShort2' />
                        </MyFormItem>

                        <MyFormItem name='created_at' label='Дата создания версии'>
                            <DatePicker
                                disabled
                                className='inputInner inputInnerShort2'
                                onChange={onDateChange('created_at')}
                            />
                        </MyFormItem>
                    </div>

                    <MyFormItem
                        name='version_name'
                        label='Название версии'
                        rules={[{ required: true, message: 'Необходимо заполнить.' }]}
                    >
                        <Input
                            className='inputInner'
                            onChange={(event) => onInputChange('version_name', event.target.value)}
                            maxLength={256}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>
                    <MyFormItem className={_.noMargin} name='version_name_by_default' valuePropName='checked'>
                        <Checkbox
                            defaultChecked
                            disabled={!isSubscrActiveOrCreate}
                            onChange={(event) => onInputChange('version_name_by_default', event.target.checked)}
                        >
                            По умолчанию
                        </Checkbox>
                    </MyFormItem>
                </Form>
            </div>
        </>
    );
};

export default PropertyVersion;
