import { FC, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';

import plus from 'assets/statuses/bluePlus.svg';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import AuthContext from 'common/AuthProvider/AuthProvider';
import PersonAvatar from 'common/PersonAvatar/PersonAvatar';

import _ from './Block.module.css';

interface IBlockProps {
    title?: string;
    num?: number | string;
    isPersonal?: boolean;
    icon?: string;
    btnText?: string;
    addBtn?: string;
    to?: string;
}

const Block: FC<IBlockProps> = ({ num, title, isPersonal, icon, btnText, addBtn, to }) => {
    const { userData } = useContext(AuthContext);
    const navigate = useNavigate();

    if (isPersonal)
        return (
            <div className={_.wrapper}>
                <div className={_.personWrapper}>
                    <PersonAvatar
                        firstName={userData?.first_name}
                        lastName={userData?.last_name}
                        photo={userData?.customer_profile?.photo}
                    />

                    <div className={_.personDataWrapper}>
                        <Title mb={0} small>
                            <div className={_.fio}>
                                <span style={{ whiteSpace: 'nowrap' }} title={userData?.last_name}>
                                    {userData?.last_name}
                                </span>{' '}
                                <span title={userData?.first_name}>{userData?.first_name}</span>{' '}
                                <span title={userData?.patronymic}>{userData?.patronymic}</span>
                            </div>
                        </Title>
                        <Text className={_.email} mb={8}>
                            {userData?.email}
                        </Text>
                        <Text mb={20}>{userData?.phone}</Text>
                    </div>
                </div>
                <div className={_.personDataButtonWrapper}>
                    <Button onClick={() => navigate('/cabinet/settings')} width='290px'>
                        Личные данные
                    </Button>
                </div>
            </div>
        );

    return (
        <div className={_.wrapper}>
            <Text mb={20}>{title}</Text>
            <Title mb={34} extra>
                {num}
            </Title>

            {icon && <img className={_.icon} src={icon} alt='icon' />}

            {btnText && (
                <div className={_.btnsWrapper}>
                    <Button width={addBtn ? '224px' : '290px'} onClick={() => to && navigate(to)}>
                        {btnText}
                    </Button>
                    {addBtn && (
                        <Tooltip title='Создать проект'>
                            <Link className={_.addBtn} to={addBtn}>
                                <img src={plus} alt='plus' />
                            </Link>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    );
};

export default Block;
