import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { openInfoNotification } from 'common/Notifications/Notifications';

import AuthForm from './components/Form/Form';

const RegisterPage = () => {
    const navigate = useNavigate();
    const { userData } = useContext(AuthContext);

    useEffect(() => {
        if (userData?.id) navigate('/cabinet');
    }, [userData?.id]);

    return (
        <>
            <Header />

            <AuthForm />

            <Footer />
        </>
    );
};

export default RegisterPage;
