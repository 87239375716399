import clsx from 'clsx';
import { FC, useState } from 'react';

import arrow from 'assets/arrowPagination.svg';

import _ from './PaginatorMock.module.css';

interface IPageProps {
    number: number;
    isActive?: boolean;
    onClick: (num: number) => void;
}
const Page: FC<IPageProps> = ({ number, isActive, onClick }) => (
    <div className={clsx(_.page, isActive && _.activePage)} onClick={() => onClick(number)}>
        {number}
    </div>
);

const pagesCount = 5;
const Pagination = () => {
    const [activePage, setActivePage] = useState(1);

    const incPage = () => setActivePage(activePage + 1);
    const decPage = () => setActivePage(activePage - 1);

    return (
        <div className={_.wrapper}>
            <div className={_.container}>
                {activePage > 1 && (
                    <div className={clsx(_.page, _.arrow, _.leftArrow)} onClick={decPage}>
                        <img src={arrow} alt='left-arrow-pagination' />
                    </div>
                )}

                {[...Array(pagesCount)].map((e, i) => (
                    <Page key={i} number={i + 1} isActive={activePage === i + 1} onClick={setActivePage} />
                ))}

                {activePage < 5 && (
                    <div className={clsx(_.page, _.arrow)} onClick={incPage}>
                        <img src={arrow} alt='right-arrow-pagination' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Pagination;
