import clsx from 'clsx';
import { FC } from 'react';

import _ from './Title.module.css';

interface ITitleProp {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    mb?: number | string;
    mt?: number | string;
    family?: string;
    width?: number | string;
    white?: boolean;
    extra?: boolean;
    main?: boolean;
    small?: boolean;
    bold?: boolean;
    medium?: boolean;
    middle?: boolean;
    wordWrapBW?: boolean;
    dangerouslySetInnerHTML?: {
        __html: any;
    };
    textAlign?: 'left' | 'center' | 'right';
}

const Title: FC<ITitleProp> = ({
    children,
    mb,
    mt,
    family,
    white,
    width,
    extra,
    main,
    medium,
    small,
    bold,
    middle,
    wordWrapBW,
    dangerouslySetInnerHTML,
    textAlign,
}) => (
    <div
        className={clsx(
            _.title,
            main && _.main,
            small && _.small,
            medium && _.medium,
            middle && _.middle,
            extra && _.extra,
            bold && _.bold,
            wordWrapBW && _.wordWrapBW,
        )}
        style={{
            fontFamily: family,
            marginBottom: mb,
            marginTop: mt,
            color: white ? '#fff' : '#000',
            maxWidth: width,
            textAlign,
        }}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
        {children}
    </div>
);

export default Title;
