import React, { createContext, useEffect, useState } from 'react';

import { ILanding } from 'components/LandingPage/LandingPage';
import { Landing } from 'connectors/query/Landing';

interface ILandingContextProps {
    codenames: string[];
    landingData: ILanding[];
    headerHighlight: 'contract_builder' | 'team' | false;
    setHeaderHighlight: React.Dispatch<React.SetStateAction<false | 'contract_builder' | 'team'>>;
}

const LandingContext = createContext<ILandingContextProps>({
    codenames: [],
    landingData: [],
    headerHighlight: false,
    setHeaderHighlight: () => {},
});

export const LandingProvider = ({ children }: { children: React.ReactNode }) => {
    const [codenames, setCodenames] = useState<string[]>([]);
    const [landingData, setLandingData] = useState<ILanding[]>([]);
    const [headerHighlight, setHeaderHighlight] = useState<'contract_builder' | 'team' | false>(false);

    const fetch = async () => {
        const { results } = await Landing.getAll();
        if (Array.isArray(results)) {
            setLandingData(results);

            const codenamesData = results.map((result: ILanding) => result.codename) as string[];
            setCodenames(codenamesData);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <LandingContext.Provider value={{ codenames, landingData, headerHighlight, setHeaderHighlight }}>
            {children}
        </LandingContext.Provider>
    );
};

export default LandingContext;
