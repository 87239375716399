import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from 'antd';

import PayForm from 'common/PayForm/PayForm';
import Calendar from 'common/Calendar/Calendar';
import { Hotline, IHotlineItem } from 'connectors/query/Hotline';
import Button from 'common/Button/Button';
import Text from 'common/StyledElements/Text/Text';
import { openInfoNotification } from 'common/Notifications/Notifications';
import AuthContext from 'common/AuthProvider/AuthProvider';
import Title from 'common/StyledElements/Title/Title';
import CalendarEvent from './components/CalendarEvent/CalendarEvent';

import _ from './CalendarBlock.module.css';

const CalendarBlock = () => {
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const { logged, hasLoaded, hotlineSubscr, isSubscrLoaded, subscrDiff } = useContext(AuthContext);
    const [eventsData, setEventsData] = useState<IHotlineItem[]>([]);
    const [eventsDataChangeCount, setEventsDataChangeCount] = useState<number>(0);
    const [eventsList, setEventsList] = useState<IHotlineItem[]>([]);
    const [currentShownMonth, setCurrentShownMonth] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date()));
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (
            pathname === '/practice' &&
            state &&
            state.showPracticeSubscriptionModal &&
            logged &&
            hasLoaded &&
            isSubscrLoaded &&
            !hotlineSubscr
        ) {
            setShowModal(true);
            window.history.replaceState({}, document.title);
        }
    }, [logged, hasLoaded]);

    useEffect(() => {
        getEventsData();
    }, [currentShownMonth]);

    useEffect(() => {
        eventsDataChangeCount === 1 && setThreeLeftEvents();
    }, [eventsData]);

    const getEventsData = async () => {
        const today = dayjs(currentShownMonth);
        const startDate = today.subtract(6, 'month');
        const endDate = today.add(6, 'month');

        const data = await Hotline.get({
            params: {
                ordering: 'starts_at',
                starts_at_gte: `${startDate.year()}-${startDate.month() + 1}-1`,
                starts_at_lte: `${endDate.year()}-${endDate.month() + 1}-${endDate.daysInMonth()}`,
            },
        });

        if (Array.isArray(data.results)) {
            setEventsData(data.results);
            setEventsDataChangeCount((prevState) => prevState + 1);
        }
    };

    const setThreeLeftEvents = (startDate: Date = new Date()) => {
        const firstIndex = eventsData
            .map((event) => {
                const date = new Date(event.starts_at);
                date.setHours(0, 0, 0, 0);
                startDate.setHours(0, 0, 0, 0);
                return startDate <= date;
            })
            .indexOf(true);
        const eventsDataCut = eventsData.slice(firstIndex, firstIndex + 3);
        setEventsList(eventsDataCut);
    };

    const handleSubscribe = async () => {
        if (!logged) {
            // PROD
            navigate('/auth', { state: { afterLoginAction: 'subsribe_to_practice' } });
            // openInfoNotification('Личный кабинет временно недоступен');
        } else {
            setShowModal(true);
        }
    };

    return (
        <div className={_.container}>
            <div className={_.titleSubscrButton}>
                <h2 className={_.title}>Календарь событий</h2>
                {((logged && hasLoaded && !hotlineSubscr && isSubscrLoaded) || (!logged && hasLoaded)) && (
                    <Button className={_.subscribeButton} width='445px' onClick={handleSubscribe}>
                        Купить
                    </Button>
                )}
                {logged && hasLoaded && hotlineSubscr && subscrDiff && (
                    <div className={_.renewSubscr} style={{ justifyContent: 'center' }}>
                        {subscrDiff && (
                            <Text mt={18} center small>
                                {`До окончания действия подписки ${subscrDiff.prevWord} `}
                                <b>{subscrDiff.timeLeft}</b>
                            </Text>
                        )}
                    </div>
                )}
            </div>

            <div className={_.main}>
                <div className={_.main__leftside}>
                    {eventsList.map((t) => (
                        <CalendarEvent
                            key={t.id}
                            date={new Date(t.starts_at)}
                            description={t.title}
                            type={t.event_type}
                            onClick={() => {
                                if (hotlineSubscr && logged) {
                                    t.event_type === 'webinar' && navigate(`/webinar/${t.id}`);
                                    t.event_type === 'lecture' && navigate(`/lecture/${t.id}`);
                                }
                            }}
                            clickable={!!hotlineSubscr && logged}
                        />
                    ))}
                </div>

                <div className={_.right__block}>
                    <Calendar
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        getUpcomingEvents={setThreeLeftEvents}
                        setCurrentShownMonth={setCurrentShownMonth}
                        currentShownMonth={currentShownMonth}
                        eventsDays={eventsData.map((event) => dayjs(new Date(event.starts_at)).startOf('day'))}
                    />
                </div>
            </div>

            <Modal
                open={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                width='700px'
                footer={null}
                centered
                destroyOnClose
            >
                <div className={_.modalInner}>
                    <Title mt={60} family='Manrope'>
                        Проверьте данные
                    </Title>
                    <PayForm
                        type='hotline'
                        closeModal={() => {
                            setShowModal(false);
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default CalendarBlock;
