import { FC } from 'react';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EllipsisOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Text from 'common/StyledElements/Text/Text';
import Link from 'common/StyledElements/Link/Link';
import { openInfoNotification } from 'common/Notifications/Notifications';
import StatusWrapper, { statusAliasProject, statusChecker } from 'common/StatusWrapper/StatusWrapper';
import { TSubscriptionStatusesWithTrial } from 'connectors/query/Subscriptions';

import _ from './CopyProjectModal.module.css';
import './styles.css';

export interface IFormattedProject {
    createdAt: string;
    name: string;
    activeTo: string | null;
    organizer_organization: { id: number; name: string } | null;
    module: string | null;
    status: TSubscriptionStatusesWithTrial | null;
    organizer_user: number;
    id: number;
}

type ColumnsTypeContragents = (offset: number) => ColumnsType<IFormattedProject>;

const columns: ColumnsTypeContragents = (offset) => [
    // {
    //     title: '№',
    //     dataIndex: 'id',
    //     key: 'id',
    //     render: (pos, obj, i) => <Text mid>{i + 1 + offset}</Text>,
    // },
    {
        title: 'Создан',
        dataIndex: 'createdAt',
        key: 'createdAt',
        // eslint-disable-next-line no-nested-ternary
        sorter: ({ createdAt: a }, { createdAt: b }) => (a > b ? 1 : a < b ? -1 : 0),
        sortDirections: ['descend', 'ascend'],
        render: (createdAt: string) => <Text mid>{dayjs(createdAt).format('DD.MM.YYYY')}</Text>,
    },
    {
        title: 'Название проекта',
        dataIndex: 'name',
        key: 'name',
        // eslint-disable-next-line no-nested-ternary
        sorter: ({ name: a }, { name: b }) => (a > b ? 1 : a < b ? -1 : 0),
        sortDirections: ['descend', 'ascend'],
        render: (name, proj) => (
            <div className={_.nameWrapper}>
                <Text mid>{name}</Text>
            </div>
        ),
    },
    // {
    //     title: 'Организация',
    //     dataIndex: 'organizer_organization',
    //     key: 'organizer_organization',
    //     sorter: (aa, bb) => {
    //         const a = aa.organizer_organization ? aa.organizer_organization.name : '-';
    //         const b = bb.organizer_organization ? bb.organizer_organization.name : '-';
    //         // eslint-disable-next-line no-nested-ternary
    //         return a > b ? 1 : a < b ? -1 : 0;
    //     },
    //     sortDirections: ['descend', 'ascend'],
    //     render: (organizer_organization) => (
    //         <div style={{ maxWidth: 115 }}>
    //             <Text mid center={!organizer_organization}>
    //                 {organizer_organization ? organizer_organization.name : '-'}
    //             </Text>
    //         </div>
    //     ),
    // },
    {
        title: 'Модуль',
        dataIndex: 'module',
        key: 'module',
        sorter: ({ module: aa }, { module: bb }) => {
            const a = aa || '-';
            const b = bb || '-';
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        render: (module) => <Text mid>{module || '-'}</Text>,
    },
    {
        title: 'Активен до',
        dataIndex: 'activeTo',
        key: 'activeTo',
        sorter: ({ activeTo: aa }, { activeTo: bb }) => {
            const a = aa || '-';
            const b = bb || '-';
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        render: (activeTo) => (
            <Text mid center={!activeTo}>
                {dayjs(activeTo).isValid() ? dayjs(activeTo).format('DD.MM.YYYY') : '-'}
            </Text>
        ),
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: ({ status: aa }, { status: bb }) => {
            const [_a, a, __a] = statusChecker(aa as string, statusAliasProject);
            const [_b, b, __b] = statusChecker(bb as string, statusAliasProject);
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        render: (status) => (
            <div style={{ maxWidth: 115, display: 'flex', justifyContent: status ? 'flex-start' : 'center' }}>
                {status ? <StatusWrapper text={status} statusAliases={statusAliasProject} /> : '-'}
            </div>
        ),
    },
];

export default columns;
