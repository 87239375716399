import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import DatePicker from 'common/DatePicker/DatePicker';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import validationRules from 'utils/formValidationRules';

interface IBasicDetails {
    onInputChange: (key: string, value: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    form: FormInstance;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    isSubscrActiveOrCreate: boolean;
}

const BasicDetails: React.FC<IBasicDetails> = ({ form, onInputChange, onDateChange, isSubscrActiveOrCreate }) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Основные реквизиты</div>

            <MyFormItem
                name='full_name_org'
                label='Полное наименование'
                rules={[{ required: true, message: 'Пожалуйста, введите полное наименование!' }]}
            >
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('full_name_org', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <MyFormItem name='short_name_org' label='Краткое наименование'>
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('short_name_org', event.target.value)}
                    maxLength={64}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <div className='shortInnersBlock'>
                <MyFormItem
                    name='inn'
                    label='ИНН'
                    rules={[{ required: true, message: 'Пожалуйста, введите ИНН!' }, validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('inn', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='birth_date' label='Дата рождения'>
                    <DatePicker
                        className='inputInner inputInnerShort2'
                        onChange={onDateChange('birth_date')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default BasicDetails;
