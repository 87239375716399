import { FC, useContext, useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import localforage from 'localforage';
import { FieldData } from 'rc-field-form/es/interface';

import { Users } from 'connectors/query/Users';
import Title from 'common/StyledElements/Title/Title';
import AuthContext, { IUserData } from 'common/AuthProvider/AuthProvider';
import Button from 'common/Button/Button';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import validationRules from 'utils/formValidationRules';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';

import _ from './Settings.module.css';


interface IModalPassProps {
    showModal: boolean;
    setShowModal: (b: boolean) => void;
    changeAction: () => void;
}

export const ModalPass: FC<IModalPassProps> = ({ showModal, setShowModal, changeAction }) => (
    <Modal
        className={_.modalCustom}
        open={showModal}
        onOk={() => {
            setShowModal(false);
        }}
        onCancel={() => {
            setShowModal(false);
        }}
        width='700px'
        footer={null}
        centered
    >
        <div className={_.modalWrapper}>
            <div style={{ height: 250 }} className={_.modalContainer}>
                <Title mb={30}>Подтвердите изменение пароля</Title>

                <Button onClick={changeAction} width='260px'>
                    Изменить пароль
                </Button>
            </div>
        </div>
    </Modal>
);

interface IModalDeleteProps {
    showModal: boolean;
    setShowModal: (b: boolean) => void;
    deleteAction: () => void;
}

export const ModalDelete: FC<IModalDeleteProps> = ({ showModal, setShowModal, deleteAction }) => (
    <Modal
        className={_.modalCustom}
        open={showModal}
        onOk={() => {
            setShowModal(false);
        }}
        onCancel={() => {
            setShowModal(false);
        }}
        footer={null}
        centered
    >
        <div className={_.modalWrapper}>
            <div style={{ height: 250 }} className={_.modalContainer}>
                <Title mb={30} small>
                    Вы уверены, что хотите удалить аккаунт?
                </Title>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: 430 }}>
                    <Button width='160px' onClick={deleteAction}>
                        Удалить
                    </Button>
                    <Button width='160px' onClick={() => setShowModal(false)}>
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    </Modal>
);

interface IModalChangeDataProps {
    type: 'fio' | 'email' | 'phone';
    showModal: boolean;
    setShowModal: (b: boolean) => void;
    changeEmail: (email?: string) => () => Promise<{
        status: string;
        errors?: FieldData[];
    }>;
    setNewEmail: (email: string) => void;
    setGettedUserData: (user: IUserData) => void;
}

const title = {
    fio: 'Изменить ФИО',
    email: 'Изменить E-mail',
    phone: 'Изменить телефон',
};

const heightType = {
    fio: 'auto',
    email: 420,
    phone: 320,
};

const validateMessages = {
    required: 'Обязательное поле',
};

export const ModalChangeData: FC<IModalChangeDataProps> = ({
    setShowModal,
    showModal,
    type,
    changeEmail,
    setNewEmail,
    setGettedUserData,
}) => {
    const { userData, checkToken } = useContext(AuthContext);
    const [form] = Form.useForm();

    const resetData = () => {
        form.setFieldsValue({
            first_name: userData?.first_name,
            last_name: userData?.last_name,
            patronymic: userData?.patronymic,
            phone: userData?.phone,
        });
    };

    const submitAction = async ({ email, emailConfirm }: { email: string; emailConfirm: string }) => {
        if (type === 'email') {
            if (email !== emailConfirm) {
                return openErrorNotification('Ошибка!', 'E-mail не совпадают!');
            }
            setNewEmail(email);
            const func = changeEmail(email);
            const result = await func();
            result.errors && form.setFields(result.errors);
        }
    };

    const onFinish = async (values: any) => {
        if (userData) {
            const valuesData = { ...values };
            if (values.phone) {
                valuesData.phone = values.phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('+', '');
            }
            const res = await Users.patchUserData({ id: userData.id, ...valuesData });

            if (res?.id) {
                await localforage.setItem('userData', res);
                setGettedUserData(res);
                checkToken();
                openSuccessNotification('Обновление прошло успешно');
                setShowModal(false);
            }

            if (res?.response?.status === 400 && res?.response?.data) {
                const errors: FieldData[] = [];
                Object.keys(res?.response?.data).forEach((key) => {
                    errors.push({ name: key, errors: res?.response?.data[key] });
                });
                form.setFields(errors);
            }
        }
    };

    useEffect(() => {
        resetData();
    }, [JSON.stringify(userData)]); // eslint-disable-line

    return (
        <Modal
            className={_.modalCustom}
            open={showModal}
            onCancel={() => {
                setShowModal(false);
                resetData();
            }}
            footer={null}
            width={700}
            centered
        >
            <div className={_.modalWrapper}>
                <div style={{ height: heightType[type] }} className={_.modalContainer}>
                    <Title mb={30}>{title[type] || ''}</Title>

                    {(type === 'fio' || type === 'phone') && (
                        <Form
                            name='fio'
                            className='authForm'
                            form={form}
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            {type === 'fio' && (
                                <>
                                    <Form.Item
                                        name={['last_name']}
                                        label='Фамилия'
                                        rules={validationRules.surnameRules}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className={_.elParamWrapper}
                                    >
                                        <Input
                                            maxLength={64}
                                            className={_.elParam}
                                            onBlur={() => handleInputBlur(form, 'last_name')}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={['first_name']}
                                        label='Имя'
                                        rules={validationRules.nameRules}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className={_.elParamWrapper}
                                    >
                                        <Input
                                            maxLength={64}
                                            className={_.elParam}
                                            onBlur={() => handleInputBlur(form, 'first_name')}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name={['patronymic']}
                                        rules={validationRules.patronymicRules}
                                        label='Отчество'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className={_.elParamWrapper}
                                    >
                                        <Input
                                            maxLength={64}
                                            className={_.elParam}
                                            onBlur={() => handleInputBlur(form, 'patronymic')}
                                        />
                                    </Form.Item>
                                </>
                            )}

                            {type === 'phone' && (
                                <Form.Item
                                    name={['phone']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Поле «Номер мобильного телефона» обязательно для заполнения',
                                        },
                                        {
                                            message: 'Введите корректный номер телефона',
                                            pattern: /^\+?[1-9]\d{5,13}$|^[1-9]\d{5,14}$/,
                                        },
                                    ]}
                                    label='Телефон'
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    className={_.elParamWrapper}
                                >
                                    <Input
                                        maxLength={15}
                                        className={_.elParam}
                                        onBlur={() => handleInputBlur(form, 'phone')}
                                    />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button style={{ marginTop: 20 }} width='400px' htmlType='submit'>
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                    )}

                    {type === 'email' && (
                        <Form
                            name='email'
                            className='authForm'
                            form={form}
                            onFinish={submitAction}
                            validateMessages={validateMessages}
                        >
                            <Form.Item
                                name={['email']}
                                label='E-mail'
                                rules={validationRules.emailRules}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={_.elParamWrapper}
                            >
                                <Input
                                    maxLength={320}
                                    className={_.elParam}
                                    onBlur={() => handleInputBlur(form, 'email')}
                                />
                            </Form.Item>

                            <Form.Item
                                name={['emailConfirm']}
                                label='Подтвердите E-mail'
                                rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(obj, value) {
                                            if (!value || getFieldValue('email') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Данные не совпадают'));
                                        },
                                    }),
                                ]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={_.elParamWrapper}
                            >
                                <Input
                                    maxLength={320}
                                    className={_.elParam}
                                    onBlur={() => handleInputBlur(form, 'emailConfirm')}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button style={{ marginTop: 20 }} width='400px' htmlType='submit'>
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </div>
            </div>
        </Modal>
    );
};
