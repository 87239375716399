import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Slider from 'react-slick';
import clsx from 'clsx';
import { Table, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CloseOutlined } from '@ant-design/icons';

import Header from 'common/Header/Header';
import Footer from 'common/Footer/Footer';
import Container from 'common/StyledElements/Container/Container';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import Text from 'common/StyledElements/Text/Text';
import download from 'assets/download.svg';
import Button from 'common/Button/Button';
import { ILectureData, Lecture } from 'connectors/query/Lecture';
import AuthContext from 'common/AuthProvider/AuthProvider';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import VideoPlayer from 'common/VideoPlayer/VideoPlayer';
import { openErrorNotification } from 'common/Notifications/Notifications';
import TypeHotline, { ETypeHotline } from 'common/TypeHotline/TypeHotline';
import FotoCard from './components/FotoCard/FotoCard';

import _ from './ArticlePage.module.css';
import './styles.css';

interface IBreadcrumbRoute {
    path: string;
    breadcrumbName: string;
}

export interface DocsType {
    name: string;
    weight: string;
    action: string;
}

type ColumnsDocsType = () => ColumnsType<DocsType>;

export const getDocsFromLectureData = (lectureData: ILectureData): DocsType[] =>
    lectureData?.attachments?.map((attachment): DocsType => {
        const sizeInKB = attachment.size / 1024;
        const sizeInMB = sizeInKB / 1024;
        const weightString =
            sizeInMB > 1
                ? `${attachment.extension}, ${sizeInMB.toFixed(2)} мб`
                : `${attachment.extension}, ${sizeInKB.toFixed(2)} кб`;

        return {
            name: attachment.description,
            weight: weightString,
            action: attachment.attachment_url,
        };
    }) || [];

const columns: ColumnsDocsType = () => [
    {
        dataIndex: 'name',
        key: 'name',
        render: (name) => (
            <div className={_.nameWrapper}>
                <Text>{name}</Text>
            </div>
        ),
        width: 700,
    },
    {
        dataIndex: 'weight',
        key: 'weight',
        render: (weight) => (
            <div className={_.weightWrapper}>
                <Text mid>{weight}</Text>
            </div>
        ),
        width: 200,
    },
    {
        dataIndex: 'action',
        key: 'action',
        render: (action) => (
            <div
                className={_.download}
                onClick={() => {
                    window.open(action, '_blank');
                }}
            >
                <Text className={_.downloadText} mid>
                    Скачать
                </Text>
                <img src={download} alt='download' />
            </div>
        ),
    },
];

const ArticlePage = () => {
    const navigate = useNavigate();
    const [lectureItems, setLectureItems] = useState<ILectureData | null>(null);
    const title = lectureItems?.title || '';
    const { id } = useParams();
    const { hasLoaded, logged, isSubscrLoaded, hotlineSubscr } = useContext(AuthContext);
    const [docs, setDocs] = useState<DocsType[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [bodyText, setBodyText] = useState('');

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (id) {
    //             const lectureData = await Lecture.getById(id);
    //             const docs = getDocsFromLectureData(lectureData);
    //             setDocs(docs);
    //             setBodyText(lectureData.body); // Обновляем состояние bodyText
    //         }
    //     };

    //     fetchData();
    // }, [id]);

    useEffect(() => {
        if (hasLoaded && !logged) {
            navigate('/practice');
            return;
        }

        if (hasLoaded && isSubscrLoaded) {
            if (hotlineSubscr) {
                getLectureInFo();
            } else {
                navigate('/practice');
            }
        }
    }, [id, hasLoaded, logged, isSubscrLoaded, hotlineSubscr]);

    const routes: IBreadcrumbRoute[] = [
        {
            path: '',
            breadcrumbName: 'Главная',
        },
        {
            path: 'practice',
            breadcrumbName: 'КИНО И ПРАВО',
        },
        {
            path: 'lectures',
            breadcrumbName: 'Лекции и вебинары',
        },
    ];

    const getLectureInFo = async () => {
        if (id) {
            const data = await Lecture.getById(id);
            if (data?.id) {
                setLectureItems(data);
                const docs = getDocsFromLectureData(data);
                setDocs(docs);
                setBodyText(data.body); // Обновляем состояние bodyText
            } else {
                navigate('/practice');
                openErrorNotification('Лекция не найдена.');
            }
        }
    };

    const handleCardClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    return (
        <>
            <Header />

            <Container column gray>
                <div className={clsx(_.container, _.breadcrumb)}>
                    <Breadcrumbs routes={routes} />
                </div>

                <div className={_.main}>
                    <div className={_.leftBlock}>
                        <div className={_.typeWebinar}>
                            <TypeHotline type={[ETypeHotline.Lecture]} />
                        </div>
                        <div className={_.title}>{logoReplacer(title)}</div>
                        <div className={_.subTitle}>{logoReplacer(lectureItems?.description)}</div>
                    </div>

                    <div className={_.rightBlock}>
                        {lectureItems?.preview && (
                            <img src={lectureItems?.preview} className={_.rightBlockImg} alt='lecture preview' />
                        )}
                    </div>
                </div>
            </Container>

            {hasLoaded && logged && isSubscrLoaded && hotlineSubscr ? (
                <Container style={{ paddingTop: 50 }} column>
                    <div className={_.mainBlockImg}>
                        <div className={_.videoWrapper}>
                            {lectureItems?.mediafile ? (
                                <VideoPlayer
                                    url={lectureItems?.mediafile}
                                    light={lectureItems?.mediafile_preview}
                                    width='1110px'
                                    height='100%'
                                />
                            ) : (
                                lectureItems?.mediafile_preview && (
                                    <img
                                        className={_.videoBox}
                                        src={lectureItems?.mediafile_preview}
                                        alt='VideoPlayerIMG'
                                    />
                                )
                            )}
                        </div>
                    </div>

                    <div className={_.mainBlockText1} dangerouslySetInnerHTML={{ __html: bodyText }} />

                    <div className={_.docs}>
                        <div className={_.docsTitle}>Документы</div>

                        <Table
                            className='docsTable'
                            showHeader={false}
                            columns={columns()}
                            dataSource={docs}
                            pagination={false}
                        />
                    </div>

                    {lectureItems?.images && lectureItems.images.length > 0 && (
                        <div className={_.mainBlockSlider}>
                            <div className={_.titleBlockSlider}>Фотоматериалы</div>

                            <Slider
                                className={clsx(_.cardsContainer, 'customSlider', 'fotoSliderArticle')}
                                slidesToShow={Math.min(4, lectureItems?.images?.length || 0)}
                            >
                                {lectureItems?.images?.map((image, i) => (
                                    <div
                                        key={i}
                                        style={{ width: 255 }}
                                        onClick={() => handleCardClick(image.attachment_url)}
                                    >
                                        <FotoCard pic={image.attachment_url} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    )}

                    <div className={_.sliderBottom}>
                        <Button
                            width='255px'
                            grey
                            onClick={() => {
                                navigate('/lectures');
                            }}
                        >
                            К списку лекций
                        </Button>
                    </div>
                </Container>
            ) : (
                <div style={{ height: 300 }} />
            )}

            <Modal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width='auto'
                className='imageModal'
                closeIcon={<CloseOutlined style={{ color: 'white' }} />}
                centered
            >
                {selectedImage && <img src={selectedImage} alt='selected' className={_.imageInModal} />}
            </Modal>

            <Footer />
        </>
    );
};

export default ArticlePage;
