import { FC, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import logo from 'assets/logo.svg';
import AuthContext from 'common/AuthProvider/AuthProvider';
import PersonAvatar from 'common/PersonAvatar/PersonAvatar';
import { ScrollPoints } from 'components/LandingPage/LandingPage';
import { openInfoNotification } from 'common/Notifications/Notifications';
import LandingContext from 'common/LandingProvider/LandingProvider';
import Button from './components/Button/Button';

import _ from './Header.module.css';

interface IHeaderProps {
    scrollToEl?: (el: any) => void;
}

const Header: FC<IHeaderProps> = ({ scrollToEl }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData, logged, hasLoaded } = useContext(AuthContext);
    const landingContextData = useContext(LandingContext);
    const { codenames, headerHighlight } = landingContextData;

    return (
        <div className={clsx(_.headerWrapper, _.border)}>
            <div className={_.header}>
                <img
                    src={logo}
                    alt='logo'
                    onClick={() => {
                        location.pathname === '/' || location.pathname === '/landing'
                            ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                            : navigate('/');
                    }}
                    className={_.logo}
                />

                <div className={_.btns}>
                    {codenames.includes('contract_builder') && (
                        <Button
                            text='Конструктор договоров'
                            black={headerHighlight === 'contract_builder' && location.pathname === '/'}
                            onClick={() => {
                                scrollToEl
                                    ? scrollToEl(ScrollPoints.EditorPoint)
                                    : navigate('/', {
                                          state: {
                                              scrolling: ScrollPoints.EditorPoint,
                                          },
                                      });
                            }}
                        />
                    )}
                    {!logged && hasLoaded && (
                        <Button
                            text='Цены'
                            black={location.pathname === '/modules'}
                            onClick={() => {
                                location.pathname === '/modules'
                                    ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                    : navigate('/modules');
                            }}
                        />
                    )}
                    <Button
                        text='КИНО И ПРАВО'
                        black={location.pathname === '/practice'}
                        onClick={() => {
                            location.pathname === '/practice'
                                ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                : navigate('/practice');
                        }}
                    />
                    <Button
                        text='Q&A'
                        black={location.pathname === '/faq'}
                        onClick={() => {
                            location.pathname === '/faq'
                                ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                : navigate('/faq');
                        }}
                    />
                    <Button
                        text='Новости'
                        black={location.pathname === '/news'}
                        onClick={() => {
                            location.pathname === '/news'
                                ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                : navigate('/news');
                        }}
                    />
                    {codenames.includes('team') && (
                        <Button
                            text='Команда'
                            black={headerHighlight === 'team' && location.pathname === '/'}
                            onClick={() => {
                                scrollToEl
                                    ? scrollToEl(ScrollPoints.TeamPoint)
                                    : navigate('/', {
                                          state: {
                                              scrolling: ScrollPoints.TeamPoint,
                                          },
                                      });
                            }}
                        />
                    )}
                    <Button
                        text='Контакты'
                        black={location.pathname === '/contacts'}
                        onClick={() => {
                            location.pathname === '/contacts'
                                ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                : navigate('/contacts');
                        }}
                    />
                </div>

                {logged && userData && (
                    <PersonAvatar
                        firstName={userData.first_name}
                        lastName={userData.last_name}
                        menu
                        photo={userData.customer_profile?.photo}
                    />
                )}
                {!logged && (
                    <Button
                        text='Начать работу'
                        background
                        onClick={() => {
                            
                            // PROD
                            navigate('/auth');
                            // openInfoNotification('Личный кабинет временно недоступен');
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default Header;
