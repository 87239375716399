import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import clsx from 'clsx';

import _ from './Button.module.css';

interface IButtonProps {
    text: string;
    background?: boolean;
    to?: string;
    onClick?: () => void;
}

const Button: FC<IButtonProps> = ({ text, background, to, onClick }) => {
    const navigate = useNavigate();

    return (
        <div
            className={clsx(_.button, background && _.background)}
            onClick={() => (onClick && onClick()) || (to && navigate(to))}
        >
            {text}
        </div>
    );
};

export default Button;
