import person from 'assets/cabinet/person.png';

export interface PeopleType {
    id: number;
    icon: string;
    name: string;
    projects: string[];
}

export const people: PeopleType[] = [
    {
        id: 1,
        icon: person,
        name: 'Николаев Михаил Сергеевич',
        projects: [
            'Развитие креативных индустрий и арт-кластеров в регионах России',
            'Фонд поддержки регионального кинематографа, учреждённый Союзом',
        ],
    },
    {
        id: 2,
        icon: person,
        name: 'Виноградова Татьяна Викторовна',
        projects: ['Развитие креативных индустрий и арт-кластеров в регионах России'],
    },
    {
        id: 3,
        icon: person,
        name: 'Суворов Александр Дмитриевич',
        projects: ['Фонд поддержки регионального кинематографа, учреждённый Союзом'],
    },
    {
        id: 4,
        icon: person,
        name: 'Николаев Михаил Сергеевич',
        projects: [
            'Развитие креативных индустрий и арт-кластеров в регионах России',
            'Фонд поддержки регионального кинематографа, учреждённый Союзом',
        ],
    },
    {
        id: 5,
        icon: person,
        name: 'Виноградова Татьяна Викторовна',
        projects: ['Развитие креативных индустрий и арт-кластеров в регионах России'],
    },
    {
        id: 6,
        icon: person,
        name: 'Суворов Александр Дмитриевич',
        projects: ['Фонд поддержки регионального кинематографа, учреждённый Союзом'],
    },
    {
        id: 7,
        icon: person,
        name: 'Николаев Михаил Сергеевич',
        projects: [
            'Развитие креативных индустрий и арт-кластеров в регионах России',
            'Фонд поддержки регионального кинематографа, учреждённый Союзом',
        ],
    },
];
