import { FC } from 'react';

import icon from 'assets/why/2.svg';
import Text from 'common/StyledElements/Text/Text';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './Reason.module.css';

interface IReasonProps {
    pic: string;
    description: string;
    title: string;
}

const Reason: FC<IReasonProps> = ({ pic, description, title }) => (
    <div className={_.container}>
        <img className={_.img} src={pic || icon} alt='icon' />

        <div className={_.title}>{logoReplacer(title)}</div>

        <Text mid gray>
            {logoReplacer(description)}
        </Text>
    </div>
);

export default Reason;
