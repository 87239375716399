import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import DatePicker from 'common/DatePicker/DatePicker';
import validationRules from 'utils/formValidationRules';

interface IRegistrationData {
    onInputChange: (key: string, value: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    form: FormInstance;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    orgPropertiesIdForEdit?: number;
    isSubscrActiveOrCreate: boolean;
}

const RegistrationData: React.FC<IRegistrationData> = (
    { form, onInputChange, onDateChange, orgPropertiesIdForEdit, isSubscrActiveOrCreate }, // Добавьте form здесь
) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Данные о регистрации</div>

            <div className='shortInnersBlock'>
                <MyFormItem
                    name='ogrnip'
                    label='ОГРНИП'
                    rules={[validationRules.digitsOnly, { required: true, message: 'Пожалуйста, введите ОГРНИП!' }]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('ogrnip', event.target.value)}
                        maxLength={13}
                        disabled={!!orgPropertiesIdForEdit || !isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='registration_date' label='Дата регистрации'>
                    <DatePicker
                        className='inputInner inputInnerShort2'
                        onChange={onDateChange('registration_date')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>

                <MyFormItem
                    name='ip_series_registration_certificate'
                    label='Серия свидетельства о регистрации'
                    rules={[validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('ip_series_registration_certificate', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='ip_number_registration_certificate'
                    label='Номер свидетельства о регистрации'
                    rules={[validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('ip_number_registration_certificate', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);
export default RegistrationData;
