import { FC } from 'react';
import Slider from 'react-slick';

import clsx from 'clsx';

import Container from 'common/StyledElements/Container/Container';
import Title from 'common/StyledElements/Title/Title';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import BigCard from './components/BigCard/BigCard';

import _ from './Reviews.module.css';
import './styles.css';

interface IReviews {
    id: number;
    first_name: string;
    last_name: string;
    position: string;
    company: string;
    avatar: string;
    text: string;
}

interface IReviewsProps {
    reviewsData: any;
}

const Reviews: FC<IReviewsProps> = ({ reviewsData }) => (
    <Container column>
        <Title mb={40}>{logoReplacer(reviewsData.title)}</Title>
        <Slider className={clsx(_.cardsContainer, 'customSlider', 'personSliderReviews')} dots>
            {Array.isArray(reviewsData.reviews) &&
                reviewsData.reviews.map((review: IReviews) => (
                    <div key={review.id} style={{ display: 'flex', width: 284 }}>
                        <BigCard {...review} />
                    </div>
                )) }
        </Slider>
    </Container>
);

export default Reviews;
