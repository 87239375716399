import paykeeper from 'assets/paykeeper.png';

import _ from './Paykeeper.module.css';

const Paykeeper = () => (
    <div className={_.paykeeper}>
        <img src={paykeeper} alt='paykeeper' />
    </div>
);

export default Paykeeper;
