import { FC } from 'react';

import Text from 'common/StyledElements/Text/Text';
import Divider from 'common/StyledElements/Divider/Divider';

import _ from './Row.module.css';

interface IRow {
    title: string;
    text: string;
}

const Row: FC<IRow> = ({ title, text }) => (
    <>
        <div className={_.row}>
            <div className={_.rowLeft}>
                <Text className={_.text} lightGray mid>
                    {title}
                </Text>
                <div>{text}</div>
            </div>
        </div>

        <Divider />
    </>
);

export default Row;
