import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Text from 'common/StyledElements/Text/Text';
import ModuleCards from 'common/ModuleCards/ModuleCards';
import Container from 'common/StyledElements/Container/Container';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { openInfoNotification } from 'common/Notifications/Notifications';
import RateSteps from './components/RateSteps/RateSteps';
import Top from '../../common/Top/Top';
import PayOption from './components/PayOption/PayOption';

import _ from './ModulePage.module.css';

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'modules',
        breadcrumbName: 'Цены',
    },
];

const ModulePage = () => {
    const navigate = useNavigate();
    const { logged, hasLoaded } = useContext(AuthContext);

    useEffect(() => {
        if (logged && hasLoaded) navigate('/cabinet');
    }, [logged, hasLoaded]);

    return (
        <>
            <Header />

            <Top title='Модули и тарифы' />

            <Container style={{ paddingTop: 30 }} column>
                <div className={clsx(_.container, _.breadcrumb)}>
                    <Breadcrumbs routes={routes} />
                </div>
                <div className={_.titleContainer}>
                    <Title mb={30}>Выберите свой модуль и тариф</Title>
                    <Text mb={75} width={932} center>
                        Воспользуйтесь нашим онлайн конструктором для создания документов под ваши индивидуальные
                        проектные условия конкретного фильма.
                    </Text>
                    <ModuleCards />
                </div>
            </Container>
            <Container gray style={{ paddingTop: 30 }} column>
                <div className={_.titleContainer}>
                    <Title mb={30}>Всего 3 шага к началу проекта</Title>
                </div>
                <RateSteps />
                <Button
                    marginTop='40px'
                    marginBottom='80px'
                    width='290px'
                    onClick={() => {
                        // if (logged) {
                        //     navigate('/cabinet/projects/add');
                        //     // openInfoNotification('Проект пока создать нельзя');
                        //     // stage prod напоминалка
                        // } else {
                        //     navigate('/auth');
                        // }

                        // PROD
                        navigate('/auth');
                        // openInfoNotification('Личный кабинет временно недоступен');
                    }}
                >
                    Начать работу
                </Button>
                <div>
                    <PayOption />
                </div>
            </Container>

            <Footer />
        </>
    );
};

export default ModulePage;
