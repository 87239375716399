import { FC } from 'react';
import dayjs from 'dayjs';

import Title from 'common/StyledElements/Title/Title';
import Divider from 'common/StyledElements/Divider/Divider';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './Row.module.css';

interface IRowProps {
    starts_at: string;
    title: string;
    isLast?: boolean;
}

const Row: FC<IRowProps> = ({ starts_at, title, isLast }) => {
    const date = dayjs(starts_at);
    return (
        <div className={_.wrapper}>
            <div className={_.dateWrapper}>
                <div className={_.date}>{date.format('DD.MM.YYYY')}</div>
            </div>
            <div className={_.text} title={logoReplacer(title, 'plainText')}>
                <Title small>{logoReplacer(title)}</Title>
            </div>

            {!isLast && <Divider />}
        </div>
    );
};

export default Row;
