import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import clsx from 'clsx';
import _ from './Top.module.css';

const title = 'Контакты';

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'contacts',
        breadcrumbName: title,
    },
];

const Top = () => (
    <div className={_.wrapper}>
        <div className={clsx(_.container, _.breadcrumb)}>
            <Breadcrumbs routes={routes} />
        </div>
        <div className={_.text}>{title}</div>
    </div>
);

export default Top;
