/* eslint-disable no-underscore-dangle */

import dayjs from 'dayjs';

/* eslint-disable @typescript-eslint/ban-types */
export const assignPropertyToObject = (obj: Record<string, any>, key: string, value: any) => {
    const result = obj;
    result[key] = value;
};
export const flattenObject = (obj: Object, parentKey?: string) => {
    let result = {};

    Object.keys(obj).forEach((key) => {
        const value = obj[key as keyof typeof obj];
        const _key = parentKey ? `${parentKey}.${key}` : key;
        if (typeof value === 'object') {
            result = { ...result, ...flattenObject(value, _key) };
        } else {
            assignPropertyToObject(result, _key, value);
        }
    });

    return result;
};
// titles вида ['год','года','лет'] для 1 год, 2 года, 5 лет и т.д
export const createLabelByNumber = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
};

export const dayDiff = (date: string) => {
    if (!dayjs(date).isValid) {
        return { days: 0, hours: 0, minutes: 0 };
    }
    const date1 = dayjs();
    const date2 = dayjs(date);

    let minutes = date2.diff(date1, 'minutes');
    const days = Math.floor(minutes / 24 / 60);
    minutes -= days * 24 * 60;
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    return { days, hours, minutes };
};

export interface ITimeLeftLabels {
    prevWord: string;
    timeLeft: string;
}
export const createTimeLeftLabel: (timeObj: { days: number; hours: number; minutes: number }) => ITimeLeftLabels = (
    timeObj,
) => {
    if (timeObj.days > 0) {
        return {
            prevWord: createLabelByNumber(timeObj.days, ['остался', 'осталось', 'осталось']),
            timeLeft: `${timeObj.days} ${createLabelByNumber(timeObj.days, ['день', 'дня', 'дней'])}`,
        };
    }
    if (timeObj.days === 0 && timeObj.hours > 0) {
        return {
            prevWord: createLabelByNumber(timeObj.days, ['остался', 'осталось', 'осталось']),
            timeLeft: `${timeObj.hours} ${createLabelByNumber(timeObj.hours, ['час', 'часа', 'часов'])}`,
        };
    }
    if (timeObj.days === 0 && timeObj.hours === 0 && timeObj.minutes > 0) {
        return {
            prevWord: createLabelByNumber(timeObj.days, ['остался', 'осталось', 'осталось']),
            timeLeft: `${timeObj.minutes} ${createLabelByNumber(timeObj.minutes, ['минута', 'минуты', 'минут'])}`,
        };
    }
    if (timeObj.days === 0 && timeObj.hours === 0 && timeObj.minutes === 0) {
        return {
            prevWord: 'осталась',
            timeLeft: '1 минута',
        };
    }

    return { prevWord: '', timeLeft: '' };
};
