import { useRef, useState, useCallback, FC } from 'react';

import ReactPlayer from 'react-player/lazy';

import play from 'assets/why/play.svg';
import stop from 'assets/why/stop.svg';
import VideoPlayer from 'common/VideoPlayer/VideoPlayer';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './Video.module.css';


interface IVideoProps {
    video?: string;
    video_description?: string;
    video_preview?: string;
}

const Video: FC<IVideoProps> = ({ video = '', video_description, video_preview }) => {
    const [showVideo, setShowVideo] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [wasPlaying, setWasPlaying] = useState(false);
    const playerRef = useRef<ReactPlayer | null>(null);

    const handleClick = useCallback(() => {
        setShowVideo(true);
        setIsPlaying(true);
    }, []);

    const handleStop = () => {
        playerRef.current?.getInternalPlayer()?.pause();
        setIsPlaying(false);
        setIsPaused(false);
        setWasPlaying(isPlaying); // сохраняем информацию о том, что видео было запущено
    };

    const handlePlay = useCallback(() => {
        const player = playerRef.current?.getInternalPlayer();
        if (player) {
            player.play();
        }
        setIsPlaying(true);
        setIsPaused(false);
    }, []);

    const handlePause = useCallback(() => {
        const player = playerRef.current?.getInternalPlayer();
        if (player) {
            player.pause();
        }
        setIsPaused(true);
    }, []);

    const handleResume = () => {
        setIsPaused(false);
        if (wasPlaying) {
            // проверяем, было ли видео запущено до остановки
            playerRef.current?.getInternalPlayer()?.play();
            setIsPlaying(true);
        }
    };

    if (!video || !video_preview) {
        return null;
    }

    return (
        <div className={_.container}>
            <div className={_.videoContainer}>
                {!showVideo && <img src={video_preview} alt='Preview' onClick={handleClick} />}
                {showVideo && (
                    <VideoPlayer
                        url={video || ''}
                        isPlaying={isPlaying}
                        onPlay={handleResume}
                        onPause={handlePause}
                        width='800px'
                        height='450px'
                        playerRef={playerRef}
                    />
                )}
            </div>
            <div className={_.playContainer}>
                {showVideo && isPlaying && !isPaused && (
                    <img className={_.stop} src={stop} alt='stop' onClick={handleStop} />
                )}
                {showVideo && !isPlaying && !isPaused && (
                    <img className={_.play} src={play} alt='play' onClick={handlePlay} />
                )}
                {showVideo && isPaused && <img className={_.play} src={play} alt='play' onClick={handleResume} />}
                {!showVideo && <img className={_.play} src={play} alt='play' onClick={handleClick} />}
                <div className={_.title}>{logoReplacer(video_description)}</div>
            </div>
        </div>
    );
};

export default Video;
