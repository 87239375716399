import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Container from 'common/StyledElements/Container/Container';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import BackButton from 'common/BackButton/BackButton';
import CalendarBlock from 'common/CalendarBlock/CalendarBlock';
import { Hotline } from 'connectors/query/Hotline';
import noLectures from 'assets/lectures/noLectures.svg';
import Text from 'common/StyledElements/Text/Text';
import { CaldendarEvent } from 'connectors/query/CaldendarEvent';
import LecturesBlock from './components/LecturesBlock/LecturesBlock';
import Top from '../../common/Top/Top';

import _ from './LecturesPage.module.css';

const title = 'Лекции и вебинары';

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'practice',
        breadcrumbName: 'КИНО И ПРАВО',
    },
    {
        path: 'lectures',
        breadcrumbName: title,
    },
];

const LecturesPage = () => {
    const [noAnyEvents, setNoAnyEvents] = useState<boolean>(false);
    const navigate = useNavigate();

    const checkForAnyEvents = async () => {
        const hotline = await Hotline.get({ params: { limit: 1 } });
        const calendar = await CaldendarEvent.getAll({ limit: 1 });
        if (hotline?.results?.length === 0 && calendar?.results?.length === 0) {
            setNoAnyEvents(true);
        }
    };

    useEffect(() => {
        checkForAnyEvents();
    }, []);

    return (
        <>
            <Header />

            <Top title={title} />

            <Container
                style={{
                    paddingTop: '30px',
                    paddingBottom: '20px',
                }}
                column
            >
                <div className={clsx(_.container, _.breadcrumb)}>
                    <Breadcrumbs routes={routes} />
                    <BackButton onClick={() => navigate('/practice')}>Вернуться назад</BackButton>
                </div>

                {/* {!noAnyEvents && <CalendarBlock />}
                {noAnyEvents && (
                    <div className={_.noLectures}>
                        <img src={noLectures} alt='no any lectures' />
                        <Text className={_.noLecturesText}>Пока нет лекций и вебинаров</Text>
                    </div>
                )} */}
            </Container>

            {!noAnyEvents && (
                <Container style={{ paddingTop: 0, paddingBottom: 40 }} column gray>
                    <LecturesBlock />
                </Container>
            )}

            <Footer mt={0} />
        </>
    );
};

export default LecturesPage;
