import { useEffect } from 'react';

import { Form, Input, FormInstance } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import DatePicker from 'common/DatePicker/DatePicker';

import _ from './CertificateBlock.module.css';

interface ICertificateBlock {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    setData: (i: any) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const CertificateBlock: React.FC<ICertificateBlock> = ({
    onInputChange,
    data,
    setData,
    onDateChange,
    form,
    isSubscrActiveOrCreate,
}) => (
    <div className='blockCard'>
        <Form form={form} name='name_form' layout='vertical'>
            <div className='titleCard mb0'>Удостоверение национального фильма</div>

            <div className={_.main}>
                <MyFormItem
                    name='certificate_number'
                    label='№ удостоверения'
                    style={{ marginBottom: 0 }}
                    rules={[
                        { required: true, message: 'Не указан № удостоверения.' },
                        { pattern: /^[0-9]{1,5}$/, message: 'Номер удостоверения должен содержать от 1 до 5 цифр.' },
                    ]}
                >
                    <Input
                        placeholder='Введите номер удостоверения'
                        maxLength={5}
                        className='inputInner inputInnerShort2'
                        onChange={(event) => {
                            const { value } = event.target;
                            if (/^\d{0,5}$/.test(value)) {
                                onInputChange('certificate_number', value);
                            }
                        }}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='certificate_date'
                    label='Дата выдачи'
                    style={{ marginBottom: 0 }}
                    rules={[
                        { type: 'object', required: true, message: 'Необходимо заполнить.' },
                        { type: 'object', whitespace: true, message: 'Необходимо заполнить.' },
                    ]}
                >
                    <DatePicker
                        className='inputInner inputInnerShort2'
                        onChange={onDateChange('certificate_date')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default CertificateBlock;
