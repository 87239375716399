import { useState, FC } from 'react';
import { IDocumentData, Documents } from 'connectors/query/Documents';
import StyledLink from 'common/StyledElements/Link/Link';
import Title from 'common/StyledElements/Title/Title';
import { Form, Modal } from 'antd';
import Checkbox from 'antd/es/checkbox';
import _ from './Agreement.module.css';

interface IAgreement {
    showAgreementCheckbox?: boolean;
    maxWidth?: number | string;
}

const Agreement: FC<IAgreement> = ({ showAgreementCheckbox, maxWidth }) => {
    const [userAgreementData, setUserAgreementData] = useState<IDocumentData | null>(null);

    const getAgreement = async (type: 'personal' | 'user') => {
        let res: IDocumentData | null = null;

        type === 'user' && (res = await Documents.userAgreement());
        type === 'personal' && (res = await Documents.personalDataPolicy());

        if (res?.name) {
            setUserAgreementData(res);
        }
    };

    return (
        <>
            <Form.Item
                valuePropName='checked'
                name='agreePerson'
                style={{ maxWidth: maxWidth || 400 }}
                rules={[
                    {
                        validator: async (agreePerson, checked) => {
                            if (!checked) {
                                return Promise.reject(
                                    new Error('Для продолжения Вы должны согласиться с условиями сервиса'),
                                );
                            }
                        },
                    },
                ]}
                required
            >
                <Checkbox defaultChecked={false} className={_.font16}>
                    Подтверждаю{' '}
                    <StyledLink
                        onClick={(e) => {
                            e?.preventDefault();
                            getAgreement('personal');
                        }}
                        row
                    >
                        согласие
                    </StyledLink>{' '}
                    на обработку моих персональных данных
                </Checkbox>
            </Form.Item>

            {showAgreementCheckbox && (
                <Form.Item
                    name='agreeUser'
                    style={{ maxWidth: maxWidth || 400 }}
                    valuePropName='checked'
                    rules={[
                        {
                            validator: async (agreePerson, checked) => {
                                if (!checked) {
                                    return Promise.reject(
                                        new Error('Для продолжения Вы должны согласиться с условиями сервиса'),
                                    );
                                }
                            },
                        },
                    ]}
                    required
                >
                    <Checkbox defaultChecked={false} className={_.font16}>
                        Подтверждаю согласие с условиями{' '}
                        <StyledLink
                            onClick={(e) => {
                                e?.preventDefault();
                                getAgreement('user');
                            }}
                            row
                        >
                            пользовательского соглашения
                        </StyledLink>
                    </Checkbox>
                </Form.Item>
            )}

            <Modal
                open={!!userAgreementData}
                onCancel={() => {
                    setUserAgreementData(null);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalInner}>
                    <Title mb={30} small family='Manrope'>
                        {userAgreementData?.name}
                    </Title>
                    <div dangerouslySetInnerHTML={{ __html: userAgreementData?.content || '' }} />
                </div>
            </Modal>
        </>
    );
};

export default Agreement;
