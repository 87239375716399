import { FC, useContext, useState } from 'react';
import { Form, Select } from 'antd';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';

import AuthContext from 'common/AuthProvider/AuthProvider';
import Button from 'common/Button/Button';
import Agreement from 'common/Modals/Agreement/Agreement';
import YandexCaptcha from 'common/YandexCaptcha/YandexCaptcha';
import { IThread, Messenger } from 'connectors/query/Messenger';
import {
    openErrorNotification,
    openInfoNotification,
    openSuccessNotification,
} from 'common/Notifications/Notifications';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import validationRules from 'utils/formValidationRules';
import { News } from 'connectors/query/News';
import { flattenObject } from 'utils/utils';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';

import _ from './AskForm.module.css';
import './styles.css';

const { TextArea } = Input;

export interface ITitileOptions {
    value: string;
    label: string;
}

const titleOptionsDefault: ITitileOptions[] = [
    {
        value: 'Конструктор документов',
        label: 'Конструктор документов',
    },
    {
        value: 'Правовой практикум «КИНО И ПРАВО»',
        label: 'Правовой практикум «КИНО И ПРАВО»',
    },
    {
        value: 'Другой вопрос',
        label: 'Другой вопрос',
    },
];

interface IAskForm {
    closeModal?: () => void;
    titleOptions?: ITitileOptions[];
    subscriptionForm?: boolean;
}

const AskForm: FC<IAskForm> = ({ closeModal, titleOptions = titleOptionsDefault, subscriptionForm = false }) => {
    const { userData, logged, hasLoaded } = useContext(AuthContext);
    const [form] = Form.useForm();
    const [captchaToken, setCaptchaToken] = useState('');
    const [captchaKeyState, setCaptchaKeyState] = useState<number>(0);

    const resetCaptcha = () => setCaptchaKeyState((prev) => prev + 1);

    interface IOnSubmitParams {
        registered: boolean;
        title: string;
        content: string;
        first_name?: string;
        last_name?: string;
        email: string;
        user_id?: number;
        agreement?: boolean;
    }
    const onSubmit = async ({
        registered,
        title,
        content,
        first_name,
        last_name,
        email,
        agreement,
    }: IOnSubmitParams) => {
        const result = registered
            ? await Messenger.Threads.create({
                  title,
                  acceptance_user_agreement: true,
                  'message.content': content,
                  'message.attachments': [],
              })
            : await Messenger.Threads.create({
                  title,
                  acceptance_user_agreement: !!agreement,
                  captcha_token: captchaToken,
                  'message.content': content,
                  'message.attachments': [],
                  'message.sender_guest.last_name': last_name,
                  'message.sender_guest.first_name': first_name,
                  'message.sender_guest.email': email,
              });

        resetCaptcha();

        if (result?.status === 400) {
            Object.keys(result?.data).forEach((key) => {
                openErrorNotification('Ошибка!', result?.data[key]);
            });
            return;
        }
        if (result?.status >= 300) {
            openErrorNotification('Ошибка отправки сообщения.', 'Попробуйте повторить действие позднее.');
            return;
        }
        if (registered && result?.id) {
            const idLast = (result as IThread).messages[(result as IThread).messages.length - 1].id;
            Messenger.MessagesNew.setMessageViewed(idLast);
        }

        openSuccessNotification('Сообщение отправлено');
        closeModal?.();
        form.resetFields();
    };

    const onFinish = async (values: any) => {
        if (!captchaToken && !userData?.id) {
            return openInfoNotification('', 'Необходимо нажать на подтверждение "Я не робот"');
        }

        const contentValue = subscriptionForm ? 'Текст сообщения отсутствует.' : values.content;
        const titleValue = subscriptionForm ? 'Подписаться на новости' : values.title || '';

        if (subscriptionForm) {
            // @ts-ignore
            let res;

            if (logged && hasLoaded) {
                res = await News.subscribe({
                    acceptance_user_agreement: true,
                });
            } else {
                res = await News.subscribe({
                    acceptance_user_agreement: values.agreePerson,
                    captcha_token: captchaToken,
                    'guest.first_name': values.firstName,
                    'guest.last_name': values.lastName,
                    'guest.email': values.email,
                });
            }
            resetCaptcha();

            if (res?.status >= 200 && res?.status <= 204) {
                openSuccessNotification('Вы подписались на новости');
                closeModal?.();
                form.resetFields();
            } else if (res?.status >= 500 && res?.data?.detail?.includes('SMTP error')) {
                openErrorNotification(
                    'Ошибка подписки на новости!',
                    'Почта недоступна. Обратитесь к администратору через форму обратной связи.',
                );
            } else if (res?.status === 400) {
                const flatErrors = flattenObject(res.data);
                Object.keys(flatErrors).forEach((key) => {
                    openErrorNotification(
                        'Ошибка!',
                        (flatErrors[key as keyof typeof flatErrors] as string).replace(
                            'гость с таким Электронная почта уже существует.',
                            'Пользователь с указанным e-mail уже подписан на рассылку новостей.',
                        ),
                    );
                });
            } else if (res?.status >= 300) {
                openErrorNotification(
                    'Ошибка подписки на новости!',
                    'Обратитесь к администратору через форму обратной связи.',
                );
            }
        } else if (userData) {
            onSubmit({
                registered: true,
                user_id: userData.id,
                title: titleValue,
                content: contentValue,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email ? userData.email : values.email,
            });
        } else {
            onSubmit({
                registered: false,
                title: titleValue,
                content: contentValue,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                agreement: values.agreePerson,
            });
        }
    };

    return (
        <div className={_.formBlock}>
            <Form form={form} name='askme' initialValues={{ agreePerson: false }} onFinish={onFinish}>
                <Row>
                    <Col span={17}>
                        {!logged && hasLoaded && (
                            <MyFormItem
                                name={['lastName']}
                                label='Фамилия'
                                rules={validationRules.surnameRules}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width: 480 }}
                            >
                                <Input
                                    className={_.elParam}
                                    maxLength={64}
                                    onBlur={() => handleInputBlur(form, 'lastName')}
                                />
                            </MyFormItem>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={17}>
                        {!logged && hasLoaded && (
                            <MyFormItem
                                name={['firstName']}
                                label='Имя'
                                rules={validationRules.nameRules}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width: 480 }}
                            >
                                <Input
                                    className={_.elParam}
                                    maxLength={64}
                                    onBlur={() => handleInputBlur(form, 'firstName')}
                                />
                            </MyFormItem>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={17}>
                        {!logged && hasLoaded && (
                            <MyFormItem
                                name={['email']}
                                label='E-mail'
                                rules={validationRules.emailRules}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width: 480 }}
                            >
                                <Input
                                    className={_.elParam}
                                    maxLength={320}
                                    onBlur={() => handleInputBlur(form, 'email')}
                                />
                            </MyFormItem>
                        )}
                    </Col>
                </Row>

                {!subscriptionForm && (
                    <Row>
                        <Col span={17}>
                            <div id='selectTitle'>
                                <MyFormItem
                                    name={['title']}
                                    label='Тема вопроса'
                                    rules={[
                                        { required: true, message: 'Поле «Тема вопроса» обязательно для заполнения' },
                                    ]}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ width: 480 }}
                                >
                                    <Select
                                        className={_.select}
                                        style={{ width: 480 }}
                                        getPopupContainer={() =>
                                            document.getElementById('selectTitle') || document.body
                                        }
                                        placeholder='Выберите тему'
                                        options={titleOptions}
                                    />
                                </MyFormItem>
                            </div>
                        </Col>
                    </Row>
                )}

                {!subscriptionForm && (
                    <Row>
                        <Col span={17}>
                            <MyFormItem
                                name={['content']}
                                label='Текст вопроса'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Поле «Текст вопроса» обязательно для заполнения' }]}
                                style={{ width: 480 }}
                            >
                                <TextArea
                                    style={{ paddingLeft: 20, backgroundColor: '#f9f9f9', height: '130px' }}
                                    maxLength={1024}
                                />
                            </MyFormItem>
                        </Col>
                    </Row>
                )}

                {!logged && hasLoaded && (
                    <YandexCaptcha setCaptchaToken={setCaptchaToken} resetCaptchaToken={captchaKeyState} />
                )}

                {!logged && hasLoaded && <Agreement />}

                <MyFormItem style={{ marginBottom: 60 }}>
                    <Button width='480px' htmlType='submit' >
                        Отправить
                    </Button>
                </MyFormItem>
            </Form>
        </div>
    );
};

export default AskForm;
