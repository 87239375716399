import { FC } from 'react';

import Text from 'common/StyledElements/Text/Text';
import Divider from 'common/StyledElements/Divider/Divider';
import PersonAvatar from 'common/PersonAvatar/PersonAvatar';

import _ from './Row.module.css';

interface IRowProps {
    last_name: string;
    first_name: string;
    patronymic: string | null;
    photo: string | null;
    projects?: { id: number; name: string }[];
    isLast?: boolean;
}

const Row: FC<IRowProps> = ({ last_name, first_name, patronymic, photo, projects, isLast }) => (
    <div className={_.wrapper}>
        <div className={_.dataWrapper}>
            <PersonAvatar firstName={first_name} lastName={last_name} photo={photo} />

            <div className={_.name}>
                <Text
                    width={360}
                    mb={6}
                    title={`${last_name} ${first_name}${patronymic ? ' ' : ''}${patronymic || ''}`}
                    ellipsis
                >{`${last_name} ${first_name}${patronymic ? ' ' : ''}${patronymic || ''}`}</Text>
                <Text width={360} mb={24} lightGray small ellipsis>
                    {projects && projects.length > 0 && projects[0].name}
                </Text>
            </div>
        </div>

        {!isLast && <Divider />}
    </div>
);

export default Row;
