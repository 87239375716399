import { useEffect, useState } from 'react';

import { Modal } from 'antd';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Button from 'common/Button/Button';
import Title from 'common/StyledElements/Title/Title';
import Text from 'common/StyledElements/Text/Text';
import AskForm from 'common/AskForm/AskForm';
import Pagination from 'common/Paginator/Paginator';
import { INewsData, News } from 'connectors/query/News';
import { openInfoNotification } from 'common/Notifications/Notifications';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import { ITagsData, Tags } from 'connectors/query/Tags';
import Top from './components/Top/Top';
import NewsItem from './components/NewsItem/NewsItem';

import _ from './NewsPage.module.css';

const title = 'Новости';

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'news',
        breadcrumbName: title,
    },
];

const limit = 5;

const NewsPage = () => {
    const [newsData, setNewsData] = useState<INewsData[] | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalNewsCount, setTotalNewsCount] = useState<number>(0);
    const totalPages = Math.ceil(totalNewsCount / limit);
    const [allTags, setAllTags] = useState<ITagsData[]>([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchAllTags();
    }, []);

    useEffect(() => {
        fetchAll((currentPage - 1) * limit);
    }, [currentPage]);

    const fetchAllTags = async () => {
        const data = await Tags.getAllTags();
        const tagsArr = data.results.map((tag: ITagsData) => ({ name: tag.name, id: tag.id }));
        setAllTags(tagsArr);
    };

    const fetchAll = async (offset: number) => {
        const data = await News.getAll({ limit, offset });

        if (data?.status >= 300) {
            openInfoNotification('Новостей нет');
            return;
        }

        if (data.count) {
            setTotalNewsCount(data.count);
        }

        if (data.results) {
            setNewsData(data.results);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Header />

            <Top />

            <div className={_.wrapper}>
                <div className={_.container}>
                    <div className={_.breadcrumb}>
                        <Breadcrumbs routes={routes} />
                    </div>

                    {/* <div className={_.search}>
                        <FindInput placeholder='Начните вводить название статьи' />
                    </div> */}

                    <Button
                        marginTop='30px'
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        Подписаться на новости
                    </Button>

                    {newsData &&
                        newsData.map((n) => (
                            <NewsItem
                                title={n.title}
                                date={n.created_at}
                                text={n.content}
                                pic={n.poster}
                                key={n.id}
                                author={n.author}
                                created_by={n.created_by}
                                tags={n.tags?.map((tag) => allTags.find((tagData) => tagData.id === tag))}
                            />
                        ))}
                </div>
            </div>

            <Pagination maxPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <Footer />

            <Modal
                open={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalInner}>
                    <Title mt={60} family='Manrope'>
                        Подписаться на новости
                    </Title>
                    <Text mt={30} width={460} center>
                        Подпишитесь на наши новости, чтобы быть в курсе актуальных событий и предложений
                    </Text>

                    <AskForm
                        closeModal={() => {
                            setShowModal(false);
                        }}
                        subscriptionForm
                    />
                </div>
            </Modal>
        </>
    );
};

export default NewsPage;
