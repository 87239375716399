import { FC, useContext } from 'react';

import Button from 'common/Button/Button';
import Container from 'common/StyledElements/Container/Container';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import VideoPlayer from 'common/VideoPlayer/VideoPlayer';
import { openInfoNotification } from 'common/Notifications/Notifications';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { useNavigate } from 'react-router-dom';

import _ from './Contract.module.css';

interface IContractProps {
    contractBuilder: any;
}

const Contract: FC<IContractProps> = ({ contractBuilder }) => {
    const navigate = useNavigate();
    const { logged, hasLoaded } = useContext(AuthContext);
    return (
        <Container minHeight='350px' column gray>
            <Title mb={30}>{logoReplacer(contractBuilder.title) || ''}</Title>
            <Text mb={40} width={932} center>
                {logoReplacer(contractBuilder.description) || ''}
            </Text>

            <Button
                className={_.btn}
                onClick={() => {

                    // PROD
                    // openInfoNotification('Личный кабинет временно недоступен');
                    // !logged && hasLoaded && navigate('/register');
                    logged && openInfoNotification('Создать договор пока нельзя.');
                    !logged && hasLoaded && navigate('/auth');
                }}
            >
                Начать работу
            </Button>

            {contractBuilder.image &&
                (!contractBuilder.video ? (
                    <img src={contractBuilder.image} className={_.img} alt='preview' />
                ) : (
                    <VideoPlayer url={contractBuilder.video} light={contractBuilder.image} isPlaying />
                ))}
        </Container>
    );
};

export default Contract;
