import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { Form, Input, Modal } from 'antd';
import Table from 'antd/es/table';
import clsx from 'clsx';

import Button from 'common/Button/Button';
import Title from 'common/StyledElements/Title/Title';
import Pagination from 'common/Paginator/Paginator';
import { openSuccessNotification } from 'common/Notifications/Notifications';
import PersonalAccount, { IProjectProperties } from 'connectors/query/PersonalAccount';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';
import { columnsProperties } from '../../columns';

import _ from './ProjectProperties.module.css';
import '../../styles.scss';

interface IProjectPropertiesBlock {
    projectId?: string;
    isActiveProject: boolean;
}
const limit = 3;
const ProjectProperties: FC<IProjectPropertiesBlock> = ({ projectId, isActiveProject }) => {
    const navigate = useNavigate();
    const [dataProperties, setDataProperties] = useState<IProjectProperties[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countAll, setCountAll] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [showCreateProjectPropertiesOrDetailsCopyModal, setShowCreateProjectPropertiesOrDetailsCopyModal] =
        useState(false);
    const [propertiesIdForCopy, setPropertiesIdForCopy] = useState(0);

    const [form] = useForm();

    useEffect(() => {
        getProjectProperties();
    }, [currentPage]);

    const closeCreateProjectPropertiesCopyModal = () => {
        setShowCreateProjectPropertiesOrDetailsCopyModal(false);
        form.resetFields();
    };

    const createProjectPropertiesCopy = async (values: { propertiesVersionName: string | undefined }) => {
        if (propertiesIdForCopy && projectId && values?.propertiesVersionName) {
            const result = await PersonalAccount.createProjectPropertiesCopy(
                projectId,
                propertiesIdForCopy,
                values.propertiesVersionName,
            );
            if (result.id) {
                openSuccessNotification('Копия характеристик проекта успешно создана!');
                closeCreateProjectPropertiesCopyModal();
                if (currentPage === 1) {
                    getProjectProperties();
                } else {
                    setCurrentPage(1);
                }
            } else {
                Object.keys(result?.data).forEach((key) => {
                    form.setFields([{ name: key, errors: result?.data[key] }]);
                });
            }
        }
    };

    const getProjectProperties = async () => {
        if (projectId) {
            setIsLoading(true);
            const res = await PersonalAccount.getProjectProperties(projectId, {
                limit,
                offset: (currentPage - 1) * limit,
                ordering: '-updated_at',
            });
            if (Array.isArray(res?.results)) {
                setDataProperties(res?.results);
            }
            if (res?.count) {
                setCountAll(res?.count);
            } else setCountAll(0);
            setIsLoading(false);
        }
    };

    return (
        <div className={_.block}>
            <Title mt={50} mb={40} small>
                Характеристики проекта
            </Title>
            <Table
                className='addProjectTable'
                columns={columnsProperties(
                    (id: number) => {
                        setShowCreateProjectPropertiesOrDetailsCopyModal(true);
                        setPropertiesIdForCopy(id);
                    },
                    projectId,
                    navigate,
                    isActiveProject,
                )}
                dataSource={dataProperties}
                pagination={false}
                loading={isLoading}
            />
            <Pagination
                maxPages={Math.ceil(countAll / limit)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                className={_.modalCustom}
                open={showCreateProjectPropertiesOrDetailsCopyModal}
                onOk={() => {
                    closeCreateProjectPropertiesCopyModal();
                }}
                onCancel={() => {
                    closeCreateProjectPropertiesCopyModal();
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainerCopyProperties}>
                        <div className={_.titleInvite}>
                            <Title mb={30} width={468}>
                                Создать копию характеристик проекта
                            </Title>
                        </div>

                        <Form
                            name='propertiesVersionForm'
                            className={clsx(_.propertiesVersionForm)}
                            form={form}
                            onFinish={createProjectPropertiesCopy}
                        >
                            <Form.Item
                                name={['propertiesVersionName']}
                                label={<div style={{ fontFamily: 'Manrope' }}>Введите название версии</div>}
                                rules={[
                                    {
                                        min: 3,
                                        message: 'Минимум 3 символа',
                                    },
                                    {
                                        required: true,
                                        message: 'Необходимо ввести название',
                                    },
                                ]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={_.projectPropertyVersionNameWrapper}
                                required={false}
                            >
                                <Input
                                    maxLength={64}
                                    className={_.projectPropertyVersionName}
                                    onBlur={() => handleInputBlur(form, 'version')}
                                />
                            </Form.Item>
                            <Button
                                grey
                                onClick={closeCreateProjectPropertiesCopyModal}
                                width='210px'
                                marginRight='20px'
                            >
                                Отмена
                            </Button>
                            <Button htmlType='submit' width='210px'>
                                Создать
                            </Button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProjectProperties;
