import { FC } from 'react';

import pic from 'assets/question.svg';

import Button from 'common/Button/Button';

import _ from './Question.module.css';

interface IQuestionProps {
    setShowModal: (isModalShown: boolean) => void;
}

const Question: FC<IQuestionProps> = ({ setShowModal }) => (
    <div className={_.wrapper}>
        <img className={_.questionImg} src={pic} alt='question icon' />
        <div className={_.title}>Остались вопросы?</div>
        <Button width='215px' onClick={() => setShowModal(true)}>
            Задать вопрос
        </Button>
    </div>
);

export default Question;
