import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'antd';

import Success from 'assets/notifications/success.svg';
import { openErrorNotification } from 'common/Notifications/Notifications';
import { Auth } from 'connectors/query/Auth';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import AuthContext from 'common/AuthProvider/AuthProvider';

import _ from './SignupConfirm.module.css';

// при переходе на эту страницу - отстреливается запрос на подтверждение регистрации
const SignUpConfirmPage = () => {
    const data = useParams();
    const navigate = useNavigate();
    const { logged } = useContext(AuthContext);
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const signupConfirm = async () => {
        if (data.uid && data.token) {
            const res = await Auth.signupConfirm({ uid: data.uid, token: data.token });

            if (res?.status !== 'ok' && res?.status !== 200 && res?.status !== 201) {
                res?.data &&
                    Object.keys(res?.data).forEach((key) => {
                        openErrorNotification('Ошибка!', res?.data[key][0]);
                    });
                navigate('/auth');
            } else {
                setShowModalSuccess(true);
            }
        } else {
            openErrorNotification('Некорректная ссылка');
            if (logged) {
                navigate('/');
            } else {
                navigate('/auth');
            }
        }
    };

    useEffect(() => {
        signupConfirm();
    }, []);

    return (
        <Modal
            open={showModalSuccess}
            onOk={() => {
                setShowModalSuccess(false);
            }}
            onCancel={() => {
                setShowModalSuccess(false);
                if (logged) {
                    navigate('/');
                } else {
                    navigate('/auth');
                }
            }}
            footer={null}
            width={700}
            centered
        >
            <div className={_.modalWrapper}>
                <div style={{ height: 250 }} className={_.modalContainer}>
                    <img style={{ marginBottom: 30 }} src={Success} alt='success' />

                    <Title mb={30}>Спасибо за регистрацию!</Title>

                    <Button
                        onClick={() => {
                            if (logged) {
                                navigate('/');
                            } else {
                                navigate('/auth');
                            }
                        }}
                        width='auto'
                    >
                        Перейти на форму авторизации
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SignUpConfirmPage;
