import Container from 'common/StyledElements/Container/Container';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import ModuleCards from 'common/ModuleCards/ModuleCards';

import _ from './Modules.module.css';

const Modules = () => (
    <Container pb={50}>
        <div className={_.titleContainer}>
            <Title mb={30}>Модули и тарифы</Title>
            <Text mb={75} width={932} center>
                Воспользуйтесь нашим онлайн конструктором для создания документов под ваши индивидуальные проектные
                условия конкретного фильма.
            </Text>
            <ModuleCards />
        </div>
    </Container>
);

export default Modules;
