import { FC } from 'react';

import Text from 'common/StyledElements/Text/Text';
import anon from 'assets/team/anon.jpg';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './BigCard.module.css';

interface IBigCardProps {
    first_name: string;
    last_name: string;
    position: string;
    company: string;
    avatar: string;
    text: string;
}

const BigCard: FC<IBigCardProps> = ({ first_name, last_name, position, company, avatar, text }) => (
    <div className={_.wrapper}>
        <div className={_.container}>
            {/* <img className={_.img} src={avatar} /> */}
            <img className={_.img} src={avatar || anon} alt={`${first_name} ${last_name}`} />

            <div>
                <div className={_.nameWrapper}>
                    <div className={_.name}>
                        {first_name} {last_name}
                    </div>
                    <Text mid lightGray>
                        {logoReplacer(position)} {logoReplacer(company)}
                    </Text>
                </div>
                <div className={_.textWrapper}>
                    <Text style={{ whiteSpace: 'break-spaces' }}>{logoReplacer(text)}</Text>
                </div>
            </div>
        </div>
    </div>
);

export default BigCard;
