import { FC } from 'react';

import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './SmallCard.module.css';

interface ISmallCardProps {
    logo_name: string;
    logo_img: string;
}

const SmallCard: FC<ISmallCardProps> = ({ logo_name, logo_img }) => (
    <div className={_.wrapper}>
        <div className={_.img}>
            <img src={logo_img} alt='logo_img' />
        </div>
        <div className={_.name}>{logoReplacer(logo_name)}</div>
    </div>
);

export default SmallCard;
