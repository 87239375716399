import { useEffect } from 'react';
import { Checkbox, Form, Input, FormInstance } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';

import _ from './MainBlock.module.css';

interface IMainBlock {
    data: any;
    setData: (i: any) => void;
    onInputChange: (key: string, value: string | null | boolean) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const MainBlock: React.FC<IMainBlock> = ({ onInputChange, data, setData, form, isSubscrActiveOrCreate }) => (
    <div className='blockCard w100'>
        <Form form={form} name='name_form' layout='vertical' scrollToFirstError>
            <div className='titleCard required-field'>Название проекта</div>
            <MyFormItem
                name='name'
                rules={[
                    {
                        required: true,
                        message: 'Поле «Название проекта» обязательно для заполнения',
                    },
                ]}
            >
                <Input
                    placeholder='Введите название проекта'
                    required
                    className='inputInner'
                    onChange={(event) => onInputChange('name', event.target.value)}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>
            <MyFormItem className={_.noMargin} name='working_name' valuePropName='checked'>
                <Checkbox
                    disabled={!isSubscrActiveOrCreate}
                    onChange={(event) => onInputChange('working_name', event.target.checked)}
                >
                    Рабочее название
                </Checkbox>
            </MyFormItem>
        </Form>
    </div>
);

export default MainBlock;
