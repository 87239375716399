import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import messagesIcon from 'assets/cabinet/messages.svg';
import projectsIcon from 'assets/cabinet/projects.svg';
import Container from 'common/StyledElements/Container/Container';
import Loader from 'common/Loader/Loader';
import { IProjects, PersonalAccount } from 'connectors/query/PersonalAccount';
import Block from './components/Block/Block';
import List from './components/List/List';
import Events from './components/Events/Events';
import People from './components/People/People';

import _ from './Projects.module.css';

const Projects = () => {
    const [projects, setProjects] = useState<IProjects | null>(null);
    const [newMessagesCount, setNewMessagesCount] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        await Promise.all([getProjects(), getUnreadMessagesCount()]);
        setIsLoading(false);
    };

    const getUnreadMessagesCount = async () => {
        const result = await PersonalAccount.getUnreadMessagesCount();
        setNewMessagesCount(result.count);
    };

    const getProjects = async () => {
        const result = await PersonalAccount.getProjects({ ordering: '-updated_at' });
        setProjects(result);
    };

    return (
        <Container column>
            <div className={_.blocksWrapper}>
                <Block isPersonal />
                <Block
                    num={projects?.count === null ? '' : projects?.count}
                    title='Всего проектов'
                    icon={projectsIcon}
                    btnText='Мои проекты'
                    to='/cabinet/projects/add'
                    addBtn='/cabinet/create'
                />
                <Block
                    num={newMessagesCount === null ? '' : newMessagesCount}
                    title='Новых сообщений'
                    icon={messagesIcon}
                    btnText='Прочитать'
                    to='/cabinet/messages'
                />
            </div>

            <List />

            <div className={clsx(_.blocksWrapper, _.last)}>
                <Events />
                <People />
            </div>

            {isLoading && <Loader top='40%' />}
        </Container>
    );
};

export default Projects;
