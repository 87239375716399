const movierightLight =
    '<span style="font-family: Manrope; font-weight: 800; display: inline-block;"><span style="margin-right: 0.11em; font-style: italic; color: rgb(47, 86, 134);">MOVIE</span><span style="color: white;">RIGHT</span></span>';
const movierightDark =
    '<span style="font-family: Manrope; font-weight: 800; display: inline-block;"><span style="margin-right: 0.11em; font-style: italic; color: rgb(47, 86, 134);">MOVIE</span><span style="color: black;">RIGHT</span></span>';
const movieRightText = 'MOVIE RIGHT';

function logoReplacer(text: string | undefined): string | JSX.Element;
function logoReplacer(text: string | undefined, type: 'dangerouslySetInnerHTML'): string;
function logoReplacer(text: string | undefined, type: 'plainText'): string;
function logoReplacer(text: string | undefined, type?: 'dangerouslySetInnerHTML' | 'plainText') {
    if (typeof text === 'string') {
        if (type === 'dangerouslySetInnerHTML') {
            return text
                .replaceAll('[[movieright-dark]]', movierightDark)
                .replaceAll('[[movieright-light]]', movierightLight);
        }
        if (type === 'plainText') {
            return text
                .replaceAll('[[movieright-dark]]', movieRightText)
                .replaceAll('[[movieright-light]]', movieRightText);
        }

        const arr = text.split(/(\[\[movieright-light\]\]|\[\[movieright-dark\]\])/g).map((el) => {
            if (el === '[[movieright-light]]' || el === '[[movieright-dark]]') {
                return (
                    <span style={{ fontWeight: 800, fontFamily: 'Manrope', display: 'inline-block' }}>
                        <span style={{ marginRight: '0.11em', fontStyle: 'italic', color: '#2f5686' }}>MOVIE</span>
                        <span
                            style={{
                                ...{ color: el === '[[movieright-light]]' ? 'white' : 'black' },
                            }}
                        >
                            RIGHT
                        </span>
                    </span>
                );
            }

            return el;
        });

        return <span>{arr}</span>;
    }
    return '';
}

export default logoReplacer;
