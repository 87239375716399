import { FC, useState } from 'react';
import { Modal } from 'antd';

import StatusWrapper, { statusAliasProject } from 'common/StatusWrapper/StatusWrapper';
import Button from 'common/Button/Button';
import Title from 'common/StyledElements/Title/Title';
import PayForm from 'common/PayForm/PayForm';
import {
    ESubscriptionStatusTrial,
    ESubscriptionStatuses,
    ISubscription,
    TSubscriptionStatusesWithTrial,
} from 'connectors/query/Subscriptions';
import { ITimeLeftLabels } from 'utils/utils';

import _ from './InfoBlock.module.css';

interface IInfoBlock {
    labelTimeLeft: ITimeLeftLabels;
    recentActiveSubscr: ISubscription | null;
    recentAnySubscrIfNoActive: ISubscription | null;
    projectSubscriptionStatus: TSubscriptionStatusesWithTrial | null;
    projectId: string | undefined;
    projectLatestPlan: number | null;
}

const InfoBlock: FC<IInfoBlock> = ({
    labelTimeLeft,
    recentActiveSubscr,
    recentAnySubscrIfNoActive,
    projectSubscriptionStatus,
    projectId,
    projectLatestPlan,
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className={_.info}>
            <div className={_.statusBlock}>
                <StatusWrapper
                    className={_.projectStatus}
                    text={projectSubscriptionStatus || ''}
                    statusAliases={statusAliasProject}
                />

                {recentActiveSubscr?.status === ESubscriptionStatuses.Active && labelTimeLeft && (
                    <div className={_.infoMessage}>
                        {`До окончания ${recentActiveSubscr?.plan.is_trial ? 'пробного периода' : 'тарифа'} ${
                            labelTimeLeft.prevWord
                        } `}
                        <b>{labelTimeLeft.timeLeft}</b>
                    </div>
                )}

                {(recentActiveSubscr?.status !== ESubscriptionStatuses.Active ||
                    (recentActiveSubscr?.status === ESubscriptionStatuses.Active &&
                        recentActiveSubscr?.plan.is_trial)) && (
                    <Button
                        width='120px'
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        Оплатить
                    </Button>
                )}
                <Modal
                    open={showModal}
                    onCancel={() => {
                        setShowModal(false);
                    }}
                    width='700px'
                    footer={null}
                    centered
                    destroyOnClose
                >
                    <div className={_.modalInner}>
                        <Title mt={60} family='Manrope'>
                            Давайте уточним данные
                        </Title>
                        <PayForm
                            type='project'
                            projectId={projectId}
                            moduleId={recentActiveSubscr?.plan.product.id || recentAnySubscrIfNoActive?.plan.product.id}
                            planId={projectLatestPlan}
                            closeModal={() => {
                                setShowModal(false);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default InfoBlock;
