import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { Form, Modal } from 'antd';

import next from 'assets/next.svg';
import prev from 'assets/prev.svg';
import Container from 'common/StyledElements/Container/Container';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';

import Button from 'common/Button/Button';
import BackButton from 'common/BackButton/BackButton';
import { ESubscriptionStatuses, ISubscription } from 'connectors/query/Subscriptions';
import { PersonalAccount } from 'connectors/query/PersonalAccount';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import Loader from 'common/Loader/Loader';
import { IOrganization, Organizations, isOrganization } from 'connectors/query/Organizations';
import StepOne from './components/StepOne/StepOne';
import MainBlock from './components/StepThree/components/MainBlock/MainBlock';
import ProjectCharacteristics from './components/StepThree/ProjectCharacteristics';
import StepFour from './components/StepFour/StepFour';
import NavbarElement from './components/NavbarElement/NavbarElement';
import PropertyVersion from './components/PropertyVersion/PropertyVersion';
import { genreOptions } from './components/StepThree/components/GenreBlock/GenreBlock';

import _ from './CreateProject.module.css';
import './styles.css';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

interface ICreateProject {
    value?: Dayjs;
    type?: number;
    projectIdForEdit?: number;
    orgIdForEdit?: number;
    orgPropertiesIdForEdit?: number;
    projectPropertiesIdForEdit?: number;
    projectIdForReturnAction?: number;
    returnToOrgs?: boolean;
    setTitle?: (title: string) => void;
}

export const defaultorgData = {
    // организация
    organization_type: '',
    short_name_org: '',
    full_name_org: '',
    inn: '',
    // UL
    corporate_form: '',
    kpp: '',
    ogrn: '',
    okpo: '',
    okato: '',
    okved: '',
    legal_address: '',
    physical_address: '',
    correspondence_address: '',
    email: '',
    phone: '',
    fio_sole_executive_body: '',
    job_title_sole_executive_body: '',
    document_exercising_sole_executive_body: '',
    fio_representative: '',
    job_title_representative: '',
    operates_on_basis: '',
    authority_representative: '',
    issue_date_power_attorney: '',
    phone_representative: '',
    registration_certificate: '',
    registration_authority_number: '',
    registration_date: '',
    bank: '',
    checking_account: '',
    correspondent_account: '',
    bik: '',
    // FL
    first_name: '',
    last_name: '',
    patronymic: '',
    birth_date: '',
    snils: '',

    passport_number: '',
    passport_issued_by: '',
    passport_issued_date: '',
    passport_department_code: '',

    // ip

    ogrnip: '',

    // ip склейка в registration_certificate
    ip_series_registration_certificate: '',
    ip_number_registration_certificate: '',
};

const defaultData = {
    // проект
    moduleId: 0,
    module_name: '',
    version_name: '',
    version_name_by_default: true,
    name: '',
    description: '',
    working_name: true,
    project_type: '',
    timing: '',
    number_episodes: '',
    episode_timing: '',
    language: 'Русский',
    choose_language: 'Русский',
    parent_characteristic: null,
    userType: 'UL',
    toAllData: '',
    additional_language: '',
    certificate_number: '',
    certificate_date: '',
    approx_timing_from: '',
    approx_timing_to: '',
    colors: ['Цветной'],
    genres: [],
    media_types: ['HDD'],
    film_type: 'game',
    film_view_type: 'documentary',
    film_format: 'feature_length',

    ...defaultorgData,
};
const MAX_AUTHOR_ENTITIES = 10;

const screenwriterArr = [];
const directorArr = [];
const customGenresArr = [];

// eslint-disable-next-line no-plusplus
for (let i = 0; i < MAX_AUTHOR_ENTITIES - 1; i++) {
    screenwriterArr.push(`name_screenwriter_${i}`);
    screenwriterArr.push(`surname_screenwriter_${i}`);
    screenwriterArr.push(`patronymic_screenwriter_${i}`);
    screenwriterArr.push(`nickname_screenwriter_${i}`);
    directorArr.push(`name_director_${i}`);
    directorArr.push(`surname_director_${i}`);
    directorArr.push(`patronymic_director_${i}`);
    directorArr.push(`nickname_director_${i}`);
}
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 4; i++) {
    customGenresArr.push(`customGenre${i}`);
}

const projectFormFieldsOrder = [
    'version_name',
    'name',
    ...screenwriterArr,
    ...directorArr,
    'film_type',
    'film_view_type',
    'film_format',
    'genres',
    ...customGenresArr,
    'episode_timing',
    'approx_timing_from',
    'approx_timing_to',
    'timing',
    'media_types',
    'custom_media_type',
    'choose_language',
    'language',
    'additional_language',
    'subtitle_language_0',
    'subtitle_language_1',
    'subtitle_language_2',
    'colors',
    'custom_color_dev',
    'certificate_number',
    'certificate_date',
];

const orgBankOrder = ['bank', 'correspondent_account', 'checking_account', 'bik'];
const orgAddressOrder = ['legal_address', 'physical_address', 'correspondence_address', 'email', 'phone'];

const orgEntityFormFieldsOrder = [
    'version_name',
    'full_name_org',
    'short_name_org',
    'inn',
    'kpp',
    'ogrn',
    'okpo',
    'okato',
    'okved',
    ...orgAddressOrder,
    'fio_sole_executive_body',
    'job_title_sole_executive_body',
    'document_exercising_sole_executive_body',
    'fio_representative',
    'job_title_representative',
    'operates_on_basis',
    'authority_representative',
    'registration_certificate',
    'registration_authority_number',
    'registration_date',
    ...orgBankOrder,
];
const orgEntrepreneurFormFieldsOrder = [
    'version_name',
    'first_name',
    'last_name',
    'patronymic',
    'inn',
    'birth_date',
    'snils',
    'passport_number',
    'passport_issued_by',
    'passport_issued_date',
    'passport_department_code',
    ...orgAddressOrder,
    ...orgBankOrder,
];

const orgIndividualFormFieldsOrder = [
    'version_name',
    'full_name_org',
    'short_name_org',
    'inn',
    'birth_date',
    'ogrnip',
    'registration_date',
    'ip_series_registration_certificate',
    'ip_number_registration_certificate',
    'passport_number',
    'passport_issued_by',
    'passport_issued_date',
    'passport_department_code',
    ...orgAddressOrder,
    'fio_representative',
    'phone_representative',
    'issue_date_power_attorney',
    ...orgBankOrder,
];

const getInitialStep = (projectIdForEdit: number | undefined, orgIdForEdit: number | undefined) => {
    if (projectIdForEdit) return 2;
    if (orgIdForEdit) return 3;
    return 1;
};

const CreateProject: FC<ICreateProject> = ({
    value,
    type,
    projectIdForEdit,
    orgIdForEdit,
    projectPropertiesIdForEdit,
    orgPropertiesIdForEdit,
    projectIdForReturnAction,
    returnToOrgs,
    setTitle,
}) => {
    const navigate = useNavigate();
    const [stepActive, setStepActive] = useState(getInitialStep(projectIdForEdit, orgIdForEdit));
    const [data, setData] = useState(defaultData);
    const [numberOfEntities, setNumberOfEntities] = useState({ authors: 1, directors: 1 });
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
    const [stepOrganizationForm] = Form.useForm();
    const [stepProjectForm] = Form.useForm();
    const [hasError, setHasError] = useState(false);
    const [isSubscrActiveOrCreate, setIsSubscrActiveOrCreate] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [customGenres, setCustomGenres] = useState<string[]>(['']);
    const [checkedGenres, setCheckedGenres] = useState<string[]>([]);

    const [checkedColors, setCheckedColors] = useState<string[]>([]);
    const [colorInput, setColorInput] = useState<string>('');

    const [showOtherLanguageInput, setShowOtherLanguageInput] = useState(false);

    const [customCarrierValue, setCustomCarrierValue] = useState('');

    const [serialCount, setSerialCount] = useState(1);
    const [activeSeriesRadioEpisodeTiming, setActiveSeriesRadioEpisodeTiming] = useState('Точный');

    useEffect(() => {
        if (!orgIdForEdit && !projectIdForEdit) setIsSubscrActiveOrCreate(true);

        if (projectIdForEdit) {
            setIsLoading(true);
            getProjectForEdit().finally(() => {
                setIsLoading(false);
            });
        }
        if (orgIdForEdit) {
            setIsLoading(true);
            getOrgForEdit().finally(() => {
                setIsLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [stepActive]);

    useEffect(() => {
        if (data) {
            const dataNew = { ...data };
            if (dataNew.registration_date) {
                // @ts-ignore
                dataNew.registration_date = dayjs(dataNew.registration_date, 'DD.MM.YYYY');
            } else {
                // @ts-ignore
                dataNew.registration_date = null;
            }
            if (dataNew.passport_issued_date) {
                // @ts-ignore
                dataNew.passport_issued_date = dayjs(dataNew.passport_issued_date, 'DD.MM.YYYY');
            } else {
                // @ts-ignore
                dataNew.passport_issued_date = null;
            }
            if (dataNew.birth_date) {
                // @ts-ignore
                dataNew.birth_date = dayjs(dataNew.birth_date, 'DD.MM.YYYY');
            } else {
                // @ts-ignore
                dataNew.birth_date = null;
            }

            if (dataNew.issue_date_power_attorney) {
                // @ts-ignore
                dataNew.issue_date_power_attorney = dayjs(dataNew.issue_date_power_attorney, 'DD.MM.YYYY');
            } else {
                // @ts-ignore
                dataNew.issue_date_power_attorney = null;
            }

            if (dataNew.certificate_date) {
                // @ts-ignore
                dataNew.certificate_date = dayjs(dataNew.certificate_date, 'DD.MM.YYYY');
            } else {
                // @ts-ignore
                dataNew.certificate_date = null;
            }

            stepOrganizationForm.setFieldsValue(dataNew);
            stepProjectForm.setFieldsValue(dataNew);
        }
    }, [JSON.stringify(data)]);

    const createProject = async () => {
        const orgId = await createOrganization();
        if (orgId && typeof orgId === 'number') {
            const writers = getAuthorsArrayByJobName('screenwriter');
            const directors = getAuthorsArrayByJobName('director');

            const payload = {
                product: data.moduleId,
                product_name: data.name,
                organizer_organization_id: orgId,
                project_characteristic: {
                    name: data.name.trim(),
                    version_name: `Основная версия ${data.name.trim()}`,
                    version_name_by_default: data.version_name_by_default,
                    description: data.description.trim(),
                    working_name: data.working_name,
                    project_type: data.project_type.trim(),
                    timing: data.timing.trim(),
                    number_episodes: serialCount || null,
                    episode_timing: getEpisodeTiming().trim(),
                    parent_characteristic: data.parent_characteristic || null,
                    language: data.language.trim(),
                    additional_language: data.additional_language.trim(),
                    certificate_number: data.certificate_number || null,
                    certificate_date: dayjs(data.certificate_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.certificate_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null, // @ts-ignore
                    colors: getColors(),
                    // @ts-ignore
                    subtitle_languages: getSubtitlesLanguages(),
                    // @ts-ignore
                    genres: data.genres || [],
                    media_types: getMediaType(),

                    // @ts-ignore
                    film_type: data.film_type,
                    // @ts-ignore
                    film_view_type: data.film_view_type,
                    // @ts-ignore
                    film_format: data.film_format,
                    writers,
                    directors,
                },
            };
            const res = await PersonalAccount.createProject(payload);

            if (!res?.id) {
                Object.keys(res?.data).forEach((key) => {
                    openErrorNotification('Ошибка!', res?.data[key]);
                });
            } else {
                openSuccessNotification('Проект успешно создан');
                res.id && navigate(`/cabinet/projects/${res.id}`);
            }
        }
    };

    const createOrganization = async () => {
        let response: any = null;
        if (data.userType === 'UL' || data.userType === 'FL' || data.userType === 'IP') {
            const getCreateOrUpdateProps = getParamsForCreateOrUpdateOrg(data.userType);
            if (getCreateOrUpdateProps) response = await Organizations.createOrganization(getCreateOrUpdateProps);
        }

        if (!response?.id)
            Object.keys(response?.data).forEach((key) => {
                stepOrganizationForm.setFields([{ name: key, errors: response?.data[key] }]);
            });

        if (response?.id) return response?.id;
        openErrorNotification('Ошибка при создании организации');
        return null;
    };

    const updateOrganization = async () => {
        let response: any = null;
        if (data.userType === 'UL' || data.userType === 'FL' || data.userType === 'IP') {
            const createOrUpdateProps = getParamsForCreateOrUpdateOrg(data.userType);
            if (createOrUpdateProps && orgPropertiesIdForEdit && orgIdForEdit) {
                const { organization_characteristic: payload } = createOrUpdateProps;
                const payloadFinal = { ...payload, version_name: data?.version_name };
                response = await Organizations.updateOrganizationDetails(
                    orgIdForEdit,
                    orgPropertiesIdForEdit,
                    payloadFinal,
                );
            }
        }

        if (!response?.id)
            Object.keys(response?.data).forEach((key) => {
                stepOrganizationForm.setFields([{ name: key, errors: response?.data[key] }]);
            });
        if (response?.id) return response.id;
        openErrorNotification('Ошибка при редактировании контрагента');
        return null;
    };

    const updateOrganizationAndFinish = async () => {
        const result = await updateOrganization();
        if (result) {
            projectIdForReturnAction && navigate(`/cabinet/projects/${projectIdForReturnAction}`);
            openSuccessNotification('Контрагент успешно изменен');
        }
    };

    const updateProjectProperties = async () => {
        if (projectIdForEdit && projectPropertiesIdForEdit) {
            const writers = getAuthorsArrayByJobName('screenwriter');
            const directors = getAuthorsArrayByJobName('director');

            const payload = {
                name: data.name.trim(),
                version_name: data.version_name.trim(),
                version_name_by_default: data.version_name_by_default,
                description: data.description.trim(),
                working_name: data.working_name,
                project_type: data.project_type.trim(),
                timing: data.timing.trim(),
                number_episodes: serialCount || null,
                episode_timing: getEpisodeTiming().trim(),
                language: data.language.trim(),
                additional_language: data.additional_language.trim(),
                certificate_number: data.certificate_number || null,
                certificate_date: dayjs(data.certificate_date, 'MM.DD.YYYY').isValid()
                    ? dayjs(data.certificate_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                    : null,
                // @ts-ignore
                colors: getColors(),
                // @ts-ignore
                subtitle_languages: getSubtitlesLanguages(),
                // @ts-ignore
                genres: data.genres || [],
                media_types: getMediaType(),
                // @ts-ignore
                film_type: data.film_type,
                // @ts-ignore
                film_view_type: data.film_view_type,
                // @ts-ignore
                film_format: data.film_format,
                writers,
                directors,
            };
            const res = await PersonalAccount.updateProjectProperties(
                projectIdForEdit,
                projectPropertiesIdForEdit,
                payload,
            );

            if (!res?.id) {
                Object.keys(res?.data).forEach((key) => {
                    openErrorNotification('Ошибка!', res?.data[key]);
                });
            } else {
                openSuccessNotification('Проект успешно изменен');
                projectIdForReturnAction && navigate(`/cabinet/projects/${projectIdForReturnAction}`);
            }
        }
    };

    const getProjectForEdit = async () => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (projectIdForEdit && projectPropertiesIdForEdit) {
            const projectData = await PersonalAccount.getProject(projectIdForEdit);
            const projectProperties = await PersonalAccount.getProjectPropertiesSet(
                projectIdForEdit,
                projectPropertiesIdForEdit,
            );

            if (projectData?.subscriptions && projectData?.subscriptions.length > 0) {
                const filteredSubs = (projectData?.subscriptions as ISubscription[]).filter(
                    (sub) => sub.status !== ESubscriptionStatuses.Pending,
                );
                if (filteredSubs.length > 0) {
                    const lastSubscr = filteredSubs.reduce((prevSubscr, currentSubscr) =>
                        dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0 ? currentSubscr : prevSubscr,
                    );
                    if (lastSubscr.status === 'ACT') {
                        setIsSubscrActiveOrCreate(true);
                    }
                }
            }

            if (projectData?.id && projectProperties?.id) {
                const projectPropertiesCreatedDate = formatDate(projectProperties.created_at);
                const writers = getAuthorsFormattedData('screenwriter', projectProperties?.writers);
                const directors = getAuthorsFormattedData('director', projectProperties?.directors);
                const writersCount = projectProperties?.writers?.length || 1;
                const directorsCount = projectProperties?.directors?.length || 1;
                // простановка жанров
                const genresUnformatted = projectProperties?.genres || [];
                const checkedGenresNew: string[] = [];
                const customGenresNew: string[] = [];
                genresUnformatted.forEach((genre: string) => {
                    if (genreOptions.includes(genre.toLowerCase()) && !checkedGenresNew.includes(genre.toLowerCase())) {
                        checkedGenresNew.push(genre.toLowerCase());
                    }
                    if (!genreOptions.includes(genre.toLowerCase())) {
                        customGenresNew.push(genre);
                    }
                });
                const genres = checkedGenresNew.concat(customGenresNew);
                if (customGenresNew.length === 0) customGenresNew.push('');

                const formCustomGenres = customGenresNew.reduce(
                    (prev: any, current: any, index) => ({ ...prev, [`customGenre${index}`]: current }),
                    {},
                );
                setCheckedGenres(checkedGenresNew);
                setCustomGenres(customGenresNew);

                // простановка языков
                const language = projectProperties?.language || '';
                if (language !== 'Русский') setShowOtherLanguageInput(true);
                const subtitleLanguages = projectProperties?.subtitle_languages || [];
                const subtLanguagesObj = {
                    subtitle_language_0: subtitleLanguages[0] || '',
                    subtitle_language_1: subtitleLanguages[1] || '',
                    subtitle_language_2: subtitleLanguages[2] || '',
                };

                // простановка цветов
                const colorOptions = ['Цветной', 'Черно-белый', 'Сепия'];
                const colorsUnformatted = projectProperties?.colors || [];
                const checkedColors: string[] = [];
                let customColor = '';

                colorsUnformatted.forEach((color: string) => {
                    if (colorOptions.includes(color)) {
                        if (!checkedColors.includes(color)) {
                            checkedColors.push(color);
                        }
                    } else if (!customColor) {
                        customColor = color;
                        if (!checkedColors.includes('Другой')) checkedColors.push('Другой');
                    }
                });
                setCheckedColors(checkedColors);
                setColorInput(customColor);

                // простановка носителя
                const mediaOptions = ['HDD', 'Негатив'];
                const mediaUnformatted = projectProperties?.media_types || [];
                const checkedMedias: string[] = [];
                let customMedia = '';

                mediaUnformatted.forEach((media: string) => {
                    if (mediaOptions.includes(media)) {
                        if (!checkedMedias.includes(media)) {
                            checkedMedias.push(media);
                        }
                    } else if (!customMedia) {
                        customMedia = media;
                        if (!checkedMedias.includes('Другой')) checkedMedias.push('Другой');
                    }
                });
                setCustomCarrierValue(customMedia);

                // простановка хронометража
                let objTiming = {};
                if (projectProperties?.number_episodes && typeof projectProperties?.number_episodes === 'number')
                    setSerialCount(projectProperties?.number_episodes);

                if (projectProperties?.film_format === 'multi_episode') {
                    if (projectProperties?.episode_timing.includes('from')) {
                        const timingArray = projectProperties?.episode_timing.split(/from|to/);
                        if (!Number.isNaN(timingArray[1]) && !Number.isNaN(timingArray[2])) {
                            objTiming = {
                                approx_timing_from: timingArray[1],
                                approx_timing_to: timingArray[2],
                                episode_timing: '',
                            };
                            setActiveSeriesRadioEpisodeTiming('Ориентировочный');
                        } else {
                            objTiming = {
                                approx_timing_from: '',
                                approx_timing_to: '',
                                episode_timing: projectProperties?.episode_timing || '',
                            };
                            setActiveSeriesRadioEpisodeTiming('Точный');
                        }
                    }
                } else {
                    objTiming = {
                        approx_timing_from: '',
                        approx_timing_to: '',
                        episode_timing: projectProperties?.episode_timing || '',
                    };
                    setActiveSeriesRadioEpisodeTiming('Точный');
                }

                const dataToInsert = {
                    ...data,
                    ...projectProperties,
                    created_at: projectPropertiesCreatedDate,
                    product_name: projectData?.subscriptions[0]?.plan?.product?.name || '',
                    ...writers,
                    ...directors,
                    ...formCustomGenres,
                    genres,
                    colors: checkedColors,
                    custom_color_dev: customColor,
                    media_types: checkedMedias,
                    custom_media_type: customMedia,
                    ...objTiming,
                    choose_language: language === 'Русский' ? 'Русский' : 'Другой язык',
                    ...subtLanguagesObj,
                    ...(projectProperties?.certificate_date &&
                    dayjs(projectProperties?.certificate_date, 'YYYY-MM-DD').isValid()
                        ? {
                              certificate_date: dayjs(projectProperties?.certificate_date, 'YYYY-MM-DD').format(
                                  'MM.DD.YYYY',
                              ),
                          }
                        : {}),
                };

                Object.keys(dataToInsert).forEach((e) => {
                    if (dataToInsert[e] === null) dataToInsert[e] = '';
                });
                setTitle && setTitle(projectProperties?.version_name || '...');
                setData(dataToInsert);
                setNumberOfEntities({ authors: writersCount, directors: directorsCount });
            } else {
                navigate(`/cabinet/projects/${projectIdForEdit}`);
                openErrorNotification('Не удалось получить информацию о проекте');
            }
        }
    };

    const getOrgForEdit = async () => {
        if (orgIdForEdit && orgPropertiesIdForEdit) {
            const orgData = await Organizations.getOrganization(orgIdForEdit);
            const orgDetails = await Organizations.getOrganizationDetails(orgIdForEdit, orgPropertiesIdForEdit);

            if (projectIdForReturnAction) {
                const projectData = await PersonalAccount.getProject(projectIdForReturnAction);

                if (projectData?.subscriptions && projectData?.subscriptions.length > 0) {
                    const filteredSubs = (projectData?.subscriptions as ISubscription[]).filter(
                        (sub) => sub.status !== ESubscriptionStatuses.Pending,
                    );
                    if (filteredSubs.length > 0) {
                        const lastSubscr = filteredSubs.reduce((prevSubscr, currentSubscr) =>
                            dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0
                                ? currentSubscr
                                : prevSubscr,
                        );
                        if (lastSubscr.status === 'ACT') {
                            setIsSubscrActiveOrCreate(true);
                        }
                    }
                }
            }

            if (
                // @ts-ignore
                orgData?.id &&
                orgDetails?.id &&
                isOrganization(orgData as IOrganization)
            ) {
                const dataToInsert = {
                    ...data,
                    ...orgData,
                    ...orgDetails,
                    ...formatOrgDataDatesAndName(orgData),
                    ...formatOrgDataDatesAndName(orgDetails),
                    full_name_org: orgDetails.full_name,
                    short_name_org: orgDetails.name,
                };
                Object.keys(dataToInsert).forEach((e) => {
                    if (dataToInsert[e] === null) dataToInsert[e] = '';
                });
                setTitle && setTitle(orgDetails?.version_name || '...');
                setData(dataToInsert);
            } else {
                navigate(`/cabinet/contragents`);
                openErrorNotification('Не удалось получить информацию о контрагенте');
            }
        }
    };

    const onInputChange = (key: string, valueChange: string | null | boolean) => {
        const newData: any = { ...data };
        newData[key] = valueChange;
        setData(newData);
    };
    const handleDateChange = (field: string) => (date: Dayjs | null) => {
        const formattedDate = date ? date.format('DD.MM.YYYY') : null;
        onInputChange(field, formattedDate);
    };
    const formatDate = (dateString: string) => dayjs(dateString).format('DD.MM.YYYY');

    const formatOrgDataDatesAndName = (orgData: object) => ({
        // @ts-ignore
        // нужно разделить объект данных   на объект  проекта и объект организации
        organization_name: orgData?.name || null,
        // @ts-ignore
        ...(orgData?.organization_type ? { userType: orgData?.organization_type } : {}),
        // @ts-ignore
        ...(orgData?.issue_date_power_attorney &&
        // @ts-ignore
        dayjs(orgData?.issue_date_power_attorney, 'YYYY-MM-DD').isValid()
            ? {
                  // @ts-ignore

                  issue_date_power_attorney: dayjs(orgData.issue_date_power_attorney, 'YYYY-MM-DD').format(
                      'MM.DD.YYYY',
                  ),
              }
            : {}),
        // @ts-ignore
        ...(orgData?.registration_date && dayjs(orgData?.registration_date, 'YYYY-MM-DD').isValid()
            ? // @ts-ignore

              { registration_date: dayjs(orgData.registration_date, 'YYYY-MM-DD').format('MM.DD.YYYY') }
            : {}),
        // @ts-ignore
        ...(orgData?.birth_date && dayjs(orgData?.birth_date, 'YYYY-MM-DD').isValid()
            ? // @ts-ignore

              { birth_date: dayjs(orgData.birth_date, 'YYYY-MM-DD').format('MM.DD.YYYY') }
            : {}),
        // @ts-ignore
        ...(orgData?.passport_issued_date && dayjs(orgData?.passport_issued_date, 'YYYY-MM-DD').isValid()
            ? {
                  // @ts-ignore
                  passport_issued_date: dayjs(orgData.passport_issued_date, 'YYYY-MM-DD').format('MM.DD.YYYY'),
              }
            : {}),
        // @ts-ignore
        ...(orgData?.created_at && dayjs(orgData?.created_at, 'YYYY-MM-DD').isValid()
            ? {
                  // @ts-ignore
                  created_at: dayjs(orgData.created_at, 'YYYY-MM-DD').format('DD.MM.YYYY'),
              }
            : {}),
    });

    const getAuthorsArrayByJobName = (jobName: string) => {
        const result = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= MAX_AUTHOR_ENTITIES; i++) {
            // @ts-ignore
            const name = data[`name_${jobName}_${i}`]?.trim() || '';
            // @ts-ignore
            const surname = data[`surname_${jobName}_${i}`]?.trim() || '';
            // @ts-ignore
            const patronymic = data[`patronymic_${jobName}_${i}`]?.trim() || '';
            // @ts-ignore
            const nickname = data[`nickname_${jobName}_${i}`]?.trim() || '';
            if (name || surname || patronymic || nickname) result.push({ name, surname, patronymic, nickname });
        }
        return result;
    };

    const getAuthorsFormattedData = (
        jobName: string,
        authors: { name: string; surname: string; patronymic: string; nickname: string }[],
    ) => {
        const result = {};
        authors.forEach((author, index) => {
            // @ts-ignore
            result[`name_${jobName}_${index}`] = author?.name || '';
            // @ts-ignore
            result[`surname_${jobName}_${index}`] = author?.surname || '';
            // @ts-ignore
            result[`patronymic_${jobName}_${index}`] = author?.patronymic || '';
            // @ts-ignore
            result[`nickname_${jobName}_${index}`] = author?.nickname || '';
        });
        return result;
    };

    const getSubtitlesLanguages = () => {
        const subtitleLanguagesArr = [];
        for (let i = 0; i <= 2; i += 1) {
            const subtitleLanguage = data[`subtitle_language_${i}` as keyof typeof data];
            if (subtitleLanguage && typeof subtitleLanguage === 'string') {
                subtitleLanguagesArr.push(subtitleLanguage.trim());
            }
        }
        return subtitleLanguagesArr;
    };

    const getColors = () => {
        const { colors } = data;
        const otherColorIndex = colors.findIndex((c) => c === 'Другой');
        if (otherColorIndex >= 0 && colorInput.trim()) {
            (colors as string[])[otherColorIndex] = colorInput.trim();
            return colors;
        }
        if (otherColorIndex >= 0) colors.splice(otherColorIndex, 1);
        return colors;
    };

    const getMediaType = () => {
        const { media_types } = data;
        const otherMediaIndex = media_types.findIndex((c) => c === 'Другой');
        if (otherMediaIndex >= 0 && customCarrierValue.trim()) {
            (media_types as string[])[otherMediaIndex] = customCarrierValue.trim();
            return media_types;
        }
        if (otherMediaIndex >= 0) media_types.splice(otherMediaIndex, 1);
        return media_types;
    };

    const getEpisodeTiming = () => {
        const { episode_timing, approx_timing_from, approx_timing_to } = data;
        if (
            approx_timing_from &&
            approx_timing_to &&
            !Number.isNaN(approx_timing_from) &&
            !Number.isNaN(approx_timing_to)
        )
            return `from${approx_timing_from}to${approx_timing_to}`;
        return episode_timing;
    };

    const getParamsForCreateOrUpdateOrg = (type: 'UL' | 'FL' | 'IP') => {
        if (type === 'UL')
            return {
                organization_characteristic: {
                    name: data.short_name_org,
                    version_name: `Основная версия ${data.short_name_org}`,
                    version_name_by_default: data.version_name_by_default,
                    full_name: data.full_name_org,
                    organization_type: data.userType,
                    inn: data.inn,
                    legal_address: data.legal_address,
                    physical_address: data.physical_address,
                    correspondence_address: data.correspondence_address,
                    email: data.email,
                    phone: data.phone,

                    bank: data.bank,
                    checking_account: data.checking_account,
                    correspondent_account: data.correspondent_account,
                    bik: data.bik,

                    // UL
                    kpp: data.kpp,
                    ogrn: data.ogrn,
                    okpo: data.okpo,
                    okato: data.okato,
                    okved: data.okved,

                    fio_sole_executive_body: data.fio_sole_executive_body,
                    job_title_sole_executive_body: data.job_title_sole_executive_body,
                    document_exercising_sole_executive_body: data.document_exercising_sole_executive_body,
                    fio_representative: data.fio_representative,
                    job_title_representative: data.job_title_representative,
                    operates_on_basis: data.operates_on_basis,
                    authority_representative: data.authority_representative,
                    registration_certificate: data.registration_certificate,
                    registration_authority_number: data.registration_authority_number,
                    registration_date: dayjs(data.registration_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.registration_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,
                },
            };
        if (type === 'FL')
            return {
                organization_characteristic: {
                    name: `${data.first_name} ${data.last_name} ${data.patronymic}`.trim(),
                    version_name: `Основная версия ${data.first_name} ${data.last_name} ${data.patronymic}`.trim(),
                    version_name_by_default: data.version_name_by_default,
                    full_name: `${data.first_name} ${data.last_name} ${data.patronymic}`.trim(),
                    organization_type: data.userType,
                    inn: data.inn,

                    legal_address: data.legal_address || null,
                    physical_address: data.physical_address || null,
                    correspondence_address: data.correspondence_address || null,
                    email: data.email || null,
                    phone: data.phone || null,

                    passport_number: data.passport_number || null,
                    passport_issued_by: data.passport_issued_by || null,
                    passport_issued_date: dayjs(data.passport_issued_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.passport_issued_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,
                    passport_department_code: data.passport_department_code || null,

                    bank: data.bank || null,
                    checking_account: data.checking_account || null,
                    correspondent_account: data.correspondent_account || null,
                    bik: data.bik || null,

                    // FL
                    first_name: data.first_name || null,
                    last_name: data.last_name || null,
                    patronymic: data.patronymic || null,
                    birth_date: dayjs(data.birth_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.birth_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,
                    snils: data.snils || null,
                },
            };
        if (type === 'IP')
            return {
                organization_characteristic: {
                    name: data.short_name_org,
                    version_name: `Основная версия ${data.short_name_org}`,
                    version_name_by_default: data.version_name_by_default,
                    full_name: data.full_name_org,
                    organization_type: data.userType,
                    inn: data.inn,
                    birth_date: dayjs(data.birth_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.birth_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,

                    ogrnip: data.ogrnip || null,
                    registration_date: dayjs(data.registration_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.registration_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,
                    registration_certificate: `${data.ip_series_registration_certificate} ${data.ip_number_registration_certificate}`,

                    passport_number: data.passport_number || null,
                    passport_issued_by: data.passport_issued_by || null,
                    passport_issued_date: dayjs(data.passport_issued_date, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.passport_issued_date, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,
                    passport_department_code: data.passport_department_code || null,

                    legal_address: data.legal_address || null,
                    physical_address: data.physical_address || null,
                    correspondence_address: data.correspondence_address || null,
                    email: data.email || null,
                    phone: data.phone || null,

                    fio_representative: data.fio_representative || null,
                    phone_representative: data.phone_representative || null,
                    registration_authority_number: data.registration_authority_number || null,
                    issue_date_power_attorney: dayjs(data.issue_date_power_attorney, 'MM.DD.YYYY').isValid()
                        ? dayjs(data.issue_date_power_attorney, 'MM.DD.YYYY').format('YYYY-MM-DD')
                        : null,

                    bank: data.bank || null,
                    checking_account: data.checking_account || null,
                    correspondent_account: data.correspondent_account || null,
                    bik: data.bik || null,
                },
            };
    };

    const nextClick = async () => {
        if (stepActive < 3) {
            if (stepActive === 2) {
                await stepProjectForm
                    .validateFields()
                    .then(() => {
                        setStepActive(stepActive + 1);
                    })
                    .catch((e) => {
                        // скролл к ошибке
                        const fieldToScroll = projectFormFieldsOrder.find((field) =>
                            e.errorFields.some((errField: any) => errField?.name[0] === field),
                        );

                        if (fieldToScroll) {
                            stepProjectForm.scrollToField(fieldToScroll, {
                                behavior: 'smooth',
                                block: 'center',
                            });
                        } else {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }
                    });
            } else {
                setStepActive(stepActive + 1);
            }
        } else {
            await stepOrganizationForm
                .validateFields()
                .then(() => {
                    stepOrganizationForm.submit();
                })
                .catch((e) => {
                    // скролл к ошибке

                    let orderArr: any = [];
                    if (data.userType === 'UL') orderArr = orgEntityFormFieldsOrder;
                    if (data.userType === 'FL') orderArr = orgEntrepreneurFormFieldsOrder;
                    if (data.userType === 'IP') orderArr = orgIndividualFormFieldsOrder;

                    const fieldToScroll = orderArr.find((field: any) =>
                        e.errorFields.some((errField: any) => errField?.name[0] === field),
                    );

                    if (fieldToScroll) {
                        stepOrganizationForm.scrollToField(fieldToScroll, {
                            behavior: 'smooth',
                            block: 'center',
                        });
                    } else {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }
                });
        }
    };

    const handleSubmit = async () => {
        if (orgIdForEdit) {
            updateOrganizationAndFinish();
        } else {
            createProject();
        }
    };

    const prevClick = () => {
        if (projectIdForEdit) {
            setStepActive(stepActive - 1 > 2 ? stepActive - 1 : 2);
        } else {
            setStepActive(stepActive - 1 > 1 ? stepActive - 1 : 1);
        }
    };

    const getNextButtonText = () => {
        if (stepActive !== 3) return 'Далее';
        if (projectIdForEdit || orgIdForEdit) return 'Сохранить изменения';
        return 'Создать проект';
    };

    const getBackButtonText = () => {
        if (returnToOrgs) return 'Контрагенты';
        if (projectIdForEdit || orgIdForEdit) return 'Проект';
        return 'Проекты';
    };

    const handleBackButtonClick = () => {
        if (projectIdForReturnAction) return navigate(`/cabinet/projects/${projectIdForReturnAction}`);
        if (returnToOrgs) return navigate('/cabinet/contragents/');
        navigate(`/cabinet/projects'`);
    };

    return (
        <Container style={{ paddingTop: 50 }}>
            <div className={clsx(_.container, 'CreateProject')}>
                <BackButton onClick={handleBackButtonClick}>{getBackButtonText()}</BackButton>

                {!orgIdForEdit && !projectIdForEdit && (
                    <div className={_.navbar}>
                        <NavbarElement num={1} title='Выбор модуля' divider={false} isActive={stepActive === 1} />
                        <NavbarElement
                            num={2}
                            title='Характеристика проекта'
                            divider={false}
                            isActive={stepActive === 2}
                        />
                        <NavbarElement num={3} title='Заказчик' divider={false} isActive={stepActive === 3} />
                    </div>
                )}

                {stepActive === 1 && <StepOne data={data} setData={setData} />}
                {stepActive === 2 && projectIdForEdit && projectPropertiesIdForEdit && (
                    <>
                        {isLoading && <Loader top='40%' />}
                        <PropertyVersion
                            form={stepProjectForm}
                            data={data}
                            onInputChange={onInputChange}
                            onDateChange={handleDateChange}
                            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                            formName='name_form'
                        />
                    </>
                )}
                {stepActive === 2 && (
                    <ProjectCharacteristics
                        isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                        value={value}
                        data={data}
                        form={stepProjectForm}
                        onInputChange={onInputChange}
                        setData={setData}
                        maxAuthorEntities={MAX_AUTHOR_ENTITIES}
                        numberOfEntities={numberOfEntities}
                        setNumberOfEntities={setNumberOfEntities}
                        onDateChange={handleDateChange}
                        setHasError={setHasError}
                        customGenres={customGenres}
                        setCustomGenres={setCustomGenres}
                        checkedGenres={checkedGenres}
                        setCheckedGenres={setCheckedGenres}
                        showOtherLanguageInput={showOtherLanguageInput}
                        setShowOtherLanguageInput={setShowOtherLanguageInput}
                        checkedColors={checkedColors}
                        setCheckedColors={setCheckedColors}
                        colorInput={colorInput}
                        setColorInput={setColorInput}
                        customCarrierValue={customCarrierValue}
                        setCustomCarrierValue={setCustomCarrierValue}
                        serialCount={serialCount}
                        setSerialCount={setSerialCount}
                        activeSeriesRadioEpisodeTiming={activeSeriesRadioEpisodeTiming}
                        setActiveSeriesRadioEpisodeTiming={setActiveSeriesRadioEpisodeTiming}
                    />
                )}
                {stepActive === 3 && orgIdForEdit && orgPropertiesIdForEdit && (
                    <>
                        {isLoading && <Loader top='40%' />}
                        <PropertyVersion
                            form={stepOrganizationForm}
                            data={data}
                            onInputChange={onInputChange}
                            onDateChange={handleDateChange}
                            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                            formName='org_form'
                        />
                    </>
                )}
                {stepActive === 3 && (
                    <StepFour
                        isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                        form={stepOrganizationForm}
                        type={type}
                        data={data}
                        defaultorgData={defaultorgData}
                        onInputChange={onInputChange}
                        setData={setData}
                        orgPropertiesIdForEdit={orgPropertiesIdForEdit}
                    />
                )}

                <Form form={stepOrganizationForm} name='org_form' onFinish={handleSubmit} />

                {!projectIdForEdit && !orgIdForEdit && (
                    <div className={clsx(_.btns, stepActive === 1 && _.jcc)}>
                        {stepActive !== 1 && !orgIdForEdit && (
                            <Button
                                disabled={!!projectIdForEdit && stepActive === 2}
                                onClick={prevClick}
                                width='350px'
                                grey
                            >
                                <img className={_.prev} src={prev} alt='prev' />
                                Назад
                            </Button>
                        )}

                        <Button
                            disabled={
                                hasError || (stepActive === 1 && !data.moduleId) || (stepActive === 2 && !data.name)
                            }
                            onClick={nextClick}
                            width='350px'
                        >
                            {getNextButtonText()}
                            <img className={_.next} src={next} alt='next' />
                        </Button>
                    </div>
                )}

                {(projectIdForEdit || orgIdForEdit) && isSubscrActiveOrCreate && (
                    <div className={_.btnBlock}>
                        <Button
                            marginTop='50px'
                            width='350px'
                            className={_.btnSave}
                            onClick={() => {
                                if (projectIdForEdit) {
                                    stepProjectForm
                                        .validateFields()
                                        .then(() => {
                                            setShowSaveChangesModal(true);
                                        })
                                        .catch((e) => {
                                            // скролл к ошибке

                                            const fieldToScroll = projectFormFieldsOrder.find((field) =>
                                                e.errorFields.some((errField: any) => errField?.name[0] === field),
                                            );

                                            if (fieldToScroll) {
                                                stepProjectForm.scrollToField(fieldToScroll, {
                                                    behavior: 'smooth',
                                                    block: 'center',
                                                });
                                            } else {
                                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                            }
                                        });
                                } else {
                                    stepOrganizationForm
                                        .validateFields()
                                        .then(() => {
                                            setShowSaveChangesModal(true);
                                        })
                                        .catch((e) => {
                                            // скролл к ошибке

                                            let orderArr: any = [];
                                            if (data.userType === 'UL') orderArr = orgEntityFormFieldsOrder;
                                            if (data.userType === 'FL') orderArr = orgEntrepreneurFormFieldsOrder;
                                            if (data.userType === 'IP') orderArr = orgIndividualFormFieldsOrder;

                                            const fieldToScroll = orderArr.find((field: any) =>
                                                e.errorFields.some((errField: any) => errField?.name[0] === field),
                                            );

                                            if (fieldToScroll) {
                                                stepOrganizationForm.scrollToField(fieldToScroll, {
                                                    behavior: 'smooth',
                                                    block: 'center',
                                                });
                                            } else {
                                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                            }
                                        });
                                }
                            }}
                        >
                            Сохранить изменения
                        </Button>
                    </div>
                )}
            </div>

            <Modal
                className={_.modalCustom}
                open={showSaveChangesModal}
                onCancel={() => {
                    setShowSaveChangesModal(false);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainer}>
                        <div className={_.titleInvite}>
                            <Title mb={30} width={468}>
                                {`Сохранение изменений версии ${orgIdForEdit ? 'организации' : 'проекта'}`}
                            </Title>
                        </div>

                        <Text center mb={30} width={530}>
                            Подтвердите сохранение
                        </Text>
                        <div className={_.btnsSaveChanges}>
                            <Button
                                onClick={() => {
                                    setShowSaveChangesModal(false);
                                }}
                                grey
                                htmlType='submit'
                                width='250px'
                            >
                                Отмена
                            </Button>
                            <Button
                                onClick={projectIdForEdit ? updateProjectProperties : updateOrganizationAndFinish}
                                htmlType='submit'
                                width='250px'
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Container>
    );
};

export default CreateProject;
