import { ERates } from 'common/Rate/Rate';

export interface TariffType {
    num: number;
    date: string;
    projectName: string;
    term: string;
    tariff: ERates;
    price: string;
    status: PayStatus;
}

export enum PayStatus {
    Paid = 'Оплачено',
    Cancel = 'Отмена',
    InProgress = 'В обработке',
}

export enum Tariffs {
    Debut = 'Дебют',
    DebutLight = 'Дебют лайт',
    DebutMax = 'Дебют Максимум',
    Documental = 'Документальный',
}

export const tariffs: TariffType[] = [
    {
        num: 1,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект',
        term: '30 дней',
        tariff: ERates.Start,
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
    {
        num: 2,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект',
        term: '30 дней',
        tariff: ERates.StartLight,
        price: '1000 руб.',
        status: PayStatus.Cancel,
    },
    {
        num: 3,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект',
        term: '30 дней',
        tariff: ERates.StartMax,
        price: '1000 руб.',
        status: PayStatus.InProgress,
    },
    {
        num: 4,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект1',
        term: '30 дней',
        tariff: ERates.Start,
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
    {
        num: 5,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект',
        term: '30 дней',
        tariff: ERates.StartLight,
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
    {
        num: 6,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект1',
        term: '30 дней',
        tariff: ERates.Start,
        price: '1000 руб.',
        status: PayStatus.InProgress,
    },
    {
        num: 7,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект',
        term: '30 дней',
        tariff: ERates.StartMax,
        price: '1000 руб.',
        status: PayStatus.Cancel,
    },
    {
        num: 8,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект2',
        term: '30 дней',
        tariff: ERates.Documental,
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
    {
        num: 9,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект',
        term: '30 дней',
        tariff: ERates.Start,
        price: '1000 руб.',
        status: PayStatus.Cancel,
    },
    {
        num: 10,
        date: '01.02.2023 00:00:00',
        projectName: 'Мой проект3',
        term: '30 дней',
        tariff: ERates.Documental,
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
];

export interface HotLineType {
    num: number;
    date: string;
    price: string;
    status: PayStatus;
}

export const hotLinePayments: HotLineType[] = [
    {
        num: 1,
        date: '01.02.2023 00:00:00',
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
    {
        num: 1,
        date: '01.01.2023 00:00:00',
        price: '1000 руб.',
        status: PayStatus.Paid,
    },
];
