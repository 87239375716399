import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import DatePicker from 'common/DatePicker/DatePicker';

interface IForDocuments {
    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    form: FormInstance;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    isSubscrActiveOrCreate: boolean;
}

const ForDocuments: React.FC<IForDocuments> = ({ form, onInputChange, onDateChange, isSubscrActiveOrCreate }) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Для документов</div>

            <MyFormItem name='fio_representative' label='ФИО представителя '>
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('fio_representative', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <div className='shortInnersBlock'>
                <MyFormItem name='phone_representative' label='Телефон'>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('phone_representative', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='issue_date_power_attorney' label='Дата выдачи доверенности на представителя'>
                    <DatePicker
                        className='inputInner inputInnerShort2'
                        onChange={onDateChange('issue_date_power_attorney')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default ForDocuments;
