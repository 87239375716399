import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

interface BarcodeProps {
    value: string;
}

const Barcode: React.FC<BarcodeProps> = ({ value }) => {
    const barcodeRef = useRef<SVGSVGElement | null>(null);

    useEffect(() => {
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, value, {
                format: 'CODE128',
                displayValue: true,
            });
        }
    }, [value]);

    return <svg ref={barcodeRef} />;
};

export default Barcode;
