import RateStep1 from 'assets/rates/RateStep1.svg';
import RateStep2 from 'assets/rates/RateStep2.svg';
import RateStep3 from 'assets/rates/RateStep3.svg';
import RateStepArrow from 'assets/rates/RateStepArrow.svg';
import Title from 'common/StyledElements/Title/Title';

import _ from './RateSteps.module.css';

const RateSteps = () => (
    <div className={_.stepsWrapper}>
        <div className={_.step}>
            <img src={RateStep1} alt='step1' />
            <div className={_.stepNum}>Шаг 1</div>
            <Title small bold>
                Зарегистрироваться
            </Title>
        </div>
        <div className={_.arrowWrapper}>
            <img className={_.arrow} src={RateStepArrow} alt='arrow' />
        </div>

        <div className={_.step}>
            <img src={RateStep2} alt='step2' />
            <div className={_.stepNum}>Шаг 2</div>
            <Title small bold>
                Создать проект
            </Title>
        </div>
        <div className={_.arrowWrapper}>
            <img className={_.arrow} src={RateStepArrow} alt='arrow' />
        </div>

        <div className={_.step}>
            <img src={RateStep3} alt='step3' />
            <div className={_.stepNum}>Шаг 3</div>
            <Title small bold>
                Оплатить
            </Title>
        </div>
    </div>
);

export default RateSteps;
