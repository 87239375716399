import { FC, useEffect } from "react";

interface ProofixPlayerProps {
    companyCode: string;
    eventCode: string;
    eventId: number;
}

const ProofixPlayer: FC<ProofixPlayerProps> = ({ companyCode, eventCode, eventId }) => {
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://api.proofix.ru/js/proofix-widgets.js?1.3.33';
        document.body.appendChild(script1);

        script1.onload = () => {
            const script2 = document.createElement('script');
            script2.innerHTML = `
        window.initProofixWidgetsController({
          companyCode: "${companyCode}",
          eventCode: "${eventCode}",
          eventId: ${eventId},
          hosts: {
            API: 'https://api.proofix.ru/api',
            socket: 'https://wss.proofix.ru/users',
            statSocket: 'https://ws-stats.proofix.ru'
          },
          isEmbed: true
        })
      `;
            document.body.appendChild(script2);
        }

        return () => {
            document.body.removeChild(script1);
        };
    }, [companyCode, eventCode, eventId]);

    return <div id="rooms" />;
};

export default ProofixPlayer;