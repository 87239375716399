import { FC, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popover } from 'antd';
import clsx from 'clsx';

import AuthContext from 'common/AuthProvider/AuthProvider';
import Text from 'common/StyledElements/Text/Text';
import { Auth } from 'connectors/query/Auth';

import _ from './PersonAvatar.module.css';

interface IPersonAvatarProps {
    firstName: string | undefined;
    lastName: string | undefined;
    mr?: string | number;
    menu?: boolean;
    photo?: string | null;
}

const PersonAvatar: FC<IPersonAvatarProps> = ({ firstName = '', lastName = '', photo, menu, mr }) => {
    const { clearAuth, userData } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [popoverIsShown, setPopoverIsShown] = useState<boolean>(false);

    const exit = async () => {
        setPopoverIsShown(false);
        const res = await Auth.logout();
        if (res?.status === 200 || res?.status === 201 || res?.status === 'ok') clearAuth();
    };
    const navigateToAcc = () => {
        setPopoverIsShown(false);
        // location.pathname === '/cabinet/settings'
        //     ? window.scrollTo({ top: 0, behavior: 'smooth' })
        //     : navigate('/cabinet/settings');
        location.pathname === '/cabinet/projects'
            ? window.scrollTo({ top: 0, behavior: 'smooth' })
            : navigate('/cabinet/projects');
        // напоминалка навигация в настройки вместо проектов
    };

    const content = (
        <>
            <Text className={_.menuBtn} onClick={navigateToAcc} small>
                Личный кабинет
            </Text>
            <Text className={_.menuBtn} onClick={exit} small>
                Выход
            </Text>
        </>
    );

    if (menu)
        return (
            <Popover
                placement='bottom'
                trigger='click'
                content={content}
                open={popoverIsShown}
                getPopupContainer={() => document.getElementById('PersonAvatarPopover') || document.body}
                onOpenChange={(open) => {
                    setPopoverIsShown(open);
                }}
            >
                <div id='PersonAvatarPopover' className={clsx(_.wrapper, photo && _.transparent)}>
                    {photo ? (
                        <img className={_.photo} src={photo} alt='user-avatar' />
                    ) : (
                        <Text white>
                            {lastName && lastName[0]}
                            {firstName && firstName[0]}
                        </Text>
                    )}
                </div>
            </Popover>
        );

    return (
        <div className={clsx(_.wrapper, photo && _.transparent, _.noCursor)} style={{ marginRight: mr }}>
            {photo ? (
                <img className={clsx(_.photo, _.noCursor)} src={photo} alt='user-avatar' />
            ) : (
                <Text white>
                    {lastName && lastName[0]}
                    {firstName && firstName[0]}
                </Text>
            )}
        </div>
    );
};

export default PersonAvatar;
