import { APIService } from 'connectors/api/rest';

export interface IFaqData {
    id: number;
    published: boolean;
    question: string;
    answer: string;
    ordinal: number;
    published_at: string;
    plan_publish_at: string | null;
}

export const Faq = {
    async getAll(params: any) {
        try {
            return await APIService.GET('/faq/', { published: true, ...params });
        } catch (e) {
            return e;
        }
    },
};
