import { FC, useEffect } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Form, FormInstance } from 'antd';

import BasicDetails from './components/BasicDetails/BasicDetails';
import StepFourAddress from '../StepFourAddress/StepFourAddress';
import ForDocuments from './components/ForDocuments/ForDocuments';
import StepFourBank from '../StepFourBank/StepFourBank';

interface IAddressProps {
    data: any;
    value?: Dayjs;
    type?: string;
    form: FormInstance;
    onChange?: () => void;
    onInputChange: (key: string, value: string | null) => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    setData: (i: any) => void;
    orgPropertiesIdForEdit?: number;
    isSubscrActiveOrCreate: boolean;
}

const StepFourEntity: FC<IAddressProps> = ({
    data,
    value,
    type,
    onChange,
    onInputChange,
    onDateChange,
    setData,
    form,
    orgPropertiesIdForEdit,
    isSubscrActiveOrCreate,
}) => (
    <div className='main'>
        <BasicDetails
            form={form}
            onInputChange={onInputChange}
            orgPropertiesIdForEdit={orgPropertiesIdForEdit}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourAddress
            form={form}
            type={type}
            onInputChange={onInputChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <ForDocuments
            form={form}
            value={value}
            data={data}
            onInputChange={onInputChange}
            onChange={onChange}
            onDateChange={onDateChange}
            setData={setData}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourBank form={form} onInputChange={onInputChange} isSubscrActiveOrCreate={isSubscrActiveOrCreate} />
    </div>
);

export default StepFourEntity;
