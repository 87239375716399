import { APIService } from 'connectors/api/rest';

export const Landing = {
    async getAll(params?: any) {
        try {
            return await APIService.GET('/landing/', { ...params });
        } catch (e) {
            return e;
        }
    },
};
