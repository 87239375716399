import { FC } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import TypeHotline, { ETypeHotline } from 'common/TypeHotline/TypeHotline';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import clsx from 'clsx';

import _ from './CalendarEvent.module.css';

interface IWebItemProps {
    date: Date;
    description: string;
    type: ETypeHotline | null;
    onClick: () => void;
    clickable: boolean;
}

const CalendarEvent: FC<IWebItemProps> = ({ date, type, description, clickable, onClick }) => (
    <div className={_.left__block} onClick={onClick}>
        <div className={clsx(_.event, clickable && _.clickable)}>
            <div className={_.leftSide}>
                <div className={_.leftSideDate}>
                    <div className={_.day}>{format(date, 'dd')}</div>
                    <div className={_.month}>{format(date, 'MMMM yyyy', { locale: ru })}</div>
                    <div className={_.dayWeek}>{format(date, 'eeee', { locale: ru })}</div>
                </div>
            </div>

            <div className={_.rightSide}>
                {type !== null && <TypeHotline type={[type]} />}

                <div className={_.description} title={logoReplacer(description, 'plainText')}>
                    {logoReplacer(description)}
                </div>
            </div>
        </div>
    </div>
);

export default CalendarEvent;
