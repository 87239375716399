import { useEffect } from 'react';
import { Form, Input, FormInstance } from 'antd';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';

interface ITimingBlock {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const TimingBlock: React.FC<ITimingBlock> = ({ onInputChange, data, setData, form, isSubscrActiveOrCreate }) => {
    const handleOnlyNumbers = (event: React.KeyboardEvent) => {
        const { charCode } = event;
        if (charCode && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    return (
        <div className='blockCard w100'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='titleCard mb0 required-field'>Хронометраж (минуты)</div>
                <div className='subTitle'>(ориентировочный, включая начальные и финальные титры)</div>

                <MyFormItem
                    name='timing'
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: 'Необходимо заполнить' }]}
                >
                    <Input
                        placeholder='###'
                        className='inputInner select3'
                        onChange={(event) => onInputChange('timing', event.target.value)}
                        onKeyPress={handleOnlyNumbers}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </Form>
        </div>
    );
};

export default TimingBlock;
