import { notification } from 'antd';

import Error from 'assets/notifications/error.svg';
import Info from 'assets/notifications/info.svg';
import Success from 'assets/notifications/success.svg';

export const openErrorNotification = (message: string, description?: string) => {
    notification.open({
        message,
        description,
        icon: (
            <div style={{ width: 100 }}>
                <img src={Error} alt='error' />
            </div>
        ),
    });
};

export const openInfoNotification = (message: string, description?: string) => {
    notification.open({
        message,
        description,
        icon: (
            <div style={{ width: 100 }}>
                <img src={Info} alt='info' />
            </div>
        ),
    });
};

export const openSuccessNotification = (message: string, description?: string) => {
    notification.open({
        message,
        description,
        icon: (
            <div style={{ width: 100 }}>
                <img src={Success} alt='success' />
            </div>
        ),
    });
};
