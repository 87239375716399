import { FC, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Modal } from 'antd';

import pic from 'assets/footerLogo.png';
import arrow from 'assets/circleArrow.svg';
import logo from 'assets/logoLight.svg';
import logoCosmik from 'assets/logoCosmicFilm.png';
import klass from 'assets/klass.svg';
import telegram from 'assets/telegram.svg';
import vk from 'assets/vk.svg';
import AskForm from 'common/AskForm/AskForm';
import Button from 'common/Button/Button';
import Title from 'common/StyledElements/Title/Title';
import { ScrollPoints } from 'components/LandingPage/LandingPage';
import { IDocumentData, Documents } from 'connectors/query/Documents';
import LandingContext from 'common/LandingProvider/LandingProvider';
import DocumentModal from 'common/DocumentModal/DocumentModal';
import AuthContext from 'common/AuthProvider/AuthProvider';
import CustomButton from './components/Button/Button';

import _ from './Footer.module.css';

interface IFooterProps {
    border?: boolean;
    isModalShown?: boolean;
    scrollToEl?: (el: any) => void;
    setShowModalProp?: (isModalShown: boolean) => void;
    mt?: number | string;
}
type DocumentType = 'privacyPolicy' | 'paymentRules' | 'refund' | 'refusal';

const Footer: FC<IFooterProps> = ({ border, scrollToEl, isModalShown, setShowModalProp, mt }) => {
    const [showModal, setShowModal] = useState(false);
    const [showDoc, setShowDoc] = useState<DocumentType | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const landingContextData = useContext(LandingContext);
    const { hasLoaded, logged } = useContext(AuthContext);
    const { codenames } = landingContextData;
    const [documentData, setDocumentData] = useState<IDocumentData | null>(null);

    const getDocumentData = async (docType: DocumentType): Promise<IDocumentData | null> => {
        const res = await Documents[docType]();
        if (res?.content) {
            setDocumentData(res);
            return res;
        }
        return null;
    };

    const openDocumentModal = async (docType: DocumentType) => {
        setShowDoc(null);
        const res = await getDocumentData(docType);
        if (res) {
            setShowDoc(docType);
        }
    };

    return (
        <div className={clsx(_.footerWrapper, border && _.border)}>
            <div className={_.topWrapper} style={{ marginTop: mt }}>
                <div className={_.footer}>
                    <div className={_.footerLogos}>
                        <img src={logoCosmik} className={_.picCosmik} alt='logoCosmik' />
                        <a href='https://fundregion.ru' target='_blank' rel='noreferrer'>
                            <img className={_.pic} src={pic} alt='fundregion.ru' />
                        </a>
                    </div>
                    <div className={_.btns}>
                        {codenames.includes('contract_builder') && (
                            <CustomButton
                                text='Конструктор договоров'
                                onClick={() => {
                                    scrollToEl
                                        ? scrollToEl(ScrollPoints.EditorPoint)
                                        : navigate('/landing', { state: { scrolling: ScrollPoints.EditorPoint } });
                                }}
                            />
                        )}
                        {!logged && hasLoaded && (
                            <CustomButton
                                text='Цены'
                                onClick={() => {
                                    location.pathname === '/modules'
                                        ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                        : navigate('/modules');
                                }}
                            />
                        )}
                        <CustomButton
                            text='КИНО И ПРАВО'
                            onClick={() => {
                                location.pathname === '/practice'
                                    ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                    : navigate('/practice');
                            }}
                        />

                        {codenames.includes('reviews') && (
                            <CustomButton
                                text='Отзывы'
                                onClick={() => {
                                    scrollToEl
                                        ? scrollToEl(ScrollPoints.ReviewsPoint)
                                        : navigate('/landing', { state: { scrolling: ScrollPoints.ReviewsPoint } });
                                }}
                            />
                        )}
                        <CustomButton
                            text='Q&A'
                            onClick={() => {
                                location.pathname === '/faq'
                                    ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                    : navigate('/faq');
                            }}
                        />
                        <CustomButton
                            text='Новости'
                            onClick={() => {
                                location.pathname === '/news'
                                    ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                    : navigate('/news');
                            }}
                        />
                        {codenames.includes('team') && (
                            <CustomButton
                                text='Команда'
                                onClick={() => {
                                    scrollToEl
                                        ? scrollToEl(ScrollPoints.TeamPoint)
                                        : navigate('/landing', { state: { scrolling: ScrollPoints.TeamPoint } });
                                }}
                            />
                        )}
                    </div>

                    <div className={_.addressWrapper}>
                        <div className={_.emailWrapper}>
                            <a href='mailto:info@movie-right.ru' className={_.email}>
                                info@movie-right.ru
                            </a>
                            <img
                                src={arrow}
                                alt='arrow'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                }}
                            />
                        </div>

                        <Button width='350px' onClick={() => setShowModal(true)}>
                            Напишите нам
                        </Button>
                    </div>
                </div>
            </div>

            <div className={_.docs}>
                <div className={clsx(_.container, _.docsBlock)}>
                    <div className={_.paymentRules} onClick={() => openDocumentModal('paymentRules')}>
                        Описание оплаты товара
                    </div>
                    <div className={_.paymentRules} onClick={() => openDocumentModal('refund')}>
                        Возврат товара
                    </div>
                    <div className={_.paymentRules} onClick={() => openDocumentModal('refusal')}>
                        Отказ от услуги
                    </div>
                </div>
            </div>

            <div className={_.bottom}>
                <div className={_.container}>
                    <div className={_.logoWrapper}>
                        <img src={logo} className={_.logo} alt='logo' />

                        <div className={_.copyright}>2023 © Космик Фильм</div>
                    </div>

                    <div className={_.policity} onClick={() => openDocumentModal('privacyPolicy')}>
                        Политика конфиденциальности
                    </div>

                    <div className={_.icons}>
                        <a href='https://vk.com/movieright' target='_blank' rel='noreferrer'>
                            <img src={vk} alt='vk' />{' '}
                        </a>

                        <img src={klass} alt='klass' />
                        <img src={telegram} alt='telegram' />
                    </div>
                </div>
            </div>

            <Modal
                open={showModal || isModalShown}
                onCancel={() => {
                    setShowModal(false);
                    setShowModalProp && setShowModalProp(false);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalInner}>
                    <Title mt={60} family='Manrope'>
                        Задать вопрос
                    </Title>

                    <AskForm
                        closeModal={() => {
                            setShowModal(false);
                            setShowModalProp && setShowModalProp(false);
                        }}
                    />
                </div>
            </Modal>

            <DocumentModal
                isOpen={showDoc !== null}
                onClose={() => setShowDoc(null)}
                title={documentData?.name || ''}
                content={documentData?.content}
            />
        </div>
    );
};

export default Footer;
