import { IUserData } from 'common/AuthProvider/AuthProvider';
import { APIService } from 'connectors/api/rest';

interface IDeleteAccountConfirm {
    uid: string;
    token: string;
}

interface IChangeEmailConfirm {
    uid: string;
    token: string;
}

interface IChangePasswordConfirm {
    uid: string;
    token: string;
    password1: string;
    password2: string;
    current_password: string;
}

export interface IUserDataPatch {
    id: number;
    email?: string;
    phone?: string;
    first_name?: string;
    last_name?: string;
    patronymic?: string;
    user_profile?: {
        photo: any;
        organization?: any;
    };
}

export const Users = {
    async deleteAccount() {
        try {
            return await APIService.POST('/users/delete_account_request/');
        } catch (e) {
            return e;
        }
    },

    async deleteAccountConfirm(params: IDeleteAccountConfirm) {
        try {
            return await APIService.POST('/users/delete_account_confirm/', params);
        } catch (e) {
            return e;
        }
    },

    async changePass() {
        try {
            return await APIService.POST('/users/change_password_request/');
        } catch (e) {
            return e;
        }
    },
    async changePassConfirm(params: IChangePasswordConfirm) {
        try {
            return await APIService.POST('/users/change_password_confirm/', params);
        } catch (e) {
            return e;
        }
    },

    async changeEmail(email: string) {
        try {
            return await APIService.POST('/users/change_email_request/', { email: email.toLowerCase() });
        } catch (e) {
            return e;
        }
    },
    async changeEmailConfirm(params: IChangeEmailConfirm) {
        try {
            return await APIService.POST('/users/change_email_confirm/', params);
        } catch (e) {
            return e;
        }
    },

    async getUser(id: string | number): Promise<IUserData> {
        try {
            return await APIService.GET(`/users/${id}/`);
        } catch (e) {
            return (await APIService.GET(`/users/${id}/`)) as IUserData;
        }
    },

    async patchUserData(params: IUserDataPatch) {
        const params2 = { ...params, ...(params.email ? { email: params.email.toLowerCase() } : {}) };
        try {
            return await APIService.PATCH(`/users/${params.id}/`, params2, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            return e;
        }
    },
    async putUserData(id: number, params: IUserDataPatch) {
        const params2 = { ...params, ...(params.email ? { email: params.email.toLowerCase() } : {}) };
        try {
            return await APIService.PUT(`/users/${id}/`, params2, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            return e;
        }
    },
};
