import { useEffect, useState } from 'react';

import { Form, Input, Radio, FormInstance } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import Divider from 'common/StyledElements/Divider/Divider';

import _ from './LanguageBlock.module.css';

interface ILanguageBlock {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    form: FormInstance;
    showOtherLanguageInput: boolean;
    setShowOtherLanguageInput: React.Dispatch<React.SetStateAction<boolean>>;
    isSubscrActiveOrCreate: boolean;
}

const LanguageBlock: React.FC<ILanguageBlock> = ({
    onInputChange,
    data,
    setData,
    form,
    showOtherLanguageInput,
    setShowOtherLanguageInput,
    isSubscrActiveOrCreate,
}) => {
    const handleSubtitleChange = (index: number, value: string) => {
        onInputChange(`subtitle_language_${index}`, value);
    };

    useEffect(() => {
        if (data) {
            setShowOtherLanguageInput(data.choose_language === 'Другой язык');
        }
    }, [data]);

    return (
        <div className='blockCard w100'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='titleCard mb0 required-field'>Язык</div>

                <MyFormItem name='choose_language' style={{ marginBottom: 0 }}>
                    <Radio.Group
                        className={_.radioBlock}
                        onChange={(e) => {
                            setData({
                                ...data,
                                language: e.target.value === 'Русский' ? 'Русский' : '  ',
                                choose_language: e.target.value,
                            });
                            setShowOtherLanguageInput(e.target.value === 'Другой язык');
                        }}
                    >
                        <Radio className={_.radioItem} value='Русский' disabled={!isSubscrActiveOrCreate}>
                            Русский
                        </Radio>
                        <Radio className={_.radioItem} value='Другой язык' disabled={!isSubscrActiveOrCreate}>
                            Другой язык
                        </Radio>
                    </Radio.Group>
                </MyFormItem>

                <div className={_.main}>
                    {showOtherLanguageInput && (
                        <MyFormItem
                            name='language'
                            label='Другой язык'
                            rules={[
                                { required: true, message: 'Не указан язык.' },
                                { whitespace: true, message: 'Не указан язык.' },
                            ]}
                        >
                            <Input
                                className='inputInner inputInnerShort2'
                                placeholder='Укажите язык'
                                onChange={(event) => onInputChange('language', event.target.value)}
                                disabled={!isSubscrActiveOrCreate}
                            />
                        </MyFormItem>
                    )}
                    <MyFormItem name='additional_language' label='Дополнительный язык'>
                        <Input
                            className='inputInner inputInnerShort2'
                            placeholder='Дополнительный язык фильма'
                            onChange={(event) => onInputChange('additional_language', event.target.value)}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>
                </div>

                <Divider />

                <div className='titleCard mb0'>Субтитры</div>

                <div className={_.main}>
                    <MyFormItem name='subtitle_language_0' style={{ marginBottom: 0 }}>
                        <Input
                            className='inputInner select4'
                            placeholder='Введите язык субтитров'
                            onChange={(event) => handleSubtitleChange(0, event.target.value)}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>
                    <MyFormItem name='subtitle_language_1' style={{ marginBottom: 0 }}>
                        <Input
                            className='inputInner select4'
                            placeholder='Введите язык субтитров'
                            onChange={(event) => handleSubtitleChange(1, event.target.value)}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>
                    <MyFormItem name='subtitle_language_2' style={{ marginBottom: 0 }}>
                        <Input
                            className='inputInner select4'
                            placeholder='Введите язык субтитров'
                            onChange={(event) => handleSubtitleChange(2, event.target.value)}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>
                </div>
            </Form>
        </div>
    );
};

export default LanguageBlock;
