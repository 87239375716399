import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import DatePicker from 'common/DatePicker/DatePicker';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import validationRules from 'utils/formValidationRules';

interface IForDocuments {
    data: any;
    onInputChange: (key: string, value: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    setData: (i: any) => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const ForDocuments: React.FC<IForDocuments> = ({ form, onInputChange, onDateChange, isSubscrActiveOrCreate }) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Для документов</div>

            <MyFormItem name='fio_sole_executive_body' label='ФИО единоличного исполнительного органа'>
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('fio_sole_executive_body', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <MyFormItem name='job_title_sole_executive_body' label='Должность единоличного исполнительного органа'>
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('job_title_sole_executive_body', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <MyFormItem
                name='document_exercising_sole_executive_body'
                label='Документ-основание осуществления полномочий единоличного исполнительного органа'
            >
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('document_exercising_sole_executive_body', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <MyFormItem name='fio_representative' label='ФИО представителя'>
                <Input
                    className='inputInner'
                    onChange={(event) => onInputChange('fio_representative', event.target.value)}
                    maxLength={256}
                    disabled={!isSubscrActiveOrCreate}
                />
            </MyFormItem>

            <div className='shortInnersBlock'>
                <MyFormItem name='job_title_representative' label='Должность представителя'>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('job_title_representative', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='operates_on_basis' label='Действует на основании'>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('operates_on_basis', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='authority_representative' label='Полномочие представителя'>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('authority_representative', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='registration_certificate'
                    label='Свидетельство о регистрации'
                    rules={[validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('registration_certificate', event.target.value)}
                        maxLength={64}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='registration_authority_number'
                    label='Номер регистрирующего органа'
                    rules={[validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('registration_authority_number', event.target.value)}
                        maxLength={64}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='registration_date' label='Дата регистрации'>
                    <DatePicker
                        className='inputInner inputInnerShort2'
                        onChange={onDateChange('registration_date')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);

export default ForDocuments;
