import { APIService } from 'connectors/api/rest';

interface ISimpleGetWithPagination {
    limit: number;
    offset: number;
    ordering?: string;
}

export interface IOrganizations {
    count: number;
    results: IOrganization[];
}

export interface IOrganization {
    id: number;
    organization_type: string;
    name: string;
    full_name: string;
    inn: string;
    kpp?: string | null;
    ogrn?: string | null;
    okpo?: string | null;
    okato?: string | null;
    okved?: string | null;
    organization_characteristic: {
        // UL
        corporate_form?: string | null;
        legal_address?: string | null;
        physical_address?: string | null;
        correspondence_address?: string | null;
        email?: string | null;
        phone?: string | null;
        fio_sole_executive_body?: string | null;
        job_title_sole_executive_body?: string | null;
        document_exercising_sole_executive_body?: string | null;
        fio_representative?: string | null;
        job_title_representative?: string | null;
        operates_on_basis?: string | null;
        authority_representative?: string | null;
        issue_date_power_attorney?: string | null;
        phone_representative?: string | null;
        registration_certificate?: string | null;
        registration_authority_number?: string | null;
        registration_date?: string | null;
        bank?: string | null;
        checking_account?: string | null;
        correspondent_account?: string | null;
        bik?: string | null;

        // FL
        first_name?: string | null;
        last_name?: string | null;
        patronymic?: string | null;
        birth_date?: string | null;

        snils?: string | null;
        passport_number?: string | null;
        passport_issued_by?: string | null;
        passport_issued_date?: string | null;
        passport_department_code?: string | null;

        // IP
        ogrnip?: string | null;

        // Дополнительные поля на основе предоставленного примера
        version_name?: string | null;
        parent_characteristic?: null;
        updated_by?: null;
    };

    // удалить потом
    address?: {
        value: string | null;
        unrestricted_value: string | null;
        invalidity: null | string;
    };
}

export interface IProjectCustomerDetails {
    // потом закинуть в IOrganization
    id: number;
    version_difference: string[];
    created_at: string;
    updated_at: string;
    organization_type: string;
    version_name: string | string;
    corporate_form: string | null;
    inn: string | null;
    ogrn: string | null;
    kpp: string | null;
    okpo: string | null;
    okato: string | null;
    okved: string | null;
    legal_address: string | null;
    physical_address: string | null;
    correspondence_address: string | null;
    email: string | null;
    phone: string | null;
    fio_sole_executive_body: string | null;
    job_title_sole_executive_body: string | null;
    document_exercising_sole_executive_body: string | null;
    fio_representative: string | null;
    job_title_representative: string | null;
    operates_on_basis: string | null;
    authority_representative: string | null;
    issue_date_power_attorney: string | null;
    phone_representative: string | null;
    registration_certificate: string | null;
    registration_authority_number: string | null;
    registration_date: string | null;
    bank: string | null;
    checking_account: string | null;
    correspondent_account: string | null;
    bik: string | null;
    first_name: string | null;
    last_name: string | null;
    patronymic: string | null;
    birth_date: string | null;
    snils: string | null;
    passport_number: string | null;
    passport_issued_by: string | null;
    passport_issued_date: string | null;
    passport_department_code: string | null;
    ogrnip: string | null;
    organization: number;
    parent_characteristic: number | null;
    created_by: number;
    updated_by: number | number;
}

export const isOrganization = (b: any): b is IOrganization => (b as IOrganization).id !== undefined;

export const Organizations = {
    async getOrganizations(params: {
        name?: string;
        inn?: string;
        limit?: number;
        offset?: number;
        ordering?: string;
        organization_type?: string | null;
    }) {
        try {
            return await APIService.GET('/organizations/', params);
        } catch (e) {
            console.error('Error get organizations: ', e);
            return e;
        }
    },

    async getOrganization(id: string | number) {
        try {
            return await APIService.GET(`/organizations/${id}/`);
        } catch (e) {
            console.error('Error get organization: ', e);
            return e;
        }
    },

    async getOrganizationDetails(id: string | number, idDetails: string | number) {
        try {
            return await APIService.GET(`/organizations/${id}/characteristics/${idDetails}/`);
        } catch (e) {
            console.error('Error get organization: ', e);
            return e;
        }
    },

    async deleteOrganization(id: number) {
        try {
            return await APIService.DELETE(`/organizations/${id}/`);
        } catch (e) {
            console.error('Error delete organization: ', e);
            return e;
        }
    },

    async updateOrganization(id: string | number, updatedData: Partial<IOrganization>) {
        try {
            const response = await APIService.PUT(`/organizations/${id}/`, updatedData);
            return response;
        } catch (e) {
            console.error('Error updating organization: ', e);
            return e;
        }
    },
    async updateOrganizationDetails(
        orgId: string | number,
        detailsId: string | number,
        updatedData: IOrganization['organization_characteristic'],
    ) {
        try {
            const response = await APIService.PATCH(
                `/organizations/${orgId}/characteristics/${detailsId}/`,
                updatedData,
            );
            return response;
        } catch (e) {
            console.error('Error updating organization characteristic: ', e);
            return e;
        }
    },

    async createOrganization(organizationData: Partial<IOrganization>) {
        try {
            const response = await APIService.POST('/organizations/', organizationData);
            return response;
        } catch (e) {
            console.error('Error creating organization: ', e);
            return e;
        }
    },

    async getProjectCustomerDetails(orgId: string | number, params?: ISimpleGetWithPagination) {
        try {
            return await APIService.GET(`/organizations/${orgId}/characteristics/`, params);
        } catch (e) {
            return e;
        }
    },
    async createProjectCustomerDetailsCopy(
        orgId: string | number,
        characteristicsId: number | string,
        versionName?: string,
    ) {
        try {
            return await APIService.POST(`/organizations/${orgId}/characteristics/${characteristicsId}/copy/`, {
                version_name: versionName,
            });
        } catch (e) {
            return e;
        }
    },
};
