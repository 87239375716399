import { useContext, useEffect, useState } from 'react';
import { Button, Popover, Upload } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/es/interface';

import pic from 'assets/cabinet/emptyPhoto.svg';
import { Users } from 'connectors/query/Users';
import Link from 'common/StyledElements/Link/Link';
import Container from 'common/StyledElements/Container/Container';
import Title from 'common/StyledElements/Title/Title';
import Text from 'common/StyledElements/Text/Text';
import Divider from 'common/StyledElements/Divider/Divider';
import AuthContext, { IUserData } from 'common/AuthProvider/AuthProvider';
import ConfirmEmailModal from 'common/Modals/ConfirmEmailModal/ConfirmEmailModal';
import { createImage, createCanvasBase64Thumbnail } from 'utils/imageUtils';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import { ModalChangeData, ModalDelete, ModalPass } from './Modals';

import _ from './Settings.module.css';

const Settings = () => {
    const { userData, updateUserData } = useContext(AuthContext);
    const [file, setFile] = useState<File | null>();
    const [gettedUserData, setGettedUserData] = useState<IUserData>();

    const [showModalPass, setShowModalPass] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalData, setShowModalData] = useState(false);
    const [showModalConfirmEmailAccDel, setShowModalConfirmEmailAccDel] = useState(false);
    const [showModalConfirmEmailChangePass, setShowModalConfirmEmailChangePass] = useState(false);
    const [showModalConfirmEmailChangeEmail, setShowModalConfirmEmailChangeEmail] = useState(false);

    const [newEmail, setNewEmail] = useState('');
    const [type, setType] = useState<'fio' | 'email' | 'phone'>('fio');

    useEffect(() => {
        if (file) {
            uploadPhoto();
            setFile(null);
        }
    }, [file]);

    useEffect(() => {
        getData();
    }, [JSON.stringify(userData?.id)]);

    const getData = async () => {
        if (userData) {
            const res = await Users.getUser(userData?.id);
            setGettedUserData(res);
        }
    };

    const deleteAcc = async () => {
        const res = await Users.deleteAccount();
        console.log(res, res?.status >= 300);
        if (res?.status >= 500 && res?.data?.detail?.includes('SMTP error')) {
            openErrorNotification(
                'Ошибка!',
                'Почта недоступна. Обратитесь к администратору через форму обратной связи.',
            );
            return;
        }
        if (res?.status >= 300) {
            openErrorNotification('Ошибка!', 'Попробуйте еще раз.');
        }

        if (res?.status === 200 || res?.status === 201) {
            if (showModalConfirmEmailAccDel) {
                openSuccessNotification('Успешно!', 'Сообщение повторно отправлено.');
                return;
            }
            setShowModalConfirmEmailAccDel(true);
            setShowModalDelete(false);
        }
    };

    const changePass = async () => {
        const res = await Users.changePass();
        console.log(res, res?.status >= 300);
        if (res?.status >= 500 && res?.data?.detail?.includes('SMTP error')) {
            openErrorNotification(
                'Ошибка!',
                'Почта недоступна. Обратитесь к администратору через форму обратной связи.',
            );
            return;
        }
        if (res?.status >= 300) {
            openErrorNotification('Ошибка!', 'Попробуйте еще раз.');
        }

        if (res?.status === 200 || res?.status === 201) {
            if (showModalConfirmEmailChangePass) {
                openSuccessNotification('Успешно!', 'Сообщение повторно отправлено.');
                return;
            }
            setShowModalConfirmEmailChangePass(true);
            setShowModalPass(false);
        }
    };

    const changeEmail = (newEmailIncome?: string) => async () => {
        const res = await Users.changeEmail(newEmailIncome || newEmail);
        console.log(res, res?.status >= 300);
        if (res?.status >= 500 && res?.data?.detail?.includes('SMTP error')) {
            openErrorNotification(
                'Ошибка!',
                'Почта недоступна. Обратитесь к администратору через форму обратной связи.',
            );
            return { status: 'error' };
        }

        if (res?.status === 400 && res?.data) {
            const errors: FieldData[] = [];
            Object.keys(res?.data).forEach((key) => {
                errors.push({ name: 'email', errors: res?.data[key] });
            });

            return { status: 'error', errors };
        }

        if (res?.status === 400) {
            Object.keys(res?.data).forEach((key) => {
                openErrorNotification('Ошибка!', res?.data[key][0]);
            });

            return { status: 'error' };
        }
        if (res?.status >= 300) {
            openErrorNotification('Ошибка!', 'Попробуйте еще раз.');
            return { status: 'error' };
        }

        if (res?.status === 200 || res?.status === 201) {
            if (showModalConfirmEmailChangeEmail) {
                openSuccessNotification('Успешно!', 'Сообщение повторно отправлено.');
            }
            setShowModalConfirmEmailChangeEmail(true);
            setShowModalData(false);
        }
        return { status: 'success' };
    };

    const uploadPhoto = async () => {
        if (userData) {
            // @ts-ignore
            const res = await Users.putUserData(userData?.id, {
                ...gettedUserData,
                // @ts-ignore
                'customer_profile.photo': file,
                'customer_profile.organization': gettedUserData?.customer_profile?.organization
                    ? gettedUserData?.customer_profile?.organization
                    : null,
            });
            if (res.id) {
                updateUserData(res);
                setGettedUserData(res);
            } else {
                openErrorNotification('Ошибка загрузки изображения');
            }
        }
    };

    const validateAvatarFile = async (file: File): Promise<boolean> => {
        if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {
            if (file.size <= 5242880) {
                const img = await createImage(file);
                if (img.height === img.width) {
                    if (img.height >= 40 && img.height <= 1200) {
                        return true;
                    }
                    openErrorNotification(
                        'Некорректное разрешение изображения',
                        'Разрешение изображения допускается от 40x40 до 1200x1200',
                    );

                    return false;
                }

                openErrorNotification(
                    'Некорректное соотношение сторон изображения',
                    'Соотношение сторон изображения должно быть 1:1',
                );

                return false;
            }
            openErrorNotification('Некорректный размер файла', 'Размер файла не должен превышать 5 Мб');

            return false;
        }
        openErrorNotification('Некорректный тип файла', 'Изобажение должно быть в формате JPEG, PNG или GIF');
        return false;
    };

    const customRequest = async ({ file }: any) => {
        if (await validateAvatarFile(file)) {
            const img = await createImage(file);
            if (img.height > 80) {
                const base64Image = await createCanvasBase64Thumbnail(img, file.type);
                const base64Fetched = await fetch(base64Image);
                const blob = await base64Fetched.blob();
                const newFile = new File([blob], file.name);
                setFile(newFile);
            } else {
                setFile(file);
            }
        }
    };

    return (
        <Container style={{ paddingTop: 50 }}>
            <div className={_.wrapper}>
                <div className={_.container}>
                    <div className={_.userData}>
                        <img className={_.photo} src={userData?.customer_profile?.photo || pic} alt='avatar' />
                        <div className={_.userNameWrapper}>
                            <Title mb={12} small>
                                <div className={_.mainFIO}>
                                    <span>{userData?.last_name}</span> <span>{userData?.first_name}</span>{' '}
                                    <span>{userData?.patronymic}</span>
                                </div>
                            </Title>

                            <div className={_.uploadWrapper}>
                                <Upload
                                    accept='.jpeg,.jpg,.png,.gif'
                                    maxCount={1}
                                    customRequest={customRequest}
                                    showUploadList={false}
                                >
                                    <Button className={_.btnUpload} type='link'>
                                        Загрузить фото
                                    </Button>
                                </Upload>

                                <Popover
                                    placement='bottomLeft'
                                    content={
                                        <ul>
                                            <li>
                                                <Text>Допустимые расширения файла: .jpg, .jpeg, .gif, .png</Text>
                                            </li>
                                            <li>
                                                <Text>Соотношение сторон изображения должно быть 1:1</Text>
                                            </li>
                                            <li>
                                                <Text>Размер изображения от 40х40 пикселей до 1200х1200 пикселей</Text>
                                            </li>
                                            <li>
                                                <Text>Размер файла не более 5 МБ</Text>
                                            </li>
                                        </ul>
                                    }
                                >
                                    <div className={_.info}>
                                        <InfoOutlined />
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>

                    <Divider />

                    <div className={_.row}>
                        <div className={_.rowLeft}>
                            <Text className={_.text} lightGray>
                                ФИО
                            </Text>
                            <div className={_.secondaryFIO}>
                                <span>{userData?.last_name}</span> <span>{userData?.first_name}</span>{' '}
                                <span>{userData?.patronymic}</span>
                            </div>
                        </div>
                        <Link
                            onClick={() => {
                                setShowModalData(true);
                                setType('fio');
                            }}
                        >
                            Изменить
                        </Link>
                    </div>

                    <Divider />

                    <div className={_.row}>
                        <div className={_.rowLeft}>
                            <Text className={_.text} lightGray>
                                E-mail
                            </Text>
                            <div>{userData?.email}</div>
                        </div>
                        <Link
                            onClick={() => {
                                setShowModalData(true);
                                setType('email');
                            }}
                        >
                            Изменить
                        </Link>
                    </div>

                    <Divider />

                    <div className={_.row}>
                        <div className={_.rowLeft}>
                            <Text className={_.text} lightGray>
                                Телефон
                            </Text>
                            <div>{userData?.phone}</div>
                        </div>
                        <Link
                            onClick={() => {
                                setShowModalData(true);
                                setType('phone');
                            }}
                        >
                            Изменить
                        </Link>
                    </div>

                    <Divider />

                    <Link mb={20} onClick={() => setShowModalPass(true)}>
                        Изменить пароль
                    </Link>

                    <Divider />

                    <Link onClick={() => setShowModalDelete(true)}>Удалить аккаунт</Link>
                </div>
            </div>

            <ModalDelete showModal={showModalDelete} setShowModal={setShowModalDelete} deleteAction={deleteAcc} />
            <ModalPass showModal={showModalPass} setShowModal={setShowModalPass} changeAction={changePass} />
            <ModalChangeData
                showModal={showModalData}
                setShowModal={setShowModalData}
                type={type}
                changeEmail={changeEmail}
                setNewEmail={setNewEmail}
                setGettedUserData={setGettedUserData}
            />
            <ConfirmEmailModal
                title='Аккаунт в процессе удаления'
                text='Перейдите по ссылке в письме для полного удаления'
                isShown={showModalConfirmEmailAccDel}
                toggleShown={setShowModalConfirmEmailAccDel}
                resend={deleteAcc}
                email={userData?.email || ''}
            />
            <ConfirmEmailModal
                title='Пароль будет изменён'
                text='Перейдите по ссылке в письме для завершения смены пароля'
                isShown={showModalConfirmEmailChangePass}
                toggleShown={setShowModalConfirmEmailChangePass}
                resend={changePass}
                email={userData?.email || ''}
            />
            <ConfirmEmailModal
                title='E-mail будет изменён'
                text='Перейдите по ссылке в письме для завершения смены E-mail'
                isShown={showModalConfirmEmailChangeEmail}
                toggleShown={setShowModalConfirmEmailChangeEmail}
                resend={changeEmail(newEmail)}
                email={newEmail}
            />
        </Container>
    );
};

export default Settings;
