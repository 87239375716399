import { FC } from 'react';

import Text from 'common/StyledElements/Text/Text';

import _ from './SimpleStatus.module.css';

export enum ESimpleStatuses {
    Ready = 'ready',
    Wip = 'wip',
}

interface ISimpleStatusProps {
    type: ESimpleStatuses;
}

const title = {
    ready: 'Заполнен',
    wip: 'Черновик',
};

const color = {
    ready: '#E8F4DC',
    wip: '#FEE4E0',
};

const SimpleStatus: FC<ISimpleStatusProps> = ({ type }) => (
    <div className={_.statusWrapper} style={{ backgroundColor: color[type] }}>
        <Text small>{title[type]}</Text>
    </div>
);

export default SimpleStatus;
