/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/default-param-last */
import { notification } from 'antd';
import axios from 'axios';
import { openErrorNotification } from 'common/Notifications/Notifications';
// import 'whatwg-fetch';
import localforage from 'localforage';
// import qs from 'qs';

const instance = axios.create({
    baseURL: '/api/v1/',
    // baseURL: 'https://mr-dev.sreda.capital/api/v1/',
});

export function deleteAllCookies() {
    // const cookies = document.cookie.split(";");
    // for (let i = 0; i < cookies.length; i++) {
    //     const cookie = cookies[i];
    //     const eqPos = cookie.indexOf("=");
    //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // }
}

const axiosApi = async () => {
    const newInstance = instance;

    const cookies = document.cookie.split(';');
    let csrf = cookies.find((c) => c.includes('csrftoken='));

    if (csrf) {
        csrf = csrf.replace('csrftoken=', '');
        newInstance.defaults.headers.common['X-CSRFToken'] = csrf;
    } else {
        delete newInstance.defaults.headers.common['X-CSRFToken'];
    }

    return newInstance;
};

// const killToken = async () => {
//     await localforage.setItem('token', null);
// };

export const APIService = {
    async checkStatus400(status, data) {
        if (status === 400) {
            console.error('400!');
            if (data?.detail === 'Blocked') {
                notification.error({
                    message: 'Блокировка',
                    description: 'Данный пользователь заблокирован',
                });
            }
            const userData = await localforage.getItem('userData');
            const regex = /^Недопустимый первичный ключ.*- объект не существует.$/;
            if (userData && regex.test(data?.sender_registered)) {
                await localforage.removeItem('userData');
                window.location.href = '/auth';
            }
        }
    },

    async checkStatus401(status) {
        console.log('status?', status);
        if (status === 401) {
            console.error('401!');
            const userData = await localforage.getItem('userData');

            if (userData) {
                await localforage.removeItem('userData');
                window.location.href = '/auth';
            }
        }
    },

    async checkStatus403(status, data) {
        if (status === 403) {
            console.error('403!');
            console.error('data', data);

            const userData = await localforage.getItem('userData');

            if (userData && data?.detail === 'Учетные данные не были предоставлены.') {
                await localforage.removeItem('userData');
                window.location.href = '/auth';
            }
            // killToken();

            // deleteAllCookies();
            // await localforage.removeItem('userData');

            // openErrorNotification('Ошибка!');

            // window.location.href = '/';
        }
    },
    async checkStatus404(status) {
        if (status === 404) {
            console.error('404!');
        }
    },

    checkStatus500(status) {
        if (status >= 500) {
            console.error('500!');
        }
    },

    async GET(route, params) {
        try {
            const API = await axiosApi();
            const response = await API.get(route, {
                params,
                //  TODO: метод делает из массива строку
                // paramsSerializer: function (params) {
                //     const test = qs.stringify(params, { arrayFormat: 'comma' });
                //     return test;
                // },
            });
            const { data } = response;

            return data;
        } catch ({ response: { status, data } }) {
            this.checkStatus401(status);
            this.checkStatus403(status, data);
            this.checkStatus404(status, data);
            this.checkStatus500(status);
            throw { data, status };
        }
    },

    async POST(route, params, config = {}, checkErrorParams = {}) {
        try {
            const API = await axiosApi();
            const response = await API.post(route, params, config);
            const { data, status } = response;
            return { ...data, status };
        } catch ({ response: { status, data } }) {
            this.checkStatus400(status, data);
            if (checkErrorParams && !checkErrorParams?.dontCheck401) {
                this.checkStatus401(status);
            }
            this.checkStatus403(status, data);
            this.checkStatus404(status, data);
            this.checkStatus500(status);
            throw { data, status };
        }
    },

    async DELETE(route, params) {
        try {
            const API = await axiosApi();
            const { data, status } = await API.delete(route, params);
            return { ...data, status };
        } catch ({ response: { status, data } }) {
            this.checkStatus401(status);
            this.checkStatus403(status, data);
            this.checkStatus404(status, data);
            this.checkStatus500(status);
            throw { data, status };
        }
    },
    async PATCH(route, params, config) {
        try {
            const API = await axiosApi();
            const { data, status } = await API.patch(route, params, config);
            return { ...data, status };
        } catch ({ response: { status, data } }) {
            this.checkStatus401(status);
            this.checkStatus403(status, data);
            this.checkStatus404(status, data);
            // this.checkStatus500(status);
            // return status;
            throw { data, status };
        }
    },
    async PUT(route, params, config) {
        try {
            const API = await axiosApi();
            const { data } = await API.put(route, params, config);
            return data;
        } catch ({ response: { status, data } }) {
            this.checkStatus401(status);
            this.checkStatus403(status, data);
            this.checkStatus404(status, data);
            // this.checkStatus500(status);
            // return status;
            throw { data, status };
        }
    },
};
