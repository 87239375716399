import React, { FC } from 'react';
import { Tabs as AntdTabs } from 'antd';

import './styles.css';

interface ITabsProps {
    defaultRoute: string;
    onChange: (key: string) => void;
}

const Tabs: FC<ITabsProps> = ({ onChange, defaultRoute }) => (
    <AntdTabs
        className='customTabs'
        activeKey={defaultRoute}
        onChange={onChange}
        items={[
            { label: 'Проекты', key: 'projects' },
            { label: 'Настройки аккаунта', key: 'settings' },
            { label: 'Сообщения', key: 'messages' },
            { label: 'Люди', key: 'people' },
            { label: 'Контрагенты', key: 'contragents' },
            { label: 'Оплата', key: 'payment' },
        ]}
    />
);

export default Tabs;
