import React from 'react';
import { Form } from 'antd';
import type { FormItemProps } from 'antd';

import _ from './MyFormItem.module.css';

function toArr(str: string | number | (string | number)[]): (string | number)[] {
    return Array.isArray(str) ? str : [str];
}

export const MyFormItem = ({ name, ...props }: FormItemProps) => {
    const MyFormItemContext = React.createContext<(string | number)[]>([]);
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

    return <Form.Item name={concatName} className={_.main} {...props} />;
};
