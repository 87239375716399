import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Text from 'common/StyledElements/Text/Text';
import TypeHotline, { ETypeHotline } from 'common/TypeHotline/TypeHotline';
import AuthContext from 'common/AuthProvider/AuthProvider';
import playIcon from 'assets/webinars/playIcon.svg';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import PayForm from 'common/PayForm/PayForm';
import Title from 'common/StyledElements/Title/Title';
import { openInfoNotification } from 'common/Notifications/Notifications';

import _ from './WebinarCard.module.css';

interface IWebItemProps {
    text: string;
    pic: string;
    archive?: boolean;
    hasMedia?: boolean;
    date?: string;
    type: ETypeHotline[] | ETypeHotline;
}

const WebinarCard: FC<IWebItemProps> = ({ text, pic, type, archive, date, hasMedia }) => {
    const { hasLoaded, logged, hotlineSubscr, isSubscrLoaded } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [subscribeButtonVisible, setSubscribeButtonVisible] = useState(false);
    const navigate = useNavigate();
    let typeUni;
    if (Array.isArray(type)) {
        typeUni = [...type];
    } else {
        typeUni = [type];
        archive && typeUni.push(ETypeHotline.Archive);
    }

    return (
        <div
            className={_.wrapper}
            style={{ ...(logged && hotlineSubscr ? { cursor: 'pointer' } : {}) }}
            onMouseEnter={() => setSubscribeButtonVisible(true)}
            onMouseLeave={() => setSubscribeButtonVisible(false)}
        >
            <div className={_.container}>
                <div className={_.imgWrapper}>{pic && <img className={_.img} src={pic} alt='cover' />}</div>
                <div className={_.typeWeb}>
                    <TypeHotline type={typeUni} />
                </div>
                <div className={_.textContainer}>
                    <Text small gray className={_.date}>
                        {dayjs(date).format('DD.MM.YYYY')}
                    </Text>
                    <Text className={_.text}>{logoReplacer(text)}</Text>
                </div>
                {hasMedia && logged && hotlineSubscr && <img className={_.imgPlayer} src={playIcon} alt='playIcon' />}
                <div
                    className={clsx(
                        _.subscription,
                        ((logged && hasLoaded && !hotlineSubscr && isSubscrLoaded) || !logged) &&
                            subscribeButtonVisible &&
                            _.visible,
                    )}
                    onClick={(e) => {
                        e.stopPropagation();

                        // PROD
                        !logged && navigate('/auth');
                        // !logged && openInfoNotification('Личный кабинет временно недоступен');
                        if (logged && hasLoaded && !hotlineSubscr && isSubscrLoaded) {
                            setShowModal(true);
                        }
                    }}
                >
                    Подпишись
                </div>
            </div>
            <Modal
                open={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                width='700px'
                footer={null}
                centered
                destroyOnClose
            >
                <div className={_.modalInner}>
                    <Title mt={60} family='Manrope'>
                        Проверьте данные
                    </Title>
                    <PayForm
                        type='hotline'
                        closeModal={() => {
                            setShowModal(false);
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default WebinarCard;
