import { FC } from 'react';

import { Dayjs } from 'dayjs';
import { Form, FormInstance } from 'antd';

import StepFourAddress from '../StepFourAddress/StepFourAddress';
import StepFourBank from '../StepFourBank/StepFourBank';
import StepFourPassport from '../StepFourPassport/StepFourPassport';
import BasicDetails from './components/BasicDetails/BasicDetails';
import ForDocuments from './components/ForDocuments/ForDocuments';
import RegistrationData from './components/RegistrationData/RegistrationData';

interface IAddressProps {
    value?: Dayjs;
    data: any;
    type?: string;
    form: FormInstance;

    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    // eslint-disable-next-line react/no-unused-prop-types
    onChange?: () => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    orgPropertiesIdForEdit?: number;
    isSubscrActiveOrCreate: boolean;
}

const StepFourIndividual: FC<IAddressProps> = ({
    data,
    type,
    value,
    onInputChange,
    setData,
    form,
    onDateChange,
    orgPropertiesIdForEdit,
    isSubscrActiveOrCreate,
}) => (
    <div className='main'>
        <BasicDetails
            form={form}
            onInputChange={onInputChange}
            onDateChange={onDateChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <RegistrationData
            form={form}
            onInputChange={onInputChange}
            onDateChange={onDateChange}
            orgPropertiesIdForEdit={orgPropertiesIdForEdit}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourPassport
            form={form}
            onInputChange={onInputChange}
            onDateChange={onDateChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourAddress
            form={form}
            type={type}
            onInputChange={onInputChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <ForDocuments
            form={form}
            value={value}
            onInputChange={onInputChange}
            setData={setData}
            onDateChange={onDateChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourBank form={form} onInputChange={onInputChange} isSubscrActiveOrCreate={isSubscrActiveOrCreate} />
    </div>
);

export default StepFourIndividual;
