import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import pic from 'assets/hotLine/people.jpg';
import Container from 'common/StyledElements/Container/Container';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import { openInfoNotification } from 'common/Notifications/Notifications';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import Check from './components/Check';

import _ from './HotLine.module.css';

interface IHotLineChecks {
    id: number;
    title: string;
    description: string;
}

interface IHotLineProps {
    hotLineData: any;
}

const HotLine: FC<IHotLineProps> = ({ hotLineData }) => {
    const navigate = useNavigate();

    return (
        <Container pb={90} dark>
            <div>
                <Title mb={30} white>
                    {logoReplacer(hotLineData.title)}
                </Title>
                <Text mb={35} white>
                    {logoReplacer(hotLineData.description)}
                </Text>
                <img className={_.img} src={hotLineData.image || pic} alt='HotLine' />
            </div>

            <div className={_.rightSideWrapper}>
                <div className={_.checksContainer}>
                    {Array.isArray(hotLineData.statements) &&
                        hotLineData.statements.map((statement: IHotLineChecks) => (
                            <Check key={statement.id} {...statement} />
                        ))}
                </div>

                <Button
                    onClick={() => {
                        navigate('/practice');
                    }}
                    width='199px'
                >
                    Перейти в раздел
                </Button>
            </div>
        </Container>
    );
};

export default HotLine;
