import { IUserData } from 'common/AuthProvider/AuthProvider';
import { APIService } from 'connectors/api/rest';

export interface INewsData {
    id: number;
    title: string;
    poster: null;
    content: string;
    created_at: string;
    created_by: IUserData;
    author: string;
    tags: number[];
}

export interface ISubscribeParams {
    acceptance_user_agreement: boolean;
    captcha_token?: string;
    'guest.first_name'?: string;
    'guest.last_name'?: string;
    'guest.email'?: string;
}

export const News = {
    async getAll(params?: any) {
        try {
            return await APIService.GET('/news/', { ordering: 'created_at', ...params });
        } catch (e) {
            return e;
        }
    },

    async subscribe(params: ISubscribeParams) {
        try {
            return await APIService.POST('/news/subscribe/', params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            return e;
        }
    },
};
