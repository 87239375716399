import { Space, Spin } from 'antd';
import { FC } from 'react';

import _ from './Loader.module.css';

interface ILoader {
    top?: string;
}

const Loader: FC<ILoader> = ({ top }) => (
    <Space className={_.loader_main} size='middle'>
        <Spin className={_.loader_item} style={{ top: top || '50%' }} size='large' />
    </Space>
);

export default Loader;
