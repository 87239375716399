import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import Text from 'common/StyledElements/Text/Text';
import Divider from 'common/StyledElements/Divider/Divider';
import StatusWrapper, { IStatusAliases } from 'common/StatusWrapper/StatusWrapper';
import { IProject } from 'connectors/query/PersonalAccount';
import { ESubscriptionStatuses, ISubscription } from 'connectors/query/Subscriptions';
import Link from 'common/StyledElements/Link/Link';

import _ from './Row.module.css';

const aliases: IStatusAliases = {
    greenCheck: [{ codeName: ESubscriptionStatuses.Active, shownName: 'Активный' }],
    redCross: [
        { codeName: ESubscriptionStatuses.Canceled, shownName: 'Отменен' },
        { codeName: ESubscriptionStatuses.Expired, shownName: 'Истекла подписка' },
    ],
    blueHourglass: [{ codeName: 'Пробный период' }],
};

interface IRowProps {
    project: IProject;
}

const Row: FC<IRowProps> = ({ project }) => {
    const { name, organizer_organization: organization } = project;
    const navigate = useNavigate();

    const filteredSubscriptions: ISubscription[] = project.subscriptions.filter(
        (subscription) => subscription.status !== ESubscriptionStatuses.Pending,
    );

    const lastSub =
        filteredSubscriptions.length > 0
            ? filteredSubscriptions.reduce((prevSubscr, currentSubscr) =>
                  dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0 ? currentSubscr : prevSubscr,
              )
            : null;

    return (
        <div className={_.wrapper}>
            <Divider />

            <div className={_.row}>
                <div>
                    <Text mb={10} gray>
                        <Link onClick={() => navigate(`/cabinet/projects/${project.id}`)}>{name}</Link>
                    </Text>

                    <div className={_.underTextWrapper}>
                        <Text small gray>
                            Создан {dayjs(project.created_at).format('DD.MM.YYYY')}
                        </Text>
                        <Divider margin={12} row />
                        <Text small gray>
                            {organization?.name || ''}
                        </Text>
                        {organization?.name && <Divider margin={12} row />}

                        {lastSub && (
                            <Text small gray>
                                {`${
                                    lastSub.status === ESubscriptionStatuses.Active ? 'Активен до' : 'Подписка истекла'
                                }`}{' '}
                                {dayjs(lastSub.ends_at).format('DD.MM.YYYY')}
                            </Text>
                        )}
                    </div>
                </div>

                <StatusWrapper
                    text={
                        lastSub?.plan.is_trial && lastSub?.status === ESubscriptionStatuses.Active
                            ? 'Пробный период'
                            : lastSub?.status || 'Статус не определен'
                    }
                    statusAliases={aliases}
                    mb={20}
                />
            </div>
        </div>
    );
};

export default Row;
