import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal, Select } from 'antd';
import Table from 'antd/es/table';

import Button from 'common/Button/Button';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import PersonalAccount, { IContract } from 'connectors/query/PersonalAccount';
import Paginator from 'common/Paginator/Paginator';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import { ContractDesigner, IContractOrderingType } from 'connectors/query/ContractDesigner';
import CopyProjectModal from './CopyProjectModal/CopyProjectModal';
import { columnsDocuments } from '../../columns';

import _ from './ProjectContracts.module.css';
import '../../styles.scss';

interface IProjectContracts {
    projectId: string | undefined;
    isActiveProject: boolean;
}
const limit = 3;
const ProjectContracts: FC<IProjectContracts> = ({ projectId, isActiveProject }) => {
    const navigate = useNavigate();

    const [contracts, setContracts] = useState<IContract[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countAll, setCountAll] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [contractToDelete, setContractToDelete] = useState<IContract | null>(null);

    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

    const [selectedContractTypeId, setSelectedContractTypeId] = useState<number | undefined>(undefined);
    const [contractTypeOptions, setContractTypeOptions] = useState<{ value: number; label: string }[]>([]);
    const [sortedInfo, setSortedInfo] = useState<{ columnKey?: IContractOrderingType; order?: 'ascend' | 'descend' }>({
        columnKey: 'created_at',
        order: 'descend',
    });
    const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
    const [selectedContract, setSelectedContract] = useState<null | IContract>(null);

    useEffect(() => {
        getContractTypes();

        if (projectId && !Number.isNaN(projectId)) getContracts(projectId);
    }, [projectId, currentPage, sortedInfo]);

    const getContractTypes = async () => {
        setIsLoading(true);

        const data = await ContractDesigner.getContractTemplateTypes();

        if (Array.isArray(data?.results) && data?.results.length > 0) {
            setSelectedContractTypeId(data.results[0].id);
            setContractTypeOptions(
                data.results.map((d: { id: number; name: string }) => ({ value: d.id, label: d.name })),
            );
        }
        setIsLoading(false);
    };

    const getContracts = async (projectId: string) => {
        setIsLoading(true);

        const data = await PersonalAccount.getProjectContracts(projectId, {
            limit,
            offset: (currentPage - 1) * limit,
            ordering: `${sortedInfo.order === 'descend' ? '-' : ''}${sortedInfo.columnKey}` as IContractOrderingType,
        });
        if (Array.isArray(data?.results)) {
            if (data?.count) {
                setCountAll(data?.count);
                setContracts(data.results);
            } else setCountAll(0);
        }
        setIsLoading(false);
    };

    const openDeleteContractModal = (contract: IContract) => {
        setContractToDelete(contract);
        setShowDeleteModal(true);
    };

    const closeDeleteContractModal = () => {
        setContractToDelete(null);
        setShowDeleteModal(false);
    };

    const closeCreateContractModal = () => {
        setShowCreateModal(false);
    };

    const handleCreateContractButtonClick = () => {
        if (!isActiveProject) return openErrorNotification('Для создания договора необходимо оплатить подписку');

        if (contractTypeOptions.length > 0) return setShowCreateModal(true);
        openErrorNotification('В данный момент создание договоров недоступно');
    };

    const confirmCreateContract = async () => {
        navigate(`/cabinet/projects/${projectId}/createContract`, {
            state: {
                contractTypeId: selectedContractTypeId,
                contractTypeName: contractTypeOptions.find((t) => t.value === selectedContractTypeId)?.label,
            },
        });
    };

    const confirmDeleteContract = async () => {
        if (contractToDelete && projectId) {
            setIsLoading(true);
            const data = await PersonalAccount.deleteContract(projectId, contractToDelete.id);
            if (data?.status >= 200 && data.status <= 204) {
                openSuccessNotification('Успех', 'Договор успешно удален');
                getContracts(projectId);
            } else {
                openErrorNotification('Ошибка', 'Не удалось удалить договор');
            }
            setIsLoading(false);
            closeDeleteContractModal();
        }
    };

    // const copyContract = async (projectId: string, contractId: string | number) => {
    //     setIsLoading(true);
    //     const data = await PersonalAccount.createContractCopy(projectId, contractId);
    //     if (data?.id) {
    //         getContracts(projectId);
    //     }
    //     setIsLoading(false);
    // };
    const openCopyModal = () => {
        setIsOpenCopyModal(true);
    };
    const closeCopyModal = () => {
        setIsOpenCopyModal(false);
    };

    const handleTableChange = (_: any, __: any, sorter: any) => {
        setSortedInfo({
            columnKey: sorter.field,
            order: sorter.order,
        });
    };

    return (
        <div className={_.block}>
            <Title mt={50} mb={40} small>
                Договора проекта
            </Title>

            <div style={{ display: 'flex' }}>
                <Button
                    width='290px'
                    marginRight='20px'
                    marginBottom='50px'
                    onClick={handleCreateContractButtonClick}
                    plus
                >
                    Создать договор
                </Button>
            </div>

            <Table
                className='contractsTable'
                columns={columnsDocuments(
                    projectId,
                    openDeleteContractModal,
                    openCopyModal,
                    navigate,
                    setSelectedContract,
                    isActiveProject,
                    sortedInfo,
                )}
                dataSource={contracts}
                pagination={false}
                loading={isLoading}
                onChange={handleTableChange}
            />
            <Paginator
                maxPages={Math.ceil(countAll / limit)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <CopyProjectModal
                isModalShowing={isOpenCopyModal}
                closeModal={closeCopyModal}
                selectedContract={selectedContract}
            />

            <Modal
                className={_.modalCustom}
                open={showDeleteModal}
                onOk={closeDeleteContractModal}
                onCancel={closeDeleteContractModal}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainerRemoveContract}>
                        <div className={_.titleDelete}>
                            <Title mb={30} width={468}>
                                Удаление договора
                            </Title>
                        </div>

                        <Text center mb={30} width={530}>
                            Вы уверены, что хотите удалить договор <b>{contractToDelete?.name}</b>?
                        </Text>
                        <div className={_.btnsRemoveContractModal}>
                            <Button onClick={confirmDeleteContract} htmlType='submit' width='250px'>
                                Удалить
                            </Button>

                            <Button onClick={closeDeleteContractModal} htmlType='submit' width='250px'>
                                Отмена
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                className={_.modalCustom}
                open={showCreateModal}
                onCancel={closeCreateContractModal}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainerRemoveContract}>
                        <div className={_.titleDelete}>
                            <Title mb={30} width={468}>
                                Создание договора
                            </Title>
                        </div>

                        <Text center mb={30} width={530}>
                            Выберите тип договора
                        </Text>
                        <Select
                            className={_.select}
                            style={{ width: 280 }}
                            options={contractTypeOptions}
                            value={selectedContractTypeId}
                            onChange={(val) => {
                                setSelectedContractTypeId(val);
                            }}
                        />
                        <div className={_.btnsRemoveContractModal}>
                            <Button onClick={confirmCreateContract} htmlType='submit' width='250px'>
                                Перейти к созданию
                            </Button>

                            <Button onClick={closeCreateContractModal} htmlType='submit' width='250px'>
                                Отмена
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ProjectContracts;
