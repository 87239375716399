import { Checkbox, Form, FormInstance, Input } from 'antd';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import { Rule } from 'antd/es/form';

import _ from './ColorBlock.module.css';

interface IColorBlock {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    form: FormInstance;
    checkedColors: string[];
    setCheckedColors: React.Dispatch<React.SetStateAction<string[]>>;
    colorInput: string;
    setColorInput: React.Dispatch<React.SetStateAction<string>>;
    isSubscrActiveOrCreate: boolean;
}

const ColorBlock: React.FC<IColorBlock> = ({
    onInputChange,
    data,
    setData,
    form,
    checkedColors,
    setCheckedColors,
    colorInput,
    setColorInput,
    isSubscrActiveOrCreate,
}) => {
    // const [lastCustomValue, setLastCustomValue] = useState<string | null>(null);
    const colorMinOneValidationFunctionChecked: Rule = () => ({
        validator(_, val) {
            if (!val.length) {
                return Promise.reject(new Error('Необходимо указать хотя бы 1 цвет.'));
            }

            return Promise.resolve();
        },
    });

    return (
        <div className='blockCard w100'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='titleCard mb0 required-field'>Цвет</div>
                <div className={_.main}>
                    <MyFormItem
                        name='colors'
                        style={{ marginBottom: 0 }}
                        rules={[colorMinOneValidationFunctionChecked]}
                    >
                        <Checkbox.Group
                            className='checkBlock'
                            value={data?.colors}
                            onChange={(checkedValues: any[]) => {
                                if (!checkedValues.includes('Другой')) {
                                    setColorInput('');
                                }
                                setCheckedColors(checkedValues);
                                setData({ ...data, colors: checkedValues });
                            }}
                        >
                            <Checkbox value='Цветной' disabled={!isSubscrActiveOrCreate}>
                                Цветной
                            </Checkbox>
                            <Checkbox value='Черно-белый' disabled={!isSubscrActiveOrCreate}>
                                Черно-белый
                            </Checkbox>
                            <Checkbox value='Сепия' disabled={!isSubscrActiveOrCreate}>
                                Сепия
                            </Checkbox>
                            <Checkbox value='Другой' disabled={!isSubscrActiveOrCreate}>
                                Другой
                            </Checkbox>
                        </Checkbox.Group>
                    </MyFormItem>
                    {checkedColors.includes('Другой') && (
                        <MyFormItem
                            name='custom_color_dev'
                            style={{ marginBottom: 0 }}
                            rules={[
                                { required: true, message: 'Необходимо заполнить.' },
                                { whitespace: true, message: 'Необходимо заполнить.' },
                            ]}
                        >
                            <Input
                                className='inputInner inputInnerShort'
                                placeholder='Введите свой вариант цвета'
                                value={colorInput}
                                onChange={(event) => {
                                    setColorInput(event.target.value);
                                }}
                                disabled={!isSubscrActiveOrCreate}
                            />
                        </MyFormItem>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default ColorBlock;
