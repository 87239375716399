import { FC } from 'react';
import clsx from 'clsx';

import pic from 'assets/top/top_img1.png';
import picSvg from 'assets/top/top_svg.svg';
import dark from 'assets/top/dark.png';
import mask from 'assets/top/mask.svg';

import _ from './Top.module.css';

interface ITopProps {
    title?: string;
}

const Top: FC<ITopProps> = ({ title }) => (
    <div className={_.main_wrapper}>
        <img className={_.background} src={dark} alt='dark' />
        <img className={_.background} src={pic} alt='pic' />
        <img className={_.svg} src={picSvg} alt='picSvg' />

        <div className={_.wrapper}>
            <div className={_.container}>
                <div className={_.titleWrapper}>
                    <img className={_.mask} src={mask} alt='mask' />
                    <div className={_.title}>{title}</div>
                    <img className={clsx(_.mask, _.bottomMask)} src={mask} alt='bottomMask' />
                </div>
            </div>
        </div>
    </div>
);

export default Top;
