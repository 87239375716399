import { FC } from 'react';

import clsx from 'clsx';

import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './WebinarContentBlock.module.css';


interface IWebinarContentBlockProps {
    label: string;
    content: string;
    zeroMargin?: boolean;
}

const WebinarContentBlock: FC<IWebinarContentBlockProps> = ({ label, content, zeroMargin }) => (
        <>
            <div className={clsx(_.webinarContentLabel, zeroMargin && _.m0)}>{label}</div>
            <div className={clsx(_.webinarDescr, zeroMargin && _.m0)}>{logoReplacer(content)}</div>
        </>
    );

export default WebinarContentBlock;