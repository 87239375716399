import { useEffect } from 'react';
import { Form, Radio, FormInstance } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';

import _ from './InfoTypeFilm.module.css';

interface IInfoTypeFilm {
    data: any;
    setData: (i: any) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const InfoTypeFilm: React.FC<IInfoTypeFilm> = ({ setData, data, form, isSubscrActiveOrCreate }) => {
    const isDisabled = data?.product_name === 'Игровой фильм' || data?.product_name === 'Неигровой фильм';

    return (
        <div className='blockCard'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='filmInfo mb0'>
                    <div className='selectEl'>
                        <div className='titleCard'>Тип фильма</div>

                        <MyFormItem name='film_type' style={{ marginBottom: 0 }}>
                            <Radio.Group
                                className={_.radioBlock}
                                onChange={(e) => {
                                    setData({ ...data, film_type: e.target.value });
                                }}
                            >
                                <Radio
                                    className={_.radioItem}
                                    value='game'
                                    disabled={isDisabled || !isSubscrActiveOrCreate}
                                >
                                    Игровой
                                </Radio>
                                <Radio
                                    className={_.radioItem}
                                    value='not_game'
                                    disabled={isDisabled || !isSubscrActiveOrCreate}
                                >
                                    Неигровой
                                </Radio>
                            </Radio.Group>
                        </MyFormItem>
                    </div>

                    <div className='devider' />

                    <div className='selectEl'>
                        <div className='titleCard'>Вид фильма</div>

                        <MyFormItem name='film_view_type' style={{ marginBottom: 0 }}>
                            <Radio.Group
                                className={_.radioBlock}
                                onChange={(e) => {
                                    setData({ ...data, film_view_type: e.target.value });
                                }}
                            >
                                <Radio className={_.radioItem} value='fictional' disabled={!isSubscrActiveOrCreate}>
                                    Художественный
                                </Radio>
                                <Radio className={_.radioItem} value='documentary' disabled={!isSubscrActiveOrCreate}>
                                    Документальный
                                </Radio>
                                <Radio
                                    className={_.radioItem}
                                    value='popular_science'
                                    disabled={!isSubscrActiveOrCreate}
                                >
                                    Научно-популярный
                                </Radio>
                                <Radio className={_.radioItem} value='television' disabled={!isSubscrActiveOrCreate}>
                                    Телевизионнный
                                </Radio>
                            </Radio.Group>
                        </MyFormItem>
                    </div>

                    <div className='devider' />

                    <div className='selectEl'>
                        <div className='titleCard'>Формат фильма</div>

                        <MyFormItem name='film_format' style={{ marginBottom: 0 }}>
                            <Radio.Group
                                className={_.radioBlock}
                                onChange={(e) => {
                                    setData({ ...data, film_format: e.target.value, timing: '' });
                                }}
                            >
                                <Radio
                                    className={_.radioItem}
                                    value='feature_length'
                                    disabled={!isSubscrActiveOrCreate}
                                >
                                    Полнометражный
                                </Radio>
                                <Radio className={_.radioItem} value='multi_episode' disabled={!isSubscrActiveOrCreate}>
                                    Многосерийный
                                </Radio>
                                <Radio className={_.radioItem} value='short_film' disabled={!isSubscrActiveOrCreate}>
                                    Короткометражный
                                </Radio>
                            </Radio.Group>
                        </MyFormItem>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default InfoTypeFilm;
