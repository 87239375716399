import { FC } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import './styles.scss';

interface IDatePickerProps {
    className?: string;
    value?: string;
    onChange?: (date: dayjs.Dayjs | null) => void;
    disabled?: boolean;
    disableFuture?: boolean;
}

const DatePicker: FC<IDatePickerProps> = ({ className, value, onChange, disabled, disableFuture }) => (
    <AntdDatePicker
        className={className}
        popupClassName='customDatePicker'
        format='DD.MM.YYYY'
        value={value ? dayjs(value, 'DD.MM.YYYY') : null}
        onChange={onChange}
        disabled={disabled}
        disabledDate={disableFuture ? (cellDate) => cellDate.isAfter(dayjs(), 'day') : undefined}
    />
);

export default DatePicker;
