import { APIService } from 'connectors/api/rest';
import { ETypeHotline } from 'common/TypeHotline/TypeHotline';
import { EWebinarStatus } from './Webinar';

export interface IHotlineGetParams {
    limit?: number;
    offset?: number;
    search?: string;
    category?: number;
    starts_at_gte?: string;
    starts_at_lte?: string;
    ordering?: '-starts_at' | 'starts_at' | 'title' | '-title';
}

export interface IParams {
    event_type?: 'webinar' | 'lecture' | 'event' | ['webinar' | 'lecture', 'webinar' | 'lecture'];
    params?: IHotlineGetParams;
}

export interface IHotlineItem {
    id: number;
    event_type: ETypeHotline;
    created_at: string;
    updated_at: string;
    published: boolean;
    published_at: string;
    scheduled_publication_at: string;
    starts_at: string;
    end_at: string;
    title: string;
    description: string;
    mediafile: null | string;
    preview: null | string;
    max_users: number;
    category: number;
    created_by: number;
    updated_by: number;
    status: EWebinarStatus | null;
    tags: number[];
}

export interface IHotlineResponse {
    count: number;
    results: IHotlineItem[];
}

export const defaultHotlineObject: IHotlineResponse = {
    count: 0,
    results: [],
};

export const Hotline = {
    async get(par: IParams) {
        const { event_type, params } = { ...par };
        const p = new URLSearchParams();
        if (event_type) {
            if (!Array.isArray(event_type)) {
                p.append('event_type', event_type);
            } else {
                event_type.forEach((t) => p.append('event_type', t));
            }
        }
        if (params) {
            Object.entries(params).forEach((entry) => {
                const [key, value] = entry;
                p.append(key, value);
            });
        }

        try {
            return await APIService.GET('/hotline/', p);
        } catch (e) {
            return e;
        }
    },

    async getEventCategory() {
        try {
            return await APIService.GET('/hotline/event-category/');
        } catch (e) {
            return e;
        }
    },
};
