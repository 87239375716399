import { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import clsx from 'clsx';

import Button from 'common/Button/Button';
import Text from 'common/StyledElements/Text/Text';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { openErrorNotification, openInfoNotification } from 'common/Notifications/Notifications';
import { IModule } from 'connectors/query/Subscriptions';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import Option from '../Option/Option';

import _ from './Card.module.css';
import './styles.scss';

interface ICardProps {
    module: IModule;
    index: number;
    bottomLess?: boolean;
    selectStates: any;
    upperHalfHeights: number[];
    modules: IModule[];
    maxUpperHalfHeight: number | null;
    setHeights: (height: number[] | ((h: number[]) => number[])) => void;
    setUpperHalfHeights: (height: number[] | ((h: number[]) => number[])) => void;
    setSelectStates: (par: (h: any) => any) => void;
}

const paddingOffset = 30;

const Card: FC<ICardProps> = ({
    module,
    index,
    bottomLess,
    upperHalfHeights,
    modules,
    maxUpperHalfHeight,
    selectStates,
    setHeights,
    setSelectStates,
    setUpperHalfHeights,
}) => {
    const { logged } = useContext(AuthContext);
    const navigate = useNavigate();
    const { plans } = module;

    const wrapperContainer = useRef<HTMLDivElement>(null);
    const upperHalfContainer = useRef<HTMLDivElement>(null);
    const options = [...module.options];
    const [initialValue, setInitialValue] = useState('');

    useEffect(() => {
        setInitialValue('Выберите тариф');
        setSelectStates((prevState: any) => ({ ...prevState, [index]: null }));
    }, []);

    useLayoutEffect(() => {
        if (upperHalfContainer?.current && upperHalfHeights.length < modules.length)
            setUpperHalfHeights((prevState: number[]) => {
                const newState = [...prevState];
                if (upperHalfContainer.current) newState.push(upperHalfContainer.current.offsetHeight);
                return newState;
            });
    }, []);

    useEffect(() => {
        if (maxUpperHalfHeight) {
            if (wrapperContainer?.current)
                setHeights((prevState: number[]) => {
                    const newState = [...prevState];
                    if (wrapperContainer.current) newState.push(wrapperContainer.current.offsetHeight);
                    return newState;
                });
        }
    }, [maxUpperHalfHeight]);

    const handleStartWork = () => {
        if (module.ptype !== 'HTL') {
            // PROD
            // openInfoNotification('Создать проект пока нельзя.');
            navigate('/auth');
            return;
        }
        if (!selectStates[index]?.name) {
            openErrorNotification('Вы не выбрали тариф!');
            return;
        }

        if (logged) {
            navigate('/practice', { state: { showPracticeSubscriptionModal: true } });
            // stage prod напоминалка
        } else {
            navigate('/auth', { state: { afterLoginAction: 'subsribe_to_practice' } });
            openInfoNotification('Авторизуйтесь, пожалуйста.');
        }
    };

    const moduleData = module;
    const minPrice = moduleData.plans.reduce((min, plan) => {
        const price = parseFloat(plan.price);
        return price < min ? price : min;
    }, Infinity);

    return (
        <div className={_.wrapper} ref={wrapperContainer}>
            <div
                className={_.container}
                ref={upperHalfContainer}
                style={
                    maxUpperHalfHeight
                        ? { height: maxUpperHalfHeight - paddingOffset, paddingTop: paddingOffset }
                        : { paddingTop: paddingOffset }
                }
            >
                <div className={_.topBlockCard}>
                    <div className={_.title}>{logoReplacer(module.name)}</div>
                    <Text className={_.description} title={module.description} small gray>
                        {logoReplacer(module.description)}
                    </Text>
                </div>

                {!bottomLess && (
                    <div className={_.optionsContainer}>
                        {options.map((o) => (
                            <Option key={o.name} text={o.name} />
                        ))}
                    </div>
                )}

                <div className={_.bottomBlock}>
                    <Select
                        className={clsx(_.select, 'disableCrossButton')}
                        value={selectStates[index]?.name || initialValue}
                        onChange={(value) => {
                            const plan = plans.find((plan) => plan.name === value);
                            setSelectStates((prevState: any) => ({ ...prevState, [index]: plan || null }));
                        }}
                        allowClear
                    >
                        {!selectStates[index]?.name && (
                            <option value='Выберите тариф' disabled>
                                Выберите тариф
                            </option>
                        )}
                        {plans.map((plan) => (
                            <option key={plan.id} value={plan.name}>
                                {plan.name}
                            </option>
                        ))}
                    </Select>

                    <div className={_.price}>
                        {`${selectStates[index]?.price === undefined ? 'от ' : ''}${
                            selectStates[index]?.price
                                ? Number(selectStates[index]?.price).toFixed(0)
                                : minPrice.toFixed(0)
                        } ₽`}
                    </div>

                    <Button width='290px' marginBottom='30px' onClick={handleStartWork}>
                        Начать работу
                    </Button>

                    <div className={_.maxParticipants}>
                        {selectStates[index]?.name && selectStates[index]?.max_participants && (
                            <Option text={`Максимальное число участников: ${selectStates[index]?.max_participants}`} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
