import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';

import Container from 'common/StyledElements/Container/Container';
import ButtonBlue from 'common/Button/Button';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import { Messenger, IThread } from 'connectors/query/Messenger';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import { validateFile } from 'utils/validateMessageFileType';

import FileUploader from 'common/FileUploader/FileUploader';
import _ from './CreateThread.module.css';

const { TextArea } = Input;

const CreateThread = () => {
    const navigate = useNavigate();
    const [form] = useForm();
    const [files, setFiles] = useState<File[]>([]);

    const postAnswer = async (content: string, title: string) => {
        const result = await Messenger.Threads.create({
            title,
            acceptance_user_agreement: true,
            'message.content': content,
            'message.attachments': files,
        });
        if (result?.status >= 400 && result?.status <= 600) {
            Object.keys(result?.data).forEach((key) => {
                openErrorNotification('Ошибка!', result?.data[key]);
            });
            return;
        }
        const idLast = (result as IThread).messages[(result as IThread).messages.length - 1].id;
        Messenger.MessagesNew.setMessageViewed(Number(idLast));
        openSuccessNotification('Сообщение отправлено');
        form.resetFields();
        setFiles([]);
        navigate('/cabinet/messages');
    };

    const customRequest = ({ file }: any) => {
        if (validateFile(file)) {
            setFiles((f) => {
                if (f.length === 5) {
                    openErrorNotification('Превышено максимальное количество файлов', 'Максимум 5 файлов');
                    return [...f];
                }
                return [...f, file];
            });
        }
    };

    const onFinish = async (values: any) => {
        postAnswer(values.content, values.title);
    };
    return (
        <Container style={{ paddingTop: 40 }}>
            <div className={_.container}>
                <Button className={_.breadcrumb__btn} type='link' onClick={() => navigate('/cabinet/messages')}>
                    <ArrowLeftOutlined />
                    Назад к сообщениям
                </Button>

                <div className={_.inner}>
                    <div className={_.title}>Написать сообщение</div>

                    <Form
                        form={form}
                        name='new_message'
                        layout='vertical'
                        className={_.newMessage}
                        onFinish={onFinish}
                        requiredMark
                    >
                        <MyFormItem
                            className={_.form}
                            name='title'
                            label='Тема'
                            rules={[{ required: true, message: 'Введите тему сообщения' }]}
                        >
                            <Input className={_.inputInner} maxLength={256} />
                        </MyFormItem>
                        <MyFormItem
                            className={_.form}
                            name='content'
                            label='Ваше сообщение'
                            rules={[{ required: true, message: 'Введите сообщение' }]}
                        >
                            <TextArea className={_.inputInner} maxLength={1024} rows={4} />
                        </MyFormItem>

                        <FileUploader files={files} setFiles={setFiles} customRequest={customRequest} />

                        <div className={_.buttonBlock}>
                            <ButtonBlue htmlType='submit' width='250px'>
                                Отправить
                            </ButtonBlue>
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default CreateThread;
