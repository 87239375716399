import { useEffect, useState } from 'react';
import { Form, Input, Button, Radio, FormInstance } from 'antd';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import minus from 'assets/icons/minus.svg';
import plus from 'assets/icons/plus.svg';
import Divider from 'common/StyledElements/Divider/Divider';
import _ from './SerialBlock.module.css';

interface ISerialBlock {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    form: FormInstance;
    serialCount: number;
    setSerialCount: React.Dispatch<React.SetStateAction<number>>;
    activeSeriesRadioEpisodeTiming: string;
    setActiveSeriesRadioEpisodeTiming: React.Dispatch<React.SetStateAction<string>>;
    isSubscrActiveOrCreate: boolean;
}

const SerialBlock: React.FC<ISerialBlock> = ({
    onInputChange,
    setData,
    data,
    form,
    serialCount,
    setSerialCount,
    activeSeriesRadioEpisodeTiming,
    setActiveSeriesRadioEpisodeTiming,
    isSubscrActiveOrCreate,
}) => {
    // const [approxTiming, setApproxTiming] = useState<{ from: string | null; to: string | null }>({
    //     from: null,
    //     to: null,
    // });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (/^\d+$/.test(value)) {
            const numberValue = parseInt(value, 10);
            if (numberValue >= 1 && numberValue <= 999) {
                setSerialCount(numberValue);
            }
        } else if (!value) {
            setSerialCount(1);
        }
    };

    const handleOnlyNumbers = (event: React.KeyboardEvent) => {
        const { charCode } = event;
        if (charCode && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    const handleRadioChange = (ev: any) => {
        form.resetFields(['episode_timing', 'approx_timing_from', 'approx_timing_to']);
        setData((prevData: any) => ({ ...prevData, episode_timing: '', approx_timing_from: '', approx_timing_to: '' }));
        // setApproxTiming({ from: null, to: null });
        setActiveSeriesRadioEpisodeTiming(ev.target.value);
    };

    return (
        <div className='blockCard'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='titleCard mb0 required-field'>Количество серий</div>
                <div className={_.main}>
                    <Button
                        className={_.leftBtn}
                        onClick={() => setSerialCount((prev: number) => Math.max(1, prev - 1))}
                        type='text'
                        disabled={!isSubscrActiveOrCreate}
                    >
                        <img src={minus} alt='minus' />
                    </Button>
                    <Input
                        className={_.inputCounter}
                        value={serialCount}
                        onChange={handleInputChange}
                        onKeyPress={handleOnlyNumbers}
                        disabled={!isSubscrActiveOrCreate}
                    />
                    <Button
                        className={_.rightBtn}
                        onClick={() => setSerialCount((prev: number) => (prev < 999 ? prev + 1 : prev))}
                        type='text'
                        disabled={!isSubscrActiveOrCreate}
                    >
                        <img src={plus} alt='plus' />
                    </Button>
                </div>
                <Divider />
                <div className='titleCard mb0 required-field'>Хронометраж серии (минуты)</div>
                <div className='subTitle'>(ориентировочный, включая начальные и финальные титры)</div>
                <Radio.Group
                    value={activeSeriesRadioEpisodeTiming}
                    className={_.radioBlock}
                    onChange={handleRadioChange}
                >
                    <Radio value='Точный' onChange={(e) => {}} disabled={!isSubscrActiveOrCreate}>
                        <div className={_.radioItem}>
                            Точный
                            <MyFormItem
                                name='episode_timing'
                                rules={
                                    activeSeriesRadioEpisodeTiming === 'Точный'
                                        ? [{ required: true, message: 'Необходимо заполнить' }]
                                        : []
                                }
                            >
                                <Input
                                    className='inputInner inputEpisode'
                                    placeholder='###'
                                    onChange={(event) => onInputChange('episode_timing', event.target.value)}
                                    onKeyPress={handleOnlyNumbers}
                                    disabled={activeSeriesRadioEpisodeTiming !== 'Точный' || !isSubscrActiveOrCreate}
                                />
                            </MyFormItem>
                        </div>
                    </Radio>
                    <Radio value='Ориентировочный' disabled={!isSubscrActiveOrCreate}>
                        <div className={_.radioItem}>
                            Ориентировочный: от
                            <MyFormItem
                                name='approx_timing_from'
                                rules={
                                    activeSeriesRadioEpisodeTiming === 'Ориентировочный'
                                        ? [{ required: true, message: 'Необходимо заполнить' }]
                                        : []
                                }
                            >
                                <Input
                                    className='inputInner inputEpisode'
                                    placeholder='###'
                                    onChange={(event) => {
                                        // setApproxTiming((prev) => ({ ...prev, from: event.target.value }));
                                        onInputChange('approx_timing_from', event.target.value);
                                    }}
                                    onKeyPress={handleOnlyNumbers}
                                    disabled={
                                        activeSeriesRadioEpisodeTiming !== 'Ориентировочный' || !isSubscrActiveOrCreate
                                    }
                                />
                            </MyFormItem>
                            до
                            <MyFormItem
                                name='approx_timing_to'
                                rules={
                                    activeSeriesRadioEpisodeTiming === 'Ориентировочный'
                                        ? [{ required: true, message: 'Необходимо заполнить' }]
                                        : []
                                }
                            >
                                <Input
                                    className='inputInner inputEpisode'
                                    placeholder='###'
                                    onChange={(event) => {
                                        // setApproxTiming((prev) => ({ ...prev, to: event.target.value }));
                                        onInputChange('approx_timing_to', event.target.value);
                                    }}
                                    onKeyPress={handleOnlyNumbers}
                                    disabled={
                                        activeSeriesRadioEpisodeTiming !== 'Ориентировочный' || !isSubscrActiveOrCreate
                                    }
                                />
                            </MyFormItem>
                        </div>
                    </Radio>
                </Radio.Group>
                <Divider />
                <div className='titleCard mb0 required-field'>Хронометраж сериала общий (минуты)</div>
                <MyFormItem
                    name='timing'
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: 'Необходимо заполнить' }]}
                >
                    <Input
                        placeholder='###'
                        className='inputInner select3'
                        onChange={(event) => onInputChange('timing', event.target.value)}
                        onKeyPress={handleOnlyNumbers}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </Form>
        </div>
    );
};

export default SerialBlock;
