import { useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import DatePicker from 'common/DatePicker/DatePicker';
import validationRules from 'utils/formValidationRules';

interface IStepFourPassport {
    onInputChange: (key: string, value: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    form: FormInstance;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    isSubscrActiveOrCreate: boolean;
}

const StepFourPassport: React.FC<IStepFourPassport> = (
    { form, onInputChange, onDateChange, isSubscrActiveOrCreate }, // Добавьте form здесь
) => (
    <div className='blockCard'>
        <Form form={form} name='org_form' layout='vertical'>
            <div className='titleCard'>Документ, удостоверяющий личность</div>

            <div className='shortInnersBlock'>
                <MyFormItem name='passport_number' label='Серия и номер паспорта' rules={[validationRules.digitsOnly]}>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('passport_number', event.target.value)}
                        maxLength={10}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='passport_issued_by' label='Кем выдан'>
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('passport_issued_by', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem name='passport_issued_date' label='Когда выдан'>
                    <DatePicker
                        className='inputInner inputInnerShort2'
                        onChange={onDateChange('passport_issued_date')}
                        disabled={!isSubscrActiveOrCreate}
                        disableFuture
                    />
                </MyFormItem>

                <MyFormItem
                    name='passport_department_code'
                    label='Код подразделения'
                    rules={[validationRules.digitsOnly]}
                >
                    <Input
                        className='inputInner inputInnerShort2'
                        onChange={(event) => onInputChange('passport_department_code', event.target.value)}
                        maxLength={6}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
            </div>
        </Form>
    </div>
);
export default StepFourPassport;
