import { FC } from 'react';

import _ from './Divider.module.css';

interface IDividerProp {
    mb?: number | string;
    mt?: number | string;
    row?: boolean;
    margin?: number | string;
    width?: number | string;
}

const Divider: FC<IDividerProp> = ({ mb, mt, row, margin, width }) => {
    if (row)
        return (
            <div style={{ marginLeft: margin || 10, marginRight: margin || 10 }} className={_.rowDividerWrapper}>
                <div className={_.rowDivider} />
            </div>
        );
    return <div className={_.divider} style={{ marginBottom: mb || 20, marginLeft: margin || 0, marginTop: mt, width }} />;
};

export default Divider;
