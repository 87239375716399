import { FC } from 'react';
import clsx from 'clsx';

import Text from 'common/StyledElements/Text/Text';
import anon from 'assets/team/anon.jpg';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './TeamItem.module.css';

interface ITeamItemProps {
    avatar: string;
    first_name: string;
    last_name: string;
    position: string;
    description: string;
    defaultPointer: boolean;
}

const TeamItem: FC<ITeamItemProps> = ({ avatar, first_name, last_name, position, description, defaultPointer }) => (
    <div className={clsx(_.wrapper, defaultPointer && _.defaultCursor)}>
        <div className={_.container}>
            <img className={_.img} src={avatar || anon} alt={`${first_name} ${last_name}`} />

            <div className={_.textContainer}>
                <div className={_.name}>
                    {first_name} {last_name}
                </div>
                <Text mb={30} width={230} small lightGray>
                    {logoReplacer(position)}
                </Text>
                <Text mid className={_.description}>
                    {logoReplacer(description)}
                </Text>
            </div>
        </div>
    </div>
);

export default TeamItem;
