import AuthContext from 'common/AuthProvider/AuthProvider';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RedirectPage = () => {
    const { pathname } = useLocation();
    const { hasLoaded, logged } = useContext(AuthContext);

    if (hasLoaded && !logged && pathname === '/subsribe-to-practice')
        return <Navigate to='/auth' state={{ afterLoginAction: 'subsribe_to_practice' }} />;
    if (hasLoaded && logged && pathname === '/subsribe-to-practice')
        return <Navigate to='/practice' state={{ showPracticeSubscriptionModal: true }} />;
    return <div />;
};

export default RedirectPage;
