import { FC } from 'react';
import clsx from 'clsx';

import Text from 'common/StyledElements/Text/Text';

import _ from './Rate.module.css';

export enum ERates {
    Start = 'start',
    StartMax = 'startMax',
    StartLight = 'startLight',
    Documental = 'documental',
}

interface IRateProps {
    type: ERates;
}

const title = {
    start: 'Дебют',
    startMax: 'Дебют Максимум',
    startLight: 'Дебют лайт',
    documental: 'Документальный',
};

const color = {
    start: '#ED8864',
    startMax: '#2F5686',
    startLight: '#1AB35E',
    documental: '#8AB9E8',
};

const Rate: FC<IRateProps> = ({ type }) => (
    <div className={clsx(_.rateWrapper, _[type])}>
        <Text style={{ color: color[type], display: 'table-caption' }} small>
            {title[type]}
        </Text>
    </div>
);

export default Rate;
