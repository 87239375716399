import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IProject, PersonalAccount } from 'connectors/query/PersonalAccount';
import noProjects from 'assets/project.png';
import Button from 'common/Button/Button';
import Header from '../Header/Header';
import Row from './components/Row/Row';

import _ from './List.module.css';


const List = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState<IProject[]>([]);

    const getProjects = async () => {
        const data = await PersonalAccount.getProjects({ ordering: '-updated_at' });

        if (Array.isArray(data.results)) {
            const res = data.results.slice(0, 5);
            setProjects(res);
        }
    };
    useEffect(() => {
        getProjects();
    }, []);

    if (projects.length === 0) {
        return (
            <div className={_.wrapper}>
                <Header title='Проекты' link='Все проекты' linkRoute='add' />
                <div className={_.noProjects}>
                    <img src={noProjects} className={_.noProjectsImg} alt='No projects' />
                    <p className={_.noProjectsTitle}>У вас пока нет ни одного проекта</p>
                    <Button
                        className={_.noProjectsBtn}
                        width='290px'
                        onClick={() => navigate('/cabinet/create')}
                        plus
                    >
                        Создать проект
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={_.wrapper}>
            <Header title='Проекты' link='Все проекты' linkRoute='add' />

            {projects.map((project) => (
                <Row key={project.id} project={project} />
            ))}
        </div>
    );
};

export default List;
