import { FC } from 'react';

import Text from 'common/StyledElements/Text/Text';
import { IPerson } from 'connectors/query/PersonalAccount';
import StatusWrapper from 'common/StatusWrapper/StatusWrapper';

import clsx from 'clsx';
import _ from './ProjectsList.module.css';

interface IProjectsList {
    participant: IPerson | null;
    selectedProject: number | null;
    setSelectedProject: (p: number | null) => void;
}

const ProjectsList: FC<IProjectsList> = ({ participant, selectedProject, setSelectedProject }) => (
    <div className={clsx(_.projectsList, _.scroll)}>
        {participant &&
            participant.projects?.map((project, i) => (
                <div
                    className={clsx(
                        _.project,
                        project.id === selectedProject && _.selectedProject,
                        project.organizer_user === participant.id && _.cursorNotAllowed,
                    )}
                    key={project.id}
                    onClick={() => {
                        if (project.organizer_user !== participant.id) setSelectedProject(project.id);
                    }}
                >
                    <Text gray>{i + 1}</Text> <Text>{project.name}</Text>
                    {project.organizer_user === participant.id && (
                        <StatusWrapper
                            text='Владелец'
                            statusAliases={{
                                greenCheck: [{ codeName: 'Владелец' }],
                            }}
                        />
                    )}
                </div>
            ))}
    </div>
);

export default ProjectsList;
