import { FC } from 'react';
import { Collapse } from 'antd';
import clsx from 'clsx';

import arrow from 'assets/collapseArrow.svg';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './Collapse.module.css';
import './styles.css';

interface ICollapseItemProps {
    title: string;
    text: string;
}

const { Panel } = Collapse;

const CollapseItem: FC<ICollapseItemProps> = ({ title, text }) => (
    <div className={_.wrapper}>
        <Collapse
            className={clsx(_.collapse, 'faqCollapse')}
            collapsible='header'
            bordered={false}
            // eslint-disable-next-line react/no-unstable-nested-components
            expandIcon={({ isActive }) => (
                <img className={clsx(_.expandIcon, isActive && _.expandIconActive)} src={arrow} alt='arrow' />
            )}
        >
            <Panel
                className={_.panel}
                header={<div dangerouslySetInnerHTML={{ __html: logoReplacer(title, 'dangerouslySetInnerHTML') }} />}
                key='1'
            >
                <div
                    className={_.panelItem}
                    dangerouslySetInnerHTML={{ __html: logoReplacer(text, 'dangerouslySetInnerHTML') }}
                />
            </Panel>
        </Collapse>
    </div>
);

export default CollapseItem;
