import { APIService } from 'connectors/api/rest';

export interface IModule {
    id: number;
    plans: IPlan[];
    ptype: string | null;
    name: string;
    description: string;
    options: { id: number; name: string; is_cinema_law_access: boolean }[];
}

export const isModule = (p: unknown): p is IModule =>
    (p as IModule).plans !== undefined &&
    (p as IModule).id !== undefined &&
    (p as IModule).ptype !== undefined &&
    (p as IModule).description !== undefined &&
    (p as IModule).options !== undefined;

export interface IPlan {
    code: string;
    moduleId: number;
    id: number;
    name: string;
    max_participants: number;
    period: number;
    price: string;
    product: { id: number; name: string };
    is_trial: boolean;
    is_archival: boolean;
}

export enum ESubscriptionStatuses {
    Canceled = 'CNL',
    Pending = 'PND',
    Active = 'ACT',
    Expired = 'EXP',
}
export enum ESubscriptionStatusTrial {
    Trial = 'TRL',
}
export type TSubscriptionStatusesWithTrial = ESubscriptionStatuses | ESubscriptionStatusTrial;

export interface ISubscription {
    id: number;
    plan: IPlan;
    starts_at: string | null;
    ends_at: string | null;
    next_period_ends_at: string | null;
    owner: {
        id: 5;
        last_name: string;
        first_name: string;
    };
    status: ESubscriptionStatuses;
    project: number;
}

export interface ICreateSubscriptionParams {
    plan_id: number;
    status: string;
    is_trial: boolean;
    project?: number;
}

export const Subscriptions = {
    async getModules() {
        try {
            return await APIService.GET('/subscriptions/products/');
        } catch (e) {
            return e;
        }
    },
    async getModule(id: number) {
        try {
            return await APIService.GET(`/subscriptions/products/${id}`);
        } catch (e) {
            return e;
        }
    },
    async getSubscriptions() {
        try {
            return await APIService.GET('/subscriptions/subscriptions/');
        } catch (e) {
            return e;
        }
    },
    async getPlans() {
        try {
            return await APIService.GET('/subscriptions/plans/');
        } catch (e) {
            return e;
        }
    },
    async createSubscription(params: ICreateSubscriptionParams) {
        try {
            return await APIService.POST('/subscriptions/subscriptions/', params);
        } catch (e) {
            return e;
        }
    },
};
