import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import Text from 'common/StyledElements/Text/Text';
import meatballsMenuIcon from 'assets/orders/meatballsMenuIcon.svg';
import settingsIcon from 'assets/orders/settingsIcon.svg';
import StatusWrapper, { IStatusAliases } from 'common/StatusWrapper/StatusWrapper';
import { IInvoice, IPaymentInvoiceOrderingType, InvoiceStatuses } from 'connectors/query/PersonalAccount';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import { BillsType } from './mock';

import _ from './Payment.module.css';
import './styles.css';

const statusAliasesPayment: IStatusAliases = {
    greenCheck: [{ codeName: InvoiceStatuses.Paid, shownName: 'Оплачен' }],
    redCross: [
        { codeName: InvoiceStatuses.Canceled, shownName: 'Отменен' },
        { codeName: InvoiceStatuses.Error, shownName: 'Ошибка' },
        { codeName: InvoiceStatuses.Overdue, shownName: 'Просрочен' },
    ],
    blueHourglass: [
        { codeName: InvoiceStatuses.Pending, shownName: 'Ожидает оплаты' },
        { codeName: InvoiceStatuses.Draft, shownName: 'Черновик' },
    ],
};

type PaymentsColumnsType = (
    offset: number,
    sort: { columnKey?: IPaymentInvoiceOrderingType; order?: 'ascend' | 'descend' },
) => ColumnsType<IInvoice>;
type BillsColumnsType = () => ColumnsType<BillsType>;

export const paymentsColumns: PaymentsColumnsType = (offset, sort) => [
    {
        title: <div />,
        width: '80px',
        render: (id, record, index) => (
            <Text lightGray mid>
                {offset + index + 1}
            </Text>
        ),
    },
    {
        title: (
            <Text bold mid>
                Дата начала подписки
            </Text>
        ),
        dataIndex: 'starts_at',
        key: 'starts_at',
        align: 'left',
        width: '250px',
        sorter: () => 0,
        sortOrder: sort.columnKey === 'starts_at' ? sort.order : undefined,
        render: (starts_at, record) => {
            const formattedDate = dayjs(record.subscription.starts_at).isValid()
                ? dayjs(record.subscription.starts_at).format('D.MM.YYYY HH:mm')
                : '-';
            return <Text mid>{formattedDate}</Text>;
        },
    },
    {
        title: (
            <Text bold mid>
                Дата завершения подписки
            </Text>
        ),
        dataIndex: 'ends_at',
        key: 'ends_at',
        align: 'left',
        width: '250px',
        sorter: () => 0,
        sortOrder: sort.columnKey === 'ends_at' ? sort.order : undefined,
        render: (date_end, record) => {
            const formattedDate = dayjs(record.subscription.ends_at).isValid()
                ? dayjs(record.subscription.ends_at).format('D.MM.YYYY HH:mm')
                : '-';
            return <Text mid>{formattedDate}</Text>;
        },
    },
    {
        title: (
            <Text bold mid>
                Номер заказа
            </Text>
        ),
        dataIndex: 'orderid',
        key: 'orderid',
        align: 'center',
        width: '190px',
        render: (orderid, record) => (
            <Text style={{ cursor: 'pointer' }} blue mid>
                {record.subscription.id}
            </Text>
        ),
    },
    // {
    //     title: (
    //         <Text bold mid>
    //             Проект
    //         </Text>
    //     ),
    //     dataIndex: 'projectName',
    //     key: 'projectName',
    //     width: '320px',
    //     render: (projectName) => (
    //         <Text style={{ cursor: 'pointer' }} blue mid>
    //             {projectName}
    //         </Text>
    //     ),
    // },
    {
        title: (
            <Text bold mid>
                Модуль
            </Text>
        ),
        width: '200px',
        align: 'center',

        render: (product, record) => <Text mid>{logoReplacer(record.subscription.plan.product.name)}</Text>,
    },
    {
        title: (
            <Text bold mid>
                Тариф
            </Text>
        ),
        key: 'plan',
        width: '260px',
        align: 'center',

        render: (planName, record) => <Text mid>{logoReplacer(record.subscription.plan.name)}</Text>,
    },
    {
        title: (
            <Text bold mid>
                Сумма
            </Text>
        ),

        width: '220px',
        align: 'center',
        render: (amount, render) => <Text mid>{render.amount} ₽</Text>,
    },
    {
        title: (
            <Text bold mid>
                Статус
            </Text>
        ),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: '160px',
        render: (status, record) => <StatusWrapper text={record.status} statusAliases={statusAliasesPayment} />,
    },
];

export const billsColumns: BillsColumnsType = () => [
    {
        title: (
            <Text bold mid>
                ID
            </Text>
        ),
        dataIndex: 'id',
        key: 'id',
        width: '40px',
        render: (id) => (
            <Text lightGray mid>
                {id}
            </Text>
        ),
    },
    {
        title: (
            <Text style={{ paddingLeft: '40px' }} bold mid>
                Дата
            </Text>
        ),
        dataIndex: 'date',
        key: 'date',
        align: 'left',
        width: '255px',
        render: (date) => <Text mid>{date}</Text>,
    },
    {
        title: (
            <Text className={clsx(_.sortBy, _.order)} bold mid>
                Заказ
            </Text>
        ),
        dataIndex: 'order',
        key: 'order',
        width: '210px',
        render: (order) => (
            <Text style={{ cursor: 'pointer' }} blue mid>
                {order}
            </Text>
        ),
    },
    {
        title: (
            <Text className={clsx(_.sortBy, _.accNum)} bold mid>
                № счета
            </Text>
        ),
        dataIndex: 'accNum',
        key: 'accNum',
        width: '230px',
        render: (accNum) => <Text mid>{accNum}</Text>,
    },
    {
        title: (
            <Text style={{ paddingLeft: '7px' }} bold mid>
                Сумма
            </Text>
        ),
        dataIndex: 'amount',
        key: 'amount',
        width: '180px',
        render: (amount) => <Text mid>{amount}</Text>,
    },
    {
        title: (
            <Text bold mid>
                Статус
            </Text>
        ),
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: '160px',
        render: (status) => <StatusWrapper text={status} statusAliases={statusAliasesPayment} />,
    },
    {
        title: (
            <div className={_.iconWrapper}>
                <img style={{ cursor: 'pointer' }} src={settingsIcon} alt='settings icon' />
            </div>
        ),
        dataIndex: 'settings',
        key: 'settings',
        align: 'right',
        render: () => (
            <div className={_.iconWrapper}>
                <img style={{ cursor: 'pointer' }} src={meatballsMenuIcon} alt='meatballs menu icon' />
            </div>
        ),
    },
];
