import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Input, FormInstance } from 'antd';
import { Rule } from 'antd/es/form';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import Icon from 'common/Icon/Icon';
import bucketIcon from 'assets/statuses/bucket.svg';
import Divider from 'common/StyledElements/Divider/Divider';
import Button from 'common/Button/Button';

import _ from './GenreBlock.module.css';

interface IMediaData {
    genres: string[];
    customGenres: string[];
}

interface IGenreBlock {
    data: IMediaData;
    setData: (data: IMediaData) => void;
    onInputChange: (key: string, value: string | null | boolean) => void;
    setHasError: (error: boolean) => void;
    form: FormInstance;
    setCustomGenres: React.Dispatch<React.SetStateAction<string[]>>;
    setCheckedGenres: React.Dispatch<React.SetStateAction<string[]>>;
    customGenres: string[];
    checkedGenres: string[];
    isSubscrActiveOrCreate: boolean;
}
export const genreOptions = [
    'биографический',
    'боевик',
    'вестерн',
    'военный',
    'детектив',
    'детский',
    'драма',
    'исторический',
    'комедия',
    'криминал',
    'мелодрама',
    'мистика',
    'музыкальный',
    'нуар',
    'приключения',
    'семейный',
    'сказка',
    'ужасы',
    'фантастика',
    'фэнтези',
];

const GenreBlock: React.FC<IGenreBlock> = ({
    setData,
    data,
    onInputChange,
    setHasError,
    form,
    setCustomGenres,
    setCheckedGenres,
    customGenres,
    checkedGenres,
    isSubscrActiveOrCreate,
}) => {
    const [errorInputIndex, setErrorInputIndex] = useState<number | null>(null);

    useEffect(() => {
        const combinedGenres = [...checkedGenres, ...customGenres.filter((g) => g.trim() !== '')];
        setData({ ...data, genres: combinedGenres });
    }, [customGenres, checkedGenres]);

    const isCheckboxDisabled = (genre: string) => {
        const isGenreChecked = checkedGenres.includes(genre);
        const totalSelected = checkedGenres.length + customGenres.filter((g) => g.trim() !== '').length;

        if (isGenreChecked) {
            return false;
        }

        return totalSelected >= 10 || checkedGenres.length >= 5;
    };

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        if (checkedValues.length > 5) {
            return;
        }
        setCheckedGenres(checkedValues as string[]);
    };

    const handleCustomGenreChange = (index: number, value: string) => {
        if (genreOptions.includes(value.toLowerCase())) {
            setErrorInputIndex(index);
            setHasError(true);
            return;
        }

        setErrorInputIndex(null);
        setHasError(false);
        const newCustomGenres = [...customGenres];
        newCustomGenres[index] = value;
        setCustomGenres(newCustomGenres);
        setData({ ...data, [`customGenre${index}`]: value });
        form.setFieldsValue({ [`customGenre${index}`]: value });
    };

    const addCustomGenre = () => {
        if (customGenres.length < 5 && checkedGenres.length + customGenres.filter((g) => g.trim() !== '').length < 10) {
            setCustomGenres((prevGenres) => [...prevGenres, '']);
        }
    };

    const removeCustomGenre = (index: number) => {
        const updatedCustomGenres = [...customGenres];
        console.log('updatedCustomGenres', updatedCustomGenres);
        updatedCustomGenres.splice(index, 1);

        const customeGenreToInsertAfter = {};
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 4; i++) {
            // @ts-ignore
            customeGenreToInsertAfter[`customGenre${i}`] = updatedCustomGenres[i] || undefined;
        }
        console.log('customeGenreToInsertAfter', customeGenreToInsertAfter);
        setData({ ...data, ...customeGenreToInsertAfter });
        setCustomGenres(updatedCustomGenres);

        // form.setFieldsValue(customeGenreToInsertAfter);
    };

    // console.log(data.genre);

    const genreMinOneValidationFunctionChecked: Rule = () => ({
        validator(_, val) {
            if (!val.length) {
                return Promise.reject(new Error('Необходимо указать хотя бы 1 жанр.'));
            }

            return Promise.resolve();
        },
    });

    return (
        <div className='blockCard'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='mb0'>
                    <div className={_.block}>
                        <div className='titleCard required-field'>Жанр фильма (не более 5)</div>

                        <MyFormItem name='genres' rules={[genreMinOneValidationFunctionChecked]}>
                            <Checkbox.Group onChange={handleCheckboxChange} className='checkBlock'>
                                {genreOptions.map((genre) => (
                                    <Checkbox
                                        key={genre}
                                        value={genre}
                                        disabled={isCheckboxDisabled(genre) || !isSubscrActiveOrCreate}
                                    >
                                        {genre}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </MyFormItem>
                    </div>
                    <Divider mt={10} />
                    <div className={_.block}>
                        <div className='titleCard'>Ваш жанр (не более 5)</div>

                        <div className={_.addGenreBlock}>
                            {customGenres.map((genre, index) => (
                                <div className={_.customGenreBlock} key={index}>
                                    <MyFormItem
                                        name={`customGenre${index}`}
                                        style={{ marginBottom: 0 }}
                                        help={
                                            errorInputIndex === index
                                                ? 'Этот жанр уже доступен для выбора из списка'
                                                : null
                                        }
                                        validateStatus={errorInputIndex === index ? 'error' : undefined}
                                    >
                                        <Input
                                            className='inputInner inputInnerShort2'
                                            value={genre}
                                            onChange={(e) => handleCustomGenreChange(index, e.target.value)}
                                            placeholder='Введите название жанра'
                                            disabled={!isSubscrActiveOrCreate}
                                        />
                                    </MyFormItem>
                                    {customGenres.length > 1 && isSubscrActiveOrCreate && (
                                        <Icon className={_.bucket} onClick={() => removeCustomGenre(index)}>
                                            <img src={bucketIcon} alt='bucket' />
                                        </Icon>
                                    )}
                                </div>
                            ))}
                            <div className='addBtn'>
                                {customGenres.length < 5 && isSubscrActiveOrCreate && (
                                    <Button width='240px' onClick={addCustomGenre} plus>
                                        Добавить жанр
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default GenreBlock;
