import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Link from 'common/StyledElements/Link/Link';
import Title from 'common/StyledElements/Title/Title';

import _ from './Header.module.css';

interface IHeaderProps {
    title: string;
    link?: string;
    linkRoute?: string;
}

const Header: FC<IHeaderProps> = ({ title, link, linkRoute }) => {
    const navigate = useNavigate();

    return (
        <div className={_.wrapper}>
            <Title>{title}</Title>
            <Link onClick={() => linkRoute && navigate(linkRoute)}>{link}</Link>
        </div>
    );
};

export default Header;
