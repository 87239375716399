import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Form } from 'antd';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import clsx from 'clsx';

import { Auth } from 'connectors/query/Auth';
import authPic from 'assets/authLong.jpg';
import logo from 'assets/logoEmpty.svg';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import { openErrorNotification, openInfoNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import ConfirmEmailModal from 'common/Modals/ConfirmEmailModal/ConfirmEmailModal';
import Agreement from 'common/Modals/Agreement/Agreement';
import YandexCaptcha from 'common/YandexCaptcha/YandexCaptcha';
import validationRules from 'utils/formValidationRules';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';
import SmsModal from './components/SmsModal/SmsModal';

import _ from './Form.module.css';
import 'react-client-captcha/dist/index.css';
import './styles.css';

// const validateMessages = {
//     required: 'Поле "${label}" обязательно для заполнения',
// };

type TypeStatus = '' | 'error' | 'success' | 'warning' | 'validating' | undefined;

type TypeObj = {
    email: TypeStatus;
    first_name: TypeStatus;
    last_name: TypeStatus;
    second_name: TypeStatus;
    phone: TypeStatus;
    password1: TypeStatus;
};

const AuthForm = () => {
    const [form] = Form.useForm();

    const [smsModalIsShown, setSmsModalIsShown] = useState(false);
    const [confirmEmailIsShown, setConfirmEmailIsShown] = useState(false);

    const [captchaToken, setCaptchaToken] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    const [captchaKeyState, setCaptchaKeyState] = useState<number>(0);
    const [captchaErrorIsShown, setCaptchaErrorisShown] = useState(false);

    const [validateNameStatusError, setValidateNameStatusError] = useState<TypeStatus>('');
    const [validateSurnameStatusError, setValidateSurnameStatusError] = useState<TypeStatus>('');
    const [validateSecondnameStatusError, setValidateSecondnameStatusError] = useState<TypeStatus>('');
    const [validateEmailStatusError, setValidateEmailStatusError] = useState<TypeStatus>('');
    const [validatePhoneStatusError, setValidatePhoneStatusError] = useState<TypeStatus>('');
    const [validatePasswordStatusError, setValidatePasswordStatusError] = useState<TypeStatus>('');

    useEffect(() => {
        setCaptchaError('');
        setCaptchaErrorisShown(false);
    }, [captchaToken]);

    const resetCaptcha = () => setCaptchaKeyState((prev) => prev + 1);

    const onFinish = async (values: any) => {
        if (!values.agreePerson || !values.agreeUser) {
            return openInfoNotification('', 'Подтвердите согласие на обработку персональных данных');
        }
        if (!captchaToken) {
            setCaptchaErrorisShown(true);
            return setCaptchaError('Необходимо нажать на подтверждение "Я не робот"');
        }

        const phoneData = values.phone.replaceAll(' ', '').replaceAll('-', '').replaceAll('+', '');

        const data = {
            email: values.email,
            password1: values.password,
            password2: values.confirmPassword,
            first_name: values.name,
            last_name: values.surname,
            patronymic: values.secondname,
            phone: phoneData,
            acceptance_user_agreement: values.agreeUser,
            consent_pdata_processing: values.agreePerson,
            captcha_token: captchaToken,
            customer_profile: {},
        };

        const signup = await Auth.signup(data);
        resetCaptcha();

        const obj: TypeObj = {
            email: 'success',
            first_name: 'success',
            last_name: 'success',
            second_name: 'success',
            phone: 'success',
            password1: 'success',
        };

        if (signup?.status === 400) {
            Object.keys(signup?.data).forEach((key) => {
                if (key === 'email') {
                    obj.email = 'error';
                } else if (key === 'first_name') {
                    obj.first_name = 'error';
                } else if (key === 'last_name') {
                    obj.last_name = 'error';
                } else if (key === 'second_name') {
                    obj.second_name = 'error';
                } else if (key === 'phone') {
                    obj.phone = 'error';
                } else if (key === 'password1') {
                    obj.password1 = 'error';
                } else if (key === 'captcha_token') {
                    setCaptchaErrorisShown(true);
                }

                setValidateNameStatusError(obj.first_name);
                setValidateSurnameStatusError(obj.last_name);
                setValidatePhoneStatusError(obj.second_name);
                setValidateEmailStatusError(obj.email);
                setValidatePhoneStatusError(obj.phone);
                setValidatePasswordStatusError(obj.password1);

                openErrorNotification('Ошибка!', signup?.data[key][0]);
            });
        } else if (signup?.status >= 500 && signup?.data?.detail?.includes('SMTP error')) {
            openErrorNotification(
                'Ошибка!',
                'Почта недоступна. Обратитесь к администратору через форму обратной связи.',
            );
        } else if (signup?.status >= 300) {
            openErrorNotification('Ошибка регистрации!', 'Обратитесь к администратору через форму обратной связи.');
        }

        if (signup?.email) {
            setConfirmEmailIsShown(true);
        }
    };

    const values = form.getFieldsValue();

    const handleResend = async () => {
        try {
            const response = await Auth.resendSignupRequest({ email: values.email });
            console.log(response);
            if (response.message) {
                openSuccessNotification('Отправлено', 'Сообщение отправлено вам на почту, проверьте папку спам');
            } else if (response.data && response.data.detail) {
                openErrorNotification('Ошибка отправки', response.data.detail);
            }
        } catch (error) {
            console.log(error);
            openErrorNotification('Ошибка', 'Произошла ошибка при отправке');
        }
    };
    
    return (
        <div className={_.wrapper}>
            <div className={_.halfScreen} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={_.container}>
                    <div className={_.leftSideForm}>
                        <Title mb={30}>Регистрация</Title>
                        <SmsModal isShown={smsModalIsShown} toggleShown={setSmsModalIsShown} />
                        <Form name='auth' className='regForm' form={form} onFinish={onFinish}>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['surname']}
                                        validateStatus={validateSurnameStatusError}
                                        label='Фамилия'
                                        rules={validationRules.surnameRules}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        hasFeedback
                                    >
                                        <Input
                                            className={_.elParam}
                                            maxLength={64}
                                            status={validateSurnameStatusError === 'error' ? 'error' : ''}
                                            onChange={() => {
                                                setValidateSurnameStatusError('');
                                            }}
                                            onBlur={() => handleInputBlur(form, 'surname')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['name']}
                                        validateStatus={validateNameStatusError}
                                        label='Имя'
                                        rules={validationRules.nameRules}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        hasFeedback
                                    >
                                        <Input
                                            className={_.elParam}
                                            maxLength={64}
                                            status={validateNameStatusError === 'error' ? 'error' : ''}
                                            onChange={() => {
                                                setValidateNameStatusError('');
                                            }}
                                            onBlur={() => handleInputBlur(form, 'name')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['secondname']}
                                        validateStatus={validateSecondnameStatusError}
                                        label='Отчество'
                                        rules={validationRules.patronymicRules}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        hasFeedback
                                    >
                                        <Input
                                            className={_.elParam}
                                            maxLength={64}
                                            status={validateSecondnameStatusError === 'error' ? 'error' : ''}
                                            onChange={() => {
                                                setValidateSecondnameStatusError('');
                                            }}
                                            onBlur={() => handleInputBlur(form, 'secondname')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['phone']}
                                        validateStatus={validatePhoneStatusError}
                                        label='Номер мобильного телефона'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Поле «Номер мобильного телефона» обязательно для заполнения',
                                            },
                                            {
                                                message: 'Введите корректный номер телефона',
                                                pattern: /^\+?[1-9]\d{5,13}$|^[1-9]\d{5,14}$/,
                                            },
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        hasFeedback
                                    >
                                        <Input
                                            className={_.elParam}
                                            maxLength={15}
                                            status={validatePhoneStatusError === 'error' ? 'error' : ''}
                                            onChange={() => {
                                                setValidatePhoneStatusError('');
                                            }}
                                            onBlur={() => handleInputBlur(form, 'phone')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['email']}
                                        validateStatus={validateEmailStatusError}
                                        label='E-mail (логин)'
                                        rules={validationRules.emailRules}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        hasFeedback
                                    >
                                        <Input
                                            className={_.elParam}
                                            maxLength={320}
                                            status={validateEmailStatusError === 'error' ? 'error' : ''}
                                            onChange={() => {
                                                setValidateEmailStatusError('');
                                            }}
                                            onBlur={() => handleInputBlur(form, 'email')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['password']}
                                        validateStatus={validatePasswordStatusError}
                                        label='Пароль'
                                        rules={[
                                            { required: true, message: 'Пожалуйста, введите пароль' },
                                            {
                                                pattern: /^(?=.*[a-z]).{1,}$/,
                                                message: 'Пароль должен содержать хотя бы одну строчную букву',
                                            },
                                            {
                                                pattern: /^(?=.*[A-Z]).{1,}$/,
                                                message: 'Пароль должен содержать хотя бы одну заглавную букву',
                                            },
                                            {
                                                pattern: /^(?=.*\d).{1,}$/,
                                                message: 'Пароль должен содержать хотя бы одну цифру',
                                            },
                                            {
                                                pattern: /^(?=.*[\W_]).{1,}$/,
                                                message: 'Пароль должен содержать хотя бы один спецсимвол',
                                            },
                                            {
                                                pattern: /^[^\u0400-\u04FF]+$/,
                                                message: 'Пароль не должен содержать кириллические символы',
                                            },
                                            { min: 8, message: 'Минимальная длина пароля — 8 символов' },
                                            { max: 64, message: 'Максимальная длина пароля — 64 символа' },
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        hasFeedback
                                    >
                                        <Input.Password
                                            className={clsx(_.elParam, 'inputPass')}
                                            maxLength={64}
                                            status={validatePasswordStatusError === 'error' ? 'error' : ''}
                                            onChange={() => {
                                                setValidatePasswordStatusError('');
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['confirmPassword']}
                                        label='Повторите пароль'
                                        rules={[
                                            { required: true },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Введенные пароли не совпадают'));
                                                },
                                            }),
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        validateTrigger='onBlur'
                                    >
                                        <Input.Password className={clsx(_.elParam, 'inputPass')} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className={clsx('captchaContainer', captchaErrorIsShown && 'captchaContainerError')}>
                                <YandexCaptcha setCaptchaToken={setCaptchaToken} resetCaptchaToken={captchaKeyState} />
                                <div className={clsx(_.captchaErrorMessage, captchaErrorIsShown && _.show)}>
                                    {captchaError}
                                </div>
                            </div>

                            <Agreement showAgreementCheckbox />

                            <Form.Item>
                                <Button width='400px' htmlType='submit'>
                                    Зарегистрироваться
                                </Button>
                            </Form.Item>

                            <div style={{ minHeight: 18 }} className={_.font16}>
                                Если вы уже зарегистрированы, авторизуйтесь <Link to='/auth'>здесь</Link>
                            </div>
                        </Form>
                    </div>

                    <div className={_.rightSide} />
                </div>
            </div>
            <div className={_.halfScreen} />
            <div className={_.halfScreen} style={{ position: 'absolute', right: 0, top: 0, maxWidth: 1000 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={_.logoWrapper}>
                        <img src={logo} alt='logo' />
                    </div>
                </div>
                <img style={{ minWidth: '100%' }} src={authPic} alt='background' />
            </div>

            <ConfirmEmailModal
                title='Подтверждение E-mail'
                text='Перейдите по ссылке в письме для подтверждения своего E-mail'
                isShown={confirmEmailIsShown}
                toggleShown={setConfirmEmailIsShown}
                resend={handleResend}
                email={values.email}
            />
        </div>
    );
};

export default AuthForm;
