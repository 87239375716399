import { FC, CSSProperties } from 'react';
import clsx from 'clsx';

import recycleIcon from 'assets/recycleIcon.svg';
import Icon from 'common/Icon/Icon';

import _ from './FileAttachmentToAddInfo.module.css';

interface IFileAttachmentToAddInfo {
    files: File[];
    setFiles: (files: File[]) => void;
    ellipsisWidth?: number | string;
    style?: CSSProperties;
}

const FileAttachmentToAddInfo: FC<IFileAttachmentToAddInfo> = ({
    files,
    setFiles,
    ellipsisWidth = 400,
    style = {},
}) => (
    <div>
        {files &&
            files.map((file: File, i) => (
                <div className={_.fileBox} style={style} key={i}>
                    <div className={clsx(_.fileName, ellipsisWidth && _.ellipsis)} style={{ width: ellipsisWidth }}>
                        {file.name}
                    </div>
                    <div
                        className={_.fileDelete}
                        onClick={() => {
                            const newFiles = [...files];
                            newFiles.splice(i, 1);
                            setFiles(newFiles);
                        }}
                    >
                        <Icon>
                            <img src={recycleIcon} alt='recycle-icon' />
                        </Icon>
                    </div>
                </div>
            ))}
    </div>
);

export default FileAttachmentToAddInfo;
