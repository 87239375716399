import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import WebinarCard from 'common/WebinarCard/WebinarCard';
import Button from 'common/Button/Button';
import { Hotline, IHotlineResponse, defaultHotlineObject } from 'connectors/query/Hotline';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { EWebinarStatus } from 'connectors/query/Webinar';

import _ from './Webinars.module.css';

const Webinars = () => {
    const navigate = useNavigate();
    const dataAuth = useContext(AuthContext);
    const { hotlineSubscr } = useContext(AuthContext);

    const limit = 6;
    const [hotlineItems, setHotlineItems] = useState<IHotlineResponse>(defaultHotlineObject);
    const getWebinarsLectures = async () => {
        const data = await Hotline.get({
            params: { limit, ordering: '-starts_at' },
            event_type: ['lecture', 'webinar'],
        });
        if (!(data?.status >= 400 && data?.status < 600) && Array.isArray(data?.results)) {
            setHotlineItems(data as IHotlineResponse);
        }
    };
    useEffect(() => {
        getWebinarsLectures();
    }, []);

    return (
        <div className={_.container}>
            <div className={_.top_content}>
                <h2 className={_.title}>Лекции и вебинары</h2>

                {/* <p className={_.descr}>
                    Предоставить равные возможности авторам и продюсерам как из ведущих кинопроизводящих регионов
                    (Москва, Санкт-Петербург), так и участникам только развивающегося регионального кинематографа.
                </p> */}
            </div>

            <div className={_.gallery}>
                {hotlineItems.results.map((t) => (
                    <div
                        key={t.id}
                        className={_.galleryBlock}
                        onClick={() => {
                            if (hotlineSubscr) {
                                dataAuth.logged && t.event_type === 'webinar' && navigate(`/webinar/${t.id}`);
                                dataAuth.logged && t.event_type === 'lecture' && navigate(`/lecture/${t.id}`);
                            }
                        }}
                    >
                        <WebinarCard
                            text={t.title}
                            type={t.event_type}
                            pic={t.preview || ''}
                            date={t.starts_at || ''}
                            archive={t.status === EWebinarStatus.ARCHIVED}
                            hasMedia={!!t.mediafile}
                        />
                    </div>
                ))}
            </div>

            <div className={_.btn}>
                <Button
                    width='350px'
                    onClick={() => {
                        navigate('/lectures');
                    }}
                >
                    Все лекции и вебинары
                </Button>
            </div>
        </div>
    );
};

export default Webinars;
