export const createImage = (src: File): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(src);
    });

export const createCanvasBase64Thumbnail = (
    img: HTMLImageElement,
    type: string,
    toWidth = 80,
    toHeight = 80,
): Promise<string> =>
    new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = toWidth;
        canvas.height = toHeight;

        if (ctx) {
            ctx.drawImage(img, 0, 0, toWidth, toHeight);
        }

        resolve(canvas.toDataURL(type));
    });
