import { useEffect, useState } from 'react';
import clsx from 'clsx';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Container from 'common/StyledElements/Container/Container';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import { Faq, IFaqData } from 'connectors/query/Faq';
import { openErrorNotification } from 'common/Notifications/Notifications';
import Top from '../../common/Top/Top';
import Question from './components/Question/Question';
import CollapseItem from './components/Collapse/Collapse';

import _ from './FaqPage.module.css';

const title = 'Q&A';

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'faq',
        breadcrumbName: title,
    },
];

const FaqPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [faqData, setFaqData] = useState<IFaqData[] | null>(null);

    const fetchAll = async () => {
        const data = await Faq.getAll({ published: true, ordering: 'ordinal' });
        data.results && setFaqData(data.results);
    };

    useEffect(() => {
        fetchAll();
    }, []);

    return (
        <>
            <Header />

            <Top title={title} />

            <Container style={{ paddingTop: 30 }} column>
                <div className={clsx(_.container, _.breadcrumb)}>
                    <Breadcrumbs routes={routes} />
                </div>

                <div className={_.main_wrapper}>
                    <div>
                        {faqData && faqData.map((q) => <CollapseItem title={q.question} text={q.answer} key={q.id} />)}
                    </div>

                    <div>
                        <Question setShowModal={setShowModal} />
                    </div>
                </div>
            </Container>

            <Footer isModalShown={showModal} setShowModalProp={setShowModal} />
        </>
    );
};

export default FaqPage;
