import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';

import Button from 'common/Button/Button';
import Container from 'common/StyledElements/Container/Container';
import Title from 'common/StyledElements/Title/Title';
import { INewsData, News } from 'connectors/query/News';
import { openErrorNotification } from 'common/Notifications/Notifications';
import NewsItem from './components/NewsItem/NewsItem';

import _ from './ServiceNews.module.css';

const ServiceNews = () => {
    const navigate = useNavigate();
    const [newsData, setNewsData] = useState<INewsData[] | null>(null);

    const fetchAll = async () => {
        const data = await News.getAll();

        if (data?.status >= 400 && data?.status <= 600) {
            Object.keys(data?.data).forEach((key) => {
                openErrorNotification('Ошибка!', data?.data[key][0]);
            });
            return;
        }
        data.results && setNewsData(data.results);
    };

    useEffect(() => {
        fetchAll();
    }, []);

    return (
        <Container column>
            <Title mb={40}>Новости сервиса</Title>

            <div className={clsx(_.newsContainer, newsData && newsData.length < 3 && _.newsContainerEvenly)}>
                {newsData && newsData.slice(-3).map((n) => <NewsItem date={n.created_at} title={n.title} key={n.id} />)}
            </div>

            <div className={_.buttonContainer}>
                <Button width='157px' marginBottom='60px' onClick={() => navigate('/news')}>
                    Все новости
                </Button>
            </div>
        </Container>
    );
};

export default ServiceNews;
