import { FC } from 'react';

import Container from 'common/StyledElements/Container/Container';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';

import _ from './TopCabinetPageName.module.css';

interface ITopCabinetPageNameProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        // path: 'cabinet/settings',
        path: 'cabinet/projects',
        breadcrumbName: 'Личный кабинет',
    },
];
// напоминалка навигация в настройки вместо проектов

const TopCabinetPageName: FC<ITopCabinetPageNameProps> = ({ children }) => (
    <Container noRound gray>
        <div className={_.wrapper}>
            <div className={_.breadcrumbs}>
                <Breadcrumbs routes={routes} />
            </div>

            {children}
        </div>
    </Container>
);

export default TopCabinetPageName;
