import { FC } from 'react';

import pic from 'assets/check.svg';
import Text from 'common/StyledElements/Text/Text';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './Option.module.css';


interface IOptionProps {
    text: string;
}

const Option: FC<IOptionProps> = ({ text }) => {
    
    let maxParticipants
    if (text.includes('Максимальное число участников: ')) {
        maxParticipants = text.split(/(Максимальное число участников: )/g)
    }


    return (
        <div className={_.wrapper}>
            <img className={_.img} src={pic} alt='check' />
            <Text small>
                {maxParticipants ? (
                    <span>
                        {maxParticipants[1]} <span className={_.maxParticipants}>{maxParticipants[2]}</span>
                    </span>
                ) : (
                    logoReplacer(text)
                )}
            </Text>
        </div>
    );
}

export default Option;
