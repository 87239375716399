import { FC } from 'react';
import Slider from 'react-slick';
import clsx from 'clsx';

import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import Title from 'common/StyledElements/Title/Title';
import Container from 'common/StyledElements/Container/Container';
import TeamItem from './components/TeamItem/TeamItem';

import _ from './Team.module.css';
import './styles.css';

interface IMember {
    id: number;
    status: boolean;
    avatar: string;
    first_name: string;
    last_name: string;
    position: string;
    description: string;
}

interface ITeamProps {
    teamData: any;
}

const Team: FC<ITeamProps> = ({ teamData }) => {
    const filteredMembers = teamData.members;
    filteredMembers.sort((a: any, b: any) => a.ordinal - b.ordinal);
    const numMembers = filteredMembers.length;
    const slidesToShow = numMembers < 3 ? numMembers : 3;

    return (
        <Container gray column>
            <Title mb={40}>{logoReplacer(teamData.title)}</Title>

            <Slider
                className={clsx(_.teamContainer, 'customSlider', 'sliderTeam')}
                slidesToShow={slidesToShow}
                infinite
                variableWidth
            >
                {Array.isArray(filteredMembers) &&
                    filteredMembers.map((member: IMember) => (
                        <div
                            key={member.id}
                            style={{ display: 'flex', width: 370, ...(numMembers < 4 ? { cursor: 'default' } : {}) }}
                        >
                            <TeamItem {...member} defaultPointer={numMembers < 4} />
                        </div>
                    ))}
            </Slider>
        </Container>
    );
};

export default Team;
