import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { Modal, Table } from 'antd';

import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';

import PersonalAccount, { IContract, IProject } from 'connectors/query/PersonalAccount';
import Paginator from 'common/Paginator/Paginator';
import { useParams } from 'react-router-dom';
import { ESubscriptionStatusTrial, ESubscriptionStatuses, ISubscription } from 'connectors/query/Subscriptions';
import {
    openErrorNotification,
    openInfoNotification,
    openSuccessNotification,
} from 'common/Notifications/Notifications';
import FindInput from 'common/FindInput/FindInput';
import projectsColumns, { IFormattedProject } from './columns';
import _ from './CopyProjectModal.module.css';
import './styles.css';

interface ICopyProjectModal {
    isModalShowing: boolean;
    closeModal: () => void;
    selectedContract: IContract | null;
}

const limit = 3;
const CopyProjectModal: FC<ICopyProjectModal> = ({ closeModal, selectedContract, isModalShowing }) => {
    const { id: projectId } = useParams();
    const [projects, setProjects] = useState<IFormattedProject[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [offsetTable, setOffsetTable] = useState<number>(0);
    const [totalProjects, setTotalProjects] = useState<number>(0);
    const [selectedProjectId, setSelectedProjectId] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        if (isModalShowing) getProjects((currentPage - 1) * limit, searchQuery);
    }, [currentPage, isModalShowing, searchQuery]);

    const copyToAnotherProject = async () => {
        if (projectId && selectedContract?.id && selectedProjectId[0]) {
            const res = await PersonalAccount.copyContractToAnotherProject(
                projectId,
                selectedContract.id,
                selectedProjectId[0],
            );
            if (res?.status && res?.status > 299) {
                openErrorNotification('Ошибка копирования договора');
            } else {
                openSuccessNotification('Договор скопирован');
                closeModalClearData();
            }
        }
    };

    const getProjects = async (offset: number, search: string) => {
        setLoadingStatus(true);
        const data = await PersonalAccount.getProjects({ limit, offset, ordering: '-updated_at', search });
        if (Array.isArray(data.results)) {
            const formattedProjects: IFormattedProject[] = (data.results as IProject[]).map((project) => {
                let lastSubscr: null | ISubscription = null;
                const filteredSubs = project.subscriptions.filter(
                    (sub) => sub.status !== ESubscriptionStatuses.Pending,
                );
                if (filteredSubs.length >= 2) {
                    lastSubscr = filteredSubs.reduce((prevSubscr, currentSubscr) =>
                        dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0 ? currentSubscr : prevSubscr,
                    );
                }
                if (filteredSubs.length === 1) {
                    [lastSubscr] = filteredSubs;
                }
                let isTrial = null;
                if (lastSubscr?.status === ESubscriptionStatuses.Active && lastSubscr.plan.is_trial) {
                    isTrial = ESubscriptionStatusTrial.Trial;
                }
                return {
                    id: project.id,
                    organizer_user: project.organizer_user,
                    createdAt: project.created_at,
                    name: project.name,
                    activeTo: lastSubscr?.ends_at || null,
                    organizer_organization: project.organizer_organization,
                    module: lastSubscr?.plan.product.name || null,
                    status: isTrial || lastSubscr?.status || null,
                };
            });
            setProjects(formattedProjects);
            setTotalProjects(data.count);
        }
        setLoadingStatus(false);
        setOffsetTable((currentPage - 1) * limit);
    };

    const closeModalClearData = () => {
        closeModal();
        setSelectedProjectId([]);
        setProjects([]);
        setCurrentPage(1);
        setLoadingStatus(false);
        setOffsetTable(0);
        setTotalProjects(0);
        setSearchQuery('');
    };

    return (
        <Modal
            className={_.modalCustom}
            open={isModalShowing}
            onCancel={closeModalClearData}
            width='1100px'
            footer={null}
            centered
            destroyOnClose
        >
            <div className={_.modalWrapper}>
                <div className={_.modalContainer}>
                    <div className={_.titleInvite}>
                        <Title mb={30} width={850}>
                            {`Выберите проект, в который хотите скопировать договор ${selectedContract?.name}`}
                        </Title>
                    </div>
                    <div className={_.tableWrapper}>
                        <div className={_.topInputBlock}>
                            <FindInput placeholder='Поиск по названию проекта' setSearch={setSearchQuery} />
                        </div>
                        <Table
                            className='copyProjectTable'
                            columns={projectsColumns(offsetTable)}
                            rowKey='id'
                            dataSource={projects}
                            pagination={false}
                            loading={loadingStatus}
                            rowSelection={{
                                type: 'radio',
                                selectedRowKeys: selectedProjectId,
                                onSelect: (pr) => {
                                    setSelectedProjectId([pr.id]);
                                },
                            }}
                            onRow={(pr) => ({
                                onClick: () => {
                                    console.log(String(pr.id), projectId);
                                    if (String(pr.id) === projectId) {
                                        openInfoNotification('Выберите проект, отличный от текущего');
                                        return;
                                    }
                                    if (
                                        pr.status !== ESubscriptionStatuses.Active &&
                                        pr.status !== ESubscriptionStatusTrial.Trial
                                    ) {
                                        openInfoNotification(
                                            'Для копирования в этот проект необходимо оплатить подписку',
                                        );
                                        return;
                                    }
                                    setSelectedProjectId([pr.id]);
                                },
                            })}
                            rowClassName={(pr) =>
                                (pr.status !== ESubscriptionStatuses.Active &&
                                    pr.status !== ESubscriptionStatusTrial.Trial) ||
                                String(pr.id) === projectId
                                    ? 'disabledRow'
                                    : ''
                            }
                        />
                        <Paginator
                            maxPages={Math.ceil(totalProjects / limit)}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                    <div className={_.btnsCopyProjectModal}>
                        <Button
                            htmlType='button'
                            width='250px'
                            onClick={copyToAnotherProject}
                            disabled={selectedProjectId.length < 1}
                        >
                            Скопировать
                        </Button>
                        <Button onClick={closeModalClearData} htmlType='submit' width='250px'>
                            Отмена
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CopyProjectModal;
