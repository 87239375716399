import { APIService } from 'connectors/api/rest';

export interface ICaldendarEventGetParams {
    ordering?: 'starts_at';
    limit?: number;
}

export interface ICaldendarEventItem {
    id: number;
    event_type: 'event' | 'webinar' | 'lecture';
    created_at: string;
    updated_at: string;
    published: boolean;
    published_at: string;
    scheduled_publication_at: string;
    title: string;
    starts_at: string;
    ends_at: string | null;
}

export interface ICaldendarEventItemResponse {
    count: number;
    results: ICaldendarEventItem[];
}

export const defaultCaldendarEventObject: ICaldendarEventItemResponse = {
    count: 0,
    results: [],
};

export const CaldendarEvent = {
    async getAll(params?: ICaldendarEventGetParams) {
        try {
            return await APIService.GET('/hotline/event/', params);
        } catch (e) {
            return e;
        }
    },
};
