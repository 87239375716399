import { useRef, useState, useEffect, FC } from 'react';
import Modal from 'antd/es/modal/Modal';
import Input, { InputRef } from 'antd/es/input/Input';
import { Button as AntdButton } from 'antd';

import Button from 'common/Button/Button';

import clsx from 'clsx';
import _ from './SmsModal.module.css';

interface ISmsModalShown {
    isShown: boolean;
    toggleShown: (is: boolean) => void;
}

const SmsModal: FC<ISmsModalShown> = ({ isShown, toggleShown }) => {
    const inputRef = [
        useRef<InputRef>(null),
        useRef<InputRef>(null),
        useRef<InputRef>(null),
        useRef<InputRef>(null),
        useRef<InputRef>(null),
        useRef<InputRef>(null),
    ];

    const [codeValues, setCodeValues] = useState('');
    const [isError, setIsError] = useState(false);
    const [attempts, setAttempts] = useState(4);

    const focusInput = () => {
        const { length } = codeValues;
        if (length < 6) {
            inputRef[length].current?.focus();
            setIsError(false);

            return;
        }
        inputRef[5].current?.focus();
        setIsError(false);
    };

    const changeInput = (newValueDigit: string) => {
        if (/^-?\d+$/.test(newValueDigit)) {
            const newValue = codeValues + newValueDigit;

            if (newValue.length < 7) {
                setCodeValues(newValue);
            }
        }
    };
    const deleteEventHandler = () => {
        setCodeValues(codeValues.slice(0, codeValues.length - 1));
    };

    const renderInputDigital = () => {
        const array = [];
        for (let i = 0; i < 6; i += 1) {
            array.push(
                <div key={i} className={_.smsCodeDigit}>
                    <Input
                        className={clsx(
                            isError ? _.errorInput : undefined,
                            i > codeValues.length ? _.greyDigital : null,
                            i === codeValues.length || (codeValues.length === 6 && i === 5) ? _.focusedInput : null,
                        )}
                        ref={inputRef[i]}
                        maxLength={1}
                        value={codeValues[i] || ''}
                        onFocus={() => {
                            focusInput();
                        }}
                        onChange={(e) => {
                            changeInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.code === 'Backspace') {
                                deleteEventHandler();
                            }
                        }}
                    />
                </div>,
            );
        }

        return array;
    };

    useEffect(() => {
        focusInput();
    }, [codeValues]);

    useEffect(() => {
        setIsError(false);
        setAttempts(4);
        setCodeValues('');
        if (isShown) setTimeout(focusInput, 0);
    }, [isShown]);

    return (
        <Modal
            className={_.modalCustom}
            open={isShown}
            onOk={() => {
                setIsError(true);
                setAttempts(3);
            }}
            onCancel={() => {
                toggleShown(false);
            }}
            width='700px'
            footer={null}
            centered
        >
            <div className={_.wrapper}>
                <div className={_.container}>
                    <div className={_.enterCodeText}>Введите код из СМС</div>
                    <div>
                        <Input value='+7 (996) 000-00-00' className={_.phoneNumber} />
                        <AntdButton className={_.changeButton} type='link'>
                            Изменить
                        </AntdButton>
                    </div>
                    <div className={_.smsCode}>{renderInputDigital()}</div>
                    <div className={clsx(_.error, !isError ? _.hideError : null)}>Введен неверный код</div>
                    <div>
                        <span>Не пришло СМС?</span>
                        <AntdButton className={_.sendCodeAgain} type='link'>
                            Отправить код еще раз
                        </AntdButton>
                    </div>
                    <div className={clsx(_.attempts, attempts > 3 ? _.hideAttempts : null)}>Осталось 3 попытки</div>
                    <div className={_.footer}>
                        <Button
                            width='200px'
                            key='back'
                            grey
                            onClick={() => {
                                setIsError(false);
                                setAttempts(4);
                                toggleShown(false);
                            }}
                        >
                            Отменить
                        </Button>
                        <Button
                            width='200px'
                            key='submit'
                            onClick={() => {
                                setIsError(true);
                                setAttempts(3);
                            }}
                        >
                            Подтвердить
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SmsModal;
