import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FormInstance } from 'antd';

import ProjectName from './components/ProjectName/ProjectName';
import AuthorsAndDirectors from './components/AuthorsAndDirectors/AuthorsAndDirectors';
import InfoTypeFilm from './components/InfoTypeFilm/InfoTypeFilm';
import CheckBlock from './components/CheckBlock/CheckBlock';
import MainBlock from './components/MainBlock/MainBlock';
import GenreBlock from './components/GenreBlock/GenreBlock';
import SerialBlock from './components/SerialBlock/SerialBlock';
import TimingBlock from './components/TimingBlock/TimingBlock';
import LanguageBlock from './components/LanguageBlock/LanguageBlock';
import ColorBlock from './components/ColorBlock/ColorBlock';
import CertificateBlock from './components/CertificateBlock/CertificateBlock';
import InformationCarrier from './components/InformationCarrier/InformationCarrier';

interface IProjectCharacteristics {
    data: any;
    onInputChange: (key: string, value: string | null | boolean) => void;
    setData: (i: any) => void;
    value?: Dayjs;
    onChange?: () => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    isSubscrActiveOrCreate: boolean;
    maxAuthorEntities: number;
    numberOfEntities: { authors: number; directors: number };
    setNumberOfEntities: (
        newVal:
            | { authors: number; directors: number }
            | ((prevVal: { authors: number; directors: number }) => { authors: number; directors: number }),
    ) => void;
    setHasError: (error: boolean) => void;
    form: FormInstance;
    setCustomGenres: React.Dispatch<React.SetStateAction<string[]>>;
    setCheckedGenres: React.Dispatch<React.SetStateAction<string[]>>;
    customGenres: string[];
    checkedGenres: string[];
    checkedColors: string[];
    setCheckedColors: React.Dispatch<React.SetStateAction<string[]>>;
    colorInput: string;
    setColorInput: React.Dispatch<React.SetStateAction<string>>;
    showOtherLanguageInput: boolean;
    setShowOtherLanguageInput: React.Dispatch<React.SetStateAction<boolean>>;
    customCarrierValue: string;
    setCustomCarrierValue: React.Dispatch<React.SetStateAction<string>>;
    serialCount: number;
    setSerialCount: React.Dispatch<React.SetStateAction<number>>;
    activeSeriesRadioEpisodeTiming: string;
    setActiveSeriesRadioEpisodeTiming: React.Dispatch<React.SetStateAction<string>>;
}

const ProjectCharacteristics: React.FC<IProjectCharacteristics> = ({
    onInputChange,
    onChange,
    onDateChange,
    setData,
    value,
    data,
    isSubscrActiveOrCreate,
    maxAuthorEntities,
    numberOfEntities,
    setNumberOfEntities,
    setHasError,
    form,
    setCustomGenres,
    setCheckedGenres,
    customGenres,
    checkedGenres,
    checkedColors,
    setCheckedColors,
    colorInput,
    setColorInput,
    showOtherLanguageInput,
    setShowOtherLanguageInput,
    customCarrierValue,
    setCustomCarrierValue,
    serialCount,
    setSerialCount,
    activeSeriesRadioEpisodeTiming,
    setActiveSeriesRadioEpisodeTiming,
}) => {
    useEffect(() => {
        if (data?.product_name === 'Неигровой фильм') {
            form.setFieldsValue({ film_type: 'not_game' });
        } else {
            form.setFieldsValue({ film_type: 'game' });
        }

        form.setFieldsValue({ film_view_type: 'documentary', film_format: 'feature_length' });

        if (data) {
            const dataNew = { ...data };
            if (dataNew.certificate_date) {
                // @ts-ignore
                dataNew.certificate_date = dayjs(dataNew.certificate_date, 'DD.MM.YYYY');
            } else {
                // @ts-ignore
                dataNew.certificate_date = null;
            }

            form.setFieldsValue(dataNew);
        }
    }, [JSON.stringify(data)]);

    return (
        <>
            <div className='title'>Характеристика проекта</div>

            <div className='main'>
                <MainBlock
                    data={data}
                    form={form}
                    setData={setData}
                    onInputChange={onInputChange}
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                />
                <AuthorsAndDirectors
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    onInputChange={onInputChange}
                    maxAuthorEntities={maxAuthorEntities}
                    numberOfEntities={numberOfEntities}
                    setNumberOfEntities={setNumberOfEntities}
                    setData={setData}
                />
                <InfoTypeFilm
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    setData={setData}
                />
                <GenreBlock
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    setData={setData}
                    onInputChange={onInputChange}
                    setHasError={setHasError}
                    customGenres={customGenres}
                    setCustomGenres={setCustomGenres}
                    checkedGenres={checkedGenres}
                    setCheckedGenres={setCheckedGenres}
                />
                {data.film_format === 'multi_episode' && (
                    <SerialBlock
                        isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                        data={data}
                        form={form}
                        setData={setData}
                        onInputChange={onInputChange}
                        serialCount={serialCount}
                        setSerialCount={setSerialCount}
                        activeSeriesRadioEpisodeTiming={activeSeriesRadioEpisodeTiming}
                        setActiveSeriesRadioEpisodeTiming={setActiveSeriesRadioEpisodeTiming}
                    />
                )}
                {(data.film_format === 'feature_length' || data.film_format === 'short_film') && (
                    <TimingBlock
                        isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                        data={data}
                        form={form}
                        setData={setData}
                        onInputChange={onInputChange}
                    />
                )}
                <InformationCarrier
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    setData={setData}
                    onInputChange={onInputChange}
                    customCarrierValue={customCarrierValue}
                    setCustomCarrierValue={setCustomCarrierValue}
                />
                <LanguageBlock
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    setData={setData}
                    onInputChange={onInputChange}
                    showOtherLanguageInput={showOtherLanguageInput}
                    setShowOtherLanguageInput={setShowOtherLanguageInput}
                />
                <ColorBlock
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    setData={setData}
                    onInputChange={onInputChange}
                    checkedColors={checkedColors}
                    setCheckedColors={setCheckedColors}
                    colorInput={colorInput}
                    setColorInput={setColorInput}
                />
                <CertificateBlock
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                    data={data}
                    form={form}
                    setData={setData}
                    onInputChange={onInputChange}
                    onDateChange={onDateChange}
                />

                {/* <CheckBlock value={value} data={data} onChange={onChange} onInputChange={onInputChange} setData={setData} /> */}
            </div>
        </>
    );
};

export default ProjectCharacteristics;
