import { FC, useEffect } from 'react';

import { Form, FormInstance, Input } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import validationRules from 'utils/formValidationRules';

interface IAddressProps {
    type?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    onChange?: () => void;
    onInputChange: (key: string, value: string | null) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

const StepFourAddress: FC<IAddressProps> = ({ form, onInputChange, type, isSubscrActiveOrCreate }) => (
    <div className='main'>
        <div className='blockCard'>
            <Form form={form} name='org_form' layout='vertical'>
                <div className='titleCard'>Адреса и телефоны</div>

                <MyFormItem name='legal_address' label={type === 'UL' ? 'Юридический адрес' : 'Адрес регистрации'}>
                    <Input
                        className='inputInner'
                        onChange={(event) => onInputChange('legal_address', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='physical_address'
                    label={
                        type === 'UL' ? 'Адрес места нахождения юридического лица (фактический)' : 'Фактический адрес'
                    }
                >
                    <Input
                        className='inputInner'
                        onChange={(event) => onInputChange('physical_address', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>

                <MyFormItem
                    name='correspondence_address'
                    label={type === 'UL' ? 'Адрес для корреспонденции' : 'Почтовый адрес'}
                >
                    <Input
                        className='inputInner'
                        onChange={(event) => onInputChange('correspondence_address', event.target.value)}
                        maxLength={256}
                        disabled={!isSubscrActiveOrCreate}
                    />
                </MyFormItem>
                <div className='shortInnersBlock'>
                    <MyFormItem name='email' label='E-mail' rules={[validationRules.emailValitationFunction]}>
                        <Input
                            className='inputInner inputInnerShort2'
                            onChange={(event) => onInputChange('email', event.target.value)}
                            maxLength={64}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>

                    <MyFormItem name='phone' label='Телефон'>
                        <Input
                            className='inputInner inputInnerShort2'
                            onChange={(event) => onInputChange('phone', event.target.value)}
                            maxLength={10}
                            disabled={!isSubscrActiveOrCreate}
                        />
                    </MyFormItem>
                </div>
            </Form>
        </div>
    </div>
);

export default StepFourAddress;
