import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Title from 'common/StyledElements/Title/Title';
import TopPageName from 'common/TopCabinetPageName/TopCabinetPageName';

import Tabs from './components/Tabs/Tabs';
import Projects from './components/Projects/Projects';
import Settings from './components/Settings/Settings';
import People from './components/People/People';
import Partners from './components/Partners/Partners';
import AddProject from './components/AddProject/AddProject';
import Contragents from './components/Contragents/Contragents';
import CreateProject from './components/CreateProject/CreateProject';
import Threads from './components/Threads/Threads';
import Correspondence from './components/Correspondence/Correspondence';
import CreateMessage from './components/CreateThread/CreateThread';
import Payment from './components/Payment/Payment';
import Orders from './components/Orders/Orders';

const returnName = (page: string) => {
    const NAMES = [
        {
            title: 'Новый проект',
            page: 'create',
        },
        {
            title: 'Редактирование проекта',
            page: 'edit-project',
        },
        {
            title: 'Редактирование контрагента',
            page: 'edit-contragent',
        },
        {
            title: 'Название версии',
            page: 'project-properties',
        },
        {
            title: 'Личный кабинет',
            page: 'projects',
        },
        {
            title: 'Личный кабинет',
            page: 'people',
        },
        {
            title: 'Личный кабинет',
            page: 'settings',
        },
        {
            title: 'Личный кабинет',
            page: 'partners',
        },
        {
            title: 'Личный кабинет',
            page: 'contragents',
        },
        {
            title: 'Личный кабинет',
            page: 'contragent',
        },
        {
            title: 'Личный кабинет',
            page: 'messages',
        },
        {
            title: 'Личный кабинет',
            page: 'correspondence',
        },
        {
            title: 'Создать сообщение',
            page: 'create-message',
        },
        {
            title: 'Личный кабинет',
            page: 'payment',
        },
        {
            title: 'Личный кабинет',
            page: 'orders',
        },
    ];
    const b = NAMES.find((name) => name.page === page);
    return b?.title || '';
};

const CabinetPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const { id } = useParams();
    const defaultRoute = location.pathname.split('/')[2] || 'projects';
    // напоминалка навигация в настройки вместо проектов
    const isAddProject = location.pathname.split('/')[2] === 'projects' && location.pathname.split('/')[3] === 'add';
    const isEditProjectProperties =
        location.pathname.split('/')[2] === 'projects' && location.pathname.split('/')[4] === 'properties';
    const isEditOrganizationProperties =
        (location.pathname.split('/')[2] === 'projects' &&
            location.pathname.split('/')[4] === 'organizations' &&
            location.pathname.split('/')[6] === 'details') ||
        (location.pathname.split('/')[2] === 'organizations' && location.pathname.split('/')[4] === 'details');
    const isAddContragent =
        location.pathname.split('/')[2] === 'contragent' && location.pathname.split('/')[3] === 'add';

    const [page, setPage] = useState(defaultRoute);
    const [title, setTitle] = useState('');

    const changePage = (newPage: string) => {
        setPage(newPage);
        navigate(`/cabinet/${newPage}`);
    };

    useEffect(() => {
        setPage(defaultRoute);
    }, [location]);
    useEffect(() => {
        if (!(page === 'projects' && isEditProjectProperties)) {
            setTitle(returnName(page));
        } else {
            setTitle('...');
        }
    }, [page]);

    // console.log('page === projects', page === 'projects');

    return (
        <>
            <Header />

            <TopPageName>
                <Title mb={50} main>
                    {title}
                </Title>

                {!isEditProjectProperties && !isEditOrganizationProperties && page !== 'create' && (
                    <div>
                        <Tabs onChange={changePage} defaultRoute={defaultRoute} />
                    </div>
                )}
            </TopPageName>

            {!isAddProject && page === 'projects' && !isEditProjectProperties && !isEditOrganizationProperties && (
                <Projects />
            )}
            {!isAddProject &&
                page === 'projects' &&
                isEditProjectProperties &&
                !Number.isNaN(location.pathname.split('/')[3]) &&
                !Number.isNaN(location.pathname.split('/')[5]) && (
                    <CreateProject
                        projectIdForEdit={Number(location.pathname.split('/')[3])}
                        projectPropertiesIdForEdit={Number(location.pathname.split('/')[5])}
                        projectIdForReturnAction={Number(location.pathname.split('/')[3])}
                        setTitle={setTitle}
                    />
                )}
            {!isAddProject && page === 'settings' && <Settings />}
            {!isAddProject && page === 'people' && <People />}
            {!isAddProject && page === 'partners' && <Partners />}
            {!isAddProject && page === 'contragents' && <Contragents />}
            {!isAddContragent &&
                page === 'projects' &&
                isEditOrganizationProperties &&
                !Number.isNaN(location.pathname.split('/')[3]) &&
                !Number.isNaN(location.pathname.split('/')[5]) &&
                !Number.isNaN(location.pathname.split('/')[7]) && (
                    <CreateProject
                        orgIdForEdit={Number(location.pathname.split('/')[5])}
                        orgPropertiesIdForEdit={Number(location.pathname.split('/')[7])}
                        projectIdForReturnAction={Number(location.pathname.split('/')[3])}
                        setTitle={setTitle}
                    />
                )}
            {!isAddContragent &&
                page === 'organizations' &&
                isEditOrganizationProperties &&
                !Number.isNaN(location.pathname.split('/')[3]) &&
                !Number.isNaN(location.pathname.split('/')[5]) && (
                    <CreateProject
                        orgIdForEdit={Number(location.pathname.split('/')[3])}
                        orgPropertiesIdForEdit={Number(location.pathname.split('/')[5])}
                        setTitle={setTitle}
                        returnToOrgs
                    />
                )}

            {!isAddProject && page === 'correspondence' && <Correspondence />}
            {!isAddProject && page === 'create-message' && <CreateMessage />}
            {!isAddProject && page === 'create' && <CreateProject />}
            {/* {!isAddProject && page === 'edit-project' && !Number.isNaN(id) && (
                <CreateProject projectIdForEdit={Number(id)} />
            )} */}
            {!isAddProject && page === 'messages' && <Threads />}
            {!isAddProject && page === 'payment' && <Payment />}
            {!isAddProject && page === 'orders' && <Orders />}

            {isAddProject && <AddProject />}

            <Footer />
        </>
    );
};

export default CabinetPage;
