import { APIService } from 'connectors/api/rest';

export interface IAttachment {
    attachment_url: string;
    attachment_filename: string;
    size: number;
    extension: string;
    description: string;
}

export enum EWebinarStatus {
    NEW = 'н',
    SCHEDULED = 'з',
    ACTIVE = 'а',
    ARCHIVED = 'р',
}

export interface IWebinarData {
    id: number;
    published: boolean;
    event_type: string;
    published_at: string;
    title: string;
    description: string;
    mediafile: string;
    preview: string;
    attachments: IAttachment[];
    starts_at: string;
    ends_at: string;
    speaker: string;
    status: EWebinarStatus;
}

export interface IProofixInfo {
    companyCode: string;
    eventCode: string;
    eventId: number;
}

export const Webinar = {
    async getAll(params: any) {
        try {
            return await APIService.GET('/hotline/webinar/', { published: true, ...params });
        } catch (e) {
            return e;
        }
    },
    async getById(id: string | number) {
        try {
            return await APIService.GET(`/hotline/webinar/${id}`);
        } catch (e) {
            return e;
        }
    },
    async create(data: IWebinarData) {
        try {
            return await APIService.POST('/hotline/webinar/', data);
        } catch (e) {
            return e;
        }
    },
    async update(id: string | number, data: IWebinarData) {
        try {
            return await APIService.PUT(`/hotline/webinar/${id}/`, data);
        } catch (e) {
            return e;
        }
    },
};
