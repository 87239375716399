import { Select, Popover, Space } from 'antd';
import { FC } from 'react';
import { InfoOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { IModule } from 'connectors/query/Subscriptions';

import _ from './CardStepOne.module.css';

const content = (
    <div>
        <p>Информация</p>
    </div>
);

interface ICardType {
    module: IModule;
    data: any;
    setData: (i: any) => void;
}

const CardStepOne: FC<ICardType> = ({ module, data, setData }) => (
    <div
        className={clsx(_.cardItem, data?.moduleId === module.id ? _.cardActive : undefined)}
        onClick={() => {
            setData({
                ...data,
                moduleId: module.id,
                product_name: module.name,
                film_type: module.name === 'Неигровой фильм' ? 'not_game' : 'game',
            });
        }}
    >
        <div className={_.cardTop}>
            <div className={_.cardTitle}>{module.name}</div>

            <div className={_.cardPrice}>
                {`от ${Math.min(...module.plans.map((plan) => Number(plan.price))).toLocaleString()} ₽`}
            </div>
        </div>

        {/* <Space wrap>
            <Popover placement='topLeft' content={content}>
                <div className={_.infoBlock}>
                    <InfoOutlined />
                </div>
            </Popover>
        </Space> */}

        {/* <div className={_.cardBot}>
                <div className={_.cardSelect}>
                    Выберите тариф
                    <Select
                        onClick={(e) => e.stopPropagation()}
                        className={_.select}
                        style={{ width: 350 }}
                        defaultValue={0}
                        onChange={(tariff) => {
                            setData({ ...data, tariff, title, price });
                        }}
                        options={[
                            {
                                value: 0,
                                label: 'Базовый',
                            },
                            {
                                value: 1,
                                label: 'Оптимальный',
                            },
                        ]}
                    />
                </div>
            </div> */}
    </div>
);

export default CardStepOne;
