import { useState } from 'react';
import { Table } from 'antd';

import Button from 'common/Button/Button';
import FindInput from 'common/FindInput/FindInput';
import Container from 'common/StyledElements/Container/Container';
import Pagination from 'common/PaginatorMock/PaginatorMock';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import { tariffsColumns, hotLineColumns } from './columns';

import { tariffs, hotLinePayments } from './mock';
import _ from './Orders.module.css';
import './styles.css';

const Orders = () => {
    const [tab, setTab] = useState<'tariffs' | 'hotLine'>('tariffs');

    return (
        <Container style={{ paddingTop: 50 }} column>
            <div className={_.top_buttons}>
                <Button
                    style={tab === 'tariffs' ? { borderColor: '#1677ff' } : {}}
                    grey2
                    width='auto'
                    marginRight='20px'
                    onClick={() => {
                        setTab('tariffs');
                    }}
                >
                    Цены
                </Button>
                <Button
                    style={{
                        ...(tab === 'hotLine' && { borderColor: '#1677ff' }),
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                    grey2
                    width='auto'
                    marginRight='20px'
                    onClick={() => {
                        setTab('hotLine');
                    }}
                >
                    Горячая линия
                </Button>
            </div>

            {tab === 'hotLine' && (
                <div className={_.hotLineExtend}>
                    <div>
                        <div>
                            <Title mb={15} small>
                                Подписка “Горячая линия”
                            </Title>
                        </div>
                        <div>
                            <Text mb={25} mid>
                                действует до: 01.03.2023 00:00
                            </Text>
                        </div>
                        <Button width='290px'>Продлить</Button>
                    </div>
                </div>
            )}

            <div className={_.top_FindInputs}>
                <div className={_.top_FindInput_item}>
                    <FindInput mb={30} placeholder='Поиск по заказам' />
                </div>
            </div>

            {tab === 'tariffs' && (
                <Table className='ordersTable' columns={tariffsColumns()} dataSource={tariffs} pagination={false} />
            )}
            {tab === 'hotLine' && (
                <Table
                    className='ordersTable'
                    columns={hotLineColumns()}
                    dataSource={hotLinePayments}
                    pagination={false}
                />
            )}
            <Pagination />
        </Container>
    );
};

export default Orders;
