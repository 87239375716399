import AskForm from 'common/AskForm/AskForm';
import question from 'assets/question.png';

import _ from './AskSpec.module.css';

const titleOptions = [
    { value: 'Календарь событий', label: 'Календарь событий' },
    { value: 'Лекции', label: 'Лекции' },
    { value: 'Вебинары', label: 'Вебинары' },
    { value: 'Предложить тему', label: 'Предложить тему' },
    { value: 'Правовой вопрос', label: 'Правовой вопрос' },
    { value: 'Другое', label: 'Другое' },
];

const AskSpec = () => (
    <div className={_.container}>
        <div className={_.blockInner}>
            <h2 className={_.title}>ЗАДАТЬ ВОПРОС</h2>

            <div className={_.blockRightImg}>
                <img src={question} alt='question' />
            </div>
        </div>

        <AskForm titleOptions={titleOptions} />
    </div>
);

export default AskSpec;
