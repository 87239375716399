import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';

import Paginator from 'common/Paginator/Paginator';
import WebinarCard from 'common/WebinarCard/WebinarCard';
import FindInput from 'common/FindInput/FindInput';
import Loader from 'common/Loader/Loader';
import { Hotline, IHotlineResponse, defaultHotlineObject } from 'connectors/query/Hotline';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { EWebinarStatus } from 'connectors/query/Webinar';

import _ from './LecturesBlock.module.css';

type OrderingType = '-starts_at' | 'starts_at' | 'title' | '-title';
type sortByType = 'starts_at' | 'title';

const LecturesBlock = () => {
    const navigate = useNavigate();
    const dataAuth = useContext(AuthContext);
    const limit = 12;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [ordering, setOrdering] = useState<OrderingType>('-starts_at');
    const [sortBy, setSortBy] = useState<sortByType>('starts_at');
    const [search, setSearch] = useState<string>('');
    const [eventType, setEventType] = useState<'Категория' | 'lecture' | 'webinar'>('Категория');
    const [rubric, setRubric] = useState<'Рубрика' | number>('Рубрика');
    const [rubricOptions, setRubricOptions] = useState<{ value: number; label: string }[]>([]);
    const [year, setYear] = useState<'Год' | '2021' | '2022' | '2023'>('Год');
    const [hotlineItems, setHotlineItems] = useState<IHotlineResponse>(defaultHotlineObject);

    useEffect(() => {
        getRubricOptions();
    }, []);

    useEffect(() => {
        getWebinarsLectures();
    }, [currentPage, ordering, search, rubric, year, eventType]);

    const getRubricOptions = async () => {
        const data = await Hotline.getEventCategory();
        if (Array.isArray(data?.results)) {
            setRubricOptions(
                data.results.map((rubric: { id: number; name: string }) => ({ value: rubric.id, label: rubric.name })),
            );
        }
    };

    const getWebinarsLectures = async () => {
        setIsLoading(true);
        const data = await Hotline.get({
            params: {
                offset: (currentPage - 1) * limit,
                limit,
                ordering,
                search,
                ...(year !== 'Год' ? { starts_at_gte: `${year}-01-01`, starts_at_lte: `${year}-12-31` } : {}),
                ...(rubric !== 'Рубрика' ? { category: rubric } : {}),
            },
            ...(eventType !== 'Категория'
                ? { event_type: eventType }
                : {
                      event_type: ['webinar', 'lecture'],
                  }),
        });
        if (!(data?.status >= 400 && data?.status < 600) && Array.isArray(data?.results)) {
            setHotlineItems(data as IHotlineResponse);
        }
        setIsLoading(false);
    };

    const sortSelectAction = (value: sortByType): void => {
        setCurrentPage(1);
        setSortBy(value);
        if (value === 'starts_at') {
            ordering === '-starts_at' ? setOrdering('starts_at') : setOrdering('-starts_at');
            return;
        }
        ordering === 'title' ? setOrdering('-title') : setOrdering('title');
    };

    return (
        <>
            <div className={_.search}>
                <FindInput setSearch={setSearch} placeholder='Введите название' />
            </div>

            <div className={_.filters}>
                <div className={_.filters__item}>
                    <Select
                        className={_.select}
                        style={{ width: 350 }}
                        value={eventType}
                        options={[
                            {
                                value: 'lecture',
                                label: 'Лекция',
                            },
                            {
                                value: 'webinar',
                                label: 'Вебинар',
                            },
                        ]}
                        onSelect={(value) => {
                            setEventType(value);
                            setCurrentPage(1);
                        }}
                        onClear={() => {
                            setEventType('Категория');
                            setCurrentPage(1);
                        }}
                        allowClear
                    />
                </div>
                {/* <div className={_.filters__item}>
                    <Select
                        className={_.select}
                        style={{ width: 350 }}
                        value={rubric}
                        options={rubricOptions}
                        onSelect={(value) => {
                            setRubric(value);
                            setCurrentPage(1);
                        }}
                        onClear={() => {
                            setRubric('Рубрика');
                            setCurrentPage(1);
                        }}
                        allowClear
                    />
                </div> */}
                <div className={_.filters__item}>
                    <Select
                        className={_.select}
                        style={{ width: 350 }}
                        value={year}
                        options={[
                            {
                                value: '2023',
                                label: '2023',
                            },
                        ]}
                        onSelect={(value) => {
                            setYear(value);
                            setCurrentPage(1);
                        }}
                        onClear={() => {
                            setYear('Год');
                            setCurrentPage(1);
                        }}
                        allowClear
                    />
                </div>
                <div className={_.filters__item}>
                    <Select
                        className={_.select}
                        style={{ width: 350 }}
                        value={sortBy}
                        onSelect={sortSelectAction}
                        options={[
                            {
                                value: 'starts_at',
                                label: 'По дате',
                            },
                            {
                                value: 'title',
                                label: 'По названию',
                            },
                        ]}
                    />
                </div>
            </div>

            <div className={_.gallery}>
                {hotlineItems.results.map((t, i) => (
                    <div
                        key={t.id}
                        className={_.galleryBlock}
                        style={{ display: 'flex', width: 350 }}
                        onClick={() => {
                            if (dataAuth.hotlineSubscr) {
                                dataAuth.logged && t.event_type === 'webinar' && navigate(`/webinar/${t.id}`);
                                dataAuth.logged && t.event_type === 'lecture' && navigate(`/lecture/${t.id}`);
                            }
                        }}
                    >
                        <WebinarCard
                            text={t.title}
                            type={t.event_type}
                            pic={t.preview || ''}
                            date={t.starts_at}
                            hasMedia={!!t.mediafile}
                            archive={t.status === EWebinarStatus.ARCHIVED}
                        />
                    </div>
                ))}
            </div>

            <Paginator
                currentPage={currentPage}
                maxPages={Math.ceil(hotlineItems.count / limit)}
                setCurrentPage={setCurrentPage}
            />
            {isLoading && <Loader />}
        </>
    );
};
export default LecturesBlock;
