import { useState, useEffect } from 'react';

import { Hotline, IHotlineItem } from 'connectors/query/Hotline';
import noEvents from 'assets/events.svg';
import Header from '../Header/Header';
import Row from './components/Row/Row';

import _ from './Events.module.css';

const Events = () => {
    const [events, setEvents] = useState<IHotlineItem[]>([]);

    const getEvents = async () => {
        const today = new Date();
        const data = await Hotline.get({
            event_type: 'event',
            params: {
                ordering: 'starts_at',
                limit: 3,
                starts_at_gte: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
            },
        });
        if (Array.isArray(data?.results)) {
            setEvents(data?.results);
        }
    };
    useEffect(() => {
        getEvents();
    }, []);

    if (events.length === 0) {
        return (
            <div className={_.wrapper}>
                <Header title='События' link='Все события' linkRoute='/practice' />
                <div className={_.noEvents}>
                    {/* <img src={noEvents} alt='No events' /> */}
                    <p className={_.noEventsTitle}>Событий пока что нет</p>
                </div>
            </div>
        );
    }

    return (
        <div className={_.wrapper}>
            <Header title='События' link='Все события' linkRoute='/practice' />

            {events.map((e) => (
                <Row key={e.id} {...e} isLast={e === events[events.length - 1]} />
            ))}
        </div>
    );
};

export default Events;
