/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
import { FC, useEffect, useRef, useState } from 'react';
// @ts-ignore
import Html2Pdf from 'html2pdf.js';
import clsx from 'clsx';
import { Button as ButtonAntd, Input, Modal, Select, Tooltip } from 'antd';
import ReactQuill from 'react-quill';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Container from 'common/StyledElements/Container/Container';
import Text from 'common/StyledElements/Text/Text';
import TopPageName from 'common/TopCabinetPageName/TopCabinetPageName';

import {
    ContractDesigner,
    IContractElement,
    IQuestion,
    ITemplate,
    ITemplateFull,
    IVar,
    IVarFree,
} from 'connectors/query/ContractDesigner';
import { useFetcher, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    openErrorNotification,
    openInfoNotification,
    openSuccessNotification,
} from 'common/Notifications/Notifications';

import BackButton from 'common/BackButton/BackButton';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import PersonalAccount, { IContract, IProject } from 'connectors/query/PersonalAccount';
import dayjs from 'dayjs';
import { ESubscriptionStatuses } from 'connectors/query/Subscriptions';
import Loader from 'common/Loader/Loader';
import { Organizations } from 'connectors/query/Organizations';
import Barcode from './Barcode';
import { flatProjectVars, download } from './editorUtils';
import _ from './EditorPage.module.css';
import 'react-quill/dist/quill.snow.css';
import './quillStyles.css';

interface IQuestionWA extends IQuestion {
    selectedAnswer?: { id: number; answer: string };
}

interface IVarFreeWV extends IVarFree {
    value?: string;
    show?: boolean;
    elementIds?: number[];
}

interface IVarPropertiesWV extends IVar {
    value?: string;
    show?: boolean;
}

interface IEditorPage {
    mode: 'create' | 'view' | 'edit';
}
interface IMissingProperty {
    model: 'projectcharacteristic' | 'organizationcharacteristic';
    tag: string;
    field: string;
    fieldName: string;
}

const saveContractTexts: { [key: string]: string } = {
    DN: 'Вы хотите сохранить договор?',
    PGS: 'Желаете сохранить промежуточную версию договора?',
};

const defaultHtml = '<div><div/>';

const hardCodeQuestionName = 'Тип организации';

const EditorPage: FC<IEditorPage> = ({ mode }) => {
    const refEditor = useRef(null);
    const [contractNumber, setContractNumber] = useState<string | null>(null);

    const { projectId, contractId } = useParams();
    const { state: contractTypeState } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();

    const [contractHtml, setContractHtml] = useState(defaultHtml);
    const [contractName, setContractName] = useState('');
    const [templateOptions, setTemplateOptions] = useState<{ value: number; label: string }[]>([]);
    const [currentTemplateOption, setCurrentTemplateOption] = useState<number | null>(null);
    const [currentTemplate, setCurrentTemplate] = useState<ITemplateFull | null>(null);
    const [fullQuestionList, setFullQuestionList] = useState<IQuestion[]>([]);

    const [hardcodeQuestion, setHardcodeQuestion] = useState<IQuestionWA | null>(null);

    const [contractElements, setContractElements] = useState<IContractElement[]>([]);

    const [properties, setProperties] = useState<{
        projectVars: any;
        orgVars: any;
    } | null>(null);
    const [propertyVars, setPropertyVars] = useState<IVarPropertiesWV[]>([]);

    // со значениями
    const [questions, setQuestions] = useState<IQuestionWA[]>([]);
    const [freeVars, setFreeVars] = useState<IVarFreeWV[]>([]);

    const [saveModal, setSaveModal] = useState<'PGS' | 'DN' | null>(null);
    const [missingProperties, setMissingProperties] = useState<IMissingProperty[]>([]);

    const [currentContract, setCurrentContract] = useState<IContract | null>(null);
    const [currentProject, setCurrentProject] = useState<IProject | null>(null);
    const [isSubscrActive, setIsSubscrActive] = useState<boolean>(false);

    const [canDownload, setCanDownload] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isRawTemplate, setIsRawTemplate] = useState(false);

    useEffect(() => {
        if (mode === 'create' && !contractTypeState?.contractTypeId) {
            navigate(`/cabinet/projects/${projectId}`);
        }
    }, [mode, contractTypeState]);

    useEffect(() => {
        if (mode === 'create') {
            document.title = 'Создание договора';
        } else if (currentContract?.name) {
            document.title = `Договор: ${currentContract?.name}`;
        } else {
            document.title = `Договор`;
        }
    }, [currentContract, mode]);

    useEffect(() => {
        getCurrentProject();
    }, [projectId]);

    useEffect(() => {
        // получаем все шаблоныв селект + весь список вопросов
        if (currentProject) {
            if (mode === 'create') {
                getTemplates();
            } else {
                getCurrentContract();
            }
            getAllQuestions();
        }
    }, [currentProject, contractId, mode]);

    useEffect(() => {
        // получаем все шаблоныв селект + весь список вопросов
        if (hardcodeQuestion && properties) {
            const orgTypeValue = hardcodeQuestion.answers.find(
                (a) => a.answer === properties.orgVars?.organization_type,
            );

            if (orgTypeValue) {
                setHardcodeQuestion({ ...hardcodeQuestion, selectedAnswer: orgTypeValue });
            }
        }
    }, [JSON.stringify(hardcodeQuestion), JSON.stringify(properties)]);

    useEffect(() => {
        // получаем конкретный шаблон при выборе в селекте
        if (currentTemplateOption) getCurrentTemplate(currentTemplateOption);
    }, [currentTemplateOption]);

    useEffect(() => {
        // перестройка элементов договора при изменении шаблона
        if (currentTemplate && fullQuestionList.length > 0) getElementsFromTemplate(currentTemplate);
    }, [currentTemplate, JSON.stringify(fullQuestionList)]);

    useEffect(() => {
        // тянем переменные из свойств проекта

        if (currentTemplate && projectId && (mode === 'create' || currentContract)) getPropertyVars(currentTemplate);
    }, [currentTemplate, projectId, mode, currentContract]);

    useEffect(() => {
        // при изменении элементов договора забираем из списка всех вопросов только нужные по порядку элементов
        const questions = contractElements
            .map((ce) => fullQuestionList.find((q) => q.id === ce?.question?.id))
            .filter((ce) => !!ce);
        // @ts-ignore
        const uniqueQuestions = [...new Set(questions)];

        if ((mode === 'view' || mode === 'edit') && currentContract?.question_answer_vars) {
            uniqueQuestions.forEach((uq: IQuestion, i: number) => {
                const savedQuestionWithAnswer = currentContract?.question_answer_vars.find(
                    (qav) => qav.question === uq.id,
                );
                if (savedQuestionWithAnswer) {
                    (uniqueQuestions[i] as IQuestionWA).selectedAnswer = uq.answers.find(
                        (a) => a.id === savedQuestionWithAnswer.answer,
                    );
                }
            });
        }

        setQuestions(uniqueQuestions as IQuestionWA[]);
    }, [JSON.stringify(contractElements), JSON.stringify(fullQuestionList), currentContract]);

    useEffect(() => {
        // перерисовка договора
        const _contract_elements = [...contractElements];
        const visibleFreeVars: string[] = [];
        const _propertyVars = structuredClone(propertyVars);
        // проставляем видимость элементов и вопросов

        const contract_elements = _contract_elements.map((ce, i) => {
            // если в элементе не указан вопрос, то элемент показывается
            if (!ce.question) {
                visibleFreeVars.push(...ce.free_vars_tags.map((fvt) => fvt.var_tag));
                _propertyVars.forEach((pv: IVarPropertiesWV, i: number) => {
                    if (ce.vars_tags.some((vt) => vt.id === pv.id)) {
                        _propertyVars[i].show = true;
                    }
                });
                return { ...ce, show: true };
            }
            // проверяем выбран ли нужный ответ вопроса для показа элеменета, в том числе хардкодный вопрос "Тип организации"
            const showElement =
                questions.find((q) => q.id === ce.question?.id && q.selectedAnswer?.id === ce.answer?.id) ||
                (ce.question.id === hardcodeQuestion?.id && ce.answer?.id === hardcodeQuestion?.selectedAnswer?.id);
            if (showElement) {
                visibleFreeVars.push(...ce.free_vars_tags.map((fvt) => fvt.var_tag));
                _propertyVars.forEach((pv: IVarPropertiesWV, i: number) => {
                    if (ce.vars_tags.some((vt) => vt.id === pv.id)) {
                        _propertyVars[i].show = true;
                    }
                });
            }
            return { ...ce, show: !!showElement };
        });

        const updFreeVars = freeVars.map((fv) => ({ ...fv, show: visibleFreeVars.includes(fv.var_tag) }));

        let template = currentTemplate?.text_template || defaultHtml;
        contract_elements.forEach((ce) => {
            const replaceText = ce.show ? ce.text_template : '';
            template = template.replaceAll(`{{${ce.element_tag}}}`, replaceText);
        });

        freeVars.forEach((fv) => {
            const value = fv.value || '';
            const cleanValue = value.replace(/\s+/g, ' ').trim();
            const replaceText = cleanValue
                ? `<span class="custom-text-color">${cleanValue}</span>`
                : `<span class="empty-value">[Данные не указаны]</span>`;
            template = template.replaceAll(`{{${fv.var_tag}}}`, replaceText);
        });

        _propertyVars.forEach((fv: IVarPropertiesWV) => {
            if (!fv.show) return;
            let replaceText = `<span class="empty-value">[Данные не указаны]</span>`;

            if (fv.model === 'projectcharacteristic' && properties?.projectVars[fv.lookup_model_field])
                replaceText = properties?.projectVars[fv.lookup_model_field];

            if (fv.model === 'organizationcharacteristic' && properties?.orgVars[fv.lookup_model_field])
                replaceText = properties?.orgVars[fv.lookup_model_field];

            template = template.replaceAll(`{{${fv.var_tag}}}`, replaceText);
        });
        // todo убрать хардкод
        if (properties?.projectVars?.writers) {
            template = template.replaceAll(`{{writers}}`, properties?.projectVars?.writers);
        }
        if (properties?.projectVars?.directors) {
            template = template.replaceAll(`{{directors}}`, properties?.projectVars?.directors);
        }

        // обновляем видимость инпутов переменных
        setFreeVars(updFreeVars);
        // обновляем видимость переменных из свойств
        setPropertyVars(_propertyVars);
        // обновляем текст договора
        const _template = template.slice(5);
        setContractHtml(_template);
        setCanDownload(true);
    }, [
        JSON.stringify(questions),
        JSON.stringify(freeVars),
        JSON.stringify(properties),
        JSON.stringify(propertyVars),
        JSON.stringify(hardcodeQuestion),
    ]);

    useEffect(() => {
        if (currentContract) {
            setContractNumber(currentContract.id.toString());
            if (isRawTemplate && currentContract.raw_text) {
                setContractHtml(currentContract.raw_text);
            }
        }
    }, [currentContract, isRawTemplate]);

    const getElementsFromTemplate = async (currentTemplate: ITemplateFull) => {
        // все вхождения элементов в текстовом шаблоне
        const contractElementsRaw = currentTemplate.text_template.match(/\{\{.+?\}\}/g) || [];
        contractElementsRaw.forEach((cer, i) => {
            contractElementsRaw[i] = cer.replace('{{', '').replace('}}', '');
        });

        // все элементы, отсортированные по порядку вхождения в текстовом шаблоне
        const contractElements = (contractElementsRaw as string[])
            .map((cer) => currentTemplate.contract_elements.find((ce) => ce.element_tag === cer))
            .filter((ce) => !!ce) as IContractElement[];

        let freeVarsNew: IVarFreeWV[] = [];
        contractElements.forEach((ce) => {
            const usedFVTRaw = ce.text_template.match(/\{\{.+?\}\}/g) || [];
            usedFVTRaw.forEach((ufvtr, i) => {
                usedFVTRaw[i] = ufvtr.replace('{{', '').replace('}}', '');
            });
            const elementFreeVars = usedFVTRaw
                .map((ufvtr) => ce.free_vars_tags.find((cefvt) => ufvtr === cefvt.var_tag))
                .filter((ufvtr) => !!ufvtr);

            freeVarsNew = [...freeVarsNew, ...(elementFreeVars as IVarFreeWV[])];
        });

        // @ts-ignore
        const uniquefreeVarsNew: IVarFreeWV[] = [...new Set(freeVarsNew)];
        if ((mode === 'view' || mode === 'edit') && currentContract) {
            uniquefreeVarsNew.forEach((fvn, i) => {
                const savedFreeVariable = currentContract.contract_free_vars.find(
                    (cfv) => cfv.contract_element_var_tag === fvn.id,
                );

                if (savedFreeVariable) {
                    uniquefreeVarsNew[i].value = savedFreeVariable?.value;
                }
            });
        }

        // обогащаем айдишками элементов, в которых есть переменные, нужно будет для сохранения

        uniquefreeVarsNew.forEach((ufvn, i) => {
            const elementIds: number[] = [];
            contractElements.forEach((ce) => {
                if (ce.free_vars_tags.find((fvt) => fvt.id === ufvn.id)) {
                    elementIds.push(ce.id);
                }
            });

            uniquefreeVarsNew[i].elementIds = elementIds;
        });

        setFreeVars(uniquefreeVarsNew);
        setContractElements(contractElements);
    };

    const getPropertyVars = async (currentTemplate: ITemplateFull) => {
        // получаем переменные из свойств проекта/договора
        if (!projectId) return;

        let variables: IVarPropertiesWV[] = [];

        currentTemplate.contract_elements.forEach((ce) => {
            variables.push(...ce.vars_tags);
        });

        const needProjectVars = variables.find((v) => v.model === 'projectcharacteristic');
        const needOrgVars = variables.find((v) => v.model === 'organizationcharacteristic');

        let projectVars = null;
        let orgVars = null;

        // const uniqueContentTypeArr = Array.from(new Set(variables.map((v) => v.content_type)));
        if (needProjectVars?.content_type) {
            let projectVarsRes;
            if (mode === 'create' || (currentContract && !currentContract?.project_characteristic)) {
                projectVarsRes = await ContractDesigner.getVersionProperties(projectId, needProjectVars.content_type);
                projectVars = Array.isArray(projectVarsRes) && !!projectVarsRes[0] ? projectVarsRes[0] : null;

                if (!projectVars) {
                    openErrorNotification('Не удалось загрузить свойства проекта');
                    navigate(`/cabinet/projects/${projectId}`);
                }
            }
            if (currentContract && currentContract?.project_characteristic) {
                projectVarsRes = [
                    await PersonalAccount.getProjectPropertiesSet(
                        projectId,
                        currentContract.project_characteristic as any,
                    ),
                ];
                projectVars = Array.isArray(projectVarsRes) && !!projectVarsRes[0] ? projectVarsRes[0] : null;

                if (!projectVars) {
                    openErrorNotification('Не удалось загрузить свойства проекта');
                    navigate(`/cabinet/projects/${projectId}`);
                }
            }
            // projectVars = Array.isArray(projectVarsRes) && !!projectVarsRes[0] ? projectVarsRes[0] : null;

            // if (!projectVars) {
            //     openErrorNotification('Не удалось загрузить свойства проекта');
            //     navigate(`/cabinet/projects/${projectId}`);
            // }
        }

        if (needOrgVars?.content_type) {
            let orgVarsRes;

            if (mode === 'create' || (currentContract && !currentContract?.organization_characteristic)) {
                orgVarsRes = await ContractDesigner.getVersionProperties(projectId, needOrgVars.content_type);

                orgVars = Array.isArray(orgVarsRes) && !!orgVarsRes[0] ? orgVarsRes[0] : null;

                if (!orgVars) {
                    openErrorNotification('Не удалось загрузить свойства проекта');
                    navigate(`/cabinet/projects/${projectId}`);
                }
            }
            if (
                currentContract &&
                currentContract?.organization_characteristic &&
                currentProject?.organizer_organization?.id
            ) {
                orgVarsRes = [
                    await Organizations.getOrganizationDetails(
                        currentProject?.organizer_organization?.id,
                        currentContract.organization_characteristic as any,
                    ),
                ];
                orgVars = Array.isArray(orgVarsRes) && !!orgVarsRes[0] ? orgVarsRes[0] : null;

                if (!orgVars) {
                    openErrorNotification('Не удалось загрузить свойства проекта');
                    navigate(`/cabinet/projects/${projectId}`);
                }
            }
        }

        const translatedProjectVars = flatProjectVars(projectVars);
        if (projectVars || orgVars) {
            setProperties({
                projectVars: translatedProjectVars,
                orgVars,
            });
            setPropertyVars(variables);
        }
    };

    const getAllQuestions = async () => {
        // грузим все вопросы
        const res = await ContractDesigner.getQuestions();
        if (res.results) {
            const hcq = res.results.find((res: any) => res.question === hardCodeQuestionName);
            if (hcq) setHardcodeQuestion(hcq);
            setFullQuestionList(res.results.filter((res: any) => res.question !== hardCodeQuestionName));
        } else {
            openErrorNotification('Не удалось загрузить шаблон договора');
            navigate(`/cabinet/projects/${projectId}`);
        }
    };
    const getTemplates = async () => {
        // грузим все шаблоны
        const res = await ContractDesigner.getAll({
            contract_template_type: contractTypeState?.contractTypeId,
        });
        if (res.results && res.results.length > 0) {
            const sortedOptions = (res.results as ITemplate[])
                .map((t: ITemplate) => ({ value: t.id, label: t.title }))
                // @ts-ignore
                .toSorted((a, b) => a.value - b.value);
            setTemplateOptions(sortedOptions);
            setCurrentTemplateOption(sortedOptions[0].value);
            return;
        }
        navigate(`/cabinet/projects/${projectId}`);
        openErrorNotification('Не удалось загрузить шаблоны договоров');
    };
    const getCurrentTemplate = async (templateId: number) => {
        // грузим выбранный шаблон
        setIsLoading(true);
        const res = await ContractDesigner.getById(templateId);
        setIsLoading(false);

        if (res.id) {
            setCurrentTemplate(res);
            setIsRawTemplate(res.is_raw); // Установка статуса is_raw
        } else {
            navigate(`/cabinet/projects/${projectId}`);
            openErrorNotification('Не удалось загрузить шаблон договора');
        }
    };
    const getCurrentProject = async () => {
        if (!projectId) return;
        setIsLoading(true);
        const res = await PersonalAccount.getProject(projectId);
        setIsLoading(false);

        if (!res.id) {
            navigate(`/cabinet/projects/${projectId}`);
            openErrorNotification('Не удалось загрузить шаблон договора');
            return;
        }

        const _isSubscrActive =
            (res as IProject).subscriptions.filter((subscr) => subscr.status === ESubscriptionStatuses.Active).length >
            0;

        if (!_isSubscrActive && mode !== 'view') {
            navigate(`/cabinet/projects/${projectId}`);
            mode === 'create'
                ? openErrorNotification('Для создания договора необходимо оплатить подписку')
                : openErrorNotification('Для редактирования договора необходимо оплатить подписку');

            return;
        }
        setIsSubscrActive(_isSubscrActive);
        setCurrentProject(res);
    };

    const getCurrentContract = async () => {
        if (!contractId || !projectId) return;
        setIsLoading(true);

        const result = await PersonalAccount.getProjectContract(projectId, contractId);
        setIsLoading(false);

        if (!result.id || !result.contract_template) {
            navigate(`/cabinet/projects/${projectId}`);
            openErrorNotification('Не удалось загрузить договор');
            return;
        }

        if (result?.question?.id && result?.answer?.id) {
            setHardcodeQuestion({
                id: result.question.id,
                question: result?.question?.question,
                answers: [
                    {
                        id: result?.answer?.id,
                        answer: result?.answer?.answer,
                    },
                ],
            });
        }
        setCurrentTemplateOption(result.contract_template);
        setContractName(result.name);
        setCurrentContract(result);
        if (result.raw_text && isRawTemplate) {
            setContractHtml(result.raw_text);
        }
    };

    const openSaveModal = () => {
        if (!projectId) return;

        // берем для сохранения только те переменные, которые отображаются слева и которые привязаны к элементам

        const contract_free_vars = freeVars
            .filter((fv) => fv.show)
            .map((fv) => ({
                value: fv.value || '',
                contract_element_var_tag: fv.id,
            }));

        const allQuestions = !questions.some((q) => !q?.selectedAnswer?.id);
        const allFreeVariables = !contract_free_vars.some((cfv) => !cfv.value);

        if (!(allQuestions && allFreeVariables && contractName))
            return openInfoNotification('Необходимо указать все данные договора в левом столбце');

        const missingPropertyVars: IMissingProperty[] = [];
        propertyVars
            .filter((fv) => fv.show)
            .forEach((fv) => {
                if (fv.model === 'projectcharacteristic' && !properties?.projectVars[fv.lookup_model_field])
                    missingPropertyVars.push({
                        model: 'projectcharacteristic',
                        field: fv.lookup_model_field,
                        fieldName: fv.display_label,
                        tag: fv.var_tag,
                    });

                if (fv.model === 'organizationcharacteristic' && !properties?.orgVars[fv.lookup_model_field])
                    missingPropertyVars.push({
                        model: 'organizationcharacteristic',
                        field: fv.lookup_model_field,
                        fieldName: fv.display_label,
                        tag: fv.var_tag,
                    });
            });

        // @ts-ignore
        const uniqueMissingPropertyVars = [...new Set(missingPropertyVars.map((o) => JSON.stringify(o)))].map((s) =>
            JSON.parse(s),
        );
        const ismissingProperty = uniqueMissingPropertyVars.length > 0;

        setMissingProperties(uniqueMissingPropertyVars);
        setSaveModal(ismissingProperty ? 'PGS' : 'DN');
    };

    const saveContract = async () => {
        if (!projectId || !saveModal) return;

        // берем для сохранения только те переменные, которые отображаются слева и которые привязаны к элементам

        const contract_free_vars = freeVars
            .filter((fv) => fv.show)
            .map((fv) => ({
                value: fv.value || '',
                contract_element_var_tag: fv.id,
            }))
            .filter((cfv) => cfv.value);

        const question_answer_vars = questions
            .map((q) => (q.selectedAnswer ? { question: q.id, answer: q.selectedAnswer?.id } : null))
            .filter((q) => !!q);

        const payload = {
            contract_free_vars,
            contract_vars: [],
            question_answer_vars,
            project_characteristic: saveModal === 'DN' ? properties?.projectVars?.id : undefined,
            organization_characteristic: saveModal === 'DN' ? properties?.orgVars?.id : undefined,
            status: saveModal,
            contract_template: currentTemplate?.id,
            name: contractName,
            raw_text: isRawTemplate ? contractHtml : undefined,
        };
        console.log('payload', payload);

        let result;

        if (mode === 'create') result = await PersonalAccount.createContract(projectId, payload);
        if (mode === 'edit' && currentContract)
            result = await PersonalAccount.updateContract(projectId, currentContract.id, payload);

        if (result?.id) {
            openSuccessNotification('Документ сохранен');
            setSaveModal(null);
            if (saveModal === 'DN') {
                navigate(`/cabinet/projects/${projectId}/contracts/${result.id}/view`);
            } else {
                navigate(`/cabinet/projects/${projectId}/contracts/${result.id}/edit`);
            }
        } else {
            openErrorNotification('Ошибка сохранения договора');
        }
    };

    const getPageName = () => {
        if (mode === 'create') {
            return `Создание: ${contractTypeState?.contractTypeName || '...'}`;
        }
        if (mode === 'edit') {
            return `Редактирование: ${currentContract?.name || '...'}`;
        }
        return `Просмотр: ${currentContract?.name || '...'}`;
    };

    const generatePdfWithBarcode = () => {
        const content = document.getElementById('contract-content');
        const barcode = document.getElementById('barcode');

        if (content && barcode) {
            content.insertBefore(barcode, content.firstChild);

            const options = {
                margin: [10, 10, 28, 10],
                filename: `${contractName}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            Html2Pdf().from(content).set(options).save();
        } else {
            console.error('Content or barcode element not found');
        }
    };

    return (
        <>
            <Header />
            <TopPageName>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Title mb={50}>{getPageName()}</Title>
                </div>
            </TopPageName>
            <Container pb={50} pt={10} column>
                <div style={{ paddingTop: 30 }} />
                <div className={_.pageUpperButtons}>
                    <BackButton
                        className={_.backButton}
                        onClick={() => {
                            navigate(`/cabinet/projects/${projectId}`);
                        }}
                    >
                        К проекту
                    </BackButton>
                    {currentContract?.status === 'DN' && canDownload && mode === 'view' && isSubscrActive && (
                        <Button className={_.saveContractButton} onClick={generatePdfWithBarcode}>
                            Скачать .pdf
                        </Button>
                    )}
                </div>

                <div
                    className={clsx(
                        _.main,
                        (mode === 'create' || (currentContract && currentContract.status !== 'DN')) && _.mainCenter,
                    )}
                >
                    {mode !== 'view' && (
                        <div className={_.leftSide}>
                            {mode === 'create' && (
                                <Select
                                    className={_.fullWidth}
                                    options={templateOptions}
                                    value={currentTemplateOption || undefined}
                                    onChange={(value) => {
                                        const selectedTemplate = templateOptions.find((t) => t.value === value);
                                        setCurrentTemplateOption(value);
                                        setContractName(selectedTemplate ? selectedTemplate.label : '');
                                    }}
                                />
                            )}
                            <div className={_.mt15}>
                                <div>Наименование документа</div>
                                <Input
                                    className={_.fullWidth}
                                    value={contractName}
                                    onChange={(e) => {
                                        setContractName(e.target.value);
                                    }}
                                    disabled={!isSubscrActive}
                                />
                            </div>
                            {questions.map((q) => (
                                <div className={_.mt15} key={q.id}>
                                    <div>{q.question}</div>
                                    <Select
                                        className={_.fullWidth}
                                        options={[...q.answers.map((qa) => ({ value: qa.id, label: qa.answer }))]}
                                        value={q.selectedAnswer?.id}
                                        status={!q.selectedAnswer?.id ? 'error' : ''}
                                        onChange={(value, selectedOption) => {
                                            const _questions = [...questions];
                                            const currentQIndex = _questions.findIndex((el) => el.id === q.id);
                                            const currentQ = _questions[currentQIndex];
                                            currentQ.selectedAnswer = {
                                                // @ts-ignore
                                                id: selectedOption.value,
                                                // @ts-ignore
                                                answer: selectedOption.label,
                                            };

                                            setQuestions(_questions);
                                        }}
                                        disabled={!isSubscrActive}
                                    />
                                </div>
                            ))}

                            <div style={{ marginBottom: 30 }} />

                            {freeVars.map((v) => {
                                if (!v.show) return null;
                                return (
                                    <div className={_.mt15} key={v.id}>
                                        <div>{v.display_label}</div>
                                        <Tooltip placement='top' title={v.value}>
                                            <Input
                                                className={_.fullWidth}
                                                value={v.value}
                                                onChange={(e) => {
                                                    const _variables = [...freeVars];
                                                    const currentVar = _variables.findIndex((el) => el.id === v.id);
                                                    _variables[currentVar].value = e.target.value;
                                                    setFreeVars(_variables);
                                                }}
                                                disabled={!isSubscrActive}
                                            />
                                        </Tooltip>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <div className={_.rightSide}>
                        <div id='contract-content' className={_.contractContent}>
                            {mode === 'view' && contractNumber && (
                                <div id='barcode' className={_.barcode}>
                                    <Barcode value={contractNumber} />
                                </div>
                            )}
                            {isRawTemplate && mode !== 'view' ? (
                                <ReactQuill
                                    value={contractHtml}
                                    onChange={setContractHtml}
                                    modules={{
                                        toolbar: [
                                            [{ header: '1' }, { header: '2' }, { font: [] }],
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [
                                                { list: 'ordered' },
                                                { list: 'bullet' },
                                                { indent: '-1' },
                                                { indent: '+1' },
                                            ],
                                            [{ align: [] }],
                                            ['clean'],
                                        ],
                                    }}
                                    formats={[
                                        'header',
                                        'font',
                                        'size',
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strike',
                                        'blockquote',
                                        'list',
                                        'bullet',
                                        'indent',
                                        'align',
                                    ]}
                                />
                            ) : (
                                <div ref={refEditor} dangerouslySetInnerHTML={{ __html: contractHtml }} />
                            )}
                        </div>
                    </div>
                </div>

                {mode !== 'view' && (
                    <Button className={_.saveContractButton} onClick={openSaveModal}>
                        Сохранить {contractName || 'документ'}
                    </Button>
                )}
            </Container>

            <Modal
                open={!!saveModal}
                onCancel={() => {
                    setSaveModal(null);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalInner}>
                    <Title mt={60} family='Manrope'>
                        Сохранение договора
                    </Title>
                    <Text mt={30} width={460}>
                        {missingProperties.length > 0 && (
                            <>
                                {`Отсутствуют следующие свойства в характеристиках проекта или реквизитах заказчика: ${missingProperties
                                    .map((mp) => mp.fieldName)
                                    .join(', ')}.`}
                                <br />
                                Для сохранения финальной версии договора с возможностью скачивания необходимо указать
                                отсутствующие данные в характеристиках проекта или реквизитах заказчика проекта. <br />
                                <br />
                            </>
                        )}

                        {saveModal && (saveContractTexts[saveModal] || '')}
                    </Text>
                    <div className={_.modalButtons}>
                        <Button className={_.saveContractButton} onClick={saveContract}>
                            Сохранить
                        </Button>
                        <Button
                            className={_.saveContractButton}
                            onClick={() => {
                                setSaveModal(null);
                            }}
                            grey
                        >
                            Отмена
                        </Button>
                    </div>
                </div>
            </Modal>
            {isLoading && <Loader />}
            <Footer />
        </>
    );
};

export default EditorPage;
