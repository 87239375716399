import { FC } from 'react';
import Slider from 'react-slick';
import clsx from 'clsx';

import Container from 'common/StyledElements/Container/Container';
import Title from 'common/StyledElements/Title/Title';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import SmallCard from '../Reviews/components/SmallCard/SmallCard';

import _ from './OurCustomers.module.css';
import '../Reviews/styles.css';

interface ICompany {
    id: number;
    logo_img: string;
    logo_name: string;
}

interface IOurCustomersProps {
    companyData: any;
}

const OurCustomers: FC<IOurCustomersProps> = ({ companyData }) => (
    <Container column>
        <Title mb={40}>{logoReplacer(companyData.title)}</Title>

        {Array.isArray(companyData.companies) && companyData.companies.length > 4 && (
            <Slider
                className={clsx(_.cardsContainer, 'customSlider', 'copmanySliderReviews')}
                slidesToShow={4}
                slidesToScroll={1}
                infinite
                variableWidth
                arrows
            >
                {companyData.companies.map((company: ICompany) => (
                    <div key={company.id} style={{ display: 'flex', width: 284 }}>
                        <SmallCard {...company} />
                    </div>
                ))}
            </Slider>
        )}
        {Array.isArray(companyData.companies) && companyData.companies.length < 5 && (
            <div className={clsx(_.cardsContainer, _.cardsContainerRow)}>
                {companyData.companies.map((company: ICompany) => (
                    <div key={company.id} style={{ display: 'flex', width: 284 }}>
                        <SmallCard {...company} />
                    </div>
                ))}
            </div>
        )}
    </Container>
);

export default OurCustomers;
