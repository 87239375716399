import { FC } from 'react';
import clsx from 'clsx';

import greenCheck from 'assets/statuses/active.svg';
import redCross from 'assets/statuses/blocked.svg';
import blueHourglass from 'assets/statuses/trial.svg';
import Text from 'common/StyledElements/Text/Text';
import { ESubscriptionStatusTrial, ESubscriptionStatuses } from 'connectors/query/Subscriptions';

import _ from './StatusWrapper.module.css';

export enum EStatuses {
    GreenCheck = 'greenCheck',
    RedCross = 'redCross',
    BlueHourglass = 'blueHourglass',
}

export interface IStatusAlias {
    codeName: string;
    shownName?: string;
}
export interface IStatusAliases {
    [EStatuses.GreenCheck]?: IStatusAlias[];
    [EStatuses.RedCross]?: IStatusAlias[];
    [EStatuses.BlueHourglass]?: IStatusAlias[];
}
export const statusAliasProject: IStatusAliases = {
    greenCheck: [{ codeName: ESubscriptionStatuses.Active, shownName: 'Активный' }],
    redCross: [
        { codeName: ESubscriptionStatuses.Canceled, shownName: 'Отменен' },
        { codeName: ESubscriptionStatuses.Expired, shownName: 'Истекла подписка' },
    ],
    blueHourglass: [
        { codeName: ESubscriptionStatuses.Pending, shownName: 'Ожидает оплаты' },
        { codeName: ESubscriptionStatusTrial.Trial, shownName: 'Пробный период' },
    ],
};

interface IStatusProps {
    text: string;
    statusAliases: IStatusAliases;
    className?: string;
    mb?: number | string;
}

const imgs = {
    greenCheck,
    redCross,
    blueHourglass,
};

const color = {
    greenCheck: '#64B31614',
    redCross: '#ED886414',
    blueHourglass: '#166DF914',
};

export const statusChecker: (t: string, s: IStatusAliases) => [boolean, string, string | undefined] = (
    text: string,
    statusAliases: IStatusAliases,
) => {
    let aliasArrIndex = -1;
    const statusIndex = Object.keys(statusAliases).find((key) =>
        statusAliases[key as keyof typeof statusAliases]?.some((alias, index) => {
            if (alias.codeName === text) {
                aliasArrIndex = index;
                return true;
            }
            return false;
        }),
    );
    const isFound = Object.values(EStatuses).includes(statusIndex as EStatuses);
    const statusAlias = statusAliases[statusIndex as EStatuses];
    let shownName: string | undefined = text;
    if (statusAlias && statusAlias[aliasArrIndex]?.shownName) {
        shownName = statusAlias[aliasArrIndex].shownName;
    }

    return [isFound, shownName || '', statusIndex];
};

const StatusWrapper: FC<IStatusProps> = ({ text, statusAliases, className, mb }) => {
    const [isFound, shownName, statusIndex] = statusChecker(text, statusAliases);

    return (
        <div
            className={clsx(_.statusWrapper, className)}
            style={{
                backgroundColor: isFound && statusIndex ? color[statusIndex as EStatuses] : undefined,
                marginBottom: mb,
            }}
        >
            {isFound && statusIndex && <img className={_.icon} src={imgs[statusIndex as EStatuses]} alt='status' />}
            <Text small>{shownName}</Text>
        </div>
    );
};

export default StatusWrapper;
