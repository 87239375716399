import { FC, CSSProperties } from 'react';
import { Button as AntdButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import _ from './Button.module.css';

interface IButtonProps {
    width?: string;
    children: React.ReactNode | React.ReactNode[];
    onClick?: () => void;
    marginBottom?: string;
    marginTop?: string;
    marginRight?: string;
    htmlType?: 'submit' | 'button' | 'reset' | undefined;
    grey?: boolean;
    grey2?: boolean;
    disabled?: boolean;
    plus?: boolean;
    style?: CSSProperties;
    className?: string;
}

const Button: FC<IButtonProps> = ({
    width,
    onClick,
    children,
    marginBottom,
    marginTop,
    htmlType,
    grey,
    grey2,
    marginRight,
    disabled,
    style = {},
    plus,
    className,
}) => (
    <AntdButton
        className={clsx(_.button, (grey && _.grey) || (grey2 && _.grey2), className)}
        style={{ width, height: '54px', marginBottom, marginTop, marginRight, ...style }}
        onClick={onClick}
        type='primary'
        htmlType={htmlType || 'button'}
        disabled={disabled}
    >
        {plus && <PlusOutlined style={{ fontSize: '25px' }} className={_.buttonIcon} />}
        {children}
    </AntdButton>
);

export default Button;
