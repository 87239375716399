import { FC } from 'react';

import clsx from 'clsx';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import downloadArrow from 'assets/webinar/downloadArrow.svg';
import { IAttachment } from 'connectors/query/Webinar';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import Divider from 'common/StyledElements/Divider/Divider';

import _ from './WebinarContent.module.css';
import WebinarContentBlock from './WebinarContentBlock/WebinarContentBlock';

interface IWebinarContentProps {
    description: string;
    starts_at: string;
    ends_at: string;
    speaker: string;
    attachments: IAttachment[];
}

const WebinarContent: FC<IWebinarContentProps> = ({ description, attachments, starts_at, ends_at, speaker }) => {
    const formatFileSize = (bytes: number): string => {
        if (bytes === 0) return '0 B';

        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const fileSize = parseFloat((bytes / k ** i).toFixed(2));
        const sizeSuffix = sizes[i];

        return `${fileSize} ${sizeSuffix}`;
    };

    const downloadFile = (url: string, fileName: string) => {
        saveAs(url, fileName);
    };

    const formatDate = (dateString: string): string => {
        if (!dayjs(dateString).isValid()) {
            return '';
        }

        return dayjs(dateString).locale('ru').format('D MMMM YYYY в HH:mm');
    };

    return (
        <>
            <div className={_.webinarTimeBlock}>
                {starts_at && (
                    <WebinarContentBlock zeroMargin label='Начало мероприятия' content={formatDate(starts_at)} />
                )}
                {ends_at && (
                    <WebinarContentBlock zeroMargin label='Завершение мероприятия' content={formatDate(ends_at)} />
                )}
            </div>

            <Divider mt={50} />

            {description && <WebinarContentBlock label='Описание вебинара' content={description} />}
            {speaker && <WebinarContentBlock label='Спикер вебинара' content={speaker} />}

            {attachments && attachments.length > 0 && (
                <>
                    <div className={_.webinarContentLabel}>Материалы вебинара</div>
                    <div className={_.webinarContent}>
                        {attachments.map((attachment) => (
                            <div className={_.webinarContentEntity}>
                                <div className={_.webinarContentEntityName}>{attachment?.description}</div>
                                <div className={_.webinarContentEntitySize}>
                                    {`${attachment.extension}, ${formatFileSize(attachment.size)}`}
                                </div>
                                <div className={_.webinarContentEntityDownload}>
                                    <div onClick={() => downloadFile(attachment.attachment_url, 'файл')}>Скачать</div>
                                    <img src={downloadArrow} alt='downloadArrow' />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default WebinarContent;
