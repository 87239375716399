import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';
import clsx from 'clsx';

import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Container from 'common/StyledElements/Container/Container';
import Title from 'common/StyledElements/Title/Title';
import { EWebinarStatus, IProofixInfo, IWebinarData, Webinar } from 'connectors/query/Webinar';
// import VideoPlayer from 'common/VideoPlayer/VideoPlayer';
import AuthContext from 'common/AuthProvider/AuthProvider';
import TypeHotline, { ETypeHotline } from 'common/TypeHotline/TypeHotline';
// import WebinarChat from './components/WebinarChat/WebinarChat';
// import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import Button from 'common/Button/Button';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import { openErrorNotification } from 'common/Notifications/Notifications';
import ProofixPlayer from './components/WebinarProofix/WebinarProofix';
import WebinarContent from './components/WebinarContent/WebinarContent';

import _ from './WebinarPage.module.css';

interface IBreadcrumbRoute {
    path: string;
    breadcrumbName: string;
}

const routes: IBreadcrumbRoute[] = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'practice',
        breadcrumbName: 'КИНО И ПРАВО',
    },
    {
        path: 'lectures',
        breadcrumbName: 'Лекции и вебинары',
    },
];

const WebinarPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dataAuth = useContext(AuthContext);
    const { hasLoaded, logged, isSubscrLoaded, hotlineSubscr } = dataAuth;
    const [webinarItems, setWebinarItems] = useState<IWebinarData | null>(null);
    const [proofixInfo, setProofixInfo] = useState<IProofixInfo | null>(null);

    useEffect(() => {
        if (hasLoaded && !logged) {
            navigate('/practice');
            return;
        }

        if (hasLoaded && isSubscrLoaded) {
            if (hotlineSubscr) {
                getWebinarInFo();
            } else {
                navigate('/practice');
            }
        }
    }, [id, hasLoaded, logged, isSubscrLoaded, hotlineSubscr]);

    const getWebinarInFo = async () => {
        if (id) {
            const data = await Webinar.getById(id);
            if (data?.id) {
                setWebinarItems(data);
                const proofixInfo = {
                    companyCode: data.proofix_info?.company_slag || '',
                    eventCode: data.proofix_info?.event_slag || '',
                    eventId: data.proofix_info?.event_id || 0,
                };
                setProofixInfo(proofixInfo);
            } else {
                navigate('/practice');
                openErrorNotification('Вебинар не найден.');
            }
        }
    };

    return (
        <>
            <Header />
            <Container gray>
                <div className={_.wrapper}>
                    <div className={_.breadcrumbs}>
                        <Breadcrumbs routes={routes} />
                    </div>

                    <div className={_.titleRow}>
                        <div className={_.typeWebinar}>
                            <div className={_.typeWebinar}>
                                <TypeHotline
                                    type={
                                        webinarItems?.status === EWebinarStatus.ARCHIVED
                                            ? [ETypeHotline.Webinar, ETypeHotline.Archive]
                                            : [ETypeHotline.Webinar]
                                    }
                                />
                            </div>
                        </div>
                        <Title mb={75} textAlign='center' medium>
                            {logoReplacer(webinarItems?.title)}
                        </Title>
                    </div>
                </div>
            </Container>

            {hasLoaded && logged && isSubscrLoaded && hotlineSubscr ? (
                <Container pt='30px'>
                    <div className={clsx(_.wrapper, _.wrapperMain)}>
                        <div className={_.backButton}>
                            <AntdButton
                                className={_.breadcrumb__btn}
                                type='link'
                                onClick={() => {
                                    navigate('/lectures');
                                }}
                            >
                                <ArrowLeftOutlined />
                                Лекции и вебинары
                            </AntdButton>
                        </div>

                        <ProofixPlayer
                            companyCode={proofixInfo?.companyCode || ''}
                            eventCode={proofixInfo?.eventCode || ''}
                            eventId={proofixInfo?.eventId || 0}
                        />

                        {/* <div className={_.videoChatWrapper}>
                        {webinarItems?.mediafile ? (
                            <VideoPlayer url={webinarItems.mediafile} />
                        ) : (
                            <img className={_.videoBox} src={webinarItems?.preview} alt='webinarMock' />
                        )}

                        <WebinarChat />
                    </div> */}

                        <WebinarContent
                            description={webinarItems?.description || ''}
                            attachments={webinarItems?.attachments || []}
                            starts_at={webinarItems?.starts_at || ''}
                            ends_at={webinarItems?.ends_at || ''}
                            speaker={webinarItems?.speaker || ''}
                        />
                        <div className={_.toLecturesButton}>
                            <Button
                                width='255px'
                                grey
                                onClick={() => {
                                    navigate('/lectures');
                                }}
                            >
                                К списку лекций
                            </Button>
                        </div>
                    </div>
                </Container>
            ) : (
                <div style={{ height: 300 }} />
            )}

            <Footer />
        </>
    );
};

export default WebinarPage;
