export enum PayStatus {
    Success = 'Успешно',
    Declined = 'Отклонен',
}


export interface BillsType {
    id: number;
    date: string;
    order: string;
    accNum: string;
    amount: string;
    status: PayStatus;
}

export const bills: BillsType[] = [
    {
        id: 11,
        date: '02.02.2023 00:00:00',
        order: '1123',
        accNum: 'R0018301381',
        amount: '1000 руб.',
        status: PayStatus.Success,
    },
    {
        id: 10,
        date: '01.02.2023 00:00:00',
        order: '1123',
        accNum: 'R0017996598',
        amount: '1000 руб.',
        status: PayStatus.Declined,
    },
];
