import { FC } from 'react';
import { Tooltip } from 'antd';
import clsx from 'clsx';

import _ from './Icon.module.css';

interface IIconProps {
    children?: React.ReactNode;
    className?: string;
    round?: boolean;
    onClick?: () => void;
    title?: string;
}

const Icon: FC<IIconProps> = ({ children, round, onClick, className = '', title }) => (
    <Tooltip title={title}>
        <div className={clsx(_.bucket, round && _.round, className)} onClick={onClick}>
            {children}
        </div>
    </Tooltip>
);

export default Icon;
