import { Checkbox, Form, Input, FormInstance } from 'antd';
import { Rule } from 'antd/es/form';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';

import { useEffect } from 'react';
import _ from './InformationCarrier.module.css';

interface IInformationCarrier {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    setData: (i: any) => void;
    form: FormInstance;
    customCarrierValue: string;
    setCustomCarrierValue: React.Dispatch<React.SetStateAction<string>>;
    isSubscrActiveOrCreate: boolean;
}

const InformationCarrier: React.FC<IInformationCarrier> = ({
    onInputChange,
    data,
    setData,
    form,
    customCarrierValue,
    setCustomCarrierValue,
    isSubscrActiveOrCreate,
}) => {
    const carrierMinOneValidationFunctionChecked: Rule = () => ({
        validator(_, val) {
            if (!val.length) {
                return Promise.reject(new Error('Необходимо указать хотя бы 1 носитель.'));
            }

            return Promise.resolve();
        },
    });
    return (
        <div className='blockCard w100'>
            <Form form={form} name='name_form' layout='vertical'>
                <div className='titleCard mb0 required-field'>Носитель</div>
                <div className={_.main}>
                    <MyFormItem
                        name='media_types'
                        style={{ marginBottom: 0 }}
                        rules={[carrierMinOneValidationFunctionChecked]}
                    >
                        <Checkbox.Group
                            className='checkBlock'
                            value={data.media_types}
                            onChange={(checkedValues) => {
                                if (!checkedValues.includes('Другой')) {
                                    setCustomCarrierValue('');
                                }
                                setData({ ...data, media_types: checkedValues });
                            }}
                        >
                            <Checkbox value='HDD' disabled={!isSubscrActiveOrCreate}>
                                HDD
                            </Checkbox>
                            <Checkbox value='Негатив' disabled={!isSubscrActiveOrCreate}>
                                Негатив
                            </Checkbox>
                            <Checkbox value='Другой' disabled={!isSubscrActiveOrCreate}>
                                Другой
                            </Checkbox>
                        </Checkbox.Group>
                    </MyFormItem>
                    {data.media_types && data.media_types.includes('Другой') && (
                        <MyFormItem
                            name='custom_media_type'
                            style={{ marginBottom: 0 }}
                            rules={[
                                { required: true, message: 'Необходимо заполнить.' },
                                { whitespace: true, message: 'Необходимо заполнить.' },
                            ]}
                        >
                            <Input
                                className='inputInner inputInnerShort'
                                placeholder='Введите название носителя'
                                value={customCarrierValue}
                                onChange={(event) => {
                                    setCustomCarrierValue(event.target.value);
                                }}
                                disabled={!isSubscrActiveOrCreate}
                            />
                        </MyFormItem>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default InformationCarrier;
