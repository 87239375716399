import { useState, useEffect } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';

import { IModule, Subscriptions } from 'connectors/query/Subscriptions';
import Card from './components/Card/Card';

import _ from './ModuleCards.module.css';
import './styles.css';

const ModuleCards = () => {
    const [modules, setModules] = useState<IModule[]>([]);
    const [upperHalfHeights, setUpperHalfHeights] = useState<number[]>([]);
    const [maxUpperHalfHeight, setMaxUpperHalfHeight] = useState<number | null>(null);
    const [heights, setHeights] = useState<number[]>([]);

    const [selectStates, setSelectStates] = useState({});

    const getModules = async () => {
        const res = await Subscriptions.getModules();
        if (Array.isArray(res)) {
            setModules(res.sort((a, b) => a.id - b.id));
        }
    };

    useEffect(() => {
        getModules();
    }, []);

    useEffect(() => {
        if (upperHalfHeights.length > modules.length) {
            setMaxUpperHalfHeight(Math.max(...upperHalfHeights));
        }
    }, [upperHalfHeights]);


    return (
        <>
            {modules.length > 3 && (
                <Slider
                    className={clsx(_.cardsContainer, 'customSlider', 'sliderRate')}
                    slidesToShow={3}
                    dots
                    infinite
                    variableWidth
                    adaptiveHeight
                >
                    {modules.map((module, i) => (
                        <div key={module.id} style={{ display: 'flex', width: 380 }}>
                            <Card
                                modules={modules}
                                index={i}
                                setSelectStates={setSelectStates}
                                selectStates={selectStates}
                                module={module}
                                setUpperHalfHeights={setUpperHalfHeights}
                                upperHalfHeights={upperHalfHeights}
                                maxUpperHalfHeight={maxUpperHalfHeight}
                                setHeights={setHeights}
                            />
                        </div>
                    ))}
                </Slider>
            )}

            {modules.length < 4 && (
                <div className={_.noSlider}>
                    {modules.map((module, i) => (
                        <div key={module.id} style={{ display: 'flex', width: 350 }}>
                            <Card
                                modules={modules}
                                index={i}
                                setSelectStates={setSelectStates}
                                selectStates={selectStates}
                                module={module}
                                setUpperHalfHeights={setUpperHalfHeights}
                                upperHalfHeights={upperHalfHeights}
                                maxUpperHalfHeight={maxUpperHalfHeight}
                                setHeights={setHeights}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default ModuleCards;
