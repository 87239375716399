import { FC } from 'react';

import fileIcon from 'assets/fileIcon.svg';

import _ from './CorrespondenceFileAttachment.module.css';

interface ICorrespondenceFileAttachment {
    file: null | string | undefined;
}

const CorrespondenceFileAttachment: FC<ICorrespondenceFileAttachment> = ({ file }) => {
    const decodedFile = file && decodeURIComponent(file);
    const fileName = (decodedFile && decodedFile.split('/')[decodedFile.split('/').length - 1]) || '';
    const fileNameArray = fileName.split('.');
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    const fileNameNoExtension = fileNameArray.slice(0, -1).join();

    if (file) {
        return (
            <a
                className={_.anchor}
                title={fileName}
                href={file}
                target='_blank'
                style={{ textDecoration: 'none' }}
                rel='noreferrer'
            >
                <div className={_.fileBox}>
                    <img className={_.fileIcon} src={fileIcon} alt='file icon' />
                    <div className={_.fileName}>{fileNameArray.length > 1 ? fileNameNoExtension : fileName}</div>
                    {fileNameArray.length > 1 && <div className={_.fileExtension}>{`.${fileExtension}`}</div>}
                </div>
            </a>
        );
    }

    return null;
};

export default CorrespondenceFileAttachment;
