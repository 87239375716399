/* eslint-disable react/no-unused-prop-types */
import { FC } from 'react';

import { Dayjs } from 'dayjs';
import { Form, FormInstance } from 'antd';

import BasicDetails from './components/BasicDetails/BasicDetails';
import StepFourPassport from '../StepFourPassport/StepFourPassport';
import StepFourAddress from '../StepFourAddress/StepFourAddress';
import StepFourBank from '../StepFourBank/StepFourBank';

interface IAddressProps {
    data: any;
    type?: string;
    value?: Dayjs;
    form: FormInstance;

    onChange?: () => void;
    setData: (i: number) => void;
    onInputChange: (key: string, value: string | null) => void;
    onDateChange: (field: string) => (date: Dayjs | null) => void;
    isSubscrActiveOrCreate: boolean;
}

const StepFourEntrepreneur: FC<IAddressProps> = ({
    data,
    onInputChange,
    type,
    form,
    onDateChange,
    isSubscrActiveOrCreate,
}) => (
    <div className='main'>
        <BasicDetails
            form={form}
            data={data}
            onInputChange={onInputChange}
            onDateChange={onDateChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourPassport
            form={form}
            onInputChange={onInputChange}
            onDateChange={onDateChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourAddress
            form={form}
            type={type}
            onInputChange={onInputChange}
            isSubscrActiveOrCreate={isSubscrActiveOrCreate}
        />
        <StepFourBank form={form} onInputChange={onInputChange} isSubscrActiveOrCreate={isSubscrActiveOrCreate} />
    </div>
);

export default StepFourEntrepreneur;
