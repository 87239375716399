import { useNavigate } from 'react-router-dom';
import Button from 'common/Button/Button';

import _ from './TopBar.module.css';

const TopBar = () => {
    const navigate = useNavigate();

    return (
        <div className={_.main}>
            <div className={_.title}>Cообщения</div>

            <div className={_.buttonBlock}>
                <Button
                    width='170px'
                    onClick={() => {
                        navigate('/cabinet/create-message');
                    }}
                >
                    Написать
                </Button>
            </div>
        </div>
    );
};

export default TopBar;
