import clsx from 'clsx';

import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Breadcrumbs from 'common/Breadcrumb/Breadcrumb';
import Container from 'common/StyledElements/Container/Container';
import CalendarBlock from 'common/CalendarBlock/CalendarBlock';
import AskSpec from './components/AskSpec/AskSpec';
import Top from '../../common/Top/Top';
import Webinars from './components/Webinars/Webinars';

import _ from './HotLinePage.module.css';

const title = 'КИНО И ПРАВО';

const routes = [
    {
        path: '',
        breadcrumbName: 'Главная',
    },
    {
        path: 'practice',
        breadcrumbName: title,
    },
];

const HotLinePage = () => (
    <>
        <Header />
        <Top title={title} />

        {/* TODO удалить, пока скрыто 03.04.23
             <NavBar /> */}

        <Container
            style={{
                paddingTop: '0px',
            }}
            column
        >
            <div className={clsx(_.container, _.breadcrumb)}>
                <Breadcrumbs routes={routes} />
            </div>

            <CalendarBlock />
        </Container>

        <Container noRound gray>
            <Webinars />
        </Container>

        <Container>
            <AskSpec />
        </Container>

        <Footer />
    </>
);

export default HotLinePage;
