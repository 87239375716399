import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';

import Text from 'common/StyledElements/Text/Text';
import meatballsMenuIcon from 'assets/orders/meatballsMenuIcon.svg';
import settingsIcon from 'assets/orders/settingsIcon.svg';
import Rate, { ERates } from 'common/Rate/Rate';
import StatusWrapper, { IStatusAliases } from 'common/StatusWrapper/StatusWrapper';
import { PayStatus, TariffType, HotLineType } from './mock';

import _ from './Orders.module.css';
import './styles.css';

const statusAliasesOrders: IStatusAliases = {
    greenCheck: [{ codeName: PayStatus.Paid }],
    redCross: [{ codeName: PayStatus.Cancel }],
    blueHourglass: [{ codeName: PayStatus.InProgress }],
};

type TariffsColumnsType = () => ColumnsType<TariffType>;
type HotLineColumnsType = () => ColumnsType<HotLineType>;

export const tariffsColumns: TariffsColumnsType = () => [
    {
        title: (
            <Text bold mid>
                №
            </Text>
        ),
        dataIndex: 'num',
        key: 'num',
        width: '40px',
        render: (num) => (
            <Text lightGray mid>
                {num}
            </Text>
        ),
    },
    {
        title: (
            <Text style={{ paddingLeft: '40px' }} bold mid>
                Дата
            </Text>
        ),
        dataIndex: 'date',
        key: 'date',
        align: 'left',
        width: '250px',
        render: (date) => <Text mid>{date}</Text>,
    },
    {
        title: (
            <Text className={clsx(_.sortBy, _.order)} bold mid>
                Проект
            </Text>
        ),
        dataIndex: 'projectName',
        key: 'projectName',
        width: '385px',
        render: (order) => (
            <Text style={{ cursor: 'pointer' }} blue mid>
                {order}
            </Text>
        ),
    },

    {
        title: (
            <Text style={{ paddingLeft: '7px' }} bold mid>
                Срок
            </Text>
        ),
        dataIndex: 'term',
        key: 'term',
        width: '235px',
        render: (term) => <Text mid>{term}</Text>,
    },
    {
        title: (
            <Text style={{ paddingLeft: '7px' }} bold mid>
                Модуль
            </Text>
        ),
        dataIndex: 'tariff',
        key: 'tariff',
        width: '235px',
        render: (tariff: ERates) => <Rate type={tariff} />,
    },
    {
        title: (
            <Text style={{ paddingLeft: '7px' }} bold mid>
                Цена
            </Text>
        ),
        dataIndex: 'price',
        key: 'price',
        width: '215px',
        render: (price) => <Text mid>{price}</Text>,
    },
    {
        title: (
            <Text bold mid>
                Статус
            </Text>
        ),
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: '180px',
        render: (status) => <StatusWrapper text={status} statusAliases={statusAliasesOrders} />,
    },
    {
        title: (
            <div className={_.iconWrapper}>
                <img style={{ cursor: 'pointer' }} src={settingsIcon} alt='settings icon' />
            </div>
        ),
        dataIndex: 'settings',
        key: 'settings',
        align: 'right',
        render: () => (
            <div className={_.iconWrapper}>
                <img style={{ cursor: 'pointer' }} src={meatballsMenuIcon} alt='meatballs menu icon' />
            </div>
        ),
    },
];

export const hotLineColumns: HotLineColumnsType = () => [
    {
        title: (
            <Text bold mid>
                №
            </Text>
        ),
        dataIndex: 'num',
        key: 'num',
        width: '140px',
        render: (num) => (
            <Text lightGray mid>
                {num}
            </Text>
        ),
    },
    {
        title: (
            <Text style={{ paddingLeft: '40px' }} bold mid>
                Дата
            </Text>
        ),
        dataIndex: 'date',
        key: 'date',
        align: 'left',
        width: '390px',
        render: (date) => <Text mid>{date}</Text>,
    },

    {
        title: (
            <Text style={{ paddingLeft: '7px' }} bold mid>
                Цена
            </Text>
        ),
        dataIndex: 'price',
        key: 'price',
        width: '280px',
        render: (price) => <Text mid>{price}</Text>,
    },
    {
        title: (
            <Text bold mid>
                Статус
            </Text>
        ),
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: '260px',
        render: (status) => <StatusWrapper text={status} statusAliases={statusAliasesOrders} />,
    },
    {
        title: (
            <div className={_.iconWrapper}>
                <img style={{ cursor: 'pointer' }} src={settingsIcon} alt='settings icon' />
            </div>
        ),
        dataIndex: 'settings',
        key: 'settings',
        align: 'right',
        render: () => (
            <div className={_.iconWrapper}>
                <img style={{ cursor: 'pointer' }} src={meatballsMenuIcon} alt='meatballs menu icon' />
            </div>
        ),
    },
];
