import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import localforage from 'localforage';

import Container from 'common/StyledElements/Container/Container';
import Pagination from 'common/Paginator/Paginator';
import Text from 'common/StyledElements/Text/Text';
import { Messenger, OrderingType, IThreads, IThread } from 'connectors/query/Messenger';
import { isUserData } from 'common/AuthProvider/AuthProvider';
import TopBar from './components/TopBar/TopBar';

import _ from './Threads.module.css';
import './styles.css';

type ColumnsTypeMessages = (
    navigate: (path: string) => void,
    sortedInfo: { columnKey?: OrderingColumn; order?: 'ascend' | 'descend' },
) => ColumnsType<IThread>;

type OrderingColumn = 'lmsg_created_at' | 'title';

const columns: ColumnsTypeMessages = (navigate, sortedInfo) => [
    {
        title: 'Тема',
        dataIndex: 'title',
        key: 'title',
        sorter: () => 0,
        width: 600,
        sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : undefined,
        render: (title, thread) => (
            <div className={_.nameWrapper}>
                <Text bold={!thread.is_lmsg_seen_by_cstmr} mid>
                    <div className={_.wordWrap}>{title}</div>
                </Text>
                <Button
                    className={_.button_link}
                    type='link'
                    onClick={() => {
                        navigate(`/cabinet/correspondence/${thread.id}`);
                    }}
                >
                    Прочитать
                </Button>
            </div>
        ),
    },
    {
        title: 'Дата',
        dataIndex: 'lmsg_created_at',
        key: 'lmsg_created_at',
        width: 200,
        sorter: () => 0,
        sortOrder: sortedInfo.columnKey === 'lmsg_created_at' ? sortedInfo.order : undefined,
        render: (lmsg_created_at) => {
            const dateFormat = new Date(lmsg_created_at);

            return (
                <div className={_.dataTimeInner} style={{ paddingRight: 0 }}>
                    <Text mid>{format(dateFormat, 'dd.MM.yyyy')}</Text>
                    <Text style={{ paddingLeft: '10px' }} mid>
                        {format(dateFormat, 'HH:mm')}
                    </Text>
                </div>
            );
        },
    },
];

const limit = 20;

const Threads = () => {
    const navigate = useNavigate();
    const [threadsAll, setThreadsAll] = useState<IThreads>({ count: 0, results: [] });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [sortedInfo, setSortedInfo] = useState<{ columnKey?: OrderingColumn; order?: 'ascend' | 'descend' }>({
        columnKey: 'lmsg_created_at',
        order: 'descend',
    });

    useEffect(() => {
        fetchAll();
    }, [currentPage, sortedInfo]);

    const fetchAll = async () => {
        setLoadingStatus(true);
        const userData = await localforage.getItem('userData');
        if (isUserData(userData)) {
            let ordering: OrderingType | undefined;
            if (sortedInfo.columnKey) {
                ordering =
                    sortedInfo.order === 'ascend' ? sortedInfo.columnKey : (`-${sortedInfo.columnKey}` as OrderingType);
            }
            const offset = (currentPage - 1) * limit;
            const data = await Messenger.Threads.getAll({
                ordering,
                limit,
                offset,
                participant_user_id: userData?.id,
            });

            if (!(data?.status >= 400 && data?.status < 600) && Array.isArray(data?.results)) {
                setThreadsAll(data as IThreads);
            }
        }
        setLoadingStatus(false);
    };

    const handleTableChange = (_: any, __: any, sorter: any) => {
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.columnKey,
        });
    };

    return (
        <Container style={{ paddingTop: 50 }} column>
            <TopBar />
            <Table
                className='messagesTable'
                columns={columns(navigate, sortedInfo)}
                dataSource={threadsAll.results}
                showSorterTooltip
                pagination={false}
                style={{ marginTop: 50 }}
                loading={loadingStatus}
                onChange={handleTableChange}
            />
            <Pagination
                maxPages={Math.ceil(threadsAll.count / limit)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </Container>
    );
};

export default Threads;
