import { FC } from 'react';
import { Calendar as AntdCalendar } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import clsx from 'clsx';

import arrow from 'assets/arrowPagination.svg';

import _ from './Calendar.module.css';
import './styles.scss';

dayjs.locale('ru');

type DateCellRenderType = (
    selectedDate: Dayjs,
    setSelectedDate: (date: Dayjs) => void,
    getUpcomingEvents: (startDate?: Date) => void,
    currentShownMonth: Date,
    setCurrentShownMonth: (d: Date) => void,
    eventsDays?: Dayjs[],
) => (value: Dayjs) => React.ReactNode | undefined;

const dateCellRender: DateCellRenderType =
    (selectedDate, setSelectedDate, getUpcomingEvents, currentShownMonth, setCurrentShownMonth, eventsDays?) =>
    (value: Dayjs) => {
        const valueDay = value.startOf('day');
        const isSelected = selectedDate.isSame(valueDay, 'date');

        let selectable = false;
        if (eventsDays && eventsDays.length > 0) {
            selectable =
                (valueDay.isSame(eventsDays[0]) || valueDay.isAfter(eventsDays[0])) &&
                (valueDay.isSame(eventsDays[eventsDays.length - 1]) ||
                    valueDay.isBefore(eventsDays[eventsDays.length - 1]));
        }

        const hasEvent =
            eventsDays &&
            eventsDays.some((ed) => {
                if (ed.isSame(valueDay)) {
                    return true;
                }
                return false;
            });

        return (
            <div
                className={clsx(
                    'simpleDate',
                    selectable && 'selectableDate',
                    hasEvent && 'eventDay',
                    isSelected && 'trueSelectedDate',
                )}
                onClick={() => {
                    if (selectable) {
                        setSelectedDate(valueDay);
                        getUpcomingEvents(valueDay.toDate());
                    }
                    if (value.year() > currentShownMonth.getFullYear()) {
                        setCurrentShownMonth(dayjs(currentShownMonth).add(1, 'M').toDate());
                    } else if (value.year() < currentShownMonth.getFullYear()) {
                        setCurrentShownMonth(dayjs(currentShownMonth).add(-1, 'M').toDate());
                    } else if (value.month() < currentShownMonth.getMonth()) {
                        setCurrentShownMonth(dayjs(currentShownMonth).add(-1, 'M').toDate());
                    } else if (value.month() > currentShownMonth.getMonth()) {
                        setCurrentShownMonth(dayjs(currentShownMonth).add(1, 'M').toDate());
                    }
                }}
            >
                {valueDay.date()}
            </div>
        );
    };

type CalendarHeaderRenderType = (
    setCurrentShownMonth: (d: Date) => void,
) => (data: { value: Dayjs; onChange: (date: Dayjs) => void }) => React.ReactNode | undefined;

const CalendarHeaderRender: CalendarHeaderRenderType =
    (setCurrentShownMonth) =>
    ({ value, onChange }) =>
        (
            <div className={_.header}>
                <div className={_.headerTime}>{value.format('MMMM YYYY')}</div>
                <div className={_.headerChangeMonths}>
                    <div
                        className={clsx(_.arrow, _.left)}
                        onClick={() => {
                            onChange(value.add(-1, 'M'));
                            setCurrentShownMonth(value.add(-1, 'M').toDate());
                        }}
                    >
                        <img src={arrow} alt='calendar-left-arrow' />
                    </div>
                    <div
                        className={_.arrow}
                        onClick={() => {
                            onChange(value.add(1, 'M'));
                            setCurrentShownMonth(value.add(1, 'M').toDate());
                        }}
                    >
                        <img src={arrow} alt='calendar-right-arrow' />
                    </div>
                </div>
            </div>
        );

interface ICalendarProps {
    selectedDate: Dayjs;
    setSelectedDate: (date: Dayjs) => void;
    getUpcomingEvents: (startDate?: Date) => void;
    currentShownMonth: Date;
    setCurrentShownMonth: (d: Date) => void;
    eventsDays?: Dayjs[];
}

const Calendar: FC<ICalendarProps> = ({
    selectedDate,
    setSelectedDate,
    getUpcomingEvents,
    setCurrentShownMonth,
    currentShownMonth,
    eventsDays,
}) => (
    <div>
        <AntdCalendar
            className='customCalendar'
            fullscreen={false}
            dateFullCellRender={dateCellRender(
                selectedDate,
                setSelectedDate,
                getUpcomingEvents,
                currentShownMonth,
                setCurrentShownMonth,
                eventsDays,
            )}
            headerRender={CalendarHeaderRender(setCurrentShownMonth)}
        />
    </div>
);

export default Calendar;
