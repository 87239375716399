import { useEffect, useState } from 'react';
import { Table } from 'antd';

import Button from 'common/Button/Button';
// import FindInput from 'common/FindInput/FindInput';
import Container from 'common/StyledElements/Container/Container';
import Paginator from 'common/Paginator/Paginator';
import { PersonalAccount, IInvoice, IPaymentInvoiceOrderingType } from 'connectors/query/PersonalAccount';
import { paymentsColumns, billsColumns } from './columns';

import { bills } from './mock';
import _ from './Payment.module.css';
import './styles.css';

export interface IInvoices {
    count: number;
    results: IInvoice[];
}

const limit = 10;
const Payment = () => {
    const [tab, setTab] = useState<'payments' | 'bills'>('payments');
    const [invoices, setInvoices] = useState<IInvoices>({ count: 0, results: [] });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sort, setSortedInfo] = useState<{ columnKey?: IPaymentInvoiceOrderingType; order?: 'ascend' | 'descend' }>({
        columnKey: 'ends_at',
        order: 'ascend',
    });
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);

    useEffect(() => {
        getInvoices();
    }, [currentPage, sort]);

    const getInvoices = async () => {
        setLoadingStatus(true);
        const result = await PersonalAccount.getInvoices({
            limit,
            offset: (currentPage - 1) * limit,
            ordering: `${sort.order === 'descend' ? '-' : ''}${sort.columnKey}` as IPaymentInvoiceOrderingType,
        });
        if (Array.isArray(result.results)) {
            setInvoices(result);
        }
        setLoadingStatus(false);
    };

    const handleTableChange = (_: any, __: any, sorter: any) => {
        setSortedInfo({
            order: sorter.order,
            columnKey: sorter.columnKey,
        });
    };

    return (
        <Container style={{ paddingTop: 50 }} column>
            <div className={_.top_buttons}>
                <Button
                    style={tab === 'payments' ? { borderColor: '#1677ff' } : {}}
                    grey2
                    width='auto'
                    marginRight='20px'
                    onClick={() => {
                        setTab('payments');
                    }}
                >
                    Платежи
                </Button>
                {/* {
                    <Button
                        style={{
                            ...(tab === 'bills' && { borderColor: '#1677ff' }),
                            paddingLeft: '25px',
                            paddingRight: '25px',
                        }}
                        grey2
                        width='auto'
                        marginRight='20px'
                        onClick={() => {
                            setTab('bills');
                        }}
                    >
                        Счета
                    </Button>
                    // MOVR-621 2. На вкладке оплата не показывать Счета
                } */}
            </div>

            {/* <div className={_.top_FindInputs}>
                <div className={_.top_FindInput_item}>
                    <FindInput mb={30} placeholder={tab === 'payments' ? 'Поиск по платежам' : 'Поиск по счетам'} />
                </div>
            // </div> */}

            {tab === 'payments' && (
                <Table
                    className='paymentTable'
                    columns={paymentsColumns((currentPage - 1) * limit, sort)}
                    dataSource={invoices.results}
                    pagination={false}
                    onChange={handleTableChange}
                    loading={loadingStatus}
                />
            )}
            {tab === 'bills' && (
                <Table className='paymentTable' columns={billsColumns()} dataSource={bills} pagination={false} />
            )}
            <Paginator
                maxPages={Math.ceil(invoices.count / limit)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </Container>
    );
};

export default Payment;
