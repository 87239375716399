import { FC } from 'react';

import _ from './FotoCard.module.css';


interface IFotoCardProps {
    pic: string;
}

const FotoCard: FC<IFotoCardProps> = ({ pic }) => (
    <div className={_.wrapper}>
        <div className={_.img}>
            <img src={pic} alt='svg' className={_.image} />
        </div>
    </div>
);

export default FotoCard;