import { FC, useState } from 'react';
import { Input } from 'antd';

import find from 'assets/statuses/find.svg';

import _ from './FindInput.module.css';

interface IFindInputProps {
    placeholder?: string;
    width?: string | number;
    maxWidth?: string | number;
    mb?: string | number;
    setSearch?: (searchText: string) => void;
}

const FindInput: FC<IFindInputProps> = ({ placeholder, width, maxWidth, mb, setSearch }) => {
    const [inputText, setInputText] = useState<string>('');
    return (
        <div className={_.inputWrapper} style={{ width, maxWidth, marginBottom: mb }}>
            <Input
                value={inputText}
                onChange={(e) => {
                    setInputText(e.target.value);
                    if (e.type === 'click') {
                        setSearch && setSearch('');
                    }
                }}
                onKeyDown={(e) => e.key === 'Enter' && setSearch && setSearch(inputText)}
                className={_.input}
                placeholder={placeholder || ''}
                allowClear
            />
            <div
                onClick={() => {
                    setSearch && setSearch(inputText);
                }}
                className={_.findBtn}
            >
                <img src={find} alt='find' />
            </div>
        </div>
    );
};

export default FindInput;
