import React, { useEffect, useState } from 'react';

import { Form, FormInstance, Select } from 'antd';
import ReactDadataBox from 'react-dadata-box';
import dayjs, { Dayjs } from 'dayjs';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import StepFourEntity from './components/StepFourEntity/StepFourEntity';
import StepFourEntrepreneur from './components/StepFourEntrepreneur/StepFourEntrepreneur';
import StepFourIndividual from './components/StepFourIndividual/StepFourIndividual';

import _ from './StepFour.module.css';

interface IStepFour {
    data: any;
    defaultorgData: any;
    form: FormInstance;
    onInputChange: (key: string, value: any) => void;
    setData: (i: any) => void;
    value?: Dayjs;
    // eslint-disable-next-line react/no-unused-prop-types
    type?: number;
    orgPropertiesIdForEdit?: number;
    isSubscrActiveOrCreate: boolean;
}

const options = [
    {
        value: 'UL',
        label: 'Юридическое лицо',
    },
    {
        value: 'FL',
        label: 'Физическое лицо',
    },
    {
        value: 'IP',
        label: 'ИП',
    },
];

const StepFour: React.FC<IStepFour> = ({
    data,
    setData,
    onInputChange,
    value,
    form,
    defaultorgData,
    orgPropertiesIdForEdit,
    isSubscrActiveOrCreate,
}) => {
    const [keyDadata, setKeyDadata] = useState(1);

    const onChangeUserType = (userType: React.SetStateAction<string>) => {
        setKeyDadata((prev) => prev + 1);

        setData({
            ...data,
            ...defaultorgData,
            userType,
        });
    };

    const onDadataChange = (dadataData: any) => {
        if ((data.userType === 'UL' || data.userType === 'IP') && dadataData && dadataData.data) {
            const { inn, kpp, ogrn, name, address, okpo, okato, okved, management } = dadataData.data;

            const full_name_org = name ? name.full_with_opf || '' : '';
            const short_name_org = name ? name.short_with_opf || '' : '';
            const fia_director = management?.name || '';
            const post_director = management?.post || '';
            const legal_address = address?.unrestricted_value || '';

            const newData = {
                ...data,
                inn: inn || data.inn,
                kpp: kpp || data.kpp,
                okpo: okpo || data.okpo,
                okato: okato || data.okato,
                okved: okved || data.okved,
                ogrn: ogrn || data.ogrn,
                ogrnip: ogrn || data.ogrnip,
                full_name_org: full_name_org || data.full_name_org,
                legal_address: legal_address || data.legal_address,
                short_name_org: short_name_org || data.short_name_org,
                fio_sole_executive_body: fia_director || data.fio_sole_executive_body,
                job_title_sole_executive_body: post_director || data.job_title_sole_executive_body,
            };

            setData(newData);
        }
    };

    const handleDateChange = (field: string) => (date: Dayjs | null) => {
        const formattedDate = date ? date.format('DD.MM.YYYY') : null;
        onInputChange(field, formattedDate);
    };


    return (
        <>
            <div className='title'>Заказчик</div>
            <div className={_.titleBlock}>
                <div className={_.titleInner}>
                    <Form form={form} name='org_form' layout='vertical' initialValues={{ userType: 'UL' }}>
                        Вид Заказчика
                        <MyFormItem name='userType'>
                            <Select
                                onClick={(e) => e.stopPropagation()}
                                className='select'
                                style={{ width: 320, marginTop: 10 }}
                                onChange={onChangeUserType}
                                options={options}
                                disabled={!!orgPropertiesIdForEdit || !isSubscrActiveOrCreate}
                            />
                        </MyFormItem>
                    </Form>
                </div>
                {data.userType !== 'FL' && isSubscrActiveOrCreate && (
                    <div className={_.reactDadata}>
                        <ReactDadataBox
                            key={keyDadata}
                            className={_.reactDadataBox}
                            placeholder='Введите название организации или ИНН'
                            token='9fcce735e17c77cbba373fe61cd352540ec473e3'
                            type='party'
                            onChange={(dadataData) => {
                                onDadataChange(dadataData);
                            }}
                        />
                    </div>
                )}
            </div>

            {data.userType === 'UL' && (
                <StepFourEntity
                    form={form}
                    type={data.userType}
                    value={value}
                    data={data}
                    onInputChange={onInputChange}
                    onDateChange={handleDateChange}
                    setData={setData}
                    orgPropertiesIdForEdit={orgPropertiesIdForEdit}
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                />
            )}
            {data.userType === 'FL' && (
                <StepFourEntrepreneur
                    form={form}
                    type={data.userType}
                    value={value}
                    data={data}
                    onInputChange={onInputChange}
                    onDateChange={handleDateChange}
                    setData={setData}
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                />
            )}
            {data.userType === 'IP' && (
                <StepFourIndividual
                    form={form}
                    type={data.userType}
                    value={value}
                    data={data}
                    onInputChange={onInputChange}
                    onDateChange={handleDateChange}
                    setData={setData}
                    orgPropertiesIdForEdit={orgPropertiesIdForEdit}
                    isSubscrActiveOrCreate={isSubscrActiveOrCreate}
                />
            )}
        </>
    );
};

export default StepFour;
