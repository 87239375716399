import { FC } from 'react';

import clsx from 'clsx';

import arrow from 'assets/arrowPagination.svg';

import _ from './Paginator.module.css';

interface IPageProps {
    number: number;
    isActive?: boolean;
    skip?: boolean;
    onClick: (num: number) => void;
}

interface IPagination {
    maxPages: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
}

const Page: FC<IPageProps> = ({ number, isActive, skip, onClick }) => (
    <div className={clsx(_.page, isActive && _.activePage)} onClick={() => onClick(number)}>
        {skip ? '...' : number}
    </div>
);

const Paginator: FC<IPagination> = ({ maxPages, currentPage, setCurrentPage }) => {
    if (maxPages <= 1) {
        return null;
    }

    const incPage = () => setCurrentPage(currentPage + 1);
    const decPage = () => setCurrentPage(currentPage - 1);
    const newPag = true;

    const renderInside = (): JSX.Element[] => {
        const buttons: JSX.Element[] = [];
        const leftEdge = Math.max(2, currentPage - 2);
        const rightEdge = Math.min(maxPages - 1, currentPage + 2);

        for (let i = leftEdge; i <= rightEdge; i += 1) {
            const isSkipped = (i === leftEdge && i > 2) || (i === rightEdge && i < maxPages - 1);
            buttons.push(
                <Page key={i} number={i} isActive={currentPage === i} skip={isSkipped} onClick={setCurrentPage} />,
            );
        }
        return buttons;
    };

    if (newPag) {
        return (
            <div className={_.wrapper}>
                <div className={_.container}>
                    {currentPage > 1 && (
                        <div className={clsx(_.page, _.arrow, _.leftArrow)} onClick={decPage}>
                            <img src={arrow} alt='arrow' />
                        </div>
                    )}

                    <Page number={1} isActive={currentPage === 1} onClick={setCurrentPage} />

                    {maxPages > 2 && <>{renderInside()}</>}

                    {maxPages > 1 && (
                        <Page number={maxPages} isActive={currentPage === maxPages} onClick={setCurrentPage} />
                    )}

                    {currentPage < maxPages && (
                        <div className={clsx(_.page, _.arrow)} onClick={incPage}>
                            <img src={arrow} alt='arrow' />
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return (
        <div className={_.wrapper}>
            <div className={_.container}>
                {currentPage > 1 && (
                    <div className={clsx(_.page, _.arrow, _.leftArrow)} onClick={decPage}>
                        <img src={arrow} alt='arrow' />
                    </div>
                )}

                {[...Array(maxPages)].map((e, i) => (
                    <Page key={i} number={i + 1} isActive={currentPage === i + 1} onClick={setCurrentPage} />
                ))}

                {currentPage < maxPages && (
                    <div className={clsx(_.page, _.arrow)} onClick={incPage}>
                        <img src={arrow} alt='arrow' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Paginator;
