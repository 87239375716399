import { useEffect, useState } from 'react';

import { Form, Input, FormInstance } from 'antd';

import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import Button from 'common/Button/Button';
import Icon from 'common/Icon/Icon';
import bucketIcon from 'assets/statuses/bucket.svg';

interface IAuthorsAndDirectors {
    data: any;
    onInputChange: (key: string, value: string | null) => void;
    maxAuthorEntities: number;
    numberOfEntities: { authors: number; directors: number };
    setNumberOfEntities: (
        newVal:
            | { authors: number; directors: number }
            | ((prevVal: { authors: number; directors: number }) => { authors: number; directors: number }),
    ) => void;
    setData: (i: any) => void;
    form: FormInstance;
    isSubscrActiveOrCreate: boolean;
}

type EntityType = 'authors' | 'directors';

const AuthorsAndDirectors: React.FC<IAuthorsAndDirectors> = ({
    onInputChange,
    data,
    maxAuthorEntities,
    numberOfEntities,
    setNumberOfEntities,
    setData,
    form,
    isSubscrActiveOrCreate,
}) => {
    const [isFormValid, setIsFormValid] = useState(false);

    const getButtonTitle = (type: EntityType, idx: number, action: 'add' | 'remove'): string => {
        const name = type === 'authors' ? 'автора' : 'режиссера';
        if (action === 'add') {
            return `Добавить ${name} ${idx + 2}`;
        }
        return `Удалить ${name} ${idx + 1}`;
    };

    const addEntity = (type: EntityType) => {
        setNumberOfEntities((prev) => {
            if (prev[type] < maxAuthorEntities) {
                return { ...prev, [type]: prev[type] + 1 };
            }
            return prev;
        });
    };

    const removeEntity = (type: EntityType, idx: number) => {
        if (numberOfEntities[type] > 1) {
            const entityInfix = type === 'authors' ? 'screenwriter' : 'director';

            const arrayOfEntities = [];
            const emptyAllEntities = {};

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i <= maxAuthorEntities; i++) {
                // забираем всех людей в массив
                // @ts-ignore
                const name = data[`name_${entityInfix}_${i}`] || '';
                // @ts-ignore
                const surname = data[`surname_${entityInfix}_${i}`] || '';
                // @ts-ignore
                const patronymic = data[`patronymic_${entityInfix}_${i}`] || '';
                // @ts-ignore
                const nickname = data[`nickname_${entityInfix}_${i}`] || '';

                // заполняем объект, которым обнулим людей в data
                // @ts-ignore
                emptyAllEntities[`name_${entityInfix}_${i}`] = '';
                // @ts-ignore
                emptyAllEntities[`surname_${entityInfix}_${i}`] = '';
                // @ts-ignore
                emptyAllEntities[`patronymic_${entityInfix}_${i}`] = '';
                // @ts-ignore
                emptyAllEntities[`nickname_${entityInfix}_${i}`] = '';
                arrayOfEntities.push({ name, surname, patronymic, nickname });
            }

            // удаляем выбранного человека из массива
            arrayOfEntities.splice(idx, 1);

            const entitiesToInsert = {};
            arrayOfEntities.forEach((entity, index) => {
                // заполняем объект, которым заполним людей в data после обнуления
                // @ts-ignore
                entitiesToInsert[`name_${entityInfix}_${index}`] = entity?.name || '';
                // @ts-ignore
                entitiesToInsert[`surname_${entityInfix}_${index}`] = entity?.surname || '';
                // @ts-ignore
                entitiesToInsert[`patronymic_${entityInfix}_${index}`] = entity?.patronymic || '';
                // @ts-ignore
                entitiesToInsert[`nickname_${entityInfix}_${index}`] = entity?.nickname || '';
            });

            setData((prevVal: any) => ({ ...prevVal, ...emptyAllEntities, ...entitiesToInsert }));
            setNumberOfEntities((prev) => ({ ...prev, [type]: prev[type] - 1 }));
        }
    };

    return (
        <>
            <div className='blockCard'>
                <Form form={form} name='name_form' layout='vertical' scrollToFirstError>
                    <div className='titleCard'>Автор сценария</div>
                    {[...Array(numberOfEntities.authors)].map((_, idx) => (
                        <div key={idx}>
                            <div className='titleCard titleCard_item'>{`Автор ${idx > 0 ? ` ${idx + 1}` : ''}`}</div>
                            <div className='filmInfo'>
                                <MyFormItem
                                    name={`name_screenwriter_${idx}`}
                                    label='Имя'
                                    rules={[{ required: true, message: 'Пожалуйста, введите имя.' }]}
                                >
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`name_screenwriter_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>

                                <MyFormItem
                                    name={`surname_screenwriter_${idx}`}
                                    label='Фамилия'
                                    rules={[{ required: true, message: 'Пожалуйста, введите фамилию.' }]}
                                >
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`surname_screenwriter_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                <MyFormItem name={`patronymic_screenwriter_${idx}`} label='Отчество'>
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`patronymic_screenwriter_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                <MyFormItem name={`nickname_screenwriter_${idx}`} label='Псевдоним'>
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`nickname_screenwriter_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                {numberOfEntities.authors > 1 && isSubscrActiveOrCreate && (
                                    <Icon className='bucket' onClick={() => removeEntity('authors', idx)}>
                                        <img src={bucketIcon} alt='bucket' />
                                    </Icon>
                                )}
                            </div>
                        </div>
                    ))}
                    {numberOfEntities.authors < maxAuthorEntities && isSubscrActiveOrCreate && (
                        <div className='addBtn'>
                            <Button width='290px' onClick={() => addEntity('authors')} plus>
                                {getButtonTitle('authors', numberOfEntities.authors - 1, 'add')}
                            </Button>
                        </div>
                    )}
                </Form>
            </div>

            <div className='blockCard'>
                <Form form={form} name='name_form' layout='vertical'>
                    <div className='titleCard'>Режиссер-постановщик</div>
                    {[...Array(numberOfEntities.directors)].map((_, idx) => (
                        <div key={idx}>
                            <div className='titleCard titleCard_item'>{`Режиссер-постановщик${
                                idx > 0 ? ` ${idx + 1}` : ''
                            }`}</div>
                            <div className='filmInfo'>
                                <MyFormItem
                                    name={`name_director_${idx}`}
                                    label='Имя'
                                    rules={[{ required: true, message: 'Пожалуйста, введите имя.' }]}
                                >
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) => onInputChange(`name_director_${idx}`, event.target.value)}
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                <MyFormItem
                                    name={`surname_director_${idx}`}
                                    label='Фамилия'
                                    rules={[{ required: true, message: 'Пожалуйста, введите фамилию.' }]}
                                >
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`surname_director_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                <MyFormItem name={`patronymic_director_${idx}`} label='Отчество'>
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`patronymic_director_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                <MyFormItem name={`nickname_director_${idx}`} label='Псевдоним'>
                                    <Input
                                        className='inputInner inputInnerShort4'
                                        onChange={(event) =>
                                            onInputChange(`nickname_director_${idx}`, event.target.value)
                                        }
                                        disabled={!isSubscrActiveOrCreate}
                                    />
                                </MyFormItem>
                                {numberOfEntities.directors > 1 && isSubscrActiveOrCreate && (
                                    <Icon className='bucket' onClick={() => removeEntity('directors', idx)}>
                                        <img src={bucketIcon} alt='bucket' />
                                    </Icon>
                                )}
                            </div>
                        </div>
                    ))}
                    {numberOfEntities.directors < maxAuthorEntities && isSubscrActiveOrCreate && (
                        <div className='addBtn'>
                            <Button width='290px' onClick={() => addEntity('directors')} plus>
                                {getButtonTitle('directors', numberOfEntities.directors - 1, 'add')}
                            </Button>
                        </div>
                    )}
                </Form>
            </div>
        </>
    );
};

export default AuthorsAndDirectors;
