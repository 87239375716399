import { APIService } from 'connectors/api/rest';

export enum Status {
    New = 'N',
    Closed = 'C',
}

export type OrderingType = '-title' | 'title' | '-lmsg_created_at' | 'lmsg_created_at';

export interface IAttachment {
    attachment: string;
}
export interface IMessage {
    attachments: IAttachment[];
    content: string;
    created_at: string;
    id: number;
    is_seen_by_cstmr: boolean;
    thread: number;
    updated_at: string;
    sender_user: number | null;
}

export interface IThread {
    id: number;
    is_lmsg_seen_by_cstmr: boolean;
    lmsg_created_at: string;
    status: string | null;
    title: string;
    messages: IMessage[];
}

export interface IThreads {
    count: number;
    results: IThread[];
}

export interface ICreateThreadParams {
    title: string;
    acceptance_user_agreement: boolean;
    captcha_token?: string;
    'message.content'?: string;
    'message.attachments': File[];
    'message.sender_guest.last_name'?: string;
    'message.sender_guest.first_name'?: string;
    'message.sender_guest.email'?: string;
}

export interface IGetThreadsParams {
    participant_user_id: number;
    ordering?: OrderingType;
    limit?: number;
    offset?: number;
}

export interface ICreateNewMessageParams {
    thread: number;
    content: string;
    attachments: File[];
    sender_user?: number;
    'sender_guest.last_name'?: string;
    'sender_guest.first_name'?: string;
    'sender_guest.email'?: string;
}

export interface IGetMessagesParams {
    message_id?: number;
    sender?: number;
    sender_emailL?: string;
    ordering?: OrderingType;
    limit?: number;
    offset?: number;
}

export const Threads = {
    async getAll(params?: IGetThreadsParams) {
        try {
            return await APIService.GET('/messenger/threads/', params);
        } catch (e) {
            return e;
        }
    },
    async getOne(id: number) {
        try {
            return await APIService.GET(`/messenger/threads/${id}`);
        } catch (e) {
            return e;
        }
    },
    async create(params: ICreateThreadParams) {
        const p = new FormData();

        Object.entries(params).forEach((entry) => {
            const [key, value] = entry;
            if (key === 'message.attachments') {
                if (Array.isArray(value)) {
                    value.forEach((attachment) => p.append('message.attachment', attachment));
                }
            } else {
                p.append(key, value);
            }
        });

        try {
            return await APIService.POST(`/messenger/threads/`, p, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            return e;
        }
    },
};

export const MessagesNew = {
    async getAll(params?: IGetMessagesParams) {
        try {
            return await APIService.GET('/messenger/messages/', params);
        } catch (e) {
            return e;
        }
    },
    async getOne(id: number) {
        try {
            return await APIService.GET(`/messenger/messages/${id}/`);
        } catch (e) {
            return e;
        }
    },
    async create(params: ICreateNewMessageParams) {
        const p = new FormData();

        Object.entries(params).forEach((entry) => {
            const [key, value] = entry;
            if (key === 'attachments') {
                if (Array.isArray(value)) {
                    value.forEach((attachment) => p.append('attachment', attachment));
                }
            } else {
                p.append(key, value);
            }
        });

        try {
            return await APIService.POST(`/messenger/messages/`, p, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (e) {
            return e;
        }
    },
    async setMessageViewed(id: number) {
        try {
            return await APIService.PATCH(`/messenger/messages/${id}/`, { is_seen_by_cstmr: true });
        } catch (e) {
            return e;
        }
    },
};

export const Messenger = {
    Threads,
    MessagesNew,
};
