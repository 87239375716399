import { Dispatch, FC, SetStateAction } from 'react';

import { SmartCaptcha } from '@yandex/smart-captcha';

interface IYandexCaptchaProps {
    setCaptchaToken: Dispatch<SetStateAction<string>>;
    resetCaptchaToken: number;
}

const YandexCaptcha: FC<IYandexCaptchaProps> = ({ setCaptchaToken, resetCaptchaToken }) => {
    const domain = window.location.hostname;
    const sitekey =
        domain === 'movie-right.ru'
            ? 'ysc1_AS0Z8bjRtGfLCBgPgupuJ4yVzRHjdSHefGSnfvSQc1949cb6'
            : 'CknTD8VMYTCi168h5HxhKf6PXbHACpyn7A6ZzUch';

    return <SmartCaptcha key={resetCaptchaToken} sitekey={sitekey} onSuccess={setCaptchaToken} language='ru' />;
};

export default YandexCaptcha;
