import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Table from 'antd/es/table';
import dayjs from 'dayjs';
import clsx from 'clsx';

import {
    openErrorNotification,
    openInfoNotification,
    openSuccessNotification,
} from 'common/Notifications/Notifications';
import Button from 'common/Button/Button';
import Footer from 'common/Footer/Footer';
import Header from 'common/Header/Header';
import Title from 'common/StyledElements/Title/Title';
import TopPageName from 'common/TopCabinetPageName/TopCabinetPageName';
import Container from 'common/StyledElements/Container/Container';
import Divider from 'common/StyledElements/Divider/Divider';
import Link from 'common/StyledElements/Link/Link';
import BackButton from 'common/BackButton/BackButton';
import Text from 'common/StyledElements/Text/Text';
import {
    defaultProject,
    IParticipant,
    IPerson,
    IProject,
    isProject,
    PersonalAccount,
} from 'connectors/query/PersonalAccount';
import {
    ISubscription,
    ESubscriptionStatuses,
    TSubscriptionStatusesWithTrial,
    ESubscriptionStatusTrial,
} from 'connectors/query/Subscriptions';
import { ITimeLeftLabels, createLabelByNumber, createTimeLeftLabel, dayDiff } from 'utils/utils';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';
import AuthContext, { IUserData } from 'common/AuthProvider/AuthProvider';
import { Users } from 'connectors/query/Users';
import Loader from 'common/Loader/Loader';
import InfoBlock from './components/InfoBlock/InfoBlock';
import Row from './components/Row/Row';
import { columnsUsers } from './columns';
import ProjectContracts from './components/ProjectContracts/ProjectContracts';
import ProjectProperties from './components/ProjectProperties/ProjectProperties';
import ProjectCustomerDetails from './components/ProjectCustomerDetails/ProjectCustomerDetails';

import _ from './ProjectPage.module.css';
import './styles.scss';

const ProjectPage = () => {
    const { userData } = useContext(AuthContext);

    const navigate = useNavigate();
    const { id: projectId } = useParams();
    const [form] = useForm();
    const [showFullProps, setShowFullProps] = useState<boolean>(false);
    const [showFullPeople, setShowFullPeople] = useState<boolean>(false);
    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [showRemoveParticipantModal, setShowRemoveParticipantModal] = useState<boolean>(false);
    const [participantEmailToRemove, setParticipantEmailToRemove] = useState<string>('');
    const [participantNameToRemove, setParticipantNameToRemove] = useState<string>('');

    const [project, setProject] = useState<IProject>(defaultProject);
    const [recentActiveSubscr, setRecentActiveSubscr] = useState<ISubscription | null>(null);
    const [recentAnySubscrIfNoActive, setRecentAnySubscrIfNoActive] = useState<ISubscription | null>(null);
    const [participants, setParticipants] = useState<IPerson[]>([]);
    const [avaibleNumberOfParticipants, setAvaibleNumberOfParticipants] = useState<number>(0);
    const [labelTimeLeft, setLabelTimeLeft] = useState<ITimeLeftLabels>({ prevWord: '', timeLeft: '' });
    const [projectSubscriptionStatus, setProjectSubscriptionStatus] = useState<TSubscriptionStatusesWithTrial | null>(
        null,
    );
    const [projectLatestPlan, setProjectLatestPlan] = useState<number | null>(null);
    const [createdByUser, setCreatedByUser] = useState<IUserData | null>(null);

    useEffect(() => {
        getProject();
    }, []);

    useEffect(() => {
        const fetchCreatorData = async () => {
            if (project.created_by) {
                const data = await Users.getUser(project.created_by);
                setCreatedByUser(data);
            }
        };

        fetchCreatorData();
    }, [project]);

    const openRemoveParticipantModal = (name: string, email: string) => {
        setShowRemoveParticipantModal(true);
        setParticipantEmailToRemove(email);
        setParticipantNameToRemove(name);
    };

    const closeRemoveParticipantModal = () => {
        setShowRemoveParticipantModal(false);
        setParticipantEmailToRemove('');
        setParticipantNameToRemove('');
    };

    const getProject = async () => {
        setIsPageLoading(true);
        if (projectId) {
            const data = await PersonalAccount.getProject(projectId);
            if (isProject(data)) {
                const peopleData = data.participants.map((participant: IParticipant) => participant.participant);
                const filteredSubs = data.subscriptions.filter(
                    (subscription) => subscription.status !== ESubscriptionStatuses.Pending,
                );
                const activeSubscrs = data.subscriptions.filter(
                    (subscr) => subscr.status === ESubscriptionStatuses.Active,
                );
                let lastActiveSubscr = null;
                let lastAnySubscr = null;

                if (activeSubscrs.length >= 2) {
                    lastActiveSubscr = activeSubscrs.reduce((prevSubscr, currentSubscr) =>
                        dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0 ? currentSubscr : prevSubscr,
                    );
                }
                if (activeSubscrs.length === 1) {
                    [lastActiveSubscr] = activeSubscrs;
                }

                if (filteredSubs.length >= 2) {
                    lastAnySubscr = filteredSubs.reduce((prevSubscr, currentSubscr) =>
                        dayjs(currentSubscr.ends_at).diff(dayjs(prevSubscr.ends_at)) > 0 ? currentSubscr : prevSubscr,
                    );
                }
                if (filteredSubs.length === 1) {
                    [lastAnySubscr] = filteredSubs;
                }

                setProject(data);
                setRecentActiveSubscr(lastActiveSubscr);
                setRecentAnySubscrIfNoActive(lastAnySubscr);
                setParticipants(peopleData);
                setAvaibleNumberOfParticipants(
                    lastActiveSubscr && lastActiveSubscr.plan.max_participants - peopleData.length > 0
                        ? lastActiveSubscr.plan.max_participants - peopleData.length
                        : 0,
                );
                setSubscrTimeLeft(lastActiveSubscr?.ends_at);

                let isTrial = null;
                if (lastActiveSubscr?.status === ESubscriptionStatuses.Active && lastActiveSubscr.plan.is_trial) {
                    isTrial = ESubscriptionStatusTrial.Trial;
                }

                setProjectSubscriptionStatus(isTrial || lastActiveSubscr?.status || lastAnySubscr?.status || null);
                let latestPlanNotTrial = null;
                if (lastActiveSubscr && !lastActiveSubscr.plan.is_trial) {
                    latestPlanNotTrial = lastActiveSubscr.plan.id;
                } else if (lastAnySubscr && !lastAnySubscr.plan.is_trial) {
                    latestPlanNotTrial = lastAnySubscr.plan.id;
                }

                setProjectLatestPlan(latestPlanNotTrial);

                document.title = `Проект: ${data.name}`;
            } else {
                navigate('/cabinet/projects/add');
            }
        }
        setIsPageLoading(false);
    };

    const setSubscrTimeLeft = (date?: string | null) => {
        const timeLeft = date ? dayDiff(date) : { days: 0, hours: 0, minutes: 0 };
        setLabelTimeLeft(createTimeLeftLabel(timeLeft));
    };

    const removeParticipant = async (id: string | number, email: string) => {
        const res = await PersonalAccount.removeParticipant(id, email);
        if (res.status !== 'ok' && res.status !== 200 && res.status !== 201 && res.status !== 204) {
            Object.keys(res?.data).forEach((key) => {
                openErrorNotification('Ошибка!', res?.data[key]);
            });
        } else {
            getProject();
            openSuccessNotification('Пользователь удален из проекта.');
            closeRemoveParticipantModal();
        }
    };

    const addParticipant = async (values: any) => {
        const { email } = values;

        if (email && projectId) {
            const res = await PersonalAccount.addParticipant(projectId, email);
            if (res.status !== 'ok' && res.status !== 200 && res.status !== 201) {
                if (res?.data.error_code === 'wrong email' || res?.data.detail === 'Пользователь не найден') {
                    form.setFields([
                        {
                            name: 'email',
                            errors: ['В проект можно пригласить только зарегистрированных пользователей'],
                        },
                    ]);
                } else {
                    Object.keys(res?.data).forEach((key) => {
                        openErrorNotification('Ошибка!', res?.data[key]);
                    });
                    form.resetFields();
                }

                Object.keys(res?.data).forEach((key) => {
                    form.setFields([{ name: key, errors: res?.data[key] }]);
                });
            } else {
                openSuccessNotification('Пользователь добавлен!');
                setShowInviteModal(false);
                form.resetFields();
                getProject();
            }
        }
    };

    const additionalProps = [
        project.description,
        project.working_name,
        project.project_type,
        project.genre,
        project.timing,
        project.number_episodes,
        project.episode_timing,
        project.media_type,
        project.color,
        project.language,
        project.fio_screenwriter,
        project.fio_director,
    ];

    const hasAdditionalProps = additionalProps.some((prop) => prop);

    return isPageLoading ? (
        <Loader />
    ) : (
        <>
            <Header />

            <TopPageName>
                <div style={{ width: '100%' }}>
                    <Title mb={50}>{project.name}</Title>
                </div>
            </TopPageName>

            <Container style={{ paddingTop: 100 }} column>
                <InfoBlock
                    labelTimeLeft={labelTimeLeft}
                    recentActiveSubscr={recentActiveSubscr}
                    recentAnySubscrIfNoActive={recentAnySubscrIfNoActive}
                    projectSubscriptionStatus={projectSubscriptionStatus}
                    projectLatestPlan={projectLatestPlan}
                    projectId={projectId}
                />

                <BackButton
                    className={_.backButton}
                    onClick={() => {
                        navigate('/cabinet/projects/add');
                    }}
                >
                    Проекты
                </BackButton>

                <div className={_.block}>
                    {(recentActiveSubscr?.plan.product.name || recentAnySubscrIfNoActive?.plan.product.name) && (
                        <Title mb={20} width={550} middle>
                            Модуль
                            {`«${
                                recentActiveSubscr?.plan.product.name ||
                                recentAnySubscrIfNoActive?.plan.product.name ||
                                '...'
                            }»`}
                        </Title>
                    )}

                    <Title mb={50} small>
                        Свойства проекта
                    </Title>

                    <Divider />

                    <Row
                        title='Владелец проекта'
                        text={createdByUser ? `${createdByUser.first_name} ${createdByUser.last_name}` : '...'}
                    />

                    <Row
                        title='Дата создания проекта'
                        text={project.created_at ? dayjs(project.created_at).format('DD.MM.YYYY') : '...'}
                    />
                </div>

                <div className={_.block}>
                    <div className={clsx(_.flex, _.jcSb)}>
                        <Title mt={50} mb={40} small>
                            Пользователи проекта
                        </Title>
                        <div className={_.flex}>
                            <div className={_.flex}>
                                <Text style={{ marginRight: 5 }} mid>
                                    Доступно для приглашения:
                                </Text>
                                <Text mid bold>
                                    {`${avaibleNumberOfParticipants} ${createLabelByNumber(
                                        avaibleNumberOfParticipants,
                                        ['новый пользователь', 'новых пользователя', 'новых пользователей'],
                                    )}`}
                                </Text>
                            </div>
                            <Button
                                style={{ marginLeft: 30 }}
                                width='170px'
                                onClick={() => {
                                    if (
                                        recentActiveSubscr?.status !== ESubscriptionStatuses.Active ||
                                        recentActiveSubscr?.plan.is_trial
                                    ) {
                                        openInfoNotification(
                                            'Для приглашения новых пользователей необходимо оплатить подписку.',
                                        );
                                        return;
                                    }
                                    avaibleNumberOfParticipants > 0
                                        ? setShowInviteModal(true)
                                        : openInfoNotification('Исчерпан лимит пользователей для текущего тарифа.');
                                }}
                            >
                                Пригласить
                            </Button>
                        </div>
                    </div>

                    <Table
                        className='addProjectTable tableWithCustomLastTh'
                        columns={columnsUsers(navigate, openRemoveParticipantModal, project.organizer_user)}
                        dataSource={showFullPeople ? participants : participants.slice(0, 2)}
                        pagination={false}
                    />
                    {participants.length > 2 && (
                        <Link style={{ marginTop: 20 }} onClick={() => setShowFullPeople(!showFullPeople)}>
                            {!showFullPeople ? 'Развернуть' : 'Скрыть'}
                        </Link>
                    )}
                </div>

                <ProjectProperties projectId={projectId} isActiveProject={!!recentActiveSubscr} />
                <ProjectCustomerDetails
                    orgId={project?.organizer_organization?.id}
                    projectId={projectId}
                    isActiveProject={!!recentActiveSubscr}
                />
                <ProjectContracts projectId={projectId} isActiveProject={!!recentActiveSubscr} />
            </Container>

            <Modal
                className={_.modalCustom}
                open={showInviteModal}
                onOk={() => {
                    setShowInviteModal(false);
                }}
                onCancel={() => {
                    setShowInviteModal(false);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div style={{ height: 414 }} className={_.modalContainer}>
                        <div className={_.titleInvite}>
                            <Title mb={30} width={468}>
                                Приглашение нового пользователя в проект
                            </Title>
                        </div>

                        <Form name='participant' className='authForm' form={form} onFinish={addParticipant}>
                            <Form.Item
                                name={['email']}
                                label='E-mail пользователя'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Введён некорректный e-mail',
                                    },
                                    {
                                        required: true,
                                        message: 'Введён некорректный e-mail',
                                    },
                                    {
                                        message: 'Не более 64 символов',
                                        max: 64,
                                    },
                                ]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={_.emailParticipantWrapper}
                            >
                                <Input
                                    maxLength={64}
                                    className={_.emailParticipant}
                                    onBlur={() => handleInputBlur(form, 'email')}
                                />
                            </Form.Item>
                            <Button htmlType='submit' width='407px'>
                                Пригласить
                            </Button>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={_.modalCustom}
                open={showRemoveParticipantModal}
                onOk={() => {
                    closeRemoveParticipantModal();
                }}
                onCancel={() => {
                    closeRemoveParticipantModal();
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainerRemoveCustomer}>
                        <div className={_.titleInvite}>
                            <Title mb={30} width={468}>
                                Удаление пользователя из проекта
                            </Title>
                        </div>

                        <Text center mb={30} width={530}>
                            Подтвердите удаление <b>{participantNameToRemove}</b> из проекта <b>{project.name}</b>
                        </Text>
                        <div className={_.btnsRemoveParticipantModal}>
                            <Button
                                onClick={() => {
                                    removeParticipant(project.id, participantEmailToRemove);
                                }}
                                htmlType='submit'
                                width='250px'
                            >
                                Удалить
                            </Button>

                            <Button
                                onClick={() => {
                                    closeRemoveParticipantModal();
                                }}
                                htmlType='submit'
                                width='250px'
                            >
                                Отмена
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Footer />
        </>
    );
};

export default ProjectPage;
