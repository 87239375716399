import { APIService } from 'connectors/api/rest';

export interface ITagsData {
    id: number;
    name: string;
}

export const Tags = {
    async getAllTags() {
        try {
            return await APIService.GET('/tags/');
        } catch (e) {
            return e;
        }
    },
};
