import { Rule } from 'antd/es/form';

const hyphenAtStartEndValitationFunctionWrapper: (label: string) => Rule = (label) => ({
    validator(obj, value: any) {
        if (value && typeof value === 'string' && (value.startsWith('-') || value.endsWith('-'))) {
            return Promise.reject(
                new Error(
                    `Поле «${label}» не может начинаться с спец. символа дефис или заканчиваться спец. символом дефис.`,
                ),
            );
        }
        return Promise.resolve();
    },
});

const surnameRules = [
    { required: true, message: 'Поле «Фамилия» обязательно для заполнения' },
    {
        message:
            'Поле «Фамилия» содержит недопустимые символы. Разрешено: кириллица, спец. символы (дефис, пробел, точка, апостроф, запятая, открывающая скобка, закрывающая скобка).',
        pattern: /^[а-яА-Я- .',()]+$/,
    },
    hyphenAtStartEndValitationFunctionWrapper('Фамилия'),
];

const nameRules = [
    { required: true, message: 'Поле «Имя» обязательно для заполнения' },
    {
        message:
            'Поле «Имя» содержит недопустимые символы. Разрешено: кириллица, спец. символы (дефис, пробел, точка, апостроф, запятая, открывающая скобка, закрывающая скобка).',
        pattern: /^[а-яА-Я- .',()]+$/,
    },
    hyphenAtStartEndValitationFunctionWrapper('Имя'),
];

const patronymicRules = [
    {
        message:
            'Поле «Отчество» содержит недопустимые символы. Разрешено: кириллица, спец. символы (дефис, пробел, точка, апостроф, запятая, открывающая скобка, закрывающая скобка).',
        pattern: /^[а-яА-Я- .',()]+$/,
    },
    hyphenAtStartEndValitationFunctionWrapper('Отчество'),
];

const emailValitationFunction: Rule = () => ({
    validator(obj, value) {
        if (
            !value ||
            (/^[a-zA-Z0-9_.-]+@([a-zA-Z0-9_.-]+\.)+[a-zA-Z]{2,}$/.test(value) &&
                !/^\.+.*$/.test(value) &&
                !/^.*\.+$/.test(value) &&
                !/^.*-+$/.test(value) &&
                !/^-+.*$/.test(value) &&
                !/@[-.]{1}/.test(value) &&
                !/[-.]{1}@/.test(value) &&
                !/[-.]{1}[-.]{1}/.test(value))
        ) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Введите корректный адрес электронной почты'));
    },
});

const digitsOnly: Rule = {
    pattern: /^\d*$/,
    message: 'Допускается только ввод цифр',
};

const emailRules: Rule[] = [
    emailValitationFunction,
    {
        required: true,
        message: 'Поле «Email» обязательно для заполнения',
    },
];

const passwordRules = [
    { required: true, message: 'Пожалуйста, введите пароль' },
    {
        pattern: /^(?=.*[a-z]).{1,}$/,
        message: 'Пароль должен содержать хотя бы одну строчную букву',
    },
    {
        pattern: /^(?=.*[A-Z]).{1,}$/,
        message: 'Пароль должен содержать хотя бы одну заглавную букву',
    },
    {
        pattern: /^(?=.*\d).{1,}$/,
        message: 'Пароль должен содержать хотя бы одну цифру',
    },
    {
        pattern: /^(?=.*[\W_]).{1,}$/,
        message: 'Пароль должен содержать хотя бы один спецсимвол',
    },
    {
        pattern: /^[^\u0400-\u04FF]+$/,
        message: 'Пароль не должен содержать кириллические символы',
    },
    { min: 8, message: 'Минимальная длина пароля — 8 символов' },
    { max: 64, message: 'Максимальная длина пароля — 64 символа' },
];

const validationRules = {
    nameRules,
    surnameRules,
    patronymicRules,
    emailRules,
    passwordRules,
    emailValitationFunction,
    digitsOnly,
};

export default validationRules;
