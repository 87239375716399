import { FC, useContext, useState } from 'react';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';

import ButtonBlue from 'common/Button/Button';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import { Messenger, IThread } from 'connectors/query/Messenger';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { validateFile } from 'utils/validateMessageFileType';

import FileUploader from 'common/FileUploader/FileUploader';
import _ from './ReplyMessage.module.css';

const { TextArea } = Input;

interface IReplyMessage {
    thread: IThread | null;
    fetchThread: () => void;
}

const ReplyMessage: FC<IReplyMessage> = ({ thread, fetchThread }) => {
    const [form] = useForm();
    const { userData } = useContext(AuthContext);
    const [files, setFiles] = useState<File[]>([]);

    const customRequest = ({ file }: any) => {
        if (validateFile(file)) {
            setFiles((f) => {
                if (f.length === 5) {
                    openErrorNotification('Превышено максимальное количество файлов', 'Максимум 5 файлов');
                    return [...f];
                }
                return [...f, file];
            });
        }
    };
    const postAnswer = async (content: string) => {
        if (thread && thread.id && userData?.id) {
            const res = await Messenger.MessagesNew.create({
                thread: thread.id,
                content,
                attachments: files,
            });

            if (res?.status >= 400 && res?.status <= 600) {
                Object.keys(res?.data).forEach((key) => {
                    openErrorNotification('Ошибка!', res?.data[key][0]);
                });
                return;
            }

            openSuccessNotification('Сообщение отправлено');
            form.resetFields();
            setFiles([]);
            fetchThread();
        }
    };

    const onFinish = async (values: any) => {
        thread?.id && postAnswer(values.content);
    };

    return (
        <div className={_.inner}>
            <div className={_.title}>Написать сообщение</div>

            <Form form={form} name='message' layout='vertical' className={_.newMessage} onFinish={onFinish}>
                <MyFormItem
                    className={_.form}
                    name='content'
                    label='Ваше сообщение'
                    rules={[{ required: true, message: 'Введите сообщение' }]}
                >
                    <TextArea maxLength={1024} rows={4} className={_.inputInner} />
                </MyFormItem>

                <FileUploader files={files} setFiles={setFiles} customRequest={customRequest} />

                <div className={_.buttonBlock}>
                    <ButtonBlue htmlType='submit' width='250px'>
                        Отправить
                    </ButtonBlue>
                </div>
            </Form>
        </div>
    );
};

export default ReplyMessage;
