import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import localforage from 'localforage';
import { Form, Modal } from 'antd';
import Checkbox from 'antd/es/checkbox';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import clsx from 'clsx';

import { Auth } from 'connectors/query/Auth';
import authPic from 'assets/auth.jpg';
import logo from 'assets/logoEmpty.svg';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import StyledLink from 'common/StyledElements/Link/Link';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import ConfirmEmailModal from 'common/Modals/ConfirmEmailModal/ConfirmEmailModal';
import validationRules from 'utils/formValidationRules';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';

import _ from './Form.module.css';
import './styles.css';


const AuthForm = () => {
    const [formResetPass] = Form.useForm();
    const [formAuth] = Form.useForm();
    const dataAuth = useContext(AuthContext);
    const [confirmEmailIsShown, setConfirmEmailIsShown] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');

    const onFinish = async (values: any) => {
        // @ts-ignore
        const loginData = await Auth.login({ email: values.name, password: values.password });

        if (loginData?.id) {
            await localforage.setItem('userData', loginData);
            dataAuth.checkToken();
            openSuccessNotification('Авторизация прошла успешно');
        } else if (loginData?.data?.error_message) {
            openErrorNotification('Ошибка авторизации!', loginData?.data?.error_message);
        } else {
            openErrorNotification('Ошибка авторизации!', 'Обратитесь к администратору через форму обратной связи.');
        }
    };

    const resetPass = async () => {
        const res = await Auth.passwordReset({ email });
        if (res?.email) {
            if (confirmEmailIsShown) {
                openSuccessNotification('Успешно!', 'Сообщение отправлено повторно');
                return;
            }
            setShowModal(false);
            setConfirmEmailIsShown(true);
            return;
        }

        if (res?.status >= 500 && res?.data?.detail?.includes('SMTP error')) {
            openErrorNotification(
                'Ошибка восстановления пароля!',
                'Почта недоступна. Обратитесь к администратору через форму обратной связи.',
            );
        } else if (res?.status === 400) {
            Object.keys(res?.data).forEach((key) => {
                key !== 'email' && openErrorNotification('Ошибка!', res?.data[key][0]);
                formResetPass.setFields([{ name: key, errors: res?.data[key] }]);
            });
        } else if (res?.status >= 300) {
            openErrorNotification(
                'Ошибка восстановления пароля!',
                'Обратитесь к администратору через форму обратной связи.',
            );
        }
    };

    return (
        <div className={_.wrapper}>
            <div className={_.halfScreen} style={{ display: 'flex', justifyContent: 'flex-end', left: 0 }}>
                <div className={_.container}>
                    <div className={_.leftSideForm}>
                        <Title mb={30}>Вход</Title>

                        <Form form={formAuth} name='auth' className='authForm' onFinish={onFinish}>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['name']}
                                        label='Логин'
                                        rules={[
                                            validationRules.emailValitationFunction,
                                            {
                                                required: true,
                                                message: 'Пожалуйста, введите логин',
                                            },
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input
                                            maxLength={320}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className={_.elParam}
                                            onBlur={() => handleInputBlur(formAuth, 'name')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['password']}
                                        label='Пароль'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Пожалуйста, введите пароль',
                                            },
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input.Password maxLength={64} className={clsx(_.elParam, 'inputPass')} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item name='link' className={_.blockBot__inner}>
                                <StyledLink onClick={() => setShowModal(true)}>Забыли пароль?</StyledLink>
                            </Form.Item>

                            <Form.Item name='remember' className={_.font16} valuePropName='checked'>
                                <Checkbox className={_.font16}>Запомнить меня</Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button width='400px' htmlType='submit'>
                                    Войти
                                </Button>
                            </Form.Item>

                            <div className={_.font16}>
                                Если вы не зарегистрированы, зарегистрируйтесь <Link to='/register'>здесь</Link>
                            </div>
                        </Form>
                    </div>

                    <div className={_.rightSide} />
                </div>
            </div>
            <div className={_.halfScreen} style={{ right: 0, minHeight: 745, maxWidth: 1000 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={_.logoWrapper}>
                        <img src={logo} alt='logo' />
                    </div>
                </div>
                <img style={{ minWidth: '100%' }} src={authPic} alt='background' />
            </div>

            <Modal
                className={_.modalCustom}
                open={showModal}
                onCancel={() => {
                    setShowModal(false);
                    formResetPass.resetFields();
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalWrapper}>
                    <div className={_.modalContainer}>
                        <Title mb={30}>Восстановление пароля</Title>

                        <Form form={formResetPass} name='auth' className='authForm' onFinish={resetPass}>
                            <Row>
                                <Col span={17}>
                                    <Form.Item
                                        name={['email']}
                                        label='Введите ваш адрес электронной почты'
                                        rules={[
                                            validationRules.emailValitationFunction,
                                            {
                                                required: true,
                                                message: 'Поле «Email» обязательно для заполнения',
                                            },
                                        ]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className={_.elParamWrapper}
                                    >
                                        <Input
                                            maxLength={320}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className={_.elParam}
                                            onBlur={() => handleInputBlur(formResetPass, 'email')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button marginTop='10px' width='400px' htmlType='submit'>
                                    Восстановить пароль
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <ConfirmEmailModal
                title='Пароль восстановлен'
                text='Перейдите по ссылке в письме для обновления пароля'
                isShown={confirmEmailIsShown}
                toggleShown={setConfirmEmailIsShown}
                resend={resetPass}
                email={email}
            />
        </div>
    );
};

export default AuthForm;
