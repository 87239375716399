import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Modal } from 'antd';
import localforage from 'localforage';

import Info from 'assets/notifications/info.svg';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import { Auth } from 'connectors/query/Auth';
import { Users } from 'connectors/query/Users';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import AuthContext, { isUserData } from 'common/AuthProvider/AuthProvider';
import validationRules from 'utils/formValidationRules';

import _ from './ResetPassConfirm.module.css';

const validateMessages = {
    required: 'Обязательное поле',
    'error-string': 'test error text',
};

// при переходе на эту страницу видим модалку для обновления пароля
const ResetPassConfirmPage = () => {
    const data = useParams();
    const dataAuth = useContext(AuthContext);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const ResetPassConfirm = async (value: any) => {
        if (data.uid && data.token) {
            const res = await Auth.passwordResetConfirm({ uid: data.uid, token: data.token, ...value });

            if (res?.status !== 'ok' && res?.status !== 200 && res?.status !== 201) {
                Object.keys(res?.data).forEach((key) => {
                    if (key === 'detail') {
                        openErrorNotification('Ошибка', res.data.detail);
                    } else if (key === 'non_field_errors') {
                        const errorMessage = res.data.non_field_errors.join(' ');
                        openErrorNotification('Ошибка', errorMessage);
                    } else {
                        form.setFields([{ name: key, errors: res?.data[key] }]);
                    }
                });
            } else {
                openSuccessNotification('Пароль успешно изменён!');
                if (typeof res?.user_id === 'number') {
                    const user = await Users.getUser(res.user_id);
                    if (isUserData(user)) {
                        await localforage.setItem('userData', user);
                        dataAuth.checkToken();
                        navigate('/cabinet');
                        return;
                    }
                }
                navigate('/auth');
            }
        } else {
            openErrorNotification('Некорректная ссылка');
            navigate('/auth');
        }
    };

    return (
        <Modal open footer={null} width={700} onCancel={() => navigate('/auth')} centered>
            <div className={_.modalWrapper}>
                <div className={_.modalContainer}>
                    <img style={{ marginBottom: 30 }} src={Info} alt='success' />

                    <Title mb={30}>Смена пароля</Title>

                    <Form
                        name='email'
                        className='authForm'
                        form={form}
                        onFinish={ResetPassConfirm}
                        validateMessages={validateMessages}
                    >
                        <Form.Item
                            name={['password1']}
                            label='Новый пароль'
                            rules={validationRules.passwordRules}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className={_.elParamWrapper}
                        >
                            <Input.Password maxLength={64} className={_.elParam} />
                        </Form.Item>

                        <Form.Item
                            name={['password2']}
                            label='Повторите новый пароль'
                            rules={[
                                { required: true },
                                { min: 8, message: 'Минимальная длина пароля - 8 символов' },
                                ({ getFieldValue }) => ({
                                    validator(obj, value) {
                                        if (!value || getFieldValue('password1') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Введенные пароли не совпадают'));
                                    },
                                }),
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className={_.elParamWrapper}
                        >
                            <Input.Password maxLength={64} className={_.elParam} />
                        </Form.Item>

                        <Form.Item>
                            <Button style={{ marginTop: 20 }} width='400px' htmlType='submit'>
                                Изменить пароль
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default ResetPassConfirmPage;
