import { useState } from 'react';

import { Modal } from 'antd';

import AskForm from 'common/AskForm/AskForm';
import Button from 'common/Button/Button';
import Container from 'common/StyledElements/Container/Container';
import pic from 'assets/camera.png';
import Title from 'common/StyledElements/Title/Title';
import Text from 'common/StyledElements/Text/Text';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import InfoElements, { IStatistic } from './components/InfoElements/InfoElements';

import _ from './WorkStart.module.css';

export interface IHeroBanner {
    codename: string;
    title: string;
    background: string;
    description: string;
}

interface IWorkStartProps {
    heroBannerData?: IHeroBanner;
    statisticsData: IStatistic;
}

const WorkStart = ({ heroBannerData, statisticsData }: IWorkStartProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Container pb={80} bgImage={heroBannerData?.background ?? pic} noRound column>
                <div className={_.containerLeft}>
                    <div className={_.mainText}>{logoReplacer(heroBannerData?.title) || ''}</div>
                    <div className={_.secondaryText}>{logoReplacer(heroBannerData?.description) || ''}</div>
                    <Button
                        marginBottom='95px'
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        Подписаться на новости
                    </Button>
                </div>
                {statisticsData && <InfoElements statisticsData={statisticsData} />}
            </Container>

            <Modal
                open={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                width='700px'
                footer={null}
                centered
            >
                <div className={_.modalInner}>
                    <Title mt={60} family='Manrope'>
                        Подписаться на новости
                    </Title>
                    <Text mt={30} width={460} center>
                        Подпишитесь на наши новости, чтобы быть в курсе актуальных событий и предложений
                    </Text>

                    <AskForm
                        closeModal={() => {
                            setShowModal(false);
                        }}
                        subscriptionForm
                    />
                </div>
            </Modal>
        </>
    );
};

export default WorkStart;
