import React, { FC } from 'react';

import ReactPlayer from 'react-player/lazy';

import _ from './VideoPlayer.module.css';

interface IVideoPlayerProps {
    url: string;
    width?: string;
    height?: string;
    isPlaying?: boolean;
    playerRef?: React.RefObject<ReactPlayer>;
    onPause?: () => void;
    onPlay?: () => void;
    light?: string;
}

const VideoPlayer: FC<IVideoPlayerProps> = ({ url, isPlaying, width, height, playerRef, onPlay, onPause, light }) => (
    <div className={_.videoContainer}>
        <ReactPlayer
            light={light}
            ref={playerRef}
            className={_.video}
            controls
            playing={isPlaying}
            config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload',
                    },
                },
            }}
            playsinline
            width={width}
            height={height}
            url={url}
            onContextMenu={(e: any) => e.preventDefault()}
            onPlay={onPlay} // обработчик события воспроизведения
            onPause={onPause} // обработчик события паузы
        />
    </div>
);

export default VideoPlayer;
