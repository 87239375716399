// @ts-ignore
import Html2Pdf from 'js-html2pdf';

// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import h2p from 'html2pdf.js';
import dayjs from 'dayjs';

/* eslint-disable prefer-const */
const filmFormats: { [key: string]: string } = {
    feature_length: 'Фильм',
    multi_episode: 'Сериал',
    short_film: 'Фильм',
};
const filmViewTypes: { [key: string]: string } = {
    fictional: 'художественный',
    documentary: 'документальный',
    popular_science: 'научно-популярный',
    television: 'телевизионнный',
};
const filmTypes: { [key: string]: string } = {
    game: 'игровой',
    not_game: 'неигровой',
};
const ruMonths = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
];
// конвертим переменные-массивы в строку для переменных-свойств проекта
export const flatProjectVars = (projectVars: any) => {
    if (projectVars === null) return;

    let {
        writers,
        directors,
        genres,
        media_types,
        colors,
        subtitle_languages,
        film_format,
        language,
        additional_language,
        film_view_type,
        film_type,
        certificate_date,
    } = projectVars;
    if (Array.isArray(writers) && writers.length > 0) {
        writers = writers.map((w) => `${w.name || ''} ${w.surname || ''}`).join(', ');
    } else {
        writers = '[Не указано]';
    }
    if (Array.isArray(directors) && directors.length > 0) {
        directors = directors.map((d) => `${d.name || ''} ${d.surname || ''}`).join(', ');
    } else {
        directors = '[Не указано]';
    }
    if (Array.isArray(genres) && genres.length > 0) {
        genres = genres.join(', ');
    } else {
        genres = '[Не указано]';
    }
    if (Array.isArray(media_types) && media_types.length > 0) {
        media_types = media_types.join(', ');
    } else {
        media_types = '[Не указано]';
    }
    if (Array.isArray(colors) && colors.length > 0) {
        colors = colors.join(', ');
    } else {
        colors = '[Не указано]';
    }
    if (Array.isArray(subtitle_languages) && subtitle_languages.length > 0) {
        subtitle_languages = subtitle_languages.join(', ');
    } else {
        subtitle_languages = '';
    }

    language = `${language}${additional_language ? `, ${additional_language}` : ''}`;

    if (typeof film_format === 'string') {
        film_format = filmFormats[film_format] || '[Не указано]';
    } else {
        film_format = '[Не указано]';
    }

    if (typeof film_view_type === 'string') {
        film_view_type = filmViewTypes[film_view_type] || '[Не указано]';
    } else {
        film_view_type = '[Не указано]';
    }
    if (typeof film_type === 'string') {
        film_type = filmTypes[film_type] || '[Не указано]';
    } else {
        film_type = '[Не указано]';
    }

    if (typeof certificate_date === 'string') {
        const certDate = dayjs(certificate_date);
        if (certDate.isValid()) {
            certificate_date = `«${certDate.format('DD')}» ${ruMonths[certDate.month()]} ${certDate.year()} г.`;
        } else {
            certificate_date = '[Не указано]';
        }
    } else {
        certificate_date = '[Не указано]';
    }

    // eslint-disable-next-line no-param-reassign
    return {
        ...projectVars,
        writers,
        directors,
        genres,
        media_types,
        colors,
        subtitle_languages,
        film_format,
        language,
        film_view_type,
        film_type,
        certificate_date,
    };
};

const removeClasses = () => {
    const customTextElements = document.querySelectorAll('.custom-text-color');
    customTextElements.forEach((element) => {
        element.setAttribute('data-original-class', 'custom-text-color');
        element.classList.remove('custom-text-color');
    });

    const emptyValueElements = document.querySelectorAll('.empty-value');
    emptyValueElements.forEach((element) => {
        element.setAttribute('data-original-class', 'empty-value');
        element.classList.remove('empty-value');
    });
};

const restoreClasses = () => {
    const elementsWithOriginalClass = document.querySelectorAll('[data-original-class]');
    elementsWithOriginalClass.forEach((element) => {
        const originalClass = element.getAttribute('data-original-class');
        if (originalClass) {
            element.classList.add(originalClass);
            element.removeAttribute('data-original-class');
        }
    });
};

const addNoBreakClass = () => {
    const allBlocks = document.querySelectorAll('div, p, span, td, th');
    allBlocks.forEach((element) => {
        element.classList.add('no-break');
    });
};

const removeNoBreakClass = () => {
    const allBlocks = document.querySelectorAll('.no-break');
    allBlocks.forEach((element) => {
        element.classList.remove('no-break');
    });
};

export const download = (
    refEditor: React.MutableRefObject<any>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    documentTitle: string,
) => {
    if (refEditor?.current) {
        removeClasses();
        addNoBreakClass();

        
        setLoading(true);
        const byId = refEditor.current;

        const options = {
            margin: [10, 10, 28, 10],
            filename: `${documentTitle}.pdf`,
            html2canvas: {
                scale: 2,
                logging: false,
                dpi: 192,
                letterRendering: true,
            },
            pageBreaks: {
                mode: ['avoid-all', 'css'],
                // before: ['*'],
                // after: [],
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        // @ts-ignore
        options.source = byId;
        // @ts-ignore
        options.download = true;
        console.log('Html2Pdf', Html2Pdf);

        const pdfWorker = h2p()
            .from(byId)
            .set(options)
            .toPdf()
            .get('pdf')
            .then((pdf: any) => {
                const totalPages = pdf.internal.getNumberOfPages();

                // eslint-disable-next-line no-plusplus
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFont('Roboto');
                    pdf.setFontSize(12);
                    pdf.text(`${i}`, pdf.internal.pageSize.getWidth() - 40, pdf.internal.pageSize.getHeight() - 10);
                }
                setLoading(false);
                restoreClasses();
            })
            .save();
    }
};
