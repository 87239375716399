import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'antd/es/modal/Modal';

import EmailIcon from 'assets/email.svg';
import Title from 'common/StyledElements/Title/Title';
import Text from 'common/StyledElements/Text/Text';
import Link from 'common/StyledElements/Link/Link';

import _ from './ConfirmEmailModal.module.css';

interface IConfirmEmailModalShown {
    isShown: boolean;
    toggleShown: (is: boolean) => void;
    resend: () => void;
    email: string;
    title: string;
    text: string;
}

const ConfirmEmailModal: FC<IConfirmEmailModalShown> = ({ isShown, toggleShown, resend, email, title, text }) => {
    const navigate = useNavigate();

    return (
        <Modal
            className={_.modalCustom}
            open={isShown}
            onOk={() => {
                // setIsError(true);
                // setAttempts(3);
            }}
            onCancel={() => {
                toggleShown(false);
                navigate('/auth');
            }}
            width='700px'
            footer={null}
            centered
        >
            <div className={_.wrapper}>
                <div className={_.container}>
                    <img style={{ marginBottom: 30 }} src={EmailIcon} alt='email' />
                    <Title mb={30}>{title}</Title>

                    <Text small>
                        На E-mail <span style={{ textDecoration: 'underline' }}>{email}</span> отправлено письмо.
                    </Text>
                    <Text mb={30} small>
                        {text}
                    </Text>

                    <Link onClick={resend}>Отправить еще раз</Link>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmEmailModal;
