import { APIService } from 'connectors/api/rest';

export interface IDocumentData {
    codename: string;
    name: string;
    content: string;
}

export const Documents = {
    async userAgreement() {
        try {
            return await APIService.GET('/documents/user_agreement/');
        } catch (e) {
            return e;
        }
    },
    async personalDataPolicy() {
        try {
            return await APIService.GET('/documents/personal_data_policy/');
        } catch (e) {
            return e;
        }
    },
    async privacyPolicy() {
        try {
            return await APIService.GET('/documents/privacy_policy/');
        } catch (e) {
            return e;
        }
    },
    async chatAgreement() {
        try {
            return await APIService.GET('/documents/chat_agreement/');
        } catch (e) {
            return e;
        }
    },
    async paymentRules() {
        try {
            return await APIService.GET('/documents/paymen_rules/');
        } catch (e) {
            return e;
        }
    },
    async refund() {
        try {
            return await APIService.GET('/documents/refund/');
        } catch (e) {
            return e;
        }
    },
    async refusal() {
        try {
            return await APIService.GET('/documents/refusal/');
        } catch (e) {
            return e;
        }
    },
};
