import { useRef, useEffect } from 'react';

const Comment = ({ text }: { text: string }) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const comment = document.createComment(text);
        ref?.current?.replaceWith(comment);
    }, [ref]);
    return <div ref={ref} />;
};

export default Comment;
