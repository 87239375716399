import { FC } from 'react';

import pic from 'assets/check.svg';
import Text from 'common/StyledElements/Text/Text';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './Check.module.css';

interface ICheckProps {
    title: string;
    description: string;
}

const Check: FC<ICheckProps> = ({ title, description }) => (
    <div className={_.wrapper}>
        <img className={_.img} src={pic} alt='check' />

        <div className={_.container}>
            <div className={_.title}>{logoReplacer(title)}</div>
            <Text mb={40} mid white2>
                {logoReplacer(description)}
            </Text>
        </div>
    </div>
);

export default Check;
