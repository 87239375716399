import { openErrorNotification } from 'common/Notifications/Notifications';

const fileExtWhiteList = /(\.GIF|\.JPEG|\.JPG|\.PNG|\.DOC|\.DOCX|\.XLS|\.XLSX|\.PPT|\.PPTX|\.PDF|\.TXT|\.RTF)$/i;
const maxFileSize = 5242880;
export const validateFile = (file: File): boolean => {
    if (fileExtWhiteList.test(file.name)) {
        if (file.size <= maxFileSize) {
            return true;
        }
        openErrorNotification('Слишком большой файл', ' Размер файла превышает 5 Мб');
    } else {
        openErrorNotification(
            'Некорректный тип файла',
            'Поддерживаются JPG, JPEG, GIF, PNG, DOC, DOCX, XLS, XLSX, PPT, PPTX, PDF, TXT, RTF',
        );
    }

    return false;
};
