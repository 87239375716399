import { APIService } from 'connectors/api/rest';

export interface IAttachment {
    attachment_url: string;
    attachment_filename: string;
    size: number;
    extension: string;
    description: string;
}

export interface ILectureData {
    id: number;
    published: boolean;
    event_type: string;
    published_at: string;
    title: string;
    description: string;
    mediafile: string;
    preview: string;
    mediafile_preview: string;
    attachments: IAttachment[];
    images: IAttachment[];
}

export const Lecture = {
    async getAll(params: any) {
        try {
            return await APIService.GET('/hotline/lecture/', { published: true, ...params });
        } catch (e) {
            return e;
        }
    },
    async getById(id: string | number) {
        try {
            return await APIService.GET(`/hotline/lecture/${id}`);
        } catch (e) {
            return e;
        }
    },
};
