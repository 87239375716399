import React, { FC } from 'react';

// import { IStatistic } from '../../WorkStart';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './InfoElements.module.css';

export interface IStatistic {
    id: number;
    codename: string;
    title: string;
    entries: { key: string; value: string }[];
    is_shown: boolean;
}

interface IStatisticProps {
    statisticsData: IStatistic;
}

const renderStatisticElement = (entry: { key: string; value: string }) => (
    <div className={_.infoElement}>
        <div className={_.infoElementBigText}>{logoReplacer(entry.value)}</div>
        <div className={_.infoElementSmallText}>{logoReplacer(entry.key)}</div>
    </div>
);

const InfoElements: FC<IStatisticProps> = ({ statisticsData }) => (
    <div className={_.infoContainer}>
        {statisticsData.entries.map((entry, index) => (
            <React.Fragment key={`${index}`}>{renderStatisticElement(entry)}</React.Fragment>
        ))}
    </div>
);

export default InfoElements;
