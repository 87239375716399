import clsx from 'clsx';
import Divider from 'common/StyledElements/Divider/Divider';
import { FC } from 'react';

import _ from './NavbarElement.module.css';

interface INavbarElementProps {
    num: number;
    title: string;
    divider: boolean;
    isActive: boolean;
}

const NavbarElement: FC<INavbarElementProps> = ({ num, title, divider, isActive }) => (
    <div className={_.navbarEl}>
        <div className={clsx(_.circle, isActive && _.stepActive)}>
            <span className={_.circleInner}>{num}</span>
        </div>
        <div className={_.text}>{title}</div>

        {divider && <Divider mb='0' width={70} margin={10} />}
    </div>
);

export default NavbarElement;
