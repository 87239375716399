import { useEffect, useState } from 'react';

import { PersonalAccount, IPerson } from 'connectors/query/PersonalAccount';
import noPeople from 'assets/people.png';
import Header from '../Header/Header';
import Row from './components/Row/Row';

import _ from './People.module.css';

const People = () => {
    const [people, setPeople] = useState<IPerson[]>([]);

    const getPeople = async () => {
        const result = await PersonalAccount.getPeople();

        if (result.count && result.count > 0) {
            if (result.results?.length > 4) result.results.length = 4;

            const ids = result.results.map((person: IPerson) => person.id);

            const resultCommonProjects = await PersonalAccount.getCommonProjects(ids);
            if (resultCommonProjects[ids[0]]) {
                const peopleWithProjects = result.results.map((person: IPerson) => {
                    const projects = resultCommonProjects[person.id];
                    return { ...person, projects };
                });

                setPeople(peopleWithProjects);
            } else {
                setPeople(result.results);
            }
        }
    };

    useEffect(() => {
        getPeople();
    }, []);

    if (people.length === 0) {
        return (
            <div className={_.wrapper}>
                <Header title='Люди' link='Все люди' linkRoute='add' />
                <div className={_.noPeople}>
                    <img className={_.noPeopleImg} src={noPeople} alt='No people' />
                    <p className={_.noPeopleTitle}>Здесь будет список людей, добавленных вами при создании проекта</p>
                </div>
            </div>
        );
    }

    return (
        <div className={_.wrapper}>
            <Header title='Люди' link='Все люди' linkRoute='/cabinet/people' />

            {people.map((e) => (
                <Row key={e.id} {...e} isLast={e === people[people.length - 1]} />
            ))}
        </div>
    );
};

export default People;
