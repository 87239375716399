import { APIService } from 'connectors/api/rest';

export interface ITemplate {
    id: number;
    title: string;
}

export interface ITemplates {
    count: number;
    results: ITemplate[];
}

// Типы для сортировки полей таблицы договоров
export type IContractOrderingType = 'created_at' | 'updated_at' | 'name';

// Типы для сортировки с направлением (возрастание/убывание)
export type IContractOrderingWithDirectionType = IContractOrderingType | `-${IContractOrderingType}`;

// Интерфейс для параметров получения контрактов
export interface IContractGetParams {
    ordering?: IContractOrderingWithDirectionType;
    limit?: number;
    offset?: number;
}

export interface ITemplateFull {
    id: number;
    contract_elements: IContractElement[];
    title: string;
    description: string;
    text_template: string;
}

export interface IContractElement {
    id: number;
    vars_tags: IVar[];
    free_vars_tags: IVarFree[];
    answer?: {
        id: number;
        answer: string;
    } | null;
    question?: {
        id: number;
        question: string;
    } | null;
    title: string;
    description: string;
    element_tag: string;
    text_template: string;
}

export interface IVar {
    id: number;
    var_tag: string;
    description: string;
    display_label: string;
    lookup_model_field: string;
    content_type: number;
    model: 'user' | 'organizationcharacteristic' | 'projectcharacteristic';
}
export interface IVarFree {
    id: number;
    var_tag: string;
    description: string;
    display_label: string;
    system_field: null | 'CONTRACT_NUMBER' | 'CONTRACT_DATE';
}

export interface IQuestions {
    count: number;
    results: IQuestion[];
}
export interface IQuestion {
    id: number;
    question: string;
    answers: {
        id: number;
        answer: string;
    }[];
}
export const ContractDesigner = {
    async getAll(params?: any) {
        try {
            return await APIService.GET('/contract-designer/contract-template/', params);
        } catch (e) {
            return e;
        }
    },
    async getById(id: string | number) {
        try {
            return await APIService.GET(`/contract-designer/contract-template/${id}/`);
        } catch (e) {
            return e;
        }
    },
    async getQuestions() {
        try {
            return await APIService.GET('/contract-designer/question/');
        } catch (e) {
            return e;
        }
    },
    async getVersionProperties(projectId: number | string, contentTypeId: string | number) {
        try {
            return await APIService.GET(`/lk/project/${projectId}/content-type/${contentTypeId}/available-objects/`);
        } catch (e) {
            return e;
        }
    },
    async getContractTemplateTypes() {
        try {
            return await APIService.GET('/contract-designer/contract-template-type/');
        } catch (e) {
            return e;
        }
    },
};
