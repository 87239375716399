import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Modal } from 'antd';

import { Users } from 'connectors/query/Users';
import { openErrorNotification, openSuccessNotification } from 'common/Notifications/Notifications';
import Title from 'common/StyledElements/Title/Title';
import Button from 'common/Button/Button';
import validationRules from 'utils/formValidationRules';

import _ from './ChangePassConfirm.module.css';

const validateMessages = {
    required: 'Обязательное поле',
};

// при переходе на эту страницу видим модалку для обновления пароля
const ChangePassConfirmPage = () => {
    const data = useParams();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [modalChangePass, setModalChangePass] = useState(true);

    const ChangePassConfirm = async (values: any) => {
        if (data.uid && data.token) {
            const res = await Users.changePassConfirm({ uid: data.uid, token: data.token, ...values });
            console.log(res, 'res');

            if (res.status !== 'ok' && res.status !== 200 && res.status !== 201) {
                Object.keys(res?.data).forEach((key) => {
                    if (key === 'detail') {
                        openErrorNotification('Ошибка', res.data.detail);
                    } else if (key === 'non_field_errors') {
                        const errorMessage = res.data.non_field_errors.join(' ');
                        openErrorNotification('Ошибка', errorMessage);
                    } else {
                        form.setFields([{ name: key, errors: res?.data[key] }]);
                    }
                });
            } else {
                openSuccessNotification('Пароль обновлён!');
                navigate('/auth');
            }
        } else {
            openErrorNotification('Некорректная ссылка');
            navigate('/auth');
        }
    };

    return (
        <Modal
            className={_.modalCustom}
            open={modalChangePass}
            onOk={() => {
                setModalChangePass(false);
                navigate('/auth');
            }}
            onCancel={() => {
                setModalChangePass(false);
                navigate('/auth');
            }}
            width='700px'
            footer={null}
            centered
        >
            <div className={_.modalWrapper}>
                <div className={_.modalContainer}>
                    <Title mb={30}>Смена пароля</Title>
                    <Form
                        name='email'
                        className='authForm'
                        form={form}
                        onFinish={ChangePassConfirm}
                        validateMessages={validateMessages}
                    >
                        <Form.Item
                            name={['current_password']}
                            label='Текущий пароль'
                            rules={[{ required: true }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className={_.elParamWrapper}
                        >
                            <Input.Password className={_.elParam} />
                        </Form.Item>

                        <Form.Item
                            name={['password1']}
                            label='Новый пароль'
                            rules={validationRules.passwordRules}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className={_.elParamWrapper}
                        >
                            <Input.Password maxLength={64} className={_.elParam} />
                        </Form.Item>

                        <Form.Item
                            name={['password2']}
                            label='Повторите новый пароль'
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(obj, value) {
                                        if (!value || getFieldValue('password1') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Введенные пароли не совпадают'));
                                    },
                                }),
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className={_.elParamWrapper}
                        >
                            <Input.Password maxLength={64} className={_.elParam} />
                        </Form.Item>

                        <Form.Item>
                            <Button style={{ marginTop: 20 }} width='400px' htmlType='submit'>
                                Изменить пароль
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default ChangePassConfirmPage;
