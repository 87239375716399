import { useState, useContext } from 'react';
import { Form, Input } from 'antd';
import { YMaps, Map, Placemark, Panorama, ZoomControl, GeolocationControl } from '@pbe/react-yandex-maps';
import YandexCaptcha from 'common/YandexCaptcha/YandexCaptcha';
import { useForm } from 'antd/es/form/Form';
import clsx from 'clsx';

import Agreement from 'common/Modals/Agreement/Agreement';
import Header from 'common/Header/Header';
import Container from 'common/StyledElements/Container/Container';
import Footer from 'common/Footer/Footer';
import emailIcon from 'assets/contacts/emailIcon.svg';
import phoneIcon from 'assets/contacts/phoneIcon.svg';
import mapIcon from 'assets/contacts/mapIcon.svg';
import docsIcon from 'assets/contacts/docsIcon.svg';
import Divider from 'common/StyledElements/Divider/Divider';
import Text from 'common/StyledElements/Text/Text';
import Title from 'common/StyledElements/Title/Title';
import { MyFormItem } from 'common/MyFormItem/MyFormItem';
import AuthContext from 'common/AuthProvider/AuthProvider';
import { validateFile } from 'utils/validateMessageFileType';
import Button from 'common/Button/Button';
import { IThread, Messenger } from 'connectors/query/Messenger';
import {
    openErrorNotification,
    openInfoNotification,
    openSuccessNotification,
} from 'common/Notifications/Notifications';
import validationRules from 'utils/formValidationRules';
import FileUploader from 'common/FileUploader/FileUploader';
import { handleInputBlur } from 'common/Helper/onBlur/onBlur';
import Top from './components/Top/Top';

import _ from './ContactsPage.module.css';
import './style.css';

const { TextArea } = Input;

interface IOnSubmitParams {
    registered: boolean;
    title: string;
    message: string;
    first_name: string;
    last_name: string;
    email: string;
    user_id?: number;
    agreement: boolean;
}

const ContactsPage = () => {
    const [form] = useForm();
    const [files, setFiles] = useState<File[]>([]);
    const { userData, hasLoaded } = useContext(AuthContext);
    const [captchaToken, setCaptchaToken] = useState('');
    const [captchaKeyState, setCaptchaKeyState] = useState<number>(0);

    const resetCaptcha = () => setCaptchaKeyState((prev) => prev + 1);

    const onSubmit = async ({
        registered,
        title,
        message,
        first_name,
        last_name,
        email,
        agreement,
    }: IOnSubmitParams) => {
        const result = registered
            ? await Messenger.Threads.create({
                  title,
                  acceptance_user_agreement: agreement,
                  'message.content': message,
                  'message.attachments': files,
              })
            : await Messenger.Threads.create({
                  title,
                  acceptance_user_agreement: agreement,
                  captcha_token: captchaToken,
                  'message.content': message,
                  'message.attachments': files,
                  'message.sender_guest.last_name': last_name,
                  'message.sender_guest.first_name': first_name,
                  'message.sender_guest.email': email,
              });

        resetCaptcha();

        if (result?.status >= 200 && result?.status <= 204) {
            if (registered && result?.id) {
                const idLast = (result as IThread).messages[(result as IThread).messages.length - 1].id;
                Messenger.MessagesNew.setMessageViewed(idLast);
            }

            openSuccessNotification('Сообщение отправлено');
            form.resetFields();
            setFiles([]);
            return;
        }

        if (result?.status === 400) {
            Object.keys(result?.data).forEach((key) => {
                openErrorNotification('Ошибка!', result?.data[key]);
            });
            return;
        }
        if (result?.status >= 300) {
            openErrorNotification('Ошибка отправки сообщения.', 'Попробуйте повторить действие позднее.');
        }
    };

    const customRequest = ({ file }: any) => {
        if (validateFile(file)) {
            setFiles((f) => {
                if (f.length === 5) {
                    openErrorNotification('Превышено максимальное количество файлов', 'Максимум 5 файлов');
                    return [...f];
                }
                return [...f, file];
            });
        }
    };

    const onFinish = (values: any) => {
        if (!captchaToken && !userData?.id) {
            return openInfoNotification('', 'Необходимо нажать на подтверждение "Я не робот"');
        }

        if (!values.agreePerson && !userData && hasLoaded) {
            return openInfoNotification('', 'Подтвердите согласие на обработку персональных данных');
        }

        if (userData) {
            onSubmit({
                registered: true,
                title: 'Страница контактов',
                message: values.content,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email ? userData.email : values.email,
                agreement: true,
            });
        } else {
            onSubmit({
                registered: false,
                title: 'Страница контактов',
                message: values.content,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                agreement: values.agreePerson,
            });
        }
    };

    return (
        <>
            <Header />
            <Top />

            <Container style={{ paddingTop: 50, paddingBottom: 60 }} column>
                <div className={_.wrapper}>
                    <div className={_.contactsBlock}>
                        <div className={_.block}>
                            <img src={emailIcon} alt='email icon' />
                            <Text>E-mail</Text>
                            <Text style={{ marginTop: '15px' }} bold darkBlue>
                                <a href='mailto:info@movie-right.ru' className={_.email}>
                                    info@movie-right.ru
                                </a>
                            </Text>
                        </div>
                        <Divider />
                        <div className={_.block}>
                            <img src={phoneIcon} style={{ marginTop: '15px' }} alt='phone icon' />
                            <Text>Телефон</Text>
                            <Text style={{ fontSize: '20px', marginTop: '15px' }} bold>
                                <a href='tel:+79037230117' className={_.phone}>
                                    +7 (903) 723 01 17
                                </a>
                            </Text>
                        </div>
                        <Divider />
                        <div className={_.block}>
                            <img src={mapIcon} style={{ marginTop: '15px' }} alt='map icon' />
                            <Text>Адрес</Text>
                            <Text style={{ fontSize: '20px', marginTop: '15px' }} bold>
                                127055, г. Москва,
                            </Text>
                            <Text style={{ fontSize: '20px' }} bold>
                                Новолесной пер., 5-26
                            </Text>
                        </div>
                        <Divider />
                        <div className={_.block}>
                            <img src={docsIcon} style={{ marginTop: '15px' }} alt='docs icon' />
                            <Text>Реквизиты</Text>
                            <Text style={{ fontSize: '20px', marginTop: '15px' }} bold>
                                ООО «КОСМИК ФИЛЬМ»
                            </Text>
                            <Text style={{ fontSize: '20px' }} bold>
                                ОГРН 1207700197286
                            </Text>
                            <Text style={{ fontSize: '20px' }} bold>
                                ИНН 9721099539
                            </Text>
                        </div>
                    </div>
                    <div className={clsx(_.writeUsBlock, userData?.id && _.shortWriteUsBlock)}>
                        <Title middle>Напишите нам</Title>
                        <Form
                            form={form}
                            name='new_message_contacts_page'
                            layout='vertical'
                            className={clsx('newMessageContactsPage', _.form)}
                            onFinish={onFinish}
                            requiredMark
                        >
                            {!userData?.id && hasLoaded && (
                                <>
                                    <MyFormItem
                                        className={clsx('newMessageContactsPage-smallError', _.form)}
                                        name='first_name'
                                        label='Имя'
                                        rules={validationRules.nameRules}
                                    >
                                        <Input
                                            className={_.inputInnerShort}
                                            maxLength={64}
                                            onBlur={() => handleInputBlur(form, 'first_name')}
                                        />
                                    </MyFormItem>
                                    <MyFormItem
                                        className={clsx('newMessageContactsPage-smallError', _.form)}
                                        name='last_name'
                                        label='Фамилия'
                                        rules={validationRules.surnameRules}
                                    >
                                        <Input
                                            className={_.inputInnerShort}
                                            maxLength={64}
                                            onBlur={() => handleInputBlur(form, 'last_name')}
                                        />
                                    </MyFormItem>
                                    <MyFormItem
                                        className={_.form}
                                        name='email'
                                        label='E-mail'
                                        rules={validationRules.emailRules}
                                    >
                                        <Input
                                            className={_.inputInner}
                                            maxLength={320}
                                            onBlur={() => handleInputBlur(form, 'email')}
                                        />
                                    </MyFormItem>
                                </>
                            )}
                            <MyFormItem
                                className={clsx('textAreaFormItem', _.form)}
                                name='content'
                                label='Введите сообщение'
                                rules={[{ required: true, message: 'Введите сообщение' }]}
                            >
                                <TextArea className={_.inputInner} maxLength={1024} rows={4} />
                            </MyFormItem>

                            {/* <div className={_.file}>
                                <Upload maxCount={1} customRequest={customRequest} showUploadList={false} multiple>
                                    <img className={_.paperclipIcon} src={paperclipIcon} alt='paperclip icon' />
                                    <ButtonAntd className={_.btn} type='link'>
                                        Прикрепить файл
                                    </ButtonAntd>
                                </Upload>

                                <FileAttachmentInfo
                                    ellipsisWidth={400}
                                    style={{ marginTop: '6px' }}
                                    files={files}
                                    setFiles={setFiles}
                                />
                            </div> */}
                            <FileUploader
                                files={files}
                                setFiles={setFiles}
                                customRequest={customRequest}
                                ellipsisWidth={600}
                            />
                            {!userData?.id && hasLoaded && (
                                <div className={_.agreement}>
                                    <Agreement maxWidth={600} />
                                </div>
                            )}
                            <div className={_.captchaAndButtonContainer}>
                                {!userData?.id && hasLoaded && (
                                    <div className={clsx(_.captchaContainer, 'captchaContainerContactsPage')}>
                                        <YandexCaptcha
                                            setCaptchaToken={setCaptchaToken}
                                            resetCaptchaToken={captchaKeyState}
                                        />
                                    </div>
                                )}

                                <div className={_.buttonContainer}>
                                    <Button htmlType='submit' width='241px'>
                                        Отправить
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className={_.map}>
                    <YMaps>
                        <Map
                            width={1110}
                            height={550}
                            controls
                            defaultState={{ center: [55.77194, 37.587847], zoom: 14 }}
                        >
                            <Placemark
                                options={{ preset: 'islands#redCinemaCircleIcon' }}
                                defaultGeometry={[55.77194, 37.587847]}
                            />
                            <Panorama defaultPoint={[55.77194, 37.587847]} />
                            <ZoomControl options={{ position: { right: '10px', bottom: '270px' }, size: 'small' }} />
                            <GeolocationControl options={{ position: { right: '10px', bottom: '210px' } }} />
                        </Map>
                    </YMaps>
                </div>
            </Container>

            <Footer />
        </>
    );
};

export default ContactsPage;
