import { useRef, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Header from 'common/Header/Header';
import Footer from 'common/Footer/Footer';
import Paykeeper from 'common/Footer/components/Paykeeper/Paykeeper';
import LandingContext from 'common/LandingProvider/LandingProvider';
import WorkStart, { IHeroBanner } from './components/WorkStart/WorkStart';
import { IStatistic } from './components/WorkStart/components/InfoElements/InfoElements';
import Contract from './components/Contract/Contract';
import HotLine from './components/HotLine/HotLine';
import Modules from './components/Modules/Modules';
import Reviews from './components/Reviews/Reviews';
import ServiceNews from './components/ServiceNews/ServiceNews';
import Team from './components/Team/Team';
import Why from './components/Why/Why';

import OurCustomers from './components/OurCustomers/OurCustomers';

const headerOffset = 91;

export enum ScrollPoints {
    EditorPoint = 'contract',
    ReviewsPoint = 'reviews',
    TeamPoint = 'team',
    NewsPoint = 'news',
    ContactsPoint = 'contacts',
}

export interface ILanding {
    id: number;
    codename: string;
    is_shown: boolean;
    description?: string;
    title?: string;
    icon?: string;
    background?: string | undefined;
    first_name?: string;
    last_name?: string;
    position?: string;
    avatar?: string;
    status?: boolean;
    logo_name?: string;
    logo_img?: string;
}

const isScrollPoints = (checkedVal: any): checkedVal is ScrollPoints =>
    Object.values(ScrollPoints).includes(checkedVal as ScrollPoints);

const LandingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const landingContextData = useContext(LandingContext);
    const { codenames, landingData, headerHighlight, setHeaderHighlight } = landingContextData;

    const refs: Record<ScrollPoints, React.RefObject<HTMLDivElement>> = {
        [ScrollPoints.EditorPoint]: useRef<HTMLDivElement>(null),
        [ScrollPoints.ReviewsPoint]: useRef<HTMLDivElement>(null),
        [ScrollPoints.TeamPoint]: useRef<HTMLDivElement>(null),
        [ScrollPoints.NewsPoint]: useRef<HTMLDivElement>(null),
        [ScrollPoints.ContactsPoint]: useRef<HTMLDivElement>(null),
    };

    useEffect(() => {
        window.addEventListener('scroll', removeHeaderHighlight);
        return () => window.removeEventListener('scroll', removeHeaderHighlight);
    }, [window.pageYOffset]);

    useEffect(() => {
        if (codenames.length > 0) {
            const loc = location.state?.scrolling;
            scrollToEl(loc);
            navigate(location.pathname, {});
        }
    }, [codenames]);

    const scrollToEl = (el: any) => {
        if (el && isScrollPoints(el)) {
            const element = refs[el].current;

            if (element !== null) {
                window.scrollTo({
                    behavior: 'smooth',
                    top: element.getBoundingClientRect().top + window.pageYOffset - headerOffset,
                });
            }
        }
    };
    const removeHeaderHighlight = () => {
        if (refs[ScrollPoints.EditorPoint].current?.getBoundingClientRect().top === headerOffset) {
            setHeaderHighlight('contract_builder');
            return;
        }
        if (refs[ScrollPoints.TeamPoint].current?.getBoundingClientRect().top === headerOffset) {
            setHeaderHighlight('team');
            return;
        }
        if (headerHighlight) setHeaderHighlight(false);
    };

    return (
        <div>
            <Header scrollToEl={scrollToEl} />

            {codenames.includes('hero_banner') && (
                <WorkStart
                    heroBannerData={landingData.find((item: any) => item.codename === 'hero_banner') as IHeroBanner}
                    statisticsData={landingData.find((item: any) => item.codename === 'statistics') as IStatistic}
                />
            )}

            {codenames.includes('why_us') && (
                <Why whyUsData={landingData.find((item: any) => item.codename === 'why_us')} />
            )}

            <div ref={refs[ScrollPoints.EditorPoint]} onScroll={removeHeaderHighlight}>
                {codenames.includes('contract_builder') && (
                    <Contract contractBuilder={landingData.find((item: any) => item.codename === 'contract_builder')} />
                )}
            </div>

            {codenames.includes('pricing_plans') && <Modules />}

            {codenames.includes('hot_line') && (
                <HotLine hotLineData={landingData.find((item: any) => item.codename === 'hot_line')} />
            )}

            <div ref={refs[ScrollPoints.ReviewsPoint]}>
                {codenames.includes('reviews') && (
                    <Reviews reviewsData={landingData.find((item: any) => item.codename === 'reviews')} />
                )}
            </div>

            {codenames.includes('our_customers') && (
                <OurCustomers companyData={landingData.find((item: any) => item.codename === 'our_customers')} />
            )}

            <div ref={refs[ScrollPoints.TeamPoint]}>
                {codenames.includes('team') && (
                    <Team teamData={landingData.find((item: any) => item.codename === 'team')} />
                )}
            </div>

            <div ref={refs[ScrollPoints.NewsPoint]}>{codenames.includes('news') && <ServiceNews />}</div>

            <div>
                <Footer scrollToEl={scrollToEl} mt={0}/>
            </div>
            <Paykeeper />
        </div>
    );
};

export default LandingPage;
