import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './NewsItem.module.css';

interface INewsItemProps {
    date: string;
    title: string;
}

const NewsItem: FC<INewsItemProps> = ({ date, title }) => {
    const fotmatDate = dayjs(date).format('DD.MM.YYYY');
    const navigate = useNavigate();

    return (
        <div className={_.wrapper} onClick={() => navigate('/news')}>
            <div className={_.container}>
                <div className={_.dateWrapper}>
                    <div className={_.date}>{fotmatDate}</div>
                </div>

                <div className={_.text}>{logoReplacer(title)}</div>
            </div>
        </div>
    );
};

export default NewsItem;
