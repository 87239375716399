import { FC } from 'react';
import clsx from 'clsx';

import dayjs from 'dayjs';

import Title from 'common/StyledElements/Title/Title';
import { IUserData } from 'common/AuthProvider/AuthProvider';
import { ITagsData } from 'connectors/query/Tags';
import logoReplacer from 'common/LogoReplacer/LogoReplacer';

import _ from './NewsItem.module.css';

interface INewsItemProps {
    date: string;
    author: string | null;
    created_by: IUserData;
    title: string;
    text: string;
    pic?: null;
    tags?: (ITagsData | undefined)[];
}

const NewsItem: FC<INewsItemProps> = ({ date, title, text, pic, author, created_by, tags }) => {
    const fotmatDate = dayjs(date).format('DD.MM.YYYY');

    return (
        <div className={_.wrapper}>
            <div className={_.half}>
                <div className={_.dateWrapper}>
                    <div className={clsx(_.date, _.additionally)}>{fotmatDate}</div>
                </div>
                <Title width={470} mt={15} wordWrapBW>
                    {logoReplacer(title)}
                </Title>

                <div className={clsx(_.author, _.additionally)}>
                    {(author && logoReplacer(author)) ||
                        (created_by ? `${created_by.last_name} ${created_by.first_name}` : '')}
                </div>
            </div>

            <div className={_.half}>
                {pic && <img className={_.img} src={pic} alt='news-pic' />}

                <div
                    style={{ marginTop: 30 }}
                    dangerouslySetInnerHTML={{ __html: logoReplacer(text, 'dangerouslySetInnerHTML') }}
                />
                {/* <div className={clsx(_.tags, _.additionally)}>
                        {tags ? tags
                            .map(tag => tag?.name)
                            .filter(Boolean)
                            .join(", ") : ""
                        }
                    </div> */}
            </div>
        </div>
    );
};

export default NewsItem;
