import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import bucketIcon from 'assets/statuses/bucket.svg';
import person from 'assets/cabinet/person.png';
import Container from 'common/StyledElements/Container/Container';
import Pagination from 'common/PaginatorMock/PaginatorMock';
import Text from 'common/StyledElements/Text/Text';
import FindInput from 'common/FindInput/FindInput';

import { people, PeopleType } from './mock';
import _ from './Partners.module.css';
import './styles.css';

const columns: ColumnsType<PeopleType> = [
    {
        title: '№',
        dataIndex: 'pos',
        key: 'pos',
        render: (a, b, c) => <Text mid>{c + 1}</Text>,
    },
    {
        title: 'ФИО',
        dataIndex: 'name',
        key: 'name',
        render: (name) => (
            <div className={_.nameWrapper}>
                <img src={person} alt='person' />
                <Text mid>{name}</Text>
            </div>
        ),
    },
    {
        title: 'Проекты, в которых участвует',
        dataIndex: 'projects',
        key: 'projects',
        render: (arr) => (
            <div className={_.list}>
                {arr.map((a: string, i: number) => (
                    <Text key={i} className={_.listEl} mid>
                        {a}
                    </Text>
                ))}
            </div>
        ),
    },
    {
        title: 'Действие',
        dataIndex: 'action',
        key: 'action',
        render: () => (
            <div className={_.bucket}>
                <img src={bucketIcon} alt='bucket' />
            </div>
        ),
    },
];

const Partners = () => (
    <Container style={{ paddingTop: 50 }} column>
        <FindInput placeholder='Поиск по названию' />

        <Table className='peopleTable' columns={columns} dataSource={people} pagination={false} />
        <Pagination />
    </Container>
);

export default Partners;
