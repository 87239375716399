import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';

import AuthPage from 'components/AuthPage/AuthPage';
import RegisterPage from 'components/RegisterPage/RegisterPage';
import LandingPage from 'components/LandingPage/LandingPage';
import NewsPage from 'components/NewsPage/NewsPage';
import FaqPage from 'components/FaqPage/FaqPage';
import CabinetPage from 'components/CabinetPage/CabinetPage';
// import EditorPage from 'components/EditorPage/EditorPage';
import EditorPageNew from 'components/EditorPage2/EditorPage';
import ModulePage from 'components/ModulePage/ModulePage';
import ProjectPage from 'components/ProjectPage/ProjectPage';
import HotLinePage from 'components/HotLinePage/HotLinePage';
import LecturesPage from 'components/LecturesPage/LecturesPage';
import WebinarPage from 'components/WebinarPage/WebinarPage';
import ArticlePage from 'components/ArticlePage/ArticlePage';
import ContactsPage from 'components/ContactsPage/ContactsPage';
import RedirectPage from 'components/RedirectPage/RedirectPage';
import TestPage from 'components/TestPage/TestPage';

import SignUpConfirmPage from 'components/Confirmation/SignupConfirm/SignupConfirm';
import ResetPassConfirmPage from 'components/Confirmation/ResetPassConfirm/ResetPassConfirm';
import DeleteAccountConfirmPage from 'components/Confirmation/DeleteAccountConfirm/DeleteAccountConfirm';
import ChangePassConfirmPage from 'components/Confirmation/ChangePassConfirm/ChangePassConfirm';
import ChangeEmailConfirmPage from 'components/Confirmation/ChangeEmailConfirm/ChangeEmailConfirm';

import { AuthProvider } from 'common/AuthProvider/AuthProvider';
import { LandingProvider } from 'common/LandingProvider/LandingProvider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import './Fonts.css';

function App() {
    return (
        <BrowserRouter>
            <ConfigProvider locale={ruRU}>
                <AuthProvider>
                    <LandingProvider>
                        <Routes>
                            <Route path='/' element={<LandingPage />} />
                            <Route path='/landing' element={<LandingPage />} />
                            {/* PROD */}
                            <Route path='/auth' element={<AuthPage />} />
                            <Route path='/register' element={<RegisterPage />} />
                            <Route path='/news' element={<NewsPage />} />
                            <Route path='/faq' element={<FaqPage />} />
                            <Route path='/lectures' element={<LecturesPage />} />
                            <Route path='/webinar/:id' element={<WebinarPage />} />
                            <Route path='/practice' element={<HotLinePage />} />
                            <Route path='/modules' element={<ModulePage />} />
                            <Route path='/lecture/:id' element={<ArticlePage />} />
                            {/* <Route path='/editor' element={<EditorPage />} /> */}
                            <Route path='/contacts' element={<ContactsPage />} />
                            <Route path='/subsribe-to-practice' element={<RedirectPage />} />
                            <Route path='/test' element={<TestPage />} />
                            <Route path='/cabinet'>
                                <Route index element={<Navigate to='projects' replace />} />
                                {/* {напоминалка навигация в настройки вместо проектов} */}
                                <Route path='projects' element={<CabinetPage />} />
                                <Route path='projects/:id' element={<ProjectPage />} />

                                <Route
                                    path='projects/:projectId/createContract'
                                    element={<EditorPageNew mode='create' />}
                                />
                                <Route
                                    path='projects/:projectId/contracts/:contractId/edit'
                                    element={<EditorPageNew mode='edit' />}
                                />
                                <Route
                                    path='projects/:projectId/contracts/:contractId/view'
                                    element={<EditorPageNew mode='view' />}
                                />

                                <Route path='projects/add' element={<CabinetPage />} />
                                <Route path='projects/:projectId/properties/:propertiesId' element={<CabinetPage />} />
                                <Route
                                    path='projects/:projectId/organizations/:orgId/details/:detailsId'
                                    element={<CabinetPage />}
                                />
                                <Route path='organizations/:orgId/details/:detailsId' element={<CabinetPage />} />
                                <Route path='settings' element={<CabinetPage />} />
                                <Route path='people' element={<CabinetPage />} />
                                {/* <Route path='partners' element={<CabinetPage />} /> */}
                                <Route path='messages' element={<CabinetPage />} />
                                <Route path='contragents' element={<CabinetPage />} />
                                <Route path='contragent/add' element={<CabinetPage />} />
                                <Route path='contragent/:id' element={<CabinetPage />} />
                                <Route path='correspondence/:id' element={<CabinetPage />} />
                                <Route path='create-message' element={<CabinetPage />} />
                                <Route path='create' element={<CabinetPage />} />
                                <Route path='edit-project/:id' element={<CabinetPage />} />
                                <Route path='project-properties/:id' element={<CabinetPage />} />
                                <Route path='payment' element={<CabinetPage />} />
                                <Route path='orders' element={<CabinetPage />} />
                                <Route path='*' element={<Navigate to='projects' replace />} />
                            </Route>
                            <Route path='/confirm'>
                                <Route path='signup/:uid/:token' element={<SignUpConfirmPage />} />
                                <Route path='reset_password/:uid/:token' element={<ResetPassConfirmPage />} />
                                <Route path='delete_account/:uid/:token' element={<DeleteAccountConfirmPage />} />
                                <Route path='change_password/:uid/:token' element={<ChangePassConfirmPage />} />
                                <Route path='change_email/:uid/:token' element={<ChangeEmailConfirmPage />} />
                            </Route>
                            <Route path='*' element={<Navigate to='/' replace />} />
                        </Routes>
                    </LandingProvider>
                </AuthProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
}

export default App;
