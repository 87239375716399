import { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { IMessage } from 'connectors/query/Messenger';
import { Users } from 'connectors/query/Users';
import whiteArrow from 'assets/correspondence/left-bottom-arrow-white.svg';
import greyArrow from 'assets/correspondence/right-top-arrow-grey.svg';
import CorrespondenceFileAttachment from '../FileAttachment/CorrespondenceFileAttachment';

import _ from './MessageBox.module.css';

interface IMessageBox {
    title?: string;
    message: IMessage | null | undefined;
    type: 'incoming' | 'outgoing';
}
const MessageBox: FC<IMessageBox> = ({ message, title, type }) => {
    const [author, setAuthor] = useState('...');

    const getName = async () => {
        if (message) {
            if (message.sender_user) {
                const senderData = await Users.getUser(message.sender_user);
                senderData.last_name &&
                    senderData.first_name &&
                    setAuthor(`${senderData.last_name} ${senderData.first_name}`);
                return;
            }
            setAuthor(`Анонимный пользователь`);
        }
    };

    useEffect(() => {
        getName();
    }, [message]);

    return (
        <div className={clsx(_.inner, type === 'outgoing' && _.outgoing)}>
            {type === 'incoming' && <img className={_.whiteArrow} src={whiteArrow} alt='message box arrow' />}
            {type === 'outgoing' && <img className={_.greyArrow} src={greyArrow} alt='message box arrow' />}

            {title && <div className={_.title}>{title || '...'}</div>}

            <div className={_.mainText}>{message?.content || '...'}</div>

            <div className={_.author}>
                <div title={author} className={_.authorName}>
                    {author}
                </div>
                {message?.created_at && (
                    <div className={clsx(_.authorDataTime, type === 'outgoing' && _.outgoing)}>
                        {format(new Date(message.created_at), 'dd.MM.yyyy HH:mm')}
                    </div>
                )}
                {message?.attachments && (
                    <div className={_.attachments}>
                        {message.attachments.map((att) => (
                            <CorrespondenceFileAttachment file={att.attachment} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MessageBox;
