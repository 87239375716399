interface ITabNames {
    [key: string]: string;
}

interface ITabNamesRegExp {
    [key: string]: RegExp;
}

const blackList: ITabNamesRegExp = {
    '/cabinet/contragent/:id': /\/cabinet\/contragent\/\d+/,
    '/cabinet/projects/:id': /\/cabinet\/projects\/\d+/,
};

const completeMatch: ITabNames = {
    '/auth': 'Авторизация',
    '/register': 'Регистрация',
    '/news': 'Блог MovieRight',
    '/faq': 'Q&A',
    '/webinar': 'Вебинары и лекции',
    '/practice': 'КИНО И ПРАВО',
    '/editor': 'Конструктор договоров',
    '/cabinet': 'Личный кабинет',
    '/contacts': 'Контакты',
    '/cabinet/projects': 'ЛК: Проекты',
    '/cabinet/projects/add': 'ЛК: Проекты',
    '/cabinet/settings': 'ЛК: Настройки аккаунта',
    '/cabinet/people': 'ЛК: Люди',
    '/cabinet/messages': 'ЛК: Сообщения',
    '/cabinet/create-message': 'ЛК: Написать сообщение',
    '/cabinet/create': 'ЛК: Новый проект',
    '/modules': 'Цены',
    '/cabinet/contragents': 'ЛК: Контрагенты',
    '/cabinet/payment': 'ЛК: Оплата',
    '/cabinet/orders': 'ЛК: Заказы',
};
const overlap: ITabNames = {
    '/cabinet/correspondence': 'ЛК: Сообщения',
    '/confirm/signup': 'Регистрация',
    '/confirm/reset_password': 'Сброс пароля',
    '/confirm/delete_account': 'Удаление аккаунта',
    '/confirm/change_password': 'Изменение пароля',
    '/confirm/change_email': 'Изменение почты',
};
const defaultTabName = 'Movie Right';

const setTabName = (path: string) => {
    const checkBlackList = Object.values(blackList).some((value) => {
        if (RegExp(value).test(path)) {
            return true;
        }
        return false;
    });
    if (checkBlackList) return;

    if (completeMatch[path]) {
        document.title = completeMatch[path];
        return;
    }

    Object.keys(overlap).forEach((key) => {
        if (path.startsWith(key)) {
            document.title = overlap[key];
        }
    });

    document.title = defaultTabName;
};

export default setTabName;
