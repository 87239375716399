import { FC } from 'react';
import { Popover, Tooltip } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import clsx from 'clsx';

import BucketIcon from 'assets/icons/bucketIcon.svg';
import EditIcon from 'assets/icons/editIcon.svg';
import ReadOnly from 'assets/icons/readOnly.svg';
import CopyIcon from 'assets/icons/copyIcon.svg';
import InfoIcon from 'assets/icons/infoIcon.svg';
import Icon from 'common/Icon/Icon';
import Text from 'common/StyledElements/Text/Text';
import SimpleStatus, { ESimpleStatuses } from 'common/SimpleStatus/SimpleStatus';
import PersonAvatar from 'common/PersonAvatar/PersonAvatar';
import { IContract, IPerson, IProjectProperties } from 'connectors/query/PersonalAccount';

import Divider from 'common/StyledElements/Divider/Divider';
import { IProjectCustomerDetails } from 'connectors/query/Organizations';
import { openErrorNotification, openInfoNotification } from 'common/Notifications/Notifications';
import _ from './ProjectPage.module.css';

interface IContent {
    projectId: string | undefined;
    contractId: number;
    copyContract: (projectId: string, contractId: string | number) => void;
}
const Content: FC<IContent> = ({ projectId, contractId, copyContract }) => (
    <div className={_.popoperContent}>
        <div
            className={_.popoverContentBtn}
            onClick={() => {
                if (projectId) copyContract(projectId, contractId);
            }}
        >
            Скопировать в проект
        </div>
        <div className={_.popoverContentBtn}>Скопировать в другой проект</div>
    </div>
);

const removeCreatedBy = (differences: any[]) =>
    differences ? differences.filter((diff) => diff !== 'Создано (кем)') : [];

interface IInfoContent {
    contractDate?: string;
    contractNumber?: string | number;
    projectPropertiesVersionDate?: string;
    projectPropertiesVersionChanges?: string[];
    customerDetailsVersionDate?: string;
    customerDetailsVersionChanges?: string[];
    contractType?: string;
}
const InfoContent: FC<IInfoContent> = ({
    contractType,
    contractDate,
    contractNumber,
    projectPropertiesVersionDate,
    projectPropertiesVersionChanges,
    customerDetailsVersionDate,
    customerDetailsVersionChanges,
}) => (
    <div className={_.infoPopoperContent}>
        {/* <div className={_.infoPopoverRow}>
            <div className={_.infoPopoverRowName}>
                <Text small bold>
                    Дата договора
                </Text>
            </div>
            <div className={_.infoPopoverRowData}>
                <Text small lightGray>
                    {contractDate}
                </Text>
            </div>
        </div> */}
        {/* <div className={_.infoPopoverRow}>
            <div className={_.infoPopoverRowName}>
                <Text small bold>
                    id договора
                </Text>
            </div>
            <div className={_.infoPopoverRowData}>
                <Text small lightGray>
                    {contractNumber}
                </Text>
            </div>
        </div> */}
        {/* <Divider /> */}
        {contractType && (
            <>
                <div className={_.infoPopoverRow}>
                    <div className={_.infoPopoverRowName}>
                        <Text small bold>
                            Тип договора
                        </Text>
                    </div>
                    <div className={_.infoPopoverRowData}>
                        <Text small lightGray>
                            {contractType}
                        </Text>
                    </div>
                </div>
                <Divider />
            </>
        )}
        <div className={_.infoPopoverRow}>
            <div className={_.infoPopoverRowName}>
                <Text small bold>
                    Версия характеристик проекта
                </Text>
            </div>
            <div className={_.infoPopoverRowData}>
                <Text small lightGray>
                    {projectPropertiesVersionDate}
                </Text>
            </div>
        </div>
        {/* <div className={_.infoPopoverRow}>
            <div className={_.infoPopoverRowName}>
                <Text small bold>
                    Изменения
                </Text>
            </div>
            <div className={_.infoPopoverRowData}>
                <Text small lightGray>
                    {projectPropertiesVersionChanges?.join(', ') || '-'}
                </Text>
            </div>
        </div> */}
        <Divider />
        <div className={_.infoPopoverRow}>
            <div className={_.infoPopoverRowName}>
                <Text small bold>
                    Версия реквизитов заказчика
                </Text>
            </div>
            <div className={_.infoPopoverRowData}>
                <Text small lightGray>
                    {customerDetailsVersionDate}
                </Text>
            </div>
        </div>
        {/* <div className={_.infoPopoverRow} style={{ marginBottom: 0 }}>
            <div className={_.infoPopoverRowName}>
                <Text small bold>
                    Изменения
                </Text>
            </div>
            <div className={_.infoPopoverRowData}>
                <Text small lightGray>
                    {customerDetailsVersionChanges?.join(', ') || '-'}
                </Text>
            </div>
        </div> */}
    </div>
);

type ColumnsTypeContragents = (
    projectId: string | undefined,
    openDeleteContractModal: (contract: IContract) => void,
    openCopyModal: () => void,
    navigate: NavigateFunction,
    setSelectedContract: React.Dispatch<React.SetStateAction<IContract | null>>,
    isActiveProject: boolean,
    sortedInfo: { columnKey?: string; order?: 'ascend' | 'descend' },
) => ColumnsType<IContract>;

export const columnsDocuments: ColumnsTypeContragents = (
    projectId,
    openDeleteContractModal,
    openCopyModal,
    navigate,
    setSelectedContract,
    isActiveProject,
    sortedInfo,
) => [
    {
        title: <Text bold>Дата создания</Text>,
        dataIndex: 'created_at',
        key: 'created_at',
        width: 300,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : undefined,
        render: (created_at) => <Text mid>{dayjs(created_at).format('DD.MM.YYYY')}</Text>,
    },
    // {
    //     title: <Text bold>Дата изменения</Text>,
    //     dataIndex: 'updated_at',
    //     key: 'updated_at',
    //     width: 300,
    //     sorter: true,
    //     sortOrder: sortedInfo.columnKey === 'updated_at' ? sortedInfo.order : undefined,
    //     render: (updated_at) => <Text mid>{dayjs(updated_at).format('DD.MM.YYYY')}</Text>,
    // },
    {
        title: <Text bold>Дата договора</Text>,
        dataIndex: 'contract_date',
        key: 'contract_date',
        // width: 300,
        // sorter: true,
        // sortOrder: sortedInfo.columnKey === 'contract_date' ? sortedInfo.order : undefined,
        render: (contract_date) => <Text mid>{contract_date || '—'}</Text>,
    },
    {
        title: <Text bold>Номер договора</Text>,
        dataIndex: 'contract_number',
        key: 'contract_number',
        width: 300,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'contract_number' ? sortedInfo.order : undefined,
        render: (contract_number) => <Text mid>{contract_number || '—'}</Text>,
    },
    {
        title: <Text bold>Наименование документа</Text>,
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : undefined,
        width: 300,
        render: (name) => <Text mid>{name}</Text>,
    },
    // {
    //     title: <Text bold>Создатель документа</Text>,
    //     dataIndex: 'created_by',
    //     key: 'created_by',
    //     sorter: false,
    //     width: 300,
    //     render: (created_by) => (
    //         <Text mid>
    //             {created_by?.last_name} {created_by?.first_name}
    //         </Text>
    //     ),
    // },
    {
        title: <Text bold>Контрагент</Text>,
        dataIndex: 'organization_characteristic',
        key: 'organization_characteristic',
        sorter: false,
        width: 300,
        render: (organization_characteristic) => (
            <Text mid>{organization_characteristic?.name ? organization_characteristic?.name : '—'}</Text>
        ),
    },

    // {
    //     title: <Text bold>Контрагент</Text>,
    //     dataIndex: 'counterparty',
    //     key: 'counterparty',
    //     // eslint-disable-next-line no-nested-ternary
    //     sorter: () => 0,
    //     sortDirections: ['descend', 'ascend'],
    //     render: (counterparty) => (
    //         <div>
    //             <Text mid>{counterparty}</Text>
    //         </div>
    //     ),
    // },
    {
        title: ' ',
        dataIndex: 'btns',
        key: 'btns',
        width: 350,
        render: (skip, contract) => (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Icon
                    className={_.icon}
                    onClick={() => {
                        navigate(`/cabinet/projects/${projectId}/contracts/${contract.id}/view`);
                    }}
                    title='Просмотр'
                >
                    <img src={ReadOnly} alt='view' />
                </Icon>

                <Icon
                    className={clsx(_.icon)}
                    onClick={() => {
                        if (!isActiveProject)
                            return openErrorNotification('Для редактирования необходимо оплатить подписку');
                        navigate(`/cabinet/projects/${projectId}/contracts/${contract.id}/edit`);
                    }}
                    title='Редактировать'
                >
                    <img className={clsx(!isActiveProject && _.faded)} src={EditIcon} alt='edit' />
                </Icon>
                <Icon
                    className={clsx(_.icon)}
                    onClick={() => {
                        if (!isActiveProject)
                            return openErrorNotification('Для копирования необходимо оплатить подписку');
                        openCopyModal();
                        setSelectedContract(contract);
                    }}
                    title='Скопировать в другой проект'
                >
                    <img className={clsx(!isActiveProject && _.faded)} src={CopyIcon} alt='copy' />
                </Icon>

                <Icon
                    onClick={() => {
                        if (!isActiveProject) return openErrorNotification('Для удаления необходимо оплатить подписку');
                        openDeleteContractModal(contract);
                    }}
                    title='Удалить'
                >
                    <img className={clsx(!isActiveProject && _.faded)} src={BucketIcon} alt='bucket' />
                </Icon>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 42,
                        placeContent: 'center',
                        margin: '0px 10px 0px 10px',
                    }}
                >
                    <SimpleStatus type={contract.status === 'DN' ? ESimpleStatuses.Ready : ESimpleStatuses.Wip} />
                </div>
                <Popover
                    overlayStyle={{ border: '2px solid #166DF9' }}
                    overlayInnerStyle={{ padding: 20 }}
                    trigger='click'
                    content={
                        <InfoContent
                            contractType={contract?.contract_template_type?.name}
                            contractDate={
                                contract.contract_date ? dayjs(contract.contract_date).format('DD.MM.YYYY') : '-'
                            }
                            contractNumber={contract.id}
                            projectPropertiesVersionDate={
                                contract.project_characteristic?.version_name
                                    ? contract.project_characteristic?.version_name
                                    : '-'
                            }
                            projectPropertiesVersionChanges={contract.project_characteristic?.version_difference}
                            customerDetailsVersionDate={
                                contract.organization_characteristic?.version_name
                                    ? contract.organization_characteristic?.version_name
                                    : '-'
                            }
                            customerDetailsVersionChanges={contract.organization_characteristic?.version_difference}
                        />
                    }
                    arrow={false}
                    placement='topRight'
                >
                    <div style={{ marginRight: 0, width: 42 }}>
                        <Icon className={_.icon} round title='Подробнее'>
                            <img src={InfoIcon} alt='info' />
                        </Icon>
                    </div>
                </Popover>
            </div>
        ),
    },
];

type ColumnsUsersList = (
    navigate: (path: string) => void,
    openRemoveModal: (name: string, email: string) => void,
    ownerId: number | undefined,
) => ColumnsType<IPerson>;

export const columnsUsers: ColumnsUsersList = (navigate, openRemoveModal, ownerId) => [
    {
        title: (
            <div className={_.smallColumn}>
                <Text bold>№</Text>
            </div>
        ),
        dataIndex: 'num',
        key: 'num',
        width: 50,
        render: (a, b, num) => <Text mid>{num + 1}</Text>,
    },
    {
        title: <Text bold>ФИО</Text>,
        dataIndex: 'name',
        key: 'name',
        width: 400,
        sorter: (aObj, bObj) => {
            const a = `${aObj.last_name || ''} ${aObj.first_name || ''} ${aObj.patronymic || ''}`;
            const b = `${bObj.last_name || ''} ${bObj.first_name || ''} ${bObj.patronymic || ''}`;
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        render: (a, { first_name, last_name, photo, patronymic }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PersonAvatar firstName={first_name} lastName={last_name} photo={photo} mr={20} />
                <Text mid>
                    {last_name || ''} {first_name || ''} {patronymic || ''}
                </Text>
            </div>
        ),
    },
    {
        title: <Text bold>Email</Text>,
        dataIndex: 'email',
        key: 'email',
        width: 300,
        // eslint-disable-next-line no-nested-ternary
        sorter: ({ email: a }, { email: b }) => (a > b ? 1 : a < b ? -1 : 0),
        sortDirections: ['descend', 'ascend'],
        render: (email) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text mid>{email || ''}</Text>
            </div>
        ),
    },
    {
        title: <Text bold>Статус</Text>,
        dataIndex: 'status',
        key: 'status',
        sorter: (aa, bb) => {
            const a = aa.id === ownerId ? 'Владелец' : 'Участник';
            const b = bb.id === ownerId ? 'Владелец' : 'Участник';
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        render: (status, record) => {
            if (record.id === ownerId) return <Text>Владелец</Text>;
            return <Text>Участник</Text>;
        },
    },
    {
        title: <Text bold>Действие</Text>,
        dataIndex: 'btns',
        key: 'btns',
        render: (btns, record) => (
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <div
                    onClick={() => {
                        if (record.id === ownerId) return openInfoNotification('Нельзя удалить владельца.');

                        openRemoveModal(`${record.first_name} ${record.last_name}`, record.email);
                    }}
                >
                    <Icon title='Удалить'>
                        <img className={clsx(record.id === ownerId && _.faded)} src={BucketIcon} alt='bucket' />
                    </Icon>
                </div>
            </div>
        ),
    },
];

export const columnsProperties: (
    openModal: (id: number) => void,
    projectId: string | undefined,
    navigate: (path: string) => void,
    isActiveProject: boolean,
) => ColumnsType<IProjectProperties> = (openModal, projectId, navigate, isActiveProject) => [
    {
        title: <Text bold>Название версии</Text>,
        dataIndex: 'version_name',
        key: 'version_name',
        width: 500,
        sorter: (aa, bb) => {
            const a = aa.version_name || '';
            const b = bb.version_name || '';
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        render: (version_name) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text bold mid>
                    {version_name || ''}
                </Text>
            </div>
        ),
    },
    {
        title: <Text bold>Дата</Text>,
        dataIndex: 'updated_at',
        key: 'updated_at',
        // eslint-disable-next-line no-nested-ternary
        sorter: ({ updated_at: a }, { updated_at: b }) => (a > b ? 1 : a < b ? -1 : 0),
        sortDirections: ['descend', 'ascend'],
        width: 200,
        render: (updated_at) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text mid>{dayjs(updated_at).format('DD.MM.YYYY')}</Text>
            </div>
        ),
    },
    {
        title: <Text bold>Изменения</Text>,
        dataIndex: 'version_difference',
        key: 'version_difference',
        sorter: (aa, bb) => {
            const a = aa.version_difference?.length || 0;
            const b = bb.version_difference?.length || 0;
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        width: 400,
        render: (version_difference) => <Text mid>{removeCreatedBy(version_difference).join(', ')}</Text>,
    },
    {
        title: ' ',
        dataIndex: 'btns',
        key: 'btns',
        align: 'right',
        render: (btns, record) => (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Icon
                    className={_.icon}
                    onClick={() => {
                        if (projectId && record?.id)
                            navigate(`/cabinet/projects/${projectId}/properties/${record.id}/`);
                    }}
                    title={isActiveProject ? 'Редактировать' : 'Просмотр'}
                >
                    <img
                        src={isActiveProject ? EditIcon : ReadOnly}
                        alt={isActiveProject ? 'edit' : 'read-only'}
                        className={clsx(!isActiveProject)}
                    />
                </Icon>
                <Icon
                    className={clsx(_.icon, _.mr0)}
                    onClick={() => {
                        if (isActiveProject) {
                            openModal(record.id);
                        } else {
                            openInfoNotification('Для копирования необходимо оплатить подписку.');
                        }
                    }}
                    title='Копировать'
                >
                    <img className={clsx(!isActiveProject && _.faded)} src={CopyIcon} alt='copy' />
                </Icon>
            </div>
        ),
    },
];

const getOrgVersionDifference = (orgType: string, diffs: string[]) => {
    const diffsCopy = [...diffs];
    if (orgType === 'IP' || orgType === 'FL') {
        const diffsAddressFixed = diffsCopy.map((diff) => {
            switch (diff) {
                case 'Юридический адрес':
                    return 'Адрес регистрации';
                case 'Физический адрес':
                    return 'Фактический адрес';
                case 'Адрес для корреспонденции':
                    return 'Почтовый адрес';
                default:
                    return diff.length > 0 ? diff.charAt(0).toUpperCase() + diff.slice(1) : diff;
            }
        });

        if (orgType === 'FL') {
            return diffsAddressFixed.filter((diff) => diff !== 'Название' && diff !== 'Полное название');
        }
        return diffsAddressFixed;
    }
    return diffs;
};

export const columnsCustomerDetails: (
    openModal: (id: number) => void,
    orgId: string | number | undefined,
    projectId: string | number | undefined,
    navigate: (path: string) => void,
    isActiveProject: boolean,
) => ColumnsType<IProjectCustomerDetails> = (openModal, orgId, projectId, navigate, isActiveProject) => [
    {
        title: <Text bold>Название версии</Text>,
        dataIndex: 'version_name',
        key: 'version_name',
        width: 500,
        sorter: (aa, bb) => {
            const a = aa.version_name || '';
            const b = bb.version_name || '';
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        render: (version_name) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text bold mid>
                    {version_name || ''}
                </Text>
            </div>
        ),
    },
    {
        title: <Text bold>Дата</Text>,
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: 200,
        // eslint-disable-next-line no-nested-ternary
        sorter: ({ updated_at: a }, { updated_at: b }) => (a > b ? 1 : a < b ? -1 : 0),
        sortDirections: ['descend', 'ascend'],
        render: (updated_at) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text mid>{dayjs(updated_at).format('DD.MM.YYYY')}</Text>
            </div>
        ),
    },
    {
        title: <Text bold>Изменения</Text>,
        width: 400,
        dataIndex: 'version_difference',
        key: 'version_difference',
        sorter: (aa, bb) => {
            const a = aa.version_difference?.length || 0;
            const b = bb.version_difference?.length || 0;
            // eslint-disable-next-line no-nested-ternary
            return a > b ? 1 : a < b ? -1 : 0;
        },
        sortDirections: ['descend', 'ascend'],
        render: (version_difference: string[], record) => (
            <Text mid>
                {version_difference
                    ? getOrgVersionDifference(record.organization_type, removeCreatedBy(version_difference)).join(', ')
                    : ''}
            </Text>
        ),
    },
    {
        title: ' ',
        dataIndex: 'btns',
        key: 'btns',
        align: 'right',
        render: (btns, record) => (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Icon
                    className={_.icon}
                    onClick={() => {
                        if (orgId && record?.id && projectId)
                            navigate(`/cabinet/projects/${projectId}/organizations/${orgId}/details/${record.id}/`);
                    }}
                    title={isActiveProject ? 'Редактировать' : 'Просмотр'}
                >
                    <img
                        src={isActiveProject ? EditIcon : ReadOnly}
                        alt={isActiveProject ? 'edit' : 'read-only'}
                        className={clsx(!isActiveProject)}
                    />
                </Icon>
                <Icon
                    className={clsx(_.icon, _.mr0)}
                    onClick={() => {
                        if (isActiveProject) {
                            openModal(record.id);
                        } else {
                            openInfoNotification('Для копирования необходимо оплатить подписку.');
                        }
                    }}
                    title='Копировать'
                >
                    <img className={clsx(!isActiveProject && _.faded)} src={CopyIcon} alt='copy' />
                </Icon>
            </div>
        ),
    },
];
