import logoReplacer from 'common/LogoReplacer/LogoReplacer';
import _ from './Top.module.css';

const Top = () => (
    <div className={_.wrapper}>
        <div className={_.text}>
            {logoReplacer('Блог [[movieright-dark]]')}
            {/* Блог <span className={_.blueText}>Movie</span>Right */}
        </div>
    </div>
);

export default Top;
