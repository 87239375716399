import { APIService } from 'connectors/api/rest';

interface IAuthData {
    email: string;
    password: string;
    login?: string;
}

export interface ISignUpData {
    email: string;
    password1: string;
    password2: string;
    first_name: string;
    last_name: string;
    patronymic?: string;
    phone: string;
    acceptance_user_agreement?: boolean;
    consent_pdata_processing?: boolean;
    customer_profile?: {
        // phone: string;
    };
}

interface IResetPass {
    email: string;
}

interface IResetPassConfirm {
    uid: string;
    token: string;
    password1: string;
    password2: string;
}

interface ISignupConfirm {
    uid: string;
    token: string;
}

export const Auth = {
    async login(params: IAuthData) {
        const params2: IAuthData = { ...params, email: params.email.toLowerCase() };
        try {
            return await APIService.POST('/auth/login/', params2, { dontCheck401: true });
        } catch (e) {
            return e;
        }
    },
    async logout() {
        try {
            return await APIService.POST('/auth/logout/');
        } catch (e) {
            return e;
        }
    },
    async signup(params: ISignUpData) {
        const params2: ISignUpData = { ...params, email: params.email.toLowerCase() };
        try {
            return await APIService.POST('/auth/signup/', params2);
        } catch (e) {
            return e;
        }
    },
    async resendSignupRequest(params: { email: string }) {
        const params2 = { email: params.email.toLowerCase() };
        try {
            return await APIService.POST('/auth/resend_signup_request/', params2);
        } catch (e) {
            return e;
        }
    },
    async signupConfirm(params: ISignupConfirm) {
        try {
            return await APIService.POST('/auth/signup_confirm/', params);
        } catch (e) {
            return e;
        }
    },
    async passwordReset(params: IResetPass) {
        const params2: IResetPass = { ...params, email: params.email.toLowerCase() };
        try {
            return await APIService.POST('/auth/reset_password_request/', params2);
        } catch (e) {
            return e;
        }
    },
    async passwordResetConfirm(params: IResetPassConfirm) {
        try {
            return await APIService.POST('/auth/reset_password_confirm/', params);
        } catch (e) {
            return e;
        }
    },
};
